import React from 'react'
import { Typography } from '@mui/material'

import { ProgressIndicator } from '../components/ProgressIndicator'

export const LoadingIndicator = React.memo(
  function LoadingIndicator_({ loadingMessage }) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <ProgressIndicator />
        <Typography
          variant="span"
          style={{
            marginTop: '1em'
          }}>
          {loadingMessage}
        </Typography>
      </div>
    )
  },
  (prev, next) => prev.loadingMessage === next.loadingMessage
)
