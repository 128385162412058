/**
 *
 * "All Reports page for viewing and interact All Reports table."
 *
 * @file   AllReportsPage.js
 * @author Lateral
 * @since  2023
 */

import { Box, Typography } from '@mui/material'
import observeReports from 'hooks/database/observeReports'
import React, { useContext } from 'react'
import AllReportsTable from './components/allReports/AllReportsTable'
import { reportTypes } from 'pages/reporting/common/reportTypes'
import { ReportingContext } from 'components/reportingContext/ReportingContext'
import { useNavigate } from 'react-router-dom'

function AllReportsPage() {
  /**
   * Generates All Report page to view on desktop sized screen
   *
   * @function
   *
   * @returns {object} - All Report page
   */
  const { reports } = observeReports()
  const navigate = useNavigate()
  const { setExistingReport, setPathValue } = useContext(ReportingContext)

  //editing involves moving page, which means the tabs need updating via setPathValue()
  function onEdit(context) {
    const report = reports.find((r) => r.id === context.id)

    setExistingReport(report)

    if (report.Type === reportTypes.PreShut) {
      navigate('/reporting/pre-shut')
      setPathValue(0)
    } else if (report.Type === reportTypes.PostShut) {
      navigate('/reporting/post-shut')
      setPathValue(1)
    } else if (report.Type === reportTypes.Parts) {
      navigate('/reporting/parts')
      setPathValue(2)
    }
  }

  return (
    <Box sx={{ height: '82%' }}>
      <Typography variant="h4" component="h4" sx={{ margin: '1em 0 0.5em' }}>
        All Reports
      </Typography>

      <Box sx={{ height: '90%', width: '100%' }}>
        <AllReportsTable reports={reports} onEdit={onEdit} />
      </Box>
    </Box>
  )
}
export default AllReportsPage
