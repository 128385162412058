/**
 *
 * "All Reports table for Dialogue page"
 *
 * @file   AllReportsTable.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { getObjectComparator } from 'common'
import EnhancedTableHead from 'components/tables/EnhancedTableHead'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  tablePaginationClasses
} from '@mui/material'
import dayjs from 'dayjs'
import { ContextMenu, SCOPES, ScopeRequirementAll } from 'components'
import { useCurrentUser } from 'hooks'

// Headings of table
const headCells = [
  {
    id: 'Revision',
    numeric: false,
    disablePadding: false,
    label: 'Revision'
  },
  {
    id: 'Type',
    numeric: false,
    disablePadding: false,
    label: 'Type'
  },
  {
    id: 'Title',
    numeric: false,
    disablePadding: false,
    label: 'Title'
  },
  {
    id: 'DateOfVisit',
    numeric: false,
    disablePadding: false,
    label: 'Date'
  },
  {
    id: 'Company',
    numeric: false,
    disablePadding: false,
    label: 'Company'
  },
  {
    id: 'Site',
    numeric: false,
    disablePadding: false,
    label: 'Site'
  }
]

export function AllReportsTable({ reports, onEdit }) {
  /**
   * Table for viewing all Reporting history.
   *
   * @function
   *
   * @param {object[]} reports - Array of report objectsObject with report components
   * @param {string} report.type - Report types Pre-Shut Report/ Post-Shut Report/ Parts Report
   * @param {string} report.Company - Name of the company
   * @param {string} report.Site - Name of the site
   * @param {string} report.DateOfVisit - Date of reporting visit
   * @param {number} report.Revision - Report revison number
   * @param {} onEdit - Action on clicking edit menu
   *
   * @returns {object} - All report table
   */
  const theme = useTheme()
  const { currentUser } = useCurrentUser()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('DateOfVisit')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)

  const availableSites = currentUser.getSitesForScope(SCOPES.reporting.Read)
  const filteredReports = currentUser.isAdmin ? reports : reports.filter((r) => availableSites.includes(r.SiteId))

  const cellSize = { width: '20%' }

  // Sort table by clicking property
  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <Table stickyHeader sx={{ minWidth: 750, borderCollapse: 'collapse' }} size="small">
          <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {filteredReports &&
              filteredReports
                .sort((a, b) => a.Revision - b.Revision)
                .sort(getObjectComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((report) => {
                  return (
                    <TableRow key={report?.id} hover tabIndex={-1} sx={{ height: '4em' }}>
                      <TableCell>{report?.Revision ?? '0'}</TableCell>
                      <TableCell sx={cellSize}>{report?.Type ?? '--'}</TableCell>
                      <TableCell sx={cellSize}>{report?.Title ?? '--'}</TableCell>
                      <TableCell sx={cellSize}>{dayjs(report?.DateOfVisit).format('DD/MM/YYYY') ?? '--'}</TableCell>
                      <TableCell sx={cellSize}>{report?.Company ?? '--'}</TableCell>
                      <TableCell sx={cellSize}>{report?.Site ?? '--'}</TableCell>
                      <TableCell sx={{ width: '0' }}>
                        <ScopeRequirementAll requirements={[SCOPES.reporting.Write]} siteId={report?.SiteId}>
                          <ContextMenu context={report} menuItems={[{ title: 'Edit', action: () => onEdit(report) }]} />
                        </ScopeRequirementAll>
                      </TableCell>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={reports.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          [`& .${tablePaginationClasses.spacer}`]: {
            display: 'none'
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            justifyContent: 'center',
            '& *': {
              color: `${theme.palette.secondary.main}`
            }
          }
        }}
      />
    </>
  )
}
export default AllReportsTable
