/**
 *
 * "Generates dropdown menu for reporting pages"
 *
 * @file   DropdownInput.js
 * @author Lateral
 * @since  2023
 */
import { Grid, MenuItem, Select, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import React from 'react'

export function DropdownInput({ name, options, onDropDownChange, control, errors, required = false }) {
  /**
   * Generates dropdown menu to use in reporting pages
   *
   * @function
   *
   * @param {string} name - Name of the menu
   * @param {object[]} options - Array of the menu items
   * @param {} onDropDownChange - Action on changing selection
   * @param {object} control - Register object
   * @param {object} errors - Errors to generate the menu
   * @param {boolean} required - If the menu is a required field
   *
   * @returns {object} - A dropdown menu
   */
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
        <Typography variant="h5" component="h5">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
        <Controller
          control={control}
          name={name}
          rules={{ required: required }}
          render={({ field: { onChange, ...otherFields } }) => (
            <Select
              id={name}
              onChange={(e) => {
                onChange(e)
                if (onDropDownChange) {
                  onDropDownChange(e, name)
                }
              }}
              sx={{ width: '50%' }}
              {...otherFields}>
              {options.map((m) => {
                return (
                  <MenuItem key={m.id} value={m.id}>
                    {m.Name}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        />

        {errors[name] && <p>This field is required</p>}
      </Grid>
    </Grid>
  )
}
