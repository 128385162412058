/**
 *
 * "A ini menu with 'Edit details' and 'Delete dialogue otion'."
 *
 * @file   DialogueContextMenu.js
 * @author Lateral
 * @since  2023
 */

import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export function DialogueContextMenu({ anchorEl, context, handleClose, handleEvent }) {
  /**
   * Creates a mini menu with 'Edit Details' and 'Delete Dialogue' options.
   *
   * @function
   *
   * @param {object} anchorEl - The anchor buttons of mini menu
   * @param {object} context - The dialogue context
   * @param {} handleClose - Action on closing menu
   * @param {} handleEvent - Action on selecting option
   *
   * @returns {object} - A mini menu
   */
  const handleClick = (eventName) => {
    handleClose()
    handleEvent(eventName, context)
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={true}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem onClick={() => handleClick('edit')}>Edit Details</MenuItem>
      <MenuItem onClick={() => handleClick('delete')}>Delete Dialogue</MenuItem>
    </Menu>
  )
}
export default DialogueContextMenu
