/**
 *
 * "Report types used in all Reporting pages."
 *
 * @file   AllReportsTable.js
 * @author Lateral
 * @since  2023
 */
export const reportTypes = {
  PostShut: 'Post-Shut Report',
  PreShut: 'Pre-Shut Report',
  Parts: 'Parts Report'
}
