/**
 *
 * "Funtion for angle convertion"
 *
 * @file   angles.js
 * @author Lateral
 * @since  2023
 */

import BigNumber from 'bignumber.js'

export const deg2Rad = (degrees, decimalPlaces = 2) => {
  /**
   * Convert angles from degrees to radians
   *
   * @function
   * @param {number} degrees - Angle in degree
   * @param {number} decimalPlaces - Maximum decimal places after conversion
   *
   * @returns {numner} - Angle in radian
   */
  const bigDegrees = new BigNumber(degrees)
  return bigDegrees.times(Math.PI / 180).toFixed(decimalPlaces, 0)
}
export default deg2Rad
