/**
 *
 * "Generates Recent Maintenance Jobs box with maintanenance reports for viewing and selecting
 * to generate post shut report."
 *
 *
 * @file   MaintenanceJobs.js
 * @author Lateral
 * @since  2023
 */

import { Box, Checkbox, Stack, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { recentJobsName } from 'pages/reporting/common/formNames'
import DeckModel from 'components/mobileDeckCanvas/components/DeckModel/DeckModel'
import { DeckMode } from 'common/deckMode'
import { dateFormat } from 'common/dates'
import { LoadingButton } from '@mui/lab'

function MaintenanceJobs({ deckRevisions, deckRevisionHistories, control, fields, isVisible, watchSite, isLoading }) {
  /**
   * Component for viewing and selecting Maintenances for a Post Shut Report.
   * Also renders hidden canvases that are revealed during the rendering process."
   *
   * @function
   *
   * @param {object[]} deckRevisions - array of deck revisions
   * @param {object[]} deckRevisionHistories - array of deck maintenance objects
   * @param {object} control - Register object
   * @param {object[]} fields - Array of post shut report filds to match with deck revisions
   * @param {number} isVisible - Number of visible revisions
   * @param {string} watchSite - Selected site ID
   *
   * @returns {object} - A box component for viewing and selecting Maintenances
   */
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          padding: '1em 1em 2em 1em',
          height: '100%',
          marginTop: '0.5em',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        {isVisible ? (
          <Stack spacing={3}>
            {fields.map((f, index) => {
              const deckRevisionHistory = deckRevisionHistories.find((d) => d.id === f.HistoryId)
              const deckRevision = deckRevisions.find((d) => d.id === deckRevisionHistory.RevisionId)
              return (
                <Stack
                  key={f.id}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ borderBottom: `1px solid ${theme.palette.primary.contrastText}` }}>
                  <Controller
                    control={control}
                    name={`${recentJobsName}.${index}.Include`}
                    render={({ field: { value, onChange, ...field } }) => (
                      <Checkbox
                        checked={value}
                        size="small"
                        onChange={onChange}
                        sx={{ color: `${theme.palette.supporting.highlight}` }}
                        {...field}
                      />
                    )}
                  />

                  <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Typography sx={{ float: 'left' }}>{f.Name}</Typography>
                    <Typography sx={{ float: 'right', opacity: '50%' }}>{`Date Performed: ${dayjs(
                      f.DatePerformed
                    ).format(dateFormat)}`}</Typography>
                  </Stack>

                  <Box id={`${f.HistoryId}`} sx={{ padding: '1em' }} hidden>
                    <DeckModel
                      data={deckRevision}
                      maintenance={deckRevisionHistory}
                      mode={DeckMode.postMaintenance}
                      selected={[]}
                      presentation={true}
                    />
                  </Box>

                  <Box id={`${f.HistoryId}-pre`} sx={{ padding: '1em' }} hidden>
                    <DeckModel
                      data={deckRevision}
                      maintenance={deckRevisionHistory}
                      mode={DeckMode.preMaintenance}
                      selected={[]}
                      presentation={true}
                    />
                  </Box>

                  <Box id={`${f.HistoryId}-default`} sx={{ padding: '1em' }} hidden>
                    <DeckModel data={deckRevision} mode={DeckMode.default} selected={[]} presentation={true} />
                  </Box>
                </Stack>
              )
            })}
          </Stack>
        ) : (
          <Box>
            <Typography>
              {watchSite ? 'No maintenance carried out during this timeframe.' : 'Please select a Company and Site.'}
            </Typography>
          </Box>
        )}
      </Box>
      {isVisible ? (
        <Box sx={{ textAlign: 'end' }}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="secondary"
            mb={1}
            sx={{ padding: '0.5em 1em' }}>
            Generate Report
          </LoadingButton>
        </Box>
      ) : null}
    </>
  )
}
export default MaintenanceJobs
