/**
 *
 * "Generates the panels as group of 3D cube objects calculated from deck revisions data from database"
 *
 * @file   DeckPanels.js
 * @author Lateral
 * @since  2023
 */
import { Cube } from '../Cube/Cube'
import BigNumber from 'bignumber.js'
import React from 'react'
import { DeckMode } from 'common/deckMode'
import { useMaintenance } from 'hooks'
import { getWearColour, pickHeatColour } from 'common/heatColours'

export function DeckPanels({
  data,
  maintenance,
  heatMapData,
  materialSelected,
  onPanelClick,
  position,
  selected,
  theme,
  emptyTexture,
  deckMode
}) {
  /**
   * Provides group of 3D cube objects calculated from deck rivion data.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {number} materialSelected - Material number
   * @param {Function} onPanelClick - Action on clicking panel
   * @param {number} position - 3D array, position of the panels
   * @param {object[]} selected - Array of selected panel objects
   * @param {Function} onSelectChange - Action on changing panel selection
   * @param {object} theme - Panel theme object
   * @param {object} emptyTexture - Image object with default texture for panels
   * @param {string} deckMode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @returns {object} - React group of 3D cubes
   */
  const kits = data.Kits
  const size = data.Size
  const emptyColour = theme.palette.primary.dark
  const { actions, getAction } = useMaintenance()

  let panels = [...Array(size.Rows)].map(() => Array(size.Columns))
  data.Panels.map((p) => {
    return (panels[p.Position.Row][p.Position.Column] = p)
  })

  let totalYCoordinate = new BigNumber(0)

  return (
    <group position={position}>
      {panels.map((row, i) => {
        let totalXCoordinate = new BigNumber(-row.length).dividedBy(2)
        let previousCol = 0
        const yCoordinate = totalYCoordinate
        totalYCoordinate = totalYCoordinate.minus(1)
        return row.map((col, j) => {
          const kit = kits.find((k) => k.MaterialNumber === col?.MaterialNumber)
          let colour = deckMode.includes(DeckMode.maintenance) ? emptyColour : kit?.Colour ?? emptyColour
          const panelSize = kit?.Size
          const isMaterialSelected = materialSelected === col?.MaterialNumber
          const isSelected =
            selected.filter(
              (s) =>
                s.MaterialNumber == col?.MaterialNumber &&
                s.Position?.Column === col?.Position?.Column &&
                s.Position?.Row === col?.Position?.Row
            ).length > 0
          totalXCoordinate = totalXCoordinate.plus(j).minus(previousCol)
          previousCol = j
          const xCoordinate = totalXCoordinate
          const height = new BigNumber(panelSize?.Height ?? 1)
          const width = new BigNumber(panelSize?.Width ?? 1)

          let texture = emptyTexture

          //if in maintenance mode, change colours
          if (deckMode.includes(DeckMode.maintenance) && maintenance) {
            const column = new BigNumber(col.Position.Column)
            const row = new BigNumber(col.Position.Row)
            const detail = maintenance.Details?.find(
              (d) => column.isEqualTo(d.Panel?.Position.Column) && row.isEqualTo(d.Panel?.Position.Row)
            )

            if (detail) {
              const maintenanceAction = getAction(detail.HistoryAction)
              if (deckMode === DeckMode.maintenance || deckMode === DeckMode.postMaintenance) {
                texture = maintenanceAction.texture
              }

              if (
                (deckMode === DeckMode.maintenance && maintenanceAction.id === actions.NoChange.id) ||
                (deckMode === DeckMode.preMaintenance &&
                  (detail.WorkingDepth || detail.WorkingDepth === 0) &&
                  (detail.ApertureWidth || detail.ApertureWidth === 0))
              ) {
                colour = getWearColour(detail, theme)
              } else if (deckMode !== DeckMode.preMaintenance && maintenanceAction.id !== actions.NoChange.id) {
                colour = 'white'
              }
            }
            //if in Heatmap mode, also change colours
          } else if (deckMode === DeckMode.heatMap) {
            const panelHeatData = heatMapData.Panels.find(
              (p) => p.Column === col?.Position?.Column && p.Row === col?.Position?.Row
            )

            const wearCount = new BigNumber(panelHeatData?.WearCount ?? 0)
            const total = heatMapData.Total.isEqualTo(0) ? new BigNumber(1) : heatMapData.Total
            const heatCount = total.minus(wearCount).dividedBy(total).times(100).toNumber()
            colour = pickHeatColour(heatCount, theme)
          }

          return (
            <Cube
              key={`panel-${i}-${j}`}
              colour={colour}
              selectedColour={theme.palette.secondary.main}
              data={col}
              position={[
                xCoordinate.plus(width.minus(1).dividedBy(2)).toNumber(),
                yCoordinate.minus(height.minus(1).dividedBy(2)).toNumber(),
                0
              ]}
              size={[width.toNumber(), height.toNumber(), 0.25]}
              selected={isSelected}
              materialSelected={isMaterialSelected}
              texture={texture}
              onPanelClick={onPanelClick}
            />
          )
        })
      })}
    </group>
  )
}
