import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { Customers } from 'models'

/*
 * Observable for the Customers table.
 */
export function observeCustomers() {
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    const retrieveData = async () => {
      const customers = await DataStore.query(Customers, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
      setCustomers(customers)
    }

    const subscription = DataStore.observe(Customers).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return customers
}

export default observeCustomers
