/**
 *
 * "CRUD (Create, read, update, and delete) for Decks table"
 *
 * @file   deckOperations.js
 * @author Lateral
 * @since  2023
 */

import { DataStore } from 'aws-amplify'
import { Decks } from 'models'

export async function createDeck(deck, createdBy) {
  /**
   * Create deck
   *
   * @function
   * @param {object} deck - Deck defined in models/index
   * @returns {object} - Varification of created deck record
   */
  return await DataStore.save(
    new Decks({
      CustomerId: deck.CustomerId,
      LocationId: deck.LocationId,
      ScreenId: deck.ScreenId,
      SiteId: deck.SiteId,
      DeckHeader: deck.DeckHeader,
      LastUpdatedBy: createdBy
    })
  )
}

export async function updateDeck(deck, id, updatedBy) {
  /**
   * update deck
   *
   * @function
   * @param {object} deck - Deck defined in models/index
   * @param {number} id - Id of the deck to update
   */
  const original = await DataStore.query(Decks, id)
  await DataStore.save(
    Decks.copyOf(original, (updated) => {
      updated.CustomerId = deck.CustomerId
      updated.LocationId = deck.LocationId
      updated.ScreenId = deck.ScreenId
      updated.SiteId = deck.SiteId
      updated.DeckHeader = deck.DeckHeader
      updated.LastUpdatedBy = updatedBy
    })
  )
}
