/**
 *
 * "Component for adding/updating various Revision types."
 * Revisions can either be
 * a proposal (not published),
 * a trial (theoretical publish)
 * or a Published.
 *
 *
 * @file   CreateRevision.js
 * @author Lateral
 * @since  2023
 */
import { Button } from '@mui/material'
import { DataStore } from 'aws-amplify'
import { FormDialog, useNotification } from 'components'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { DeckRevisions, KitsItems, KitSizeItems } from 'models'
import React, { useState } from 'react'
import { createFirstRevision, createRevision, updateRevision } from 'data/revisionOperations'
import ConfirmForm from 'pages/deck/common/ConfirmForm'
import { useCSVDownloader, useCSVReader } from 'react-papaparse'
import BigNumber from 'bignumber.js'
import { useCurrentUser } from 'hooks'
import { updateDeck } from 'data/deckOperations'
import { useLocation } from 'react-router-dom'
import ArchiveForm from 'pages/deck/common/ArchiveForm'

function CreateRevision({ deck }) {
  /**
   * Generates components and buttons to add or update revisions
   *
   * @function
   *
   * @param {object} deck - Selected deck object
   *
   * @returns {object} - Revision related components on edit deck page
   */
  const defaultModalState = { isOpen: false, title: '', buttonText: '', data: null }
  const { setDeckRevisionId, deckRevision, setDeckRevision, history, database } = useCurrentDeckContext()
  const { notify } = useNotification()
  const { CSVReader } = useCSVReader()
  const { CSVDownloader } = useCSVDownloader()
  const { getUserName, getTransactionMetaData } = useCurrentUser()
  const { state } = useLocation()
  const [editingOld, setEditingOld] = useState(state?.isEditingOld ?? false)

  const isFirst =
    deckRevision.RevisionNumber === 0 && deckRevision.MinorRevisionNumber === 0 && !deckRevision.IsPublished

  const proposalText = deckRevision?.IsPublished
    ? 'Create Proposal'
    : deckRevision?.IsTrial
    ? 'Update Trial'
    : 'Update Proposal'
  const trialText = deckRevision?.IsTrial && !deckRevision?.IsPublished ? 'Publish Trial' : 'Create Trial'
  const csvConfig = {
    header: true,
    skipEmptyLines: true
  }
  const buttonStyling = {
    margin: '0 0.5em 0 0.5em'
  }

  const maxRevision = Math.max(
    ...database.deckRevisions.filter((d) => d.DeckId === deck.id).map((d) => d.RevisionNumber)
  )

  const [proposalModalState, setProposalModalState] = useState(defaultModalState)
  const [trialModalState, setTrialModalState] = useState(defaultModalState)
  const [publishModalState, setPublishModalState] = useState(defaultModalState)
  const [updateHistoryModalState, setUpdateHistoryModalState] = useState(defaultModalState)
  const [archiveModalState, setArchiveModalState] = useState(defaultModalState)

  //Generates modal form for proposal revision
  async function onProposal(modalState) {
    if (modalState?.isSave) {
      deckRevision.IsPublished = false
      deckRevision.Description = modalState.data.Description
      deckRevision.CustomerId = deck.CustomerId
      deckRevision.SiteId = deck.SiteId
      deckRevision.CreatedBy = getUserName()
      const existing = await DataStore.query(DeckRevisions, deckRevision.id)
      if (existing && !existing.IsPublished) {
        const updatedRevision = await updateRevision(existing, deckRevision, getTransactionMetaData())
        setDeckRevision(structuredClone(updatedRevision))
        setDeckRevisionId(updatedRevision.id)
        notify('Deck updated')
      } else {
        deckRevision.MinorRevisionNumber = new BigNumber(
          database?.deckRevisions?.filter(
            (d) => d.DeckId === deckRevision.DeckId && d.RevisionNumber === deckRevision.RevisionNumber
          ).length ?? 1
        ).toNumber()
        const newRevision = await createRevision(deckRevision, getTransactionMetaData())
        setDeckRevision(structuredClone(newRevision))
        setDeckRevisionId(newRevision.id)
        notify('Deck updated.')
      }

      history.clearHistory()
    }
    setProposalModalState(defaultModalState)
  }

  //Generates modal form for trial revision
  async function onTrial(modalState) {
    if (modalState?.isSave) {
      deckRevision.IsTrial = true
      deckRevision.CreatedBy = getUserName()
      deckRevision.CustomerId = deck.CustomerId
      deckRevision.SiteId = deck.SiteId
      deckRevision.Description = modalState.data.Description
      if (!isFirst) {
        deckRevision.RevisionNumber = new BigNumber(deckRevision.RevisionNumber).plus(1).toNumber()
        deckRevision.MinorRevisionNumber = new BigNumber(
          database?.deckRevisions?.filter(
            (d) => d.DeckId === deckRevision.DeckId && d.RevisionNumber === deckRevision.RevisionNumber
          ).length ?? 0
        ).toNumber()
      }
      const existing = await DataStore.query(DeckRevisions, deckRevision.id)

      if (existing && !existing.IsPublished) {
        if (existing.IsTrial) {
          deckRevision.IsPublished = true
          deckRevision.IsTrial = false
        }
        const updatedRevision = await updateRevision(existing, deckRevision, getTransactionMetaData())
        setDeckRevision(structuredClone(updatedRevision))
        setDeckRevisionId(updatedRevision.id)
        notify('Deck updated.')
      } else {
        deckRevision.IsPublished = false
        const newRevision = await createRevision(deckRevision, getTransactionMetaData())
        setDeckRevision(structuredClone(newRevision))
        setDeckRevisionId(newRevision.id)
        notify('Deck updated.')
      }
      history.clearHistory()
    }
    setTrialModalState(defaultModalState)
  }

  //Generates modal form for published revision
  async function onPublish(modalState) {
    if (modalState?.isSave) {
      const isPublished = deckRevision.IsPublished

      //if not a trial, and not the very first revision
      if (!deckRevision.IsTrial && !isFirst) {
        deckRevision.RevisionNumber = new BigNumber(deckRevision.RevisionNumber).plus(1).toNumber()
      }

      deckRevision.MinorRevisionNumber = 0
      deckRevision.IsPublished = true
      deckRevision.IsTrial = false
      deckRevision.Description = modalState.data.Description
      deckRevision.CreatedBy = getUserName()
      deckRevision.CustomerId = deck.CustomerId
      deckRevision.SiteId = deck.SiteId

      const existing = await DataStore.query(DeckRevisions, deckRevision.id)
      let result = {}

      if (existing && !isPublished) {
        result = await updateRevision(existing, deckRevision, getTransactionMetaData())
      } else {
        result = await createRevision(deckRevision, getTransactionMetaData())
      }

      setDeckRevision(structuredClone(result))
      setDeckRevisionId(result.id)
      updateDeckIfSizeChange(deckRevision)
      notify('Deck updated.')
      history.clearHistory()
    }
    setPublishModalState(defaultModalState)
  }

  //Action on update revision
  async function onUpdateHistory(modalState) {
    if (modalState?.isSave) {
      deckRevision.Description = modalState.data.Description
      deckRevision.CreatedBy = getUserName()
      deckRevision.CustomerId = deck.CustomerId
      deckRevision.SiteId = deck.SiteId
      const existing = await DataStore.query(DeckRevisions, deckRevision.id)
      const updatedRevision = await updateRevision(existing, deckRevision, getTransactionMetaData())
      setDeckRevision(structuredClone(updatedRevision))
      notify('Deck updated.')
      history.clearHistory()
    }
    setUpdateHistoryModalState(defaultModalState)
  }

  //archives the revision
  async function onArchive(modalState) {
    if (modalState?.isSave) {
      const existing = await DataStore.query(DeckRevisions, deckRevision.id)
      const clone = structuredClone(deckRevision)
      clone.IsArchived = true
      await updateRevision(existing, clone, getTransactionMetaData())
      notify('Revision archived.')
      setArchiveModalState(defaultModalState)
      const revisionToFind = existing.IsTrial ? existing.RevisionNumber - 1 : existing.RevisionNumber
      const previousRevisions = database.deckRevisions
        .filter(
          (d) => d.DeckId === deck.id && d.RevisionNumber === revisionToFind && !d.IsArchived && d.id !== existing.id
        )
        .sort((a, b) => b.MinorRevisionNumber - a.MinorRevisionNumber)

      if (previousRevisions.length) {
        const previousClone = structuredClone(previousRevisions[0])
        setDeckRevision(previousClone)
      }
    } else {
      setArchiveModalState(defaultModalState)
    }
  }

  //Action on update deck size
  async function updateDeckIfSizeChange(deckRevision) {
    if (
      deckRevision.Size.Columns !== deck.DeckHeader.DeckSize.Columns ||
      deckRevision.Size.Rows !== deck.DeckHeader.DeckSize.Rows
    ) {
      let clone = structuredClone(deck)
      clone.DeckHeader.DeckSize.Columns = deckRevision.Size.Columns
      clone.DeckHeader.DeckSize.Rows = deckRevision.Size.Rows
      await updateDeck(clone, deck.id, getTransactionMetaData())
    }
  }

  //Action on new revision
  async function onNewRevision() {
    const newRevision = await createFirstRevision(deck, getTransactionMetaData())
    setDeckRevision(structuredClone(newRevision))
    setDeckRevisionId(newRevision.id)
  }

  //Uses data from CSV import to set Kits.
  function importKits(results) {
    results.data.forEach((result) => {
      if (!deckRevision.Kits.find((k) => k.MaterialNumber === result.SandvikMaterialNumber)) {
        const description = result.MaterialDescription ?? result.Description
        deckRevision.Kits.push(
          new KitsItems({
            Quantity: Number(result.Quantity ?? 0),
            PartNumber: result.PartNumber,
            CustomerStockCode: result.CustomerStockCode ?? result.Code,
            OpenArea: result.OpenArea,
            MaterialNumber: result.SandvikMaterialNumber ? result.SandvikMaterialNumber : description,
            MaterialType: Number(result.MaterialType),
            MaterialDescription: result.SandvikMaterialNumber ? description : '',
            Colour: result.Colour,
            Weight: result.Weight,
            WorkingDepth: result.WorkingDepth,
            FailureWorkingDepth: result.FailureWorkingDepth,
            ApertureWidth: result.ApertureWidth,
            FailureApertureWidth: result.FailureApertureWidth,
            Size: new KitSizeItems({
              Height: Number(result.Height ?? 1),
              Width: Number(result.Width ?? 1)
            })
          })
        )
      }
    })

    if (results.errors.length) {
      notify('Error when importing. Some data was not imported.')
    } else {
      notify('Kits successfully imported.')
    }

    setDeckRevision(structuredClone(deckRevision))
    setDeckRevisionId(deckRevision.id)
  }

  // Export kits data in csv
  function getKitDataForExport() {
    return deckRevision.Kits.map((k) => {
      const isOther =
        !deckRevision.Panels.some((p) => p.MaterialNumber === k.MaterialNumber) &&
        !deckRevision.SideLiners.some((s) => s.MaterialNumber === k.MaterialNumber)
      return {
        Quantity: isOther
          ? k.Quantity
          : new BigNumber(deckRevision.Panels.filter((p) => p.MaterialNumber === k.MaterialNumber).length)
              .plus(deckRevision.SideLiners.filter((s) => s.MaterialNumber === k.MaterialNumber).length)
              .toNumber(),
        SandvikMaterialNumber: k.MaterialDescription ? k.MaterialNumber : '',
        Description: k.MaterialDescription ? k.MaterialDescription : k.MaterialNumber,
        PartNumber: k.PartNumber,
        Code: k.CustomerStockCode,
        Weight: k.Weight,
        Price: k.UnitPrice,
        OpenArea: k.OpenArea,
        WorkingDepth: k.WorkingDepth,
        FailureWorkingDepth: k.FailureWorkingDepth,
        ApertureWidth: k.ApertureWidth,
        FailureApertureWidth: k.FailureApertureWidth,
        MaterialType: k.MaterialType,
        Colour: k.Colour,
        Height: k.Size?.Height ?? 1,
        Width: k.Size?.Width ?? 1
      }
    })
  }

  // Action on click publish button
  function onSetPublish() {
    if (
      deckRevision.Panels.filter((p) => !p.MaterialNumber).length > 0 ||
      deckRevision.SideLiners.filter((s) => !s.MaterialNumber).length > 0
    ) {
      notify('Items missing from Deck!')
    } else {
      setPublishModalState({
        isOpen: true,
        title: 'Confirm Revision',
        buttonText: 'Create',
        data: null
      })
    }
  }

  // Action on click Edit latest button
  function onEditLatest() {
    const revision = database.deckRevisions.find((d) => d.DeckId === deck.id && d.RevisionNumber === maxRevision)
    setDeckRevision(structuredClone(revision))
    setEditingOld(false)
  }

  return (
    <>
      {deckRevision && Object.entries(deckRevision).length ? (
        <>
          {!deckRevision.Kits || !Object.entries(deckRevision.Kits).length ? (
            <CSVReader config={csvConfig} onUploadAccepted={(results) => importKits(results)}>
              {({ getRootProps }) => (
                <Button {...getRootProps()} variant="outlined" color="secondary" sx={buttonStyling}>
                  Import Kits
                </Button>
              )}
            </CSVReader>
          ) : (
            <CSVDownloader
              bom
              filename={`${deck.DeckHeader.DeckLevel}-${deck.DeckHeader.MaterialNumber.replace('.', '-')}`}
              data={getKitDataForExport}>
              <Button variant="outlined" color="secondary" sx={buttonStyling}>
                Export Kits
              </Button>
            </CSVDownloader>
          )}
          {editingOld ? (
            <>
              <Button variant="outlined" color="secondary" onClick={onEditLatest} sx={buttonStyling}>
                Edit Latest
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  setUpdateHistoryModalState({
                    isOpen: true,
                    title: 'Confirm Update',
                    buttonText: 'Update',
                    data: null
                  })
                }
                sx={buttonStyling}>
                Update History
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  setProposalModalState({
                    isOpen: true,
                    title: deckRevision.IsTrial ? 'Confirm Update Trial' : 'Confirm Proposal',
                    buttonText: deckRevision.IsPublished ? 'Create' : 'Update',
                    data: deckRevision.IsPublished ? null : deckRevision
                  })
                }
                sx={buttonStyling}>
                {proposalText}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  setTrialModalState({
                    isOpen: true,
                    title: deckRevision.IsTrial ? 'Publish Trial Deck' : 'Confirm Trial Deck',
                    buttonText: deckRevision.IsTrial ? 'Publish' : 'Create',
                    data: deckRevision.IsTrial && !deckRevision.IsPublished ? deckRevision : null
                  })
                }
                sx={buttonStyling}>
                {trialText}
              </Button>
              <Button variant="outlined" color="secondary" onClick={onSetPublish} sx={buttonStyling}>
                Publish Revision
              </Button>
              {deckRevision?.IsPublished ? (
                false
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    setArchiveModalState({
                      isOpen: true,
                      title: deckRevision.IsTrial ? 'Archive Trial' : 'Archive Proposal',
                      buttonText: 'Archive',
                      data: deckRevision
                    })
                  }
                  sx={buttonStyling}>
                  {deckRevision.IsTrial ? 'Archive Trial' : 'Archive Proposal'}
                </Button>
              )}
            </>
          )}

          {proposalModalState.isOpen && (
            <FormDialog modalState={proposalModalState} onOpenChange={onProposal}>
              <ConfirmForm name="Proposal" />
            </FormDialog>
          )}

          {trialModalState.isOpen && (
            <FormDialog modalState={trialModalState} onOpenChange={onTrial}>
              <ConfirmForm name="Trial" />
            </FormDialog>
          )}

          {publishModalState.isOpen && (
            <FormDialog modalState={publishModalState} onOpenChange={onPublish}>
              <ConfirmForm name="Revision" />
            </FormDialog>
          )}

          {updateHistoryModalState.isOpen && (
            <FormDialog modalState={updateHistoryModalState} onOpenChange={onUpdateHistory}>
              <ConfirmForm name="Update" />
            </FormDialog>
          )}

          {archiveModalState.isOpen && (
            <FormDialog modalState={archiveModalState} onOpenChange={onArchive}>
              <ArchiveForm isTrial={deckRevision.IsTrial} />
            </FormDialog>
          )}
        </>
      ) : deck && Object.entries(deck).length ? (
        <Button variant="outlined" color="secondary" onClick={onNewRevision} sx={buttonStyling}>
          Create Revision
        </Button>
      ) : null}
    </>
  )
}

export default CreateRevision
