/**
 *
 * "A modal for viewing Update history.""
 *
 * @file   ViewUpdatesModal.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { dateFormat } from 'common/dates'

export function ViewUpdatesModal({ data }) {
  return (
    <Stack spacing={2}>
      {data.map((update) => {
        const date = dayjs(update.Date).format(dateFormat)
        const text = `${update.User} - ${date} - ${update.Description}`
        return <Typography key={text}>{text}</Typography>
      })}
    </Stack>
  )
}
export default ViewUpdatesModal
