import observeRoles from 'hooks/database/observeRoles'
import observeUserRoles from 'hooks/database/observeUserRoles'
import { createContext, useContext } from 'react'
import dayjs from 'dayjs'

/*
 * Hook for getting the current user logged in, and associated information.
 */
const UserContext = createContext()

const useCurrentUser = () => useContext(UserContext)

const UserProvider = ({ currentUser, children }) => {
  const [, userRoles] = observeUserRoles()
  const [, roles] = observeRoles()
  const currentEmail = currentUser.attributes.email

  function getCustomerIds() {
    const customerIds = currentUser?.attributes['customerName']

    //because the old way assumed 1 customer at a time, we attempt the new way (an array) and if fail, return the old as an array
    try {
      const parsed = JSON.parse(customerIds)
      return parsed.filter((c) => c)
    } catch {
      return [customerIds]
    }
  }

  function getSiteIds() {
    const currentSites = currentUser?.attributes['sites']

    try {
      const parsed = JSON.parse(currentSites)
      return parsed.filter((s) => s)
    } catch {
      return []
    }
  }

  const currentCustomerIds = getCustomerIds()
  const isAdmin =
    process.env.REACT_APP_ADMIN_CUSTOMER_ID && currentCustomerIds.includes(process.env.REACT_APP_ADMIN_CUSTOMER_ID)

  function getJWTToken() {
    return currentUser.signInUserSession.accessToken.jwtToken
  }

  function getGroups() {
    const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups']
    return groups
  }
  function getRoles(siteId) {
    const usersGroups = getGroups()
    const ownUserRoles = userRoles
      ?.filter((u) => u.CognitoId === currentUser.attributes.sandvikUserId)
      .map((u) => u.RoleId)

    const ownedRoles = roles?.filter(
      (role) =>
        (!siteId || isAdmin || role.SiteIds?.includes(siteId)) &&
        usersGroups?.includes(role.Group) &&
        ownUserRoles?.includes(role.id) &&
        role.Enabled
    )

    return ownedRoles
  }

  function getScopes(siteId) {
    const allAssociatedRoles = getRoles(siteId)
    const allScopes = allAssociatedRoles.map((x) => x.Scopes)
    const distinctScopes = new Set([].concat.apply([], allScopes))
    const scopesArray = Array.from(distinctScopes)
    return scopesArray?.filter((d) => d)
  }

  function hasAllScopes(requirements, siteId) {
    if (isAdmin) {
      return true
    }
    const scopes = getScopes(siteId)
    for (const requirement of requirements) {
      const splitRequirements = requirement.split('.')
      const scope = scopes.find((s) => s.Name === splitRequirements[0])

      if (!scope) {
        return false
      }

      //we assume if it isn't Read, then it's Write
      if (splitRequirements[1] === 'Read') {
        if (scope.CanRead) {
          continue
        }
      } else {
        if (scope.CanWrite) {
          continue
        }
      }

      return false
    }

    return true
  }

  function hasAtLeastOneScope(requirements, siteId) {
    const scopes = getScopes(siteId)

    for (const requirement of requirements) {
      const splitRequirements = requirement.split('.')
      const scope = scopes.find((s) => s.Name === splitRequirements[0])

      if (!scope) {
        continue
      }

      //we assume if it isn't Read, then it's Write
      if (splitRequirements[1] === 'Read') {
        if (scope.CanRead) {
          return true
        }
      } else {
        if (scope.CanWrite) {
          return true
        }
      }
    }

    return false
  }

  function getSitesForScope(requirement) {
    const roles = getRoles()
    const results = []
    const isRead = requirement.includes('.Read')

    for (const role of roles) {
      const scopes = role.Scopes.filter(
        (s) => requirement.includes(s.Name) && ((isRead && s.CanRead) || (!isRead && s.CanWrite))
      )

      if (scopes.length) {
        results.push(role.SiteIds)
      }
    }

    return [...new Set(results.flat())]
  }

  function getCustomersForScope(requirement) {
    const roles = getRoles()
    const results = []
    const isRead = requirement.includes('.Read')

    for (const role of roles) {
      const scopes = role.Scopes.filter(
        (s) => requirement.includes(s.Name) && ((isRead && s.CanRead) || (!isRead && s.CanWrite))
      )

      if (scopes.length) {
        results.push(role.CustomerId)
      }
    }

    return results
  }

  function getUserName() {
    const userName = `${currentUser.attributes.given_name ?? ''} ${currentUser.attributes.family_name ?? ''}`
    const email = currentUser.attributes.email
    return userName?.trim() ? userName : email
  }

  function getTransactionMetaData() {
    return {
      Name: getUserName(),
      Email: currentEmail,
      CustomerIds: currentCustomerIds,
      Date: dayjs().toISOString()
    }
  }

  currentUser.getGroups = getGroups
  currentUser.getRoles = getRoles
  currentUser.getScopes = getScopes
  currentUser.hasAllScopes = hasAllScopes
  currentUser.hasAtLeastOneScope = hasAtLeastOneScope
  currentUser.getSitesForScope = getSitesForScope
  currentUser.getCustomersForScope = getCustomersForScope
  currentUser.customerIds = currentCustomerIds
  currentUser.siteIds = getSiteIds()
  currentUser.email = currentEmail
  currentUser.isAdmin = isAdmin

  const value = {
    currentUser,
    getUserName,
    getGroups,
    getScopes,
    getRoles,
    getJWTToken,
    getTransactionMetaData
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export { useCurrentUser, UserProvider }
