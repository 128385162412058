/**
 *
 * "Layout page where the App retrieves and fills data."
 *
 * @file   PageLayout.js
 * @author Lateral
 * @since  2023
 */
import React, { useState, useEffect, useRef } from 'react'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import { AllRoutes, navRoutes } from './AllRoutes'
import { SideNav, BottomNav } from 'components'
import Box from '@mui/material/Box'
import './PageLayout.scss'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DESKTOP_MIN_WIDTH } from 'common/config'
import { CurrentDeckProvider } from 'components/currentDeckContext/CurrentDeckContext'
import { Customers, Decks, DeckRevisions, Locations, Screens, Sites, DeckRevisionHistory } from 'models'
import { Auth, DataStore, SortDirection, Predicates } from 'aws-amplify'
import { useCurrentUser } from 'hooks'
import { Loading } from 'components/Loading'

/*
 *Layout page that also retrieves and fills data.
 */
export function PageLayout() {
  /**
   * Generates layout page to retrieves and fills data
   *
   * @function
   *
   * @returns {object} - A layout page
   */
  const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH)
  const [database, setDatabase] = useState()
  const [bottomNavDimen, setBottomNavDim] = useState({
    height: 0,
    width: 0
  })
  const ref = useRef()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (!database) {
      localStorage.removeItem('selectedPanel')
    }
  }, [database])

  useEffect(() => {
    const retrieveData = async () => {
      const customerData = await DataStore.query(Customers, null, {
        page: 0,
        sort: (s) => s.Name(SortDirection.ASCENDING),
        limit: 10000
      })
      const siteData = await DataStore.query(Sites, null, {
        page: 0,
        sort: (s) => s.Name(SortDirection.ASCENDING),
        limit: 10000
      })
      const locationData = await DataStore.query(Locations, null, {
        page: 0,
        sort: (s) => s.Name(SortDirection.ASCENDING),
        limit: 10000
      })
      const screenData = await DataStore.query(Screens, null, {
        page: 0,
        sort: (s) => s.Name(SortDirection.ASCENDING),
        limit: 10000
      })
      const deckData = await DataStore.query(Decks, null, {
        page: 0,
        limit: 10000
      })

      const deckRevisionData = await DataStore.query(DeckRevisions, Predicates.ALL, {
        page: 0,
        limit: 10000
      })
      const deckRevisionHistoriesData = await DataStore.query(DeckRevisionHistory, Predicates.ALL, {
        page: 0,
        limit: 10000
      }).then((drh) => {
        return drh.filter((f) => deckRevisionData.map((dr) => dr.id).includes(f.RevisionId))
      })

      const data = {
        decks: deckData,
        screens: screenData,
        customers: customerData,
        locations: locationData,
        sites: siteData,
        deckRevisions: deckRevisionData,
        deckRevisionHistories: deckRevisionHistoriesData
      }
      setDatabase(data)
    }
    retrieveData()
  }, [])

  useEffect(() => {
    const customerSub = DataStore.observe(Customers).subscribe(async () => {
      //Unfortunately, These methods fire 3 times
      //INSERT with no version: This is the event generated when the item is first created and persisted locally
      //UPDATE with version 1: This is from the response of the GraphQL mutation to send the item to the backend
      //UPDATE with version 1: This is from the message received via the GraphQL subscription
      const latestData = await DataStore.query(Customers, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
      setDatabase({ ...database, customers: latestData })
    })

    const sitesSub = DataStore.observe(Sites).subscribe(async () => {
      const latestData = await DataStore.query(Sites, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
      setDatabase({ ...database, sites: latestData })
    })

    const locationsSub = DataStore.observe(Locations).subscribe(async () => {
      const latestData = await DataStore.query(Locations, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
      setDatabase({ ...database, locations: latestData })
    })

    const screensSub = DataStore.observe(Screens).subscribe(async () => {
      const latestData = await DataStore.query(Screens, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
      setDatabase({ ...database, screens: latestData })
    })

    const decksSub = DataStore.observe(Decks).subscribe(async () => {
      const latestData = await DataStore.query(Decks)
      setDatabase({ ...database, decks: latestData })
    })

    const deckRevisionsSub = DataStore.observe(DeckRevisions).subscribe(async () => {
      const latestData = await DataStore.query(DeckRevisions)
      setDatabase({ ...database, deckRevisions: latestData })
    })
    const deckRevisionHistoriesSub = DataStore.observe(DeckRevisionHistory).subscribe(async () => {
      const latestData = await DataStore.query(DeckRevisionHistory)
      setDatabase({ ...database, deckRevisionHistories: latestData })
    })

    return () => {
      sitesSub.unsubscribe()
      customerSub.unsubscribe()
      locationsSub.unsubscribe()
      screensSub.unsubscribe()
      decksSub.unsubscribe()
      deckRevisionsSub.unsubscribe()
      deckRevisionHistoriesSub.unsubscribe()
    }
  }, [database])

  useEffect(() => {
    if (isDesktop) {
      return
    }
    const interval = setInterval(() => {
      if (ref.current) {
        setBottomNavDim({
          height: ref.current.clientHeight,
          width: ref.current.clientWidth
        })
        clearInterval(interval)
      }
    }, 100)
    return () => {
      clearInterval(interval)
    }
  }, [])

  if (!database) {
    return <Loading type="circular" message="Loading app ..." />
  }

  const roles = currentUser.getRoles()
  if (roles.length === 0) {
    return (
      <Dialog open={true}>
        <Stack spacing={2} sx={{ padding: '2em' }}>
          <Typography>You have no roles associated with your account.</Typography>
          <Typography>Please contact Support for more assistance.</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              DataStore.stop().then(() => DataStore.clear())
              Auth.signOut()
            }}>
            Logout
          </Button>
        </Stack>
      </Dialog>
    )
  }

  return (
    <CurrentDeckProvider database={database} bottomNavDimen={bottomNavDimen} isDesktop={isDesktop}>
      <Box className="app">
        {isDesktop && <SideNav navRoutes={navRoutes} />}

        <Box className="main">
          <Box className="content">
            <AllRoutes />
          </Box>

          {!isDesktop && (
            <Box className="bottom-nav">
              <BottomNav ref={ref} />
            </Box>
          )}
        </Box>
      </Box>
    </CurrentDeckProvider>
  )
}

export default PageLayout
