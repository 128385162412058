/**
 *
 * "convert iamges to base64 encoded string."
 *
 * @file   converters.js
 * @author Lateral
 * @since  2023
 */
export function blobToBase64(blob) {
  /**
   * Read image blob/file object as as a base64 encoded string.
   *
   * @function
   * @param {object} blob - The Blob or File from which to read
   * @returns {string} - Image encoded to base64 encoded string
   */
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
