/**
 *
 * "A Generic form for confirming actions accross the app"
 *
 * @file   ChooseDeck.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function ConfirmForm({ name, onSubmit }) {
  /**
   *  Generates a form to confirm action
   *
   * @function
   *
   * @param {string} name - Action name
   * @param {} onSubmit- Action on submitting the form
   *
   * @returns {object} - A form with confirmation button
   */
  const defaultValues = {
    Description: ''
  }
  const { register, handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3} xs={12}>
          <Typography variant="h5" component="h5">
            {`${name} Description`}
          </Typography>
        </Grid>
        <Grid item md={9} xs={12}>
          <OutlinedInput
            id={`${name}Description`}
            name={`${name}Description`}
            placeholder={`Input ${name} Description`}
            color="secondary"
            fullWidth
            {...register('Description', { required: false })}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default ConfirmForm
