/**
 *
 * "Modal to confirm a logout with warnings."
 *
 * @file   LogoutDialog.js
 * @author Lateral
 * @since  2023
 */
import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'

export const LogoutDialog = ({ isOpen, onClose }) => {
  /**
   *
   * @const
   * @param {boolean} isOpen - If the logout modal is open
   * @param {} onClose - Action on closing the window
   * @returns {object} - React modal dialog with warnings, cancel button, and proceed button
   */
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      background: theme.palette.supporting.black
    }
  }))

  const handleClose = (action) => {
    if (onClose) {
      onClose(action)
    }
  }
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Warning: Potential Data Loss </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are offline. If you proceed any pending data changes will be lost.
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" sx={{ mt: '10px' }}>
          RECOMMENDED: Please restore network connectivity before continuing
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* canceling logout */}
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={() => handleClose(true)} autoFocus>
          {/* proceeding logout */}
          Proceed
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default LogoutDialog
