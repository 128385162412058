/**
 *
 * "A modal for confirming archiving."
 *
 * @file   ArchiveTrialForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'
import { parsedRevisionNumberWithSubLetter } from 'common/revisionNumber'

/**
 * Generates a modal to confirm archiving
 *
 * @function
 *
 * @param {DeckRevision} data - Deck revision object
 * @param {boolean} isTrial - whether the incoming revision is a trial or not
 * @param {} onSubmit - Action on submitting form
 *
 * @returns {object} - A modal form with confirmation button and warning
 */
export function ArchiveForm({ data, isTrial, onSubmit }) {
  const defaultValues = {
    Id: data?.id ?? ''
  }

  const { handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body1" component="p">
            {`Are you sure you want to archive ${isTrial ? 'Trial' : 'Proposal'}`}
            <strong>{` ${parsedRevisionNumberWithSubLetter(data)}`}</strong>?
          </Typography>
          <Typography variant="body1" component="p">
            This action cannot be undone.
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default ArchiveForm
