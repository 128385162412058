/**
 *
 * "Company table page"
 *
 * @file   CompanyTablePage.js
 * @author Lateral
 * @since  2023
 */
import { Button, Stack, Typography } from '@mui/material'
import { FormDialog, SCOPES, ScopeRequirementAll } from 'components'
import { ContactItem, Customers } from 'models'
import React, { useState, useContext, useEffect } from 'react'
import { addCustomer, deleteCustomer, updateCustomer } from '../../common/data'
import AddCompanyForm from './components/AddCompanyForm'
import CompaniesTable from './components/CompaniesTable'
import { LoadingButton } from '@mui/lab'

import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { Storage } from 'aws-amplify'
import { blobToBase64 } from 'common/converters'
import { MaintenanceDialog } from 'components/maintenanceSelector/MaintenanceDialog'
import DialogContentText from '@mui/material/DialogContentText'
import { MaintenanceNotifier } from 'components/maintenanceSelector/MaintenanceNotifier'
import { Loading } from 'components/Loading'

function CompanyTablePage({ setCustomerId }) {
  /**
   * Page for company table
   *
   * @function
   * @param {} setCustomerId - Action on setting customer id in the context
   *
   * @returns {object} - React app page
   */
  const [addCompanyModalState, setAddCompanyModalState] = useState({ isOpen: false })
  const [deleteCompanyModalState, setDeleteCompanyModalState] = useState({ isOpen: false })
  const [isDeleting, setIsDeleting] = useState(false)
  const [apiMessage, setApiMessage] = useState()
  const [rows, setRows] = useState([])
  const { database } = useContext(CurrentDeckContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    updateRows()
  }, [database.customers, database.sites, database.locations, database.decks])

  //Action on viewing site. Setting customer id in the context to view sites of the company
  function onViewSites(context) {
    setCustomerId(context.id)
  }

  // Action on edting details of the company
  function onEditDetails(context) {
    setAddCompanyModalState({ isOpen: true, title: 'Edit Company', buttonText: 'Update', data: context })
  }

  // Action on deleting a company
  function onDeleteCompany(context) {
    setDeleteCompanyModalState({
      isOpen: true,
      title: `Delete Company '${context.CompanyName}'?`,
      buttonText: 'Delete',
      data: { id: context.id }
    })
  }

  //Action on confirming delete company
  async function onDeleteConfirmation(customerId) {
    try {
      setIsDeleting(true)
      await deleteCustomer(customerId)
      setApiMessage({
        message: 'Request to delete customer is being processed',
        type: 'success'
      })
    } catch {
      setApiMessage({
        message: 'An error occurred while processing your request',
        type: 'error'
      })
    } finally {
      setDeleteCompanyModalState({ isOpen: false })
      setIsDeleting(false)
    }
  }

  // Action on adding new company
  async function onAddCompany(modelState) {
    if (modelState?.isSave) {
      const existing = database.customers.find((c) => c.id === modelState.data.Id)
      let fileKey = ''

      if (modelState.data.Logo.length) {
        const file = modelState.data.Logo[0]
        const result = await Storage.put(`${modelState.data.Name}-logo.jpg`, file, {
          metadata: { original: 'true' }
        })
        fileKey = result.key
      }

      if (existing) {
        let model = structuredClone(existing)
        model.Name = modelState.data.Name
        model.LogoKey = fileKey
        model.Contact = new ContactItem({
          Email: modelState.data.Email,
          Name: modelState.data.Contact,
          Position: 'Manager'
        })
        await updateCustomer(model)
      } else {
        const model = new Customers({
          Name: modelState.data.Name,
          LogoKey: fileKey,
          Contact: new ContactItem({
            Email: modelState.data.Email,
            Name: modelState.data.Contact,
            Position: 'Manager'
          })
        })

        await addCustomer(model)
      }
    }

    setAddCompanyModalState({ isOpen: false })
  }

  // Update a row in company table
  async function updateRows() {
    const companies = database.customers
    const results = []

    for (const c of companies) {
      const sites = database.sites.filter((s) => s.CustomerId === c.id)
      const siteIds = sites.map((s) => {
        return s.id
      })
      const locations = database.locations.filter((l) => siteIds.includes(l.SiteId))
      const locationIds = locations.map((l) => {
        return l.id
      })
      const decks = database.decks.filter((d) => locationIds.includes(d.LocationId))
      const screenTotal = []
      decks.forEach((d) => {
        if (!screenTotal.find((s) => s.EquipmentId === d.DeckHeader.EquipmentId && s.LocationId === d.LocationId)) {
          screenTotal.push({ EquipmentId: d.DeckHeader.EquipmentId, LocationId: d.LocationId })
        }
      })

      let logo = ''
      try {
        if (c.LogoKey) {
          const blob = await Storage.get(c.LogoKey, { download: true })
          if (blob) {
            logo = await blobToBase64(blob.Body)
          }
        }
      } catch {
        //ignore
      }

      results.push({
        id: c.id,
        CompanyName: c.Name,
        SitesTotal: sites.length,
        LocationsTotal: locations.length,
        ScreensTotal: screenTotal.length,
        CompanyContact: c.Contact?.Name,
        CompanyEmail: c.Contact?.Email,
        Logo: logo,
        LogoName: c.LogoKey
      })
    }
    setIsLoading(false)
    setRows(results)
  }

  return (
    <>
      <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Manage Sites</Typography>
        <ScopeRequirementAll requirements={[SCOPES.siteManagement.Write]}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ justifySelf: 'flex-end', width: '10em', height: '2em', padding: 0 }}
            onClick={() =>
              setAddCompanyModalState({ isOpen: true, title: 'Create New Company', buttonText: 'Create' })
            }>
            Add Company
          </Button>
        </ScopeRequirementAll>
      </Stack>
      {isLoading ? (
        <Loading withLogo={false} type="circular" message="Loading companies ..." />
      ) : (
        <CompaniesTable
          onViewSites={onViewSites}
          onEditDetails={onEditDetails}
          onDelete={onDeleteCompany}
          rows={rows}
        />
      )}

      {addCompanyModalState.isOpen && (
        <FormDialog modalState={addCompanyModalState} onOpenChange={onAddCompany}>
          <AddCompanyForm />
        </FormDialog>
      )}

      <MaintenanceDialog
        title={deleteCompanyModalState.title}
        open={deleteCompanyModalState.isOpen}
        color="error"
        Actions={
          <>
            <LoadingButton
              variant="contained"
              color="error"
              loading={isDeleting}
              onClick={() => {
                onDeleteConfirmation(deleteCompanyModalState.data.id)
              }}
              sx={{ justifySelf: 'flex-end', width: '10em', height: '2em', padding: 0 }}>
              Yes, delete
            </LoadingButton>
            <Button
              onClick={() => {
                setDeleteCompanyModalState({ isOpen: false })
              }}>
              No, cancel
            </Button>
          </>
        }>
        <DialogContentText>
          <Typography>This action once successful, cannot be reversed!</Typography>
        </DialogContentText>
      </MaintenanceDialog>
      {!!apiMessage && (
        <MaintenanceNotifier type={apiMessage.type} autoHideDuration={300} onClose={() => setApiMessage(undefined)}>
          {apiMessage.message}
        </MaintenanceNotifier>
      )}
    </>
  )
}
export default CompanyTablePage
