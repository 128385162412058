/**
 *
 * "Provides a group object consists of sideliners and panels, and control their zoom and rotation"
 *
 * @file   DeckModel.js
 * @author Lateral
 * @since  2023
 */
import { DeckPanels } from '../DeckPanels/DeckPanels'
import { SideLiner } from '../SideLiner/SideLiner'
import { SelectObject } from '../Select/SelectObject'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTexture } from '@react-three/drei'
import defaultTexture from 'assets/img/Empty.png'
import { useThree } from '@react-three/fiber'

export function DeckModel({
  data,
  maintenance,
  heatMapData,
  materialSelected,
  onPanelClick,
  rotation,
  selected,
  onSelectChange,
  theme,
  deckMode
}) {
  /**
   * Provides a structure with sideliners and panels and control their zoom and rotation.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {number} materialSelected - Material number
   * @param {Function} onPanelClick - Action on clicking panel
   * @param {number} rotation - Deck rotation angles in 3D space in radian
   * @param {object[]} selected - Array of selected panel objects
   * @param {Function} onSelectChange - Action on changing panel selection
   * @param {object} theme - Panel theme object
   * @param {string} deckMode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @returns {object} - React group element of deck model with components
   */
  const sideLinerSize = 1
  const sideLinerMinSpacing = 2
  const columns = new BigNumber(data.Size.Columns)
  const rows = new BigNumber(data.Size.Rows)
  const deckWidth = columns.dividedBy(2).plus(3)
  const deckHeight = rows.dividedBy(2)
  const emptyTexture = useTexture(defaultTexture)

  //camera details for projection zooming
  const { camera } = useThree()
  const max = new BigNumber(28)
  const min = new BigNumber(20)
  const bigger = columns.isGreaterThan(rows) ? columns : rows
  const defaultZoom = new BigNumber(20)

  if (bigger.isGreaterThan(max)) {
    camera.zoom = defaultZoom.minus(bigger.minus(max).dividedBy(4)).toNumber()
  } else if (bigger.isLessThan(min)) {
    camera.zoom = defaultZoom.plus(min.minus(bigger).times(2)).toNumber()
  } else {
    camera.zoom = defaultZoom.toNumber()
  }

  camera.updateProjectionMatrix()

  return (
    <SelectObject
      multiple
      box
      onChange={onSelectChange}
      filter={(items) =>
        items.map((i) => {
          return i.userData
        })
      }>
      <group rotation={rotation}>
        <SideLiner
          data={data.SideLiners.filter((d) => d.Side === 'Left')}
          maintenance={maintenance}
          heatMapData={heatMapData}
          kits={data.Kits}
          size={sideLinerSize}
          position={[Math.max(deckWidth.toNumber(), sideLinerMinSpacing), deckHeight, 0]}
          selected={selected}
          materialSelected={materialSelected}
          onPanelClick={onPanelClick}
          theme={theme}
          emptyTexture={emptyTexture}
          deckMode={deckMode}
        />
        <DeckPanels
          data={data}
          maintenance={maintenance}
          heatMapData={heatMapData}
          position={[0, deckHeight, 0]}
          selected={selected}
          materialSelected={materialSelected}
          onPanelClick={onPanelClick}
          theme={theme}
          emptyTexture={emptyTexture}
          deckMode={deckMode}
        />
        <SideLiner
          data={data.SideLiners.filter((d) => d.Side === 'Right')}
          maintenance={maintenance}
          heatMapData={heatMapData}
          kits={data.Kits}
          size={sideLinerSize}
          position={[Math.min(-deckWidth.toNumber(), -sideLinerMinSpacing), deckHeight, 0]}
          selected={selected}
          materialSelected={materialSelected}
          onPanelClick={onPanelClick}
          theme={theme}
          emptyTexture={emptyTexture}
          deckMode={deckMode}
        />
      </group>
    </SelectObject>
  )
}

export default DeckModel
