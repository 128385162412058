/**
 *
 * "Creates a context hook for report pages as a chldren, so they can share certain info."
 *
 * @file   ReportingContext.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

//React context hook to share info accross app
export const ReportingContext = React.createContext({})

export function ReportingProvider({ children }) {
  /**
   *
   * @function
   * @param {React.ElementType} children - React app chldren component
   * @returns {object} - React children component as context hook
   */

  const location = useLocation()

  let initialValue = 3
  if (location.pathname === '/reporting/pre-shut') {
    initialValue = 0
  } else if (location.pathname === '/reporting/post-shut') {
    initialValue = 1
  } else if (location.pathname === '/reporting/parts') {
    initialValue = 2
  } else if (location.pathname === '/reporting/all') {
    initialValue = 3
  }

  const [pathValue, setPathValue] = useState(initialValue)
  const [existingReport, setExistingReport] = useState({})
  const [showTabs, setShowTabs] = useState(true)

  const value = {
    pathValue,
    setPathValue,
    existingReport,
    setExistingReport,
    showTabs,
    setShowTabs
  }

  // Context hook, similar in all report pages
  return <ReportingContext.Provider value={value}>{children}</ReportingContext.Provider>
}
