/**
 *
 * "Side navigation bar for all pages in desktop mode"
 *
 * @file   SideNav.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import { DataStore, Auth } from 'aws-amplify'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Typography, ClickAwayListener } from '@mui/material'
import manifest from '../../../package.json'
import { LogoutDialog } from './LogoutDialog'
import { ScopeRequirementAll } from 'components/auth/ScopeRequirement'
import { LogoutOutlined } from '@mui/icons-material'

const drawerWidth = 240

const openedMixin = (theme) => ({
  /**
   *
   * Side navigation bar expansion settings
   *
   * @function
   * @param {object} theme - Theme of the app
   */
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  border: 'none'
})

const closedMixin = (theme) => ({
  /**
   *
   * Side navigation bar closing settings
   *
   * @function
   * @param {object} theme - Theme of the app
   */
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  border: 'none'
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  /**
   *
   * Helper to open and close side navigation bar
   *
   * @const
   * @param {object} theme - Theme of the app
   */
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

export const SideNav = ({ navRoutes }) => {
  /**
   *
   * Generates side navifation bar
   *
   * @const
   *
   * @param {object} navRoutes - Navigation routes
   * @returns {object} - React drwaer element containg side navigation bar components
   */
  const theme = useTheme()
  const location = useLocation()
  const splitPath = location.pathname.split('/')
  const parentRoute = splitPath[1]
  const childRoute = splitPath[splitPath.length - 1]
  const [open, setOpen] = React.useState(false)
  const [openParent, setOpenParent] = useState('')
  const [timer, setTimer] = useState('')
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const navigate = useNavigate()

  function Logout(isProceed) {
    setIsConfirmDialogOpen(false)
    if (isProceed === true) {
      DataStore.clear()
      Auth.signOut()
    }
  }

  function TryLogout() {
    if (DataStore.sync.online === false) {
      setIsConfirmDialogOpen(true)
    } else {
      Logout(true)
    }
  }

  function onMouseHover(value) {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => onTimeoutSubmit(value), 100)
    setTimer(timeoutId)
  }

  async function onTimeoutSubmit(value) {
    setOpen(value)
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Drawer
          variant={'permanent'}
          anchor="left"
          open={open}
          style={{
            backgroundColor: theme.palette.supporting.primaryMedium
          }}
          PaperProps={{
            style: {
              zIndex: 1,
              backgroundColor: theme.palette.supporting.primaryMedium
            }
          }}
          onMouseOver={() => onMouseHover(true)}
          onMouseLeave={() => onMouseHover(false)}>
          <List
            style={{
              height: '100vh',
              width: '100%',
              maxWidth: Drawer
            }}>
            {/* Menu entries are based on the routing in AllRoutes.js */}
            {navRoutes.map((item) => {
              const isActive = parentRoute === (item.parentPath ?? item.path)
              const isOpen = openParent === item.path
              const parentActiveColor = isActive ? theme.palette.supporting.gold : theme.palette.supporting.highlight
              return (
                <React.Fragment key={item.props?.title}>
                  <ScopeRequirementAll requirements={item.props?.scopes ?? []}>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ justifyContent: 'center', height: '68px' }}
                        onClick={() => {
                          setOpen(true)
                          setOpenParent(item.path)
                        }}>
                        <ListItemIcon sx={{ justifyContent: 'center' }}>
                          {React.cloneElement(item.icon, {
                            style: { height: '24px', fill: parentActiveColor }
                          })}
                        </ListItemIcon>

                        {open && (
                          <ListItemText
                            disableTypography
                            primary={<Typography variant="h5"> {item.props?.title}</Typography>}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>

                    {open && isOpen && item.children.length > 0 && (
                      <Stack direction="row">
                        <Divider
                          sx={{ ml: 5, bgcolor: theme.palette.supporting.gold }}
                          orientation="vertical"
                          flexItem
                        />

                        <List component="div" disablePadding>
                          {item.children.map((childItem) => {
                            if (!childItem.props?.title) {
                              return null
                            }
                            const fullPath = `${item.path}/${childItem.path}`
                            const childActiveColor =
                              childRoute === childItem.path
                                ? theme.palette.secondary.main
                                : theme.palette.supporting.highlight

                            return (
                              <ScopeRequirementAll
                                key={childItem.props?.title}
                                requirements={childItem.props?.scopes ?? []}>
                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate(fullPath)}>
                                  <ListItemText
                                    disableTypography
                                    primary={
                                      <Typography variant="h5" sx={{ color: `${childActiveColor}` }}>
                                        {' '}
                                        {childItem.props?.title}
                                      </Typography>
                                    }
                                  />
                                </ListItemButton>
                              </ScopeRequirementAll>
                            )
                          })}
                        </List>
                      </Stack>
                    )}
                  </ScopeRequirementAll>
                </React.Fragment>
              )
            })}
          </List>
          <Typography variant="caption" textAlign="center">
            [v{manifest.version}]
          </Typography>
          <Button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={TryLogout}>
            <LogoutOutlined />
          </Button>
        </Drawer>
      </ClickAwayListener>
      <LogoutDialog isOpen={isConfirmDialogOpen} onClose={Logout}></LogoutDialog>
    </>
  )
}

export default SideNav
