/**
 *
 * "Generic form for deleting across the Admin pages
 * with warning messages."
 *
 * @file   DeleteForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function DeleteForm({ data, onSubmit }) {
  /**
   * Provides a form to delete records in admin pages
   *
   * @function
   * @param {object[]} data - Record to delete
   * @param {string} data.id - ID of the Record to delete
   * @param {object[]} data.name - Name of the Record to delete
   * @param {} onSubmit - Action on submiting the form
   *
   * @returns {object} - A form to delete record
   */
  const defaultValues = {
    Id: data?.id ?? ''
  }

  const { handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body1" component="p">
            Are you sure you want to delete
            <strong>{` ${data?.Name}`}</strong>?
          </Typography>
          <Typography variant="body1" component="p">
            This action cannot be undone.
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default DeleteForm
