import React, { useEffect, useState } from 'react'
import { Header, useNotification } from 'components'
import { Box, Grid, Typography, Stack, TextField, Divider } from '@mui/material'
import { defaultRoutes } from 'pages'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Auth, API } from 'aws-amplify'
import { LoadingButton } from '@mui/lab'

import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'
const apiName = 'AdminQueries'

const getHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  }
}

const LegalDocumentUrl = ({ placeholder, value = '', onChange }) => {
  return (
    <Grid>
      <Grid container spacing={2} flexDirection="row" padding={2} alignItems="center">
        <Grid md={4} item>
          <Typography variant="h6">{placeholder}</Typography>
        </Grid>
        <Grid md={8} item>
          <TextField
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            sx={{ ml: '0.5em' }}
            placeholder={placeholder}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  )
}

export const LegalRequirment = () => {
  const [legalDocuments, setLegalDocuments] = useState({
    privacyPolicyLocation: '',
    eulDocumentLocation: ''
  })

  const { notify } = useNotification()

  const fetchLegalDocuments = useQuery(
    ['fetchLegalDocuments'],
    async () => {
      return API.get(apiName, '/legal', {
        headers: await getHeaders()
      })
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (fetchLegalDocuments.data) {
      setLegalDocuments({
        privacyPolicyLocation: fetchLegalDocuments.data.privacyPolicyDocumentLocation ?? '',
        eulDocumentLocation: fetchLegalDocuments.data.eulDocumentLocation ?? ''
      })
    }
  }, [fetchLegalDocuments.data])

  const saveLegalDocumentsLocation = useMutation({
    mutationFn: async () => {
      return API.put(apiName, '/legal', {
        headers: await getHeaders(),
        body: {
          privacyPolicyDocumentLocation: legalDocuments.privacyPolicyLocation,
          eulDocumentLocation: legalDocuments.eulDocumentLocation
        }
      })
    },
    onSuccess: () => {
      fetchLegalDocuments.refetch()
      notify('Legal documents have been successfully saved')
    },
    onError: () => {
      notify('An error occurred while saving legal documents')
    },
    retry: false
  })

  const isButtonDisabled =
    saveLegalDocumentsLocation.isLoading ||
    fetchLegalDocuments.isFetching ||
    fetchLegalDocuments.isLoading ||
    legalDocuments.privacyPolicyLocation.trim().length === 0 ||
    legalDocuments.eulDocumentLocation.trim().length === 0 ||
    (legalDocuments.privacyPolicyLocation === fetchLegalDocuments.data?.privacyPolicyDocumentLocation &&
      legalDocuments.eulDocumentLocation === fetchLegalDocuments.data?.eulDocumentLocation)

  const onLegalDocumentUrlChange = (key) => {
    return (val) => {
      setLegalDocuments((current) => {
        return {
          ...current,
          [key]: val
        }
      })
    }
  }
  return (
    <Box sx={{ px: '15px' }}>
      <Header routes={defaultRoutes} />
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid item md={12}>
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }} mt={1} mb={1}>
            <Typography variant="h1">Legal</Typography>
          </Stack>
          <Divider />
        </Grid>
      </Grid>
      {fetchLegalDocuments.isInitialLoading && (
        <Grid container justifyContent="center" mt={2} mb={2} maxWidth="60%">
          <Grid item md={5}>
            <ProgressIndicator />
          </Grid>
        </Grid>
      )}

      <Grid flexDirection="column" container padding={6} columnGap={1} maxWidth="60%">
        <Grid md={5} item>
          <LegalDocumentUrl
            value={legalDocuments.privacyPolicyLocation}
            placeholder="Privacy document S3 name"
            onChange={onLegalDocumentUrlChange('privacyPolicyLocation')}
          />
        </Grid>

        <Grid md={5} item>
          <LegalDocumentUrl
            value={legalDocuments.eulDocumentLocation}
            placeholder="EUL document S3 name"
            onChange={onLegalDocumentUrlChange('eulDocumentLocation')}
          />
        </Grid>

        <Grid justifyContent="flex-end" container md={5} item mt={1}>
          <LoadingButton
            disabled={isButtonDisabled}
            loading={saveLegalDocumentsLocation.isLoading}
            variant="contained"
            color="secondary"
            onClick={() => saveLegalDocumentsLocation.mutate()}>
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
