import { Box, Tab, Tabs } from '@mui/material'
import { useWearAppContext } from '../../WearAppContext'
import { TabPanel } from './TabPanel'

export const WearAppTabs = () => {
  const { activeTab, onTabchange } = useWearAppContext()
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '1em' }}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            onTabchange(newValue)
          }}
          aria-label="Tabs"
          centered>
          <Tab label="WIDTH" id="tab-0" />
          <Tab label="WEAR" id="tab-1" />
          <Tab label="EFFICIENCY" id="tab-2" />
        </Tabs>
      </Box>
      <TabPanel />
    </Box>
  )
}
