/**
 *
 * "A form to add screen item on View Screen Assets page"
 *
 * @file   ScreenItemForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { useForm, Controller } from 'react-hook-form'
import { MenuItem, Select, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { dateFormat } from 'common/dates'

export const ScreenItemForm = ({ data, locations, customers, sites, screens, onSubmit }) => {
  /**
   * Add new screen to datasotre as equipment to view in Screen Assets page
   *
   * @const
   * @async
   *
   * @param {object} data - Screen asset/ Equipment object created from add screen modal to view recent
   * @param {string} data.name - Screen name
   * @param {string} data.locationId - ID of screen location
   * @param {string} data.ScreenId - ID of screen
   * @param {string} data.DWGNumber - Scheck DWG number
   * @param {string} data.SchenckSerial - Scheck serial number
   * @param {string} data.SiteSerial - Site serial number
   * @param {string} data.Exciters - Esciter number
   * @param {object} data.DateInstalled - Date of installation
   * @param {string} data.ScreenTonnage - Tonnage of screen in tone
   * @param {string} data.ScreenCapacity - Capacity of screen in tone
   * @param {string} data.MaintenanceFrequency - Grequency of Maintenance
   * @param {object[]} sites - array of site objects
   * @param {object[]} locations - array of location objects
   * @param {object[]} screens - Array of listed screen objects
   * @param {object[]} customers - Array of customer objects
   * @param {} onSubmit - Action on form submision
   *
   * @returns {object} - A modal form to add screen
   */
  const defaultValues = {
    id: data?.id ?? null,
    Name: data?.Name ?? '',
    CustomerId: data?.CustomerId ?? '',
    SiteId: data?.SiteId ?? '',
    LocationId: data?.LocationId ?? '',
    DWGNumber: data?.DWGNumber ?? '',
    SchenckSerial: data?.SchenckSerial ?? '',
    SiteSerial: data?.SiteSerial ?? '',
    ScreenId: data?.ScreenId ?? '',
    Exciters: data?.Exciters ?? '',
    DateInstalled: data?.DateInstalled ? dayjs(data?.DateInstalled, dateFormat) : dayjs(),
    ScreenTonnage: data?.ScreenTonnage ?? '',
    ScreenCapacity: data?.ScreenCapacity ?? '',
    MaintenanceFrequency: data?.MaintenanceFrequency ?? ''
  }
  const { register, handleSubmit, control, watch } = useForm({ defaultValues })

  const watchCompany = watch('CustomerId', '')
  const watchSite = watch('SiteId', '')
  const companyIdForFilter = watchCompany.length ? watchCompany : data?.CustomerId
  const siteIdForFilter = watchSite.length ? watchSite : data?.SiteId

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography component="h5">Screen Name</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Name"
            name="Name"
            placeholder="Insert Screen Name"
            color="secondary"
            fullWidth
            {...register('Name', { required: true })}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Company
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="CustomerId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {customers.map((l) => {
                  return (
                    <MenuItem key={l.id} value={l.id}>
                      {l.Name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Site
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="SiteId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {sites
                  .filter((s) => s.CustomerId === companyIdForFilter)
                  .map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.Name}
                      </MenuItem>
                    )
                  })}
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Location
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="LocationId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {locations
                  .filter((l) => l.SiteId === siteIdForFilter)
                  .map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.Name}
                      </MenuItem>
                    )
                  })}
              </Select>
            )}
          />
        </Grid>

        <Grid item md={3}>
          <Typography component="h5">Screen Body</Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            control={control}
            name="ScreenId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {screens.map((s) => {
                  return (
                    <MenuItem key={s.id} value={s.id}>
                      {s.Name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </Grid>

        <Grid item md={3}>
          <Typography component="h5">Sandvik DWG Number</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="DWGNumber"
            name="DWGNumber"
            placeholder="Insert DWG Number"
            color="secondary"
            fullWidth
            {...register('DWGNumber')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography component="h5">Sandvik Serial Number</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="SchenckSerial"
            name="SchenckSerial"
            placeholder="Insert Sandvik Serial Number"
            color="secondary"
            fullWidth
            {...register('SchenckSerial')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography component="h5">Site Serial Number</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="SiteSerial"
            name="SiteSerial"
            placeholder="Insert Site Serial Number"
            color="secondary"
            fullWidth
            {...register('SiteSerial')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography component="h5">Exciters</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Exciters"
            name="Exciters"
            placeholder="Insert Exciters"
            color="secondary"
            fullWidth
            {...register('Exciters')}
          />
        </Grid>
        <Grid item md={3}>
          <Typography component="h5">Date Installed</Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            control={control}
            name="DateInstalled"
            render={({ field }) => (
              <DatePicker
                {...field}
                inputFormat={dateFormat}
                renderInput={(params) => <TextField color="secondary" fullWidth {...params} />}
              />
            )}
          />
        </Grid>
        <Grid item md={3}>
          <Typography component="h5">Screen Tonnage</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="ScreenTonnage"
            name="ScreenTonnage"
            placeholder="Insert Screen Tonnage"
            color="secondary"
            endAdornment={<InputAdornment position="end">Tonnes</InputAdornment>}
            fullWidth
            {...register('ScreenTonnage')}
          />
        </Grid>
        <Grid item md={3}>
          <Typography component="h5">Screen Capacity</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="ScreenCapacity"
            name="ScreenCapacity"
            placeholder="Insert Screen Capacity"
            color="secondary"
            fullWidth
            endAdornment={<InputAdornment position="end">Tonnes</InputAdornment>}
            {...register('ScreenCapacity')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography component="h5">Maintenance Frequency</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="MaintenanceFrequency"
            name="MaintenanceFrequency"
            placeholder="Insert Maintenance Frequency"
            color="secondary"
            fullWidth
            endAdornment={<InputAdornment position="end">Weeks</InputAdornment>}
            {...register('MaintenanceFrequency')}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default ScreenItemForm
