/**
 *
 * "Display a table to show the History of the incoming Deck."
 *
 * @file   HistoryTable.js
 * @author Lateral
 * @since  2023
 */

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { ContextMenu, SCOPES } from 'components'
import React from 'react'
import BigNumber from 'bignumber.js'
import { dateFormat } from 'common/dates'
import { parsedRevisionNumberWithSubLetter } from 'common/revisionNumber'
import { useCurrentUser } from 'hooks'

function HistoryTable({
  deckRevisionId,
  historyId,
  deckRevisions,
  deckRevisionHistories,
  onClick,
  onEditDetails,
  onRestore,
  onEditHistory,
  onEditMaintenance,
  onViewUpdates
}) {
  /**
   *  Create a table of Deck history from record of maintenance and revisions
   *
   * @function
   * @param {number} deckRevisionId - Deck revision ID
   * @param {number} historyId - Deck mentenance ID
   * @param {object[]} deckRevisions - array of deck revision/trial objects
   * @param {object[]} deckRevisionHistories - array of deck maintenance objects
   * @param {} onclick - define the action on clicking a row
   * @param {} onEditDetails - define the action on editing details of maintenance such as date and/or description
   * @param {} onRestore - define the action on restore revision
   * @param {} onEditHistory - define the action on edit details of revision
   * @param {} onEditMaintenance - define the action on edit details of maintenance
   *
   * @returns {object} - Dashboard page
   */
  const theme = useTheme()

  const { currentUser } = useCurrentUser()
  const data = getData()
  const maxRevision = Math.max(...deckRevisions.map((d) => d.RevisionNumber))
  const editableRevision = new BigNumber(maxRevision).minus(1)
  const unpublishedSites = currentUser.getSitesForScope(SCOPES.deckLayoutReview.Write)
  const maintenanceSites = currentUser.getSitesForScope(SCOPES.deckMaintenance.Write)

  //history data is divided between the DeckRevision and maintenance tables.
  function getData() {
    /**
     *  Create an array of deck history including Maintenances and Revisions sorted based on revision number
     * @function
     * @returns {Array} - Maintenance and revision histories of incoming deck
     */

    // First map the revisions in history
    let histories = deckRevisions.map((revision) => {
      return {
        id: revision.id,
        historyId: '',
        siteId: revision.SiteId,
        revisionNumber: revision.RevisionNumber,
        minorRevisionNumber: revision.MinorRevisionNumber,
        revisionNumberToView: parsedRevisionNumberWithSubLetter(revision),
        type: revision.IsArchived
          ? 'Archived'
          : revision.IsTrial
          ? 'Trial'
          : revision.IsPublished
          ? 'Revision'
          : 'Proposal',
        description: revision.Description,
        reported: dayjs(revision.createdAt),
        created: dayjs(revision.createdAt),
        user: revision.CreatedBy
      }
    })

    // If the event is a maintenance add it to the same array
    if (deckRevisionHistories) {
      histories = histories.concat(
        deckRevisionHistories.map((history) => {
          const revision = deckRevisions?.find((d) => d.id === history.RevisionId)
          return {
            id: revision?.id,
            historyId: history.id,
            siteId: history.SiteId,
            isHistory: true,
            revisionNumber: revision?.RevisionNumber,
            minorRevisionNumber: revision?.MinorRevisionNumber,
            revisionNumberToView: revision ? parsedRevisionNumberWithSubLetter(revision) : 0,
            type: 'Maintenance',
            description: history.ShortDescription,
            reported: dayjs(history.DatePerformed ?? history.createdAt),
            created: dayjs(history.createdAt),
            user: history.CreatedBy,
            updates: history.Updates
          }
        })
      )
    }

    //sorting is a multi-branch process, so spelling it out is easier
    histories = histories.sort((a, b) => {
      if (b.revisionNumber > a.revisionNumber) {
        return 1
      } else if (b.revisionNumber < a.revisionNumber) {
        return -1
      }

      if (b.minorRevisionNumber > a.minorRevisionNumber) {
        return 1
      } else if (b.minorRevisionNumber < a.minorRevisionNumber) {
        return -1
      }

      if (b.reported.isBefore(a.reported)) {
        return -1
      } else if (b.reported.isAfter(a.reported)) {
        return 1
      } else {
        return 0
      }
    })

    return histories
  }

  function onRowClick(id, historyId) {
    /**
     *  call the input function to the HistoryTable on clicking a row.
     */
    onClick(id, historyId)
  }

  // Return a table of deck revision and maintenance history sorted by revision number
  return (
    <TableContainer
      sx={{
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '2px',
          height: '2px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table stickyHeader sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow>
            <TableCell>Revision</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Works Date</TableCell>
            <TableCell>Reported</TableCell>
            <TableCell>User</TableCell>
            <TableCell sx={{ width: '0' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((history) => {
            const isHighlighted = deckRevisionId === history.id && historyId === history.historyId
            let menuItems = [{ title: 'Edit Details', action: () => onEditDetails(history) }]

            if (history.isHistory) {
              menuItems.push({
                title: 'Edit Maintenance',
                condition: history.isHistory,
                action: () => onEditMaintenance(history)
              })

              if (history.updates?.length) {
                menuItems.push({
                  title: 'View Updates',
                  condition: history.isHistory,
                  action: () => onViewUpdates(history)
                })
              }
            } else {
              if (editableRevision.isLessThanOrEqualTo(history.revisionNumber)) {
                menuItems.push({
                  title: 'Edit Revision',
                  condition: history.isHistory,
                  action: () => onEditHistory(history)
                })
              }
              menuItems.push({
                title: 'Restore Revision',
                condition: history.isHistory,
                action: () => onRestore(history)
              })
            }
            return (
              <TableRow
                key={history.historyId ? history.historyId : history.id}
                hover
                sx={{ backgroundColor: isHighlighted ? theme.palette.primary.light : '' }}
                onClick={() => onRowClick(history.id, history.historyId)}>
                <TableCell>{history.revisionNumberToView}</TableCell>
                <TableCell>{history.type}</TableCell>
                <TableCell>{history.description}</TableCell>
                <TableCell>{history.reported.format(dateFormat)}</TableCell>
                <TableCell>{history.created.format(dateFormat)}</TableCell>
                <TableCell sx={{ width: 'auto' }}>{history.user}</TableCell>
                <TableCell sx={{ width: '0' }}>
                  {currentUser.isAdmin ||
                  (history.isHistory && maintenanceSites.includes(history.siteId)) ||
                  (!history.isHistory && unpublishedSites.includes(history.siteId)) ? (
                    <ContextMenu context={history} menuItems={menuItems} />
                  ) : (
                    false
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default HistoryTable
