/**
 *
 * "CRUD (Create, read, update, and delete) for Decks revisions table"
 *
 * @file   revisionOperations.js
 * @author Lateral
 * @since  2023
 */

import { DataStore } from 'aws-amplify'
import { SideLinersItems } from 'models'
import { PanelsItems } from 'models'
import { DeckRevisions } from 'models'

export async function createFirstRevision(deck, createdBy) {
  /**
   * Create deckrevisions for a new deck
   * Note: Use this function only for new deck that
   * doesn't exist in revision table.
   *
   *
   * @function
   * @param {object} deck - Deck defined in models/index
   * @returns {object} - Revision for new deck
   */

  //Create pannel object for evry row and column in deck size
  let panels = []
  for (let i = 0; i < deck.DeckHeader.DeckSize.Rows; i++) {
    for (let j = 0; j < deck.DeckHeader.DeckSize.Columns; j++) {
      panels.push(
        new PanelsItems({
          Position: { Column: j, Row: i }
        })
      )
    }
  }

  //Create sideLiners object for evry row in left and right column of deck
  let sideLiners = []
  for (let i = 0; i < deck.DeckHeader.DeckSize.Rows; i++) {
    sideLiners.push(
      new SideLinersItems({
        Side: 'Left',
        StartPosition: i,
        Height: 1
      })
    )
    sideLiners.push(
      new SideLinersItems({
        Side: 'Right',
        StartPosition: i,
        Height: 1
      })
    )
  }
  const newRevision = await DataStore.save(
    new DeckRevisions({
      DeckId: deck.id,
      CustomerId: deck.CustomerId,
      SiteId: deck.SiteId,
      IsPublished: false,
      IsTrial: false,
      IsArchived: false,
      Kits: [],
      Panels: panels,
      RevisionNumber: 0,
      MinorRevisionNumber: 0,
      SideLiners: sideLiners,
      Size: deck.DeckHeader.DeckSize,
      CreatedBy: deck.CreatedBy,
      LastUpdatedBy: createdBy
    })
  )

  return newRevision
}

//CRUD for DeckRevisions table
export async function createRevision(revision, createdBy) {
  /**
   * Create deckrevisions for a existing deck
   *
   *
   * @function
   * @param {object} revision - Deck revison object defined in CurrentDeckContext.js
   * @returns {object} - Revision table entry
   */
  return await DataStore.save(
    new DeckRevisions({
      DeckId: revision.DeckId,
      SiteId: revision.SiteId,
      CustomerId: revision.CustomerId,
      Description: revision.Description,
      IsPublished: revision.IsPublished,
      IsTrial: revision.IsTrial,
      IsArchived: revision.IsArchived,
      Kits: revision.Kits,
      Panels: revision.Panels,
      RevisionNumber: revision.RevisionNumber,
      MinorRevisionNumber: revision.MinorRevisionNumber,
      SideLiners: revision.SideLiners,
      Size: revision.Size,
      CreatedBy: revision.CreatedBy,
      LastUpdatedBy: createdBy
    })
  )
}

export async function updateRevision(original, revision, updatedBy) {
  /**
   * Update deckrevisions for a existing deck
   *
   *
   * @function
   * @param {object} revision - Deck revison object defined in CurrentDeckContext.js
   * @returns {object} - Revision table update entry
   */
  return await DataStore.save(
    DeckRevisions.copyOf(original, (updated) => {
      updated.DeckId = revision.DeckId
      updated.SiteId = revision.SiteId
      updated.CustomerId = revision.CustomerId
      updated.Description = revision.Description
      updated.IsPublished = revision.IsPublished
      updated.IsTrial = revision.IsTrial
      updated.IsArchived = revision.IsArchived
      updated.Kits = revision.Kits
      updated.Panels = revision.Panels
      updated.RevisionNumber = revision.RevisionNumber
      updated.MinorRevisionNumber = revision.MinorRevisionNumber
      updated.SideLiners = revision.SideLiners
      updated.Size = revision.Size
      updated.CreatedBy = revision.CreatedBy
      updated.LastUpdatedBy = updatedBy
    })
  )
}

export async function deleteRevision(id) {
  const existing = await DataStore.query(DeckRevisions, id)
  if (existing) {
    await DataStore.delete(existing)
  }
}
