/**
 *
 * "Drop down menu items for deck selection"
 *
 * 1. Select a customer: Showes all customer name to select
 * 2. Select a site: Filtered site names for the specified customer
 * 3. Select a location: Location of specified site
 * 4. Select a Screen: List of screen on that location
 * 5. Select a deck: Deck of that screen e.g. TopDeck/BottomDeck
 *
 * @file   DeckSelector.js
 * @author Lateral
 * @since  2023
 */
import { Box, MenuItem, Select } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { observeEquipment } from 'hooks'
import React from 'react'
import './DeckSelector.scss'

export function DeckSelector({ decks, screens, customers, locations, sites }) {
  /**
   * deck selection drop down menu element generator
   *
   * @function
   * @param {object[]} decks - Array of deck objects
   * @param {string} decks.CustomerId - Cusotmer ID of the deck
   * @param {object} decks.deckHeader - Headers of the deck properties
   * @param {string} decks.deckHeader.customerId - Cusotmer ID of the deck
   * @param {string} decks.deckHeader.DeckLevel - Level of the deck, TopDeck/BottomDeck
   * @param {object} decks.deckHeader.DeckSize - Size of the deck, Columns and Rows.
   * @param {string} decks.deckHeader.EquipmentId - Deck equipment ID
   * @param {string} decks.deckHeader.LocationId - ID of the deck location
   * @param {string} decks.deckHeader.MaterialNumber - Number of the panel material
   * @param {string} decks.deckHeader.ScreenId - ID of the screen the deck mounted
   * @param {string} decks.deckHeader.SerialNumber - Deck serial number
   * @param {string} decks.deckHeader.Site - Site name
   * @param {string} decks.deckHeader.SiteId - Site ID
   * @param {string} decks.LocationId - ID of the deck location
   * @param {string} decks.ScreenId - ID of the screen
   * @param {string} decks.SiteId - Site ID of the deck from
   * @param {string} decks.id - Deck model ID
   * @param {object[]} screens - Array of listed screen objects
   * @param {string} screens.LocationId - ID of the screen location
   * @param {string} screens.Name - Name of the screen
   * @param {string} screens.id - ID of the screen
   * @param {object[]} customers - Array of customer objects
   * @param {string} customers.name - Customer name
   * @param {string} customers.id - Cusotmer ID
   * @param {object[]} locations - Array of location objects
   * @param {string} locations.Name - Name of location
   * @param {string} locations.SiteId - Site ID of the site the location from
   * @param {string} locations.id - ID of the location
   * @param {object[]} sites - Array of location objects
   * @param {string} sites.customerId - Cusotmer ID of the site
   * @param {string} sites.Name - Name of site
   * @param {string} sites.id - ID of the site
   * @param {object[]} deckRevisions - Array of deck rivision records
   * @returns {object} - React box element with drop down menus
   */
  const defaultString = ``

  const theme = useTheme()
  const {
    customerId,
    deckId,
    equipmentId,
    locationId,
    siteId,
    setCustomerId,
    setDeckId,
    setDeckRevisionId,
    setEquipmentId,
    setLocationId,
    setScreenId,
    setSiteId,
    setDeckRevision
  } = useCurrentDeckContext()

  const equipment = observeEquipment()

  const filteredEquipment = () => {
    /**
     * Create the list of screens for screen menu
     *
     * @const
     */
    const filteredDecks = decks
      .filter((d) => d.CustomerId === customerId && d.SiteId === siteId && d.LocationId === locationId)
      .map((d) => d.DeckHeader.EquipmentId)

    let filteredEquipment = equipment.filter((e) => e.LocationId === locationId).map((e) => e.Name)

    filteredEquipment = filteredEquipment.concat(filteredDecks)

    const results = [...new Set(filteredEquipment)].sort()

    return results
  }

  function chooseCustomer(id) {
    /**
     * Creates and pop site menu for selected customer
     *
     * @function
     */
    if (setCustomerId(id)) {
      chooseSite(defaultString, true)
    }
  }

  function chooseSite(id, confirm = false) {
    /**
     * Creates and pop Location menu for selected site
     *
     * @function
     */
    if (setSiteId(id, confirm)) {
      chooseLocation(defaultString, true)
    }
  }

  function chooseLocation(id, confirm = false) {
    /**
     * Creates and pop screen menu for selected location
     *
     * @function
     */
    if (setLocationId(id, confirm)) {
      chooseEquipment(defaultString, true)
    }
  }

  function chooseEquipment(id, confirm = false) {
    /**
     * Creates and pop deck menu for selected screen
     *
     * @function
     */
    if (setEquipmentId(id, confirm)) {
      chooseDeck(defaultString, true)
    }
  }

  function chooseDeck(id, confirm = false) {
    /**
     * set ract context hook of current deck
     *
     * @function
     */
    if (id.length) {
      const newDeck = decks.find((d) => d.id == id)
      const deckSet = setDeckId(id, confirm)
      if (deckSet) {
        const newScreen = screens.find((s) => s.id == newDeck.ScreenId)
        if (newScreen) {
          setScreenId(newScreen.id, deckSet)
        } else {
          setDeckRevisionId('')
          setDeckRevision({})
        }
      }
    } else {
      const deckSet = setDeckId(id, confirm)
      if (deckSet) {
        setScreenId(id)
        setDeckRevisionId(id)
        setDeckRevision({})
      }
    }
  }

  const StyledSelect = styled(Select)(() => ({
    '& .MuiSelect-outlined': {
      fontSize: '14px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: '2px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0
    }
  }))

  //note that each dropdown filters in order. So, CustomerId filters the Sites, SiteId filters the Locations, etc.
  return (
    <Box className="deck-selector">
      <StyledSelect value={customerId} onChange={(e) => chooseCustomer(e.target.value)}>
        <MenuItem value={defaultString}>Select a Customer</MenuItem>
        {customers.map((c) => {
          return (
            <MenuItem key={`${c.id}`} value={`${c.id}`}>
              {c.Name}
            </MenuItem>
          )
        })}
      </StyledSelect>
      {customerId.length ? (
        <StyledSelect value={siteId} onChange={(e) => chooseSite(e.target.value)}>
          <MenuItem value={defaultString}>Select a Site</MenuItem>
          {sites
            .filter((s) => s.CustomerId == customerId)
            .map((s) => {
              return (
                <MenuItem key={`${s.id}`} value={`${s.id}`}>
                  {s.Name}
                </MenuItem>
              )
            })}
        </StyledSelect>
      ) : null}
      {siteId.length ? (
        <StyledSelect value={locationId} onChange={(e) => chooseLocation(e.target.value)}>
          <MenuItem value={defaultString}>Select a Location</MenuItem>
          {locations
            .filter((l) => l.SiteId == siteId)
            .map((l) => {
              return (
                <MenuItem key={`${l.id}`} value={`${l.id}`}>
                  {l.Name}
                </MenuItem>
              )
            })}
        </StyledSelect>
      ) : null}
      {locationId.length ? (
        <StyledSelect value={equipmentId} onChange={(e) => chooseEquipment(e.target.value)}>
          <MenuItem value={defaultString}>Select a Screen</MenuItem>
          {filteredEquipment().map((e) => {
            return (
              <MenuItem key={`${e}`} value={`${e}`}>
                {`${e}`}
              </MenuItem>
            )
          })}
        </StyledSelect>
      ) : null}
      {equipmentId.length ? (
        <StyledSelect value={deckId} onChange={(e) => chooseDeck(e.target.value, true)}>
          <MenuItem value={defaultString}>Select a Deck</MenuItem>
          {decks
            .filter(
              (d) =>
                d.CustomerId == customerId &&
                d.SiteId == siteId &&
                d.LocationId == locationId &&
                d.DeckHeader.EquipmentId == equipmentId
            )
            .map((d) => {
              return (
                <MenuItem key={`${d.id}`} value={`${d.id}`}>
                  {`${d.DeckHeader.DeckLevel} ${d.DeckHeader.MaterialNumber}`}
                </MenuItem>
              )
            })}
        </StyledSelect>
      ) : null}
    </Box>
  )
}

export default DeckSelector
