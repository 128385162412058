/**
 *
 * "Array helper methods"
 *
 * @file   arrays.js
 * @author Lateral
 * @since  2023
 */

export const groupBy =
  (key) =>
  (array = []) =>
    /**
     *  Create object from key and array values
     *
     * @function
     * @param {string} key - Keys of object
     * @param {number} array - Values in array
     * @returns {object} Object with key as keys and value from array.
     */
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {}) ?? []
