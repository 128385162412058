import React, { useState } from 'react'
import { AnalysisDrawer } from './AnalysisDrawer'
import { DrawerItem } from './DrawerItem'
import { ReplacePanelResults } from './ReplacePanelDecisionResults'
import { Close } from '@mui/icons-material'
import { useWearAppAnalysisContext } from '../../WearAppAnalysisContext'

export const ResultsDrawer = () => {
  const {
    currentAnalysisResultsRef: {
      current: { results }
    }
  } = useWearAppAnalysisContext()
  // const selectedPanel = useSelector(
  //   (state: WearAppState) => state.analysis.selectedPanel
  // )
  const [showResults, setShowResults] = useState(true)

  return (
    <AnalysisDrawer setShowResults={setShowResults} showResults={showResults}>
      <ReplacePanelResults detail>
        <Close
          style={{
            cursor: 'pointer'
          }}
          onClick={() => setShowResults(false)}
        />
      </ReplacePanelResults>
      <DrawerItem isGray title="Average Aperture Width:">
        <p>{results?.w_mean?.toFixed(2)}mm</p>
      </DrawerItem>

      <DrawerItem title="95th Percentile Aperture Width:">
        <p>{results?.w_p95?.toFixed(2)}mm</p>
      </DrawerItem>

      <DrawerItem isGray title="Open Area:">
        <p>{(results?.per_oa ?? 0).toFixed(1)}%</p>
      </DrawerItem>
    </AnalysisDrawer>
  )
}
