/**
 *
 * "A confirmation form for restoring a Revision.""
 *
 * @file   EditRevisionForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function RestoreForm({ data, onSubmit }) {
  /**
   * Generates a form to edit Revision details
   *
   * @function
   *
   * @param {object} data - Deck revision data
   * @param {string} data.id - Revision ID
   * @param {} onSubmit - Action su8bmitting the form
   *
   * @returns {object} - Form to restore deck revison
   */

  const defaultValues = {
    Id: data?.Id ?? ''
  }

  const { handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body1" component="p">
            Are you sure you want to restore
            <strong>{` ${data?.Name}`}</strong>?
          </Typography>
          <Typography variant="body1" component="p">
            This will restore all panel data and cannot be reversed.
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default RestoreForm
