/**
 *
 * "Creates B.O.M. (Bill of material) table with panel material information in"
 * The table has two parts
 * Main part always view:
 * part has panels and sideliners material numberm, part number abd code
 * Optional additional part:
 * Details of the materials like weight, price and so on
 *
 * @file   BomTable.js
 * @author Lateral
 * @since  2023
 */
import {
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { groupBy } from 'common/arrays'
import React, { useEffect, useMemo, useState } from 'react'
import { useTheme, styled } from '@mui/material/styles'
import BigNumber from 'bignumber.js'
import { DeckMode } from 'common/deckMode'
import { DeckAccordion } from 'pages/deck/common/DeckAccordion'
import { useGetMaterials } from 'hooks/database/useGetMaterials'

const StyledTypographyMaxWidth = styled(Typography)(() => ({
  maxWidth: '200px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}))

const StyledCell = styled(TableCell)(() => ({
  minWidth: '100px'
}))

const sideLinerName = 'sideliner'
const panelName = 'panel'
const otherName = 'other'

let colors = []

function BomTable({
  data,
  selected,
  materialSelected,
  onClick,
  deckMode,
  heatMapData,
  showTitle,
  canGenerateColor,
  setDeckRevision
}) {
  /**
   *  Generates table for displaying BOM data.
   * 
   * @function
   * @param {object} data - Deck data with panel information
   * @param {string} data.DeckId - ID of the deck
   * @param {object[]} data.kits - Details of the materials used in the deck
   * @param {object[]} data.Panels - Details of the panels in the deck with material 
   * types and positon
   * @param {object[]} data.Sideliners - Details of the sideliners in the deck with material 
   * @param {object} data.Size - Row and column size of the deck
   * @param {string} data.id - Deck ID
   * @param {object[]} selected - Array of selected panel objects
   * @param {object[]} materialSelected - Materials of selected panels
   * @param {Array} onClick - Action on clicking on table
   * @param {string} deckMode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @param {object[]} heatMapData - Heatmap data of the panels and sideliners in heatmap page

   * @returns {object} - BOM table with material details of panels and sideliners
   */

  const [includeBom, setIncludeBom] = useState(true)

  const sideLiners = useMemo(() => {
    return groupBy('MaterialNumber')(data.SideLiners)
  }, [data.SideLiners, data.SideLiners?.length])

  const panels = useMemo(() => {
    return groupBy('MaterialNumber')(data.Panels)
  }, [data.Panels, data.Panels?.length])

  const kits = useMemo(() => {
    return [...(data.Kits ?? [])].sort((a, b) => a.MaterialNumber.localeCompare(b.MaterialNumber))
  }, [data.Kits, data.Kits?.length])

  let counter = 0
  const theme = useTheme()

  const [kitData, setKitData] = useState({
    sideLinerKits: [],
    panelKits: [],
    otherKits: []
  })
  const { materials } = useGetMaterials()

  useEffect(() => {
    const sideLinerKits = kits.filter((k) => panelTypeCheck(k.MaterialNumber) === sideLinerName)
    const panelKits = kits.filter((k) => panelTypeCheck(k.MaterialNumber) === panelName)
    const otherKits = kits.filter((k) => panelTypeCheck(k.MaterialNumber) === otherName)

    setKitData({
      sideLinerKits: sideLinerKits,
      panelKits: panelKits,
      otherKits
    })
  }, [kits])

  useEffect(() => {
    return () => {
      colors = []
    }
  }, [])

  const sideLinersMaterials = useMemo(() => {
    return kitData.sideLinerKits.map((s) => {
      return s.MaterialNumber
    })
  }, [kitData.sideLinerKits])

  const panelMaterials = useMemo(() => {
    return kitData.panelKits.map((s) => {
      return s.MaterialNumber
    })
  }, [kitData.panelKits])

  const otherMaterials = useMemo(() => {
    return kitData.otherKits.map((s) => {
      return s.MaterialNumber
    })
  }, [kitData.panelKits])

  const autoGenerateColor = (e, kit, i, type) => {
    if (!canGenerateColor || !setDeckRevision) {
      return
    }
    e.stopPropagation()
    let color = Math.floor(Math.random() * 16777215).toString(16)
    while (colors.includes(color) || color.length !== 6) {
      color = Math.floor(Math.random() * 16777215).toString(16)
    }
    colors.push(color)
    const kitIndex = data.Kits.findIndex(({ MaterialNumber }) => MaterialNumber === kit.MaterialNumber)
    const dataCloned = structuredClone(data)
    setKitData((previous) => {
      const items = previous[type]
      previous[type].splice(i, 1, {
        ...kit,
        Colour: `#${color}`
      })
      return {
        ...previous,
        [type]: [...items]
      }
    })
    dataCloned.Kits.splice(kitIndex, 1, {
      ...data.Kits[kitIndex],
      Colour: `#${color}`
    })
    setDeckRevision(dataCloned)
  }

  function panelTypeCheck(material) {
    const panelKeys = Object.keys(panels)
    const sideLinerKeys = Object.keys(sideLiners)

    if (panelKeys.includes(material)) {
      return panelName
    }

    if (sideLinerKeys.includes(material)) {
      return sideLinerName
    }

    return otherName
  }

  function getWearLife(materialNumber, panelType) {
    let results = []
    if (panelType === panelName) {
      results = heatMapData.Panels.filter((p) => p.MaterialNumber === materialNumber)
    } else if (panelType === sideLinerName) {
      results = heatMapData.SideLiners.filter((s) => s.MaterialNumber === materialNumber)
    } else {
      return 0
    }
    const sum = results.reduce((partialSum, a) => partialSum + a.WearCalculation.toNumber(), 0)

    return new BigNumber(sum).dividedBy(results.length).toFormat(2)
  }

  const getKitColorStyling = (kit) => {
    return { minWidth: canGenerateColor ? '10px' : '5px', padding: 0, backgroundColor: `${kit.Colour}` }
  }

  return (
    <DeckAccordion title="B.O.M">
      <Stack sx={{ height: '100%' }}>
        <Stack direction="row" sx={{ alignItems: 'center', height: 'min-content !important' }}>
          {showTitle && <Typography variant="h5" component="h5"></Typography>}
          {/* Button to switch for additional optional columns */}
          {deckMode !== DeckMode.heatMap ? (
            <Stack direction="row" sx={{ width: '100%', alignItems: 'center', justifyContent: 'end' }}>
              <Typography variant="h5" component="h5">
                Include B.O.M Items
              </Typography>
              <Switch checked={includeBom} onChange={() => setIncludeBom(!includeBom)} color="secondary" />
            </Stack>
          ) : null}
        </Stack>

        <TableContainer
          sx={{
            maxHeight: '100%',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '2px',
              height: '2px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.supporting.dark
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.supporting.pale,
              borderRadius: 2
            }
          }}>
          <Table stickyHeader sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {/* Main part */}
                <TableCell sx={{ width: '10px', padding: 0 }}></TableCell>
                <TableCell>Item</TableCell>
                <TableCell sx={{ width: '16px' }}>Quantity</TableCell>
                <StyledCell>Material Number</StyledCell>
                <StyledCell>Description</StyledCell>
                <StyledCell>Part Number</StyledCell>

                <StyledCell>Code</StyledCell>
                {deckMode === DeckMode.heatMap ? (
                  <StyledCell>Wear Life</StyledCell>
                ) : includeBom ? (
                  <>
                    {/* optional part */}
                    <StyledCell>Weight</StyledCell>
                    <StyledCell>Open Area</StyledCell>
                    <StyledCell>Working Depth</StyledCell>
                    <StyledCell>Failure Working Depth</StyledCell>
                    <StyledCell>Aperture Width</StyledCell>
                    <StyledCell>Failure Aperture Width</StyledCell>
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Separate Panels, Sideliners, and Other, to maintain table structure */}
              <TableRow>
                <StyledCell sx={{ px: '1em', py: '0.1em', backgroundColor: theme.palette.primary.light }} colSpan="14">
                  PANELS
                </StyledCell>
              </TableRow>
              {kitData.panelKits.map((kit, i) => {
                const total = panels[kit.MaterialNumber]?.length ?? 0
                const isHighlighted =
                  selected.find((s) => s.MaterialNumber === kit.MaterialNumber) ||
                  materialSelected == kit.MaterialNumber
                counter += 1
                return (
                  <TableRow
                    key={`${kit.MaterialNumber}_${i}`}
                    sx={{ backgroundColor: isHighlighted ? theme.palette.primary.light : '' }}
                    onClick={() => onClick(kit, panelMaterials, panelName)}>
                    <TableCell
                      onClick={(e) => {
                        autoGenerateColor(e, kit, i, 'panelKits')
                      }}
                      sx={getKitColorStyling(kit)}
                    />
                    <TableCell>{counter}</TableCell>
                    <TableCell>{total}</TableCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">
                        {kit.MaterialDescription ? kit.MaterialNumber : ''}
                      </StyledTypographyMaxWidth>{' '}
                    </StyledCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">
                        {kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber}
                      </StyledTypographyMaxWidth>
                    </StyledCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">{kit.PartNumber}</StyledTypographyMaxWidth>{' '}
                    </StyledCell>

                    <StyledCell>{kit.CustomerStockCode}</StyledCell>
                    {deckMode === DeckMode.heatMap ? (
                      <StyledCell>{`${getWearLife(kit.MaterialNumber, panelName)} Weeks`}</StyledCell>
                    ) : includeBom ? (
                      <>
                        <StyledCell>{kit.Weight}</StyledCell>
                        <StyledCell>{kit.OpenArea}</StyledCell>
                        <StyledCell>{kit.WorkingDepth}</StyledCell>
                        <StyledCell>
                          {kit.FailureWorkingDepth ||
                            materials.find(({ MaterialNumber }) => MaterialNumber === kit.MaterialNumber)?.SafetyDepth}
                        </StyledCell>
                        <StyledCell>{kit.ApertureWidth}</StyledCell>
                        <StyledCell>{kit.FailureApertureWidth}</StyledCell>
                      </>
                    ) : null}
                  </TableRow>
                )
              })}

              <TableRow>
                <StyledCell sx={{ px: '1em', py: '0.1em', backgroundColor: theme.palette.primary.light }} colSpan="14">
                  SIDELINERS
                </StyledCell>
              </TableRow>
              {kitData.sideLinerKits.map((kit, i) => {
                const total = sideLiners[kit.MaterialNumber]?.length ?? 0
                const isHighlighted =
                  selected.find((s) => s.MaterialNumber === kit.MaterialNumber) ||
                  materialSelected == kit.MaterialNumber

                counter += 1
                return (
                  <TableRow
                    key={`${kit.MaterialNumber}_${i}`}
                    sx={{ backgroundColor: isHighlighted ? theme.palette.primary.light : '' }}
                    onClick={() => onClick(kit, sideLinersMaterials, sideLinerName)}>
                    <TableCell
                      onClick={(e) => {
                        autoGenerateColor(e, kit, i, 'sideLinerKits')
                      }}
                      sx={getKitColorStyling(kit)}
                    />
                    <TableCell>{counter}</TableCell>
                    <TableCell>{total}</TableCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">
                        {kit.MaterialDescription ? kit.MaterialNumber : ''}
                      </StyledTypographyMaxWidth>{' '}
                    </StyledCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">
                        {kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber}
                      </StyledTypographyMaxWidth>{' '}
                    </StyledCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">{kit.PartNumber}</StyledTypographyMaxWidth>{' '}
                    </StyledCell>

                    <StyledCell>{kit.CustomerStockCode}</StyledCell>
                    {deckMode === DeckMode.heatMap ? (
                      <StyledCell>{`${getWearLife(kit.MaterialNumber, sideLinerName)} Weeks`}</StyledCell>
                    ) : includeBom ? (
                      <>
                        <StyledCell>{kit.Weight}</StyledCell>
                        <StyledCell>{kit.OpenArea}</StyledCell>
                        <StyledCell>{kit.WorkingDepth}</StyledCell>
                        <StyledCell>{kit.FailureWorkingDepth}</StyledCell>
                        <StyledCell>{kit.ApertureWidth}</StyledCell>
                        <StyledCell>{kit.FailureApertureWidth}</StyledCell>
                      </>
                    ) : null}
                  </TableRow>
                )
              })}

              <TableRow>
                <StyledCell sx={{ px: '1em', py: '0.1em', backgroundColor: theme.palette.primary.light }} colSpan="14">
                  OTHERS
                </StyledCell>
              </TableRow>
              {kitData.otherKits.map((kit, i) => {
                const isHighlighted =
                  selected.find((s) => s.MaterialNumber === kit.MaterialNumber) ||
                  materialSelected == kit.MaterialNumber
                counter += 1
                return (
                  <TableRow
                    key={`${kit.MaterialNumber}_${i}`}
                    sx={{ backgroundColor: isHighlighted ? theme.palette.primary.light : '' }}
                    onClick={() => onClick(kit, otherMaterials, otherName)}>
                    <TableCell
                      onClick={(e) => {
                        autoGenerateColor(e, kit, i, 'otherKits')
                      }}
                      sx={getKitColorStyling(kit)}
                    />
                    <TableCell>{counter}</TableCell>
                    <TableCell>{kit.Quantity ?? 0}</TableCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">
                        {kit.MaterialDescription ? kit.MaterialNumber : ''}
                      </StyledTypographyMaxWidth>{' '}
                    </StyledCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">
                        {kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber}
                      </StyledTypographyMaxWidth>{' '}
                    </StyledCell>
                    <StyledCell>
                      <StyledTypographyMaxWidth variant="body2">{kit.PartNumber}</StyledTypographyMaxWidth>{' '}
                    </StyledCell>

                    <StyledCell>{kit.CustomerStockCode}</StyledCell>
                    {deckMode === DeckMode.heatMap ? (
                      <StyledCell>0 Weeks</StyledCell>
                    ) : includeBom ? (
                      <>
                        <StyledCell>{kit.Weight}</StyledCell>
                        <StyledCell>{kit.OpenArea}</StyledCell>
                        <StyledCell>{kit.WorkingDepth}</StyledCell>
                        <StyledCell>{kit.FailureWorkingDepth}</StyledCell>
                        <StyledCell>{kit.ApertureWidth}</StyledCell>
                        <StyledCell>{kit.FailureApertureWidth}</StyledCell>
                      </>
                    ) : null}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </DeckAccordion>
  )
}

export default BomTable
