/**
 *
 * "Deck table under screen assets"
 *
 * @file   DeckTable.js
 * @author Lateral
 * @since  2023
 */
import React, { useContext, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { visuallyHidden } from '@mui/utils'
import { getObjectComparator } from 'common'
import { ContextMenu, SCOPES } from 'components'
import { useNavigate } from 'react-router-dom'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { useCurrentUser } from 'hooks'

//Headings of table
const headCells = [
  {
    id: 'DeckLevel',
    numeric: false,
    disablePadding: false,
    label: 'Deck Level'
  },
  {
    id: 'MaterialNumber',
    numeric: false,
    disablePadding: false,
    label: 'Kit Number'
  },
  {
    id: 'Kits',
    numeric: false,
    disablePadding: false,
    label: 'Kit Items'
  },
  {
    id: 'Columns',
    numeric: false,
    disablePadding: false,
    label: 'Columns'
  },
  {
    id: 'Rows',
    numeric: false,
    disablePadding: false,
    label: 'Rows'
  },
  {
    id: 'RevisionNumber',
    numeric: false,
    disablePadding: false,
    label: 'Revision'
  },
  {
    id: 'RevisionDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Revision Date'
  }
]

// Sortable table head
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ border: 'none' }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

function DeckTable({ decks, onEditDeck, onCloneDeck }) {
  /**
   * Component for viewing the Deck sub-table.
   *
   * @function
   *
   * @param {object[]} decks - Array of deck objects
   * @param {} onEditDeck - Action on clicking 'Edit Details'
   * @param {} onCloneDeck - Action on clicking 'Clone'
   *
   * @returns {object} - A table with decks
   */

  const { currentUser } = useCurrentUser()

  const rows = decks.map((deck) => {
    return {
      id: deck.id,
      CompanyId: deck.DeckHeader.CustomerId,
      SiteId: deck.DeckHeader.SiteId,
      LocationId: deck.DeckHeader.LocationId,
      ScreenId: deck.DeckHeader.ScreenId,
      EquipmentId: deck.DeckHeader.EquipmentId,
      DeckLevel: deck.DeckHeader.DeckLevel,
      Kits: deck.Revision?.Kits?.length ?? 0,
      MaterialNumber: deck.DeckHeader.MaterialNumber,
      SerialNumber: deck.DeckHeader.SerialNumber,
      Columns: deck.DeckHeader.DeckSize.Columns,
      Rows: deck.DeckHeader.DeckSize.Rows,
      RevisionId: deck.Revision?.id,
      RevisionNumber: deck.RevisionNumber,
      RevisionDate: deck.RevisionDate
    }
  })

  const {
    setCustomerId,
    setSiteId,
    setLocationId,
    setEquipmentId,
    setScreenId,
    setDeckId,
    setDeckRevisionId,
    setDeckRevision,
    database
  } = useContext(CurrentDeckContext)
  const navigate = useNavigate()
  const theme = useTheme()

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('customerName')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function navigateTo(row, endpoint) {
    setCustomerId(row.CompanyId)
    setSiteId(row.SiteId)
    setLocationId(row.LocationId)
    setEquipmentId(row.EquipmentId)
    setScreenId(row.ScreenId)
    setDeckId(row.id)
    setDeckRevisionId(row.RevisionId)
    setDeckRevision(
      structuredClone(
        database.deckRevisions.filter((d) => d.DeckId === row.id).sort((a, b) => b.RevisionNumber - a.RevisionNumber)[0]
      )
    )

    navigate(endpoint)
  }

  return (
    <TableContainer
      sx={{
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table sx={{ minWidth: 750, maxWidth: 850 }} size="small">
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {rows &&
            rows.sort(getObjectComparator(order, orderBy)).map((row, i) => {
              const menuItems = []

              if (currentUser?.hasAtLeastOneScope([SCOPES.deckLayoutReview.Write], row.SiteId)) {
                menuItems.push({
                  title: 'Edit Details',
                  action: () => {
                    onEditDeck(row)
                  }
                })
              }

              menuItems.push({ title: 'View Deck', action: () => navigateTo(row, '/equipment/decklayout') })

              if (currentUser?.hasAtLeastOneScope([SCOPES.deckMaintenance.Write], row.SiteId)) {
                menuItems.push({ title: 'Maintenance', action: () => navigateTo(row, '/equipment/maintenance') })
              }

              if (currentUser?.hasAtLeastOneScope([SCOPES.deckLayoutReview.Write], row.SiteId)) {
                menuItems.push({ title: 'Edit Deck', action: () => navigateTo(row, '/equipment/edit') })
              }

              if (currentUser?.hasAtLeastOneScope([SCOPES.heatmap.Read], row.SiteId)) {
                menuItems.push({ title: 'Heat Map', action: () => navigateTo(row, '/equipment/heatmap') })
              }

              menuItems.push({ title: 'History', action: () => navigateTo(row, '/equipment/history') })

              if (currentUser?.hasAtLeastOneScope([SCOPES.deckLayoutReview.Write], row.SiteId)) {
                menuItems.push({
                  title: 'Clone',
                  action: () => {
                    onCloneDeck(row)
                  }
                })
              }

              return (
                <TableRow key={i} hover tabIndex={-1}>
                  <TableCell>{row?.DeckLevel}</TableCell>
                  <TableCell>{row?.MaterialNumber}</TableCell>
                  <TableCell>{row?.Kits}</TableCell>
                  <TableCell>{row?.Columns}</TableCell>
                  <TableCell>{row?.Rows}</TableCell>
                  <TableCell>{row?.RevisionNumber}</TableCell>
                  <TableCell>{row?.RevisionDate}</TableCell>
                  <TableCell>
                    <ContextMenu context={row} menuItems={menuItems} />
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default DeckTable
