import { getApiClient } from './apiClient'

const apiClient = getApiClient(process.env.REACT_APP_PANEL_CORNER_DETECTION_BACKEND_URL)

export const detectCorners = async (imageKey, siteId) => {
  try {
    const { data } = await apiClient.post(
      '/detect',
      {
        image_key: imageKey
      },
      {
        headers: {
          env: process.env.REACT_APP_APP_ENV,
          'site-id': siteId
        }
      }
    )
    return {
      isSuccess: data.statusCode === 200 && !data.body.error,
      result: JSON.parse(data.body.result ?? '[]')
    }
  } catch (e) {
    return {
      isSuccess: false,
      result: []
    }
  }
}
