/**
 *
 * "A modal form for importing a Deck.
 * The form shows dropdown lists to slect customer > site >
 * Location > Dry product > equipment > Deck"
 *
 * @file   ImportForm.js
 * @author Lateral
 * @since  2023
 */
import React, { useContext } from 'react'
import Grid from '@mui/material/Grid'
import { useForm, Controller } from 'react-hook-form'
import { Select, MenuItem, Typography } from '@mui/material'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'

const DefaultString = ''
const CustomerString = 'CustomerId'
const LocationString = 'LocationId'
const SiteString = 'SiteId'
const EquipmentString = 'EquipmentId'
const DeckString = 'DeckId'

export function ImportForm({ onSubmit }) {
  /**
   * GEnerates a modal form with dropdown list to select deck to import
   *
   * @function
   *
   * @param {} onSubmit - Action on submitting deck import form
   *
   * @returns {object} - Modal form
   */
  const defaultValues = {
    Description: ''
  }
  const { watch, handleSubmit, control } = useForm({ defaultValues })
  const {
    database: { decks, customers, sites, locations }
  } = useContext(CurrentDeckContext)

  const watchCustomerId = watch(CustomerString, DefaultString)
  const watchLocationId = watch(LocationString, DefaultString)
  const watchSiteId = watch(SiteString, DefaultString)
  const watchEquipmentId = watch(EquipmentString, DefaultString)

  const filteredDecks = () => {
    const filteredDecks = decks.filter(
      (d) => d.CustomerId === watchCustomerId && d.SiteId === watchSiteId && d.LocationId === watchLocationId
    )
    return [
      ...new Set(
        filteredDecks.map((f) => {
          return f.DeckHeader.EquipmentId
        })
      )
    ]
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {/* Dropdown list for Customer */}
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Customer
          </Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            name={CustomerString}
            control={control}
            defaultValue={DefaultString}
            render={({ field }) => (
              <Select {...field} color="secondary" fullWidth>
                <MenuItem key={DefaultString} value={DefaultString}>
                  Select a Customer
                </MenuItem>
                {customers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        {watchCustomerId ? (
          <>
            {/* Dropdown list for Site */}
            <Grid item md={3}>
              <Typography variant="h5" component="h5">
                Site
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Controller
                name={SiteString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem key={DefaultString} value={DefaultString}>
                      Select a Site
                    </MenuItem>
                    {sites
                      .filter((s) => s.CustomerId === watchCustomerId)
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.Name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchSiteId ? (
          <>
            {/* Dropdown list for Location */}
            <Grid item md={3}>
              <Typography variant="h5" component="h5">
                Location
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Controller
                name={LocationString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem key={DefaultString} value={DefaultString}>
                      Select a Location
                    </MenuItem>
                    {locations
                      .filter((l) => l.SiteId === watchSiteId)
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.Name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchLocationId ? (
          <>
            {/* Dropdown list for Equipment */}
            <Grid item md={3}>
              <Typography variant="h5" component="h5">
                Equipment
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Controller
                name={EquipmentString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem value={DefaultString}>Select a Screen</MenuItem>
                    {filteredDecks().map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchEquipmentId ? (
          <>
            {/* Dropdown list for Deck */}
            <Grid item md={3}>
              <Typography variant="h5" component="h5">
                Deck
              </Typography>
            </Grid>
            <Grid item md={9}>
              <Controller
                name={DeckString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem value={DefaultString}>Select a Deck</MenuItem>
                    {decks
                      .filter(
                        (d) =>
                          d.CustomerId == watchCustomerId &&
                          d.SiteId == watchSiteId &&
                          d.LocationId == watchLocationId &&
                          d.DeckHeader.EquipmentId == watchEquipmentId
                      )
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.DeckHeader.DeckLevel} ${option.DeckHeader.MaterialNumber}`}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </form>
  )
}
export default ImportForm
