/**
 *
 * "Dialogue page to view and interact with dialogue table"
 *
 * @file   DialoguePage.js
 * @author Lateral
 * @since  2023
 */

import React, { useState, useContext } from 'react'
import { Box, Stack, Button, Typography, OutlinedInput } from '@mui/material'
import { observeEquipment, useCurrentUser } from 'hooks'
import DialogueTable from './components/DialogueTable'
import { ReactComponent as AddCommentIcon } from 'assets/icons/Add-Comment.svg'
import AddNewDialogueModal from './components/AddNewDialogueModal'
import DialogueContextMenu from './components/DialogueContextMenu'
import { FormDialog, useNotification } from 'components'
import { addDialogue, updateDialogue, deleteDialogue } from './common/data'
import { log } from 'common'
import { defaultRoutes } from 'pages'
import { Header } from 'components'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import SearchIcon from '@mui/icons-material/Search'
import dayjs from 'dayjs'

function DialoguePage() {
  /**
   * Generates Dialogue page
   *
   * @function
   *
   * @returns {object} - Dialogue page
   */
  const { getUserName, getTransactionMetaData } = useCurrentUser()
  const { notify } = useNotification()
  const { database } = useContext(CurrentDeckContext)
  const equipment = observeEquipment()
  const [searchText, setSearchText] = useState('')
  const [timer, setTimer] = useState('')
  const [openNewDialogue, setOpenNewDialogue] = useState({ isOpen: false })
  const [deckFilter, setDeckFilter] = useState([])

  function applyFilter(event, value, filterName) {
    const filters = deckFilter.filter((x) => x.name !== filterName) ?? []
    const newArr = [...filters]
    if (value) {
      newArr.push({
        name: filterName,
        filterFn: (x) => {
          return x[filterName]?.Name === value?.Name
        }
      })
    }
    setDeckFilter(newArr)
  }

  // Handle dialogue objects after closing add/edit dialogue modal
  async function handleDialogueItemDialogClose(modalState) {
    if (modalState?.isSave) {
      const data = modalState.data
      data.User = getUserName()
      data.Date = dayjs()
      data.CustomerId = database.customers.find((c) => c.Name === data.Company)?.id

      try {
        if (data.id) {
          //Update Item
          await updateDialogue(data, getTransactionMetaData())
          notify(`Dialogue updated successfully`)
        } else {
          //Add Item
          const newDialogue = await addDialogue(data, getTransactionMetaData())
          applyFilter({}, newDialogue, 'dialogue')
          notify(`Added Dialogue Successfully`)
        }
      } catch (error) {
        log.error(JSON.stringify(error))
        notify(`An error occurred whilst attempting to save`)
      }
    }
    setOpenNewDialogue({ isOpen: false })
  }

  // Handle CRUD of dialogue table items
  async function handleScreenItemEvent(eventName, context) {
    if (eventName === 'edit') {
      setOpenNewDialogue({ isOpen: true, title: 'Edit Dialogue', buttonText: 'Update', data: context })
    } else if (eventName === 'delete') {
      await deleteDialogue(context)
      notify(`Deleted Dialogue Successfully`)
    }
  }

  // Action on changing search text
  function onSearchChange(value) {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => onTimeoutSubmit(value), 500)
    setTimer(timeoutId)
  }

  async function onTimeoutSubmit(value) {
    setSearchText(value)
  }

  return (
    <Box sx={{ px: '15px' }}>
      <Header routes={defaultRoutes}></Header>
      <Stack>
        <Typography variant="h1">Dialogue</Typography>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end', minWidth: '200px', marginBottom: '1em' }}>
          {/* Search box */}
          <OutlinedInput
            color="secondary"
            sx={{ height: '32px' }}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            placeholder="Search"
            endAdornment={<SearchIcon color="secondary" />}
          />
          {/* Add dialogue button */}
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCommentIcon style={{ height: '1em', width: '1em' }} />}
            onClick={() => [setOpenNewDialogue({ isOpen: true, title: 'New Dialogue', buttonText: 'Create' })]}>
            Add Dialogue
          </Button>
        </Stack>
      </Stack>
      {/* Dialogue table */}
      <DialogueTable
        customers={database.customers}
        locations={database.locations}
        sites={database.sites}
        decks={database.decks}
        equipment={equipment}
        getUser={getUserName}
        searchText={searchText}
        dialogueContextMenu={
          <DialogueContextMenu handleEvent={(event, context) => handleScreenItemEvent(event, context)} />
        }></DialogueTable>
      {openNewDialogue.isOpen && (
        <FormDialog modalState={openNewDialogue} onOpenChange={handleDialogueItemDialogClose}>
          <AddNewDialogueModal
            customers={database.customers}
            locations={database.locations}
            sites={database.sites}
            decks={database.decks}
            equipment={equipment}
          />
        </FormDialog>
      )}
    </Box>
  )
}
export default DialoguePage
