/**
 *
 * "A form to add new company or update/Delete existing company"
 *
 * @file   AddCompanyForm.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Button, Stack, Typography } from '@mui/material'

export function AddCompanyForm({ data, onSubmit }) {
  /**
   *
   * Form to ad new company or delete/update existing company
   *
   * @const
   *
   * @param {object} data - Company details
   * @param {object} data.id - Company id (For existing company)
   * @param {string} data.CompanyName - Company name
   * @param {string} data.CompanyEmail - Company email
   * @param {string} data.Logo - Company logo id
   * @param {string} data.LogoName - Company logo name
   * @param {} onSubmit - Action on submiting the form
   *
   * @returns {object} - Form to add, update, and delete company
   */
  const defaultValues = {
    Id: data?.id ?? '',
    Name: data?.CompanyName ?? '',
    Contact: data?.CompanyContact ?? null,
    Email: data?.CompanyEmail ?? '',
    Logo: data?.Logo ?? '',
    LogoName: data?.LogoName ?? ''
  }

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ defaultValues })
  const [logoName, setLogoName] = useState(data?.LogoName)

  function onLogoChange(e) {
    const file = e.target.files[0]
    if (file) {
      if (file.size > 2097152) {
        setError('Logo', { message: 'File too big! Please make sure it is 2MB or less.' })
      } else if (!file.type.includes('image') && !file.type.includes('img')) {
        setError('Logo', { message: 'Wrong file type! Please choose an image file.' })
      } else {
        setLogoName(file.name)
        clearErrors()
      }
    }
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Company Name
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Name"
            name="Name"
            placeholder="e.g. Schenk Processing"
            color="secondary"
            fullWidth
            {...register('Name', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Company Contact
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Contact"
            name="Contact"
            placeholder="e.g. Bob Bobson"
            color="secondary"
            fullWidth
            {...register('Contact', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Contact Email
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Email"
            name="Email"
            placeholder="e.g. user@schenckprocess.com"
            color="secondary"
            fullWidth
            {...register('Email', { required: true })}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Logo
          </Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" color="secondary" component="label">
              Upload
              <input hidden accept="image/*" type="file" {...register('Logo', { onChange: (e) => onLogoChange(e) })} />
            </Button>
            <Typography>{logoName}</Typography>
          </Stack>
          {errors['Logo'] && <Typography>{errors['Logo'].message}</Typography>}
        </Grid>
      </Grid>
    </form>
  )
}
export default AddCompanyForm
