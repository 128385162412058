/**
 *
 * Renders a circle in 3D space to be clicked for adding.deleting columns/rows
 *
 * @file   Selector.js
 * @author Lateral
 * @since  2023
 */
import { Line } from '@react-three/drei'
import React, { useState } from 'react'

/*

*/
export function Selector({ theme, circlePosition, startPosition, endPosition, onClick }) {
  /**
   * Provides a circle object
   *
   * @function
   * @param {object} theme - Panel theme object
   * @param {number} circlePosition - Position of the circle in canvas
   * @param {number} startPosition - Start point to highlight when pointer hover on selctor
   * @param {number} endPosition - End point to highlight when pointer hover on selctor
   * @param {Function} onClick - Action on clicking circle
   * @returns {object} - Cirlce object
   */
  const [isHighlighted, setIsHighlighted] = useState(false)

  return (
    <>
      <mesh
        onClick={onClick}
        onPointerOver={() => setIsHighlighted(true)}
        onPointerOut={() => setIsHighlighted(false)}
        position={circlePosition}
        scale={0.5}>
        <circleGeometry></circleGeometry>
        <meshStandardMaterial
          color={isHighlighted ? theme.palette.secondary.main : theme.palette.supporting.pale}></meshStandardMaterial>
      </mesh>
      {isHighlighted ? <Line color="black" lineWidth={5} points={[startPosition, endPosition]} /> : null}
    </>
  )
}
