/**
 *
 * "Convert big numbers to alphabet wthin base of 10-36"
 *
 * @file   numberToAlphabet.js
 * @author Lateral
 * @since  2023
 */
export function toAlphabet(bigNumber, upperCase = true) {
  /**
   * Convert number to String in base of 36
   *
   * @function
   * @param {number} bigNumber - Type BigNumber from bigNumber module
   * @param {boolean} upperCase - Whether to return the string as uppercase or not
   * @returns {String} String of the Number converted on base 36 (0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ)
   */

  const result = bigNumber.plus(9).toString(36)
  return upperCase ? result.toUpperCase() : result
}
