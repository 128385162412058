/**
 *
 * "A wrapper for all modal forms with similar look"
 *
 * @file   DeckSelector.js
 * @author Lateral
 * @since  2023
 */

import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled, useTheme } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'

export const FormDialog = React.memo(function FormDialog_({
  modalState,
  onOpenChange,
  children,
  center = false,
  maxWidth = 'sm',
  isMobile = false,
  isLoading = false
}) {
  /**
   *
   *
   * @const
   * @returns Reat dialog element as a wrapper fro modal forms
   */
  const theme = useTheme()

  if (modalState.data) {
    modalState.originalData = modalState.data
  }

  const StyledDialog = styled(Dialog)(() => [
    {
      '& .MuiPaper-root': {
        background: theme.palette.supporting.black
      }
    },
    isMobile
      ? {
          '& .MuiPaper-root': {
            width: '100%',
            margin: '0'
          }
        }
      : null
  ])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    onOpenChange(modalState)
  }

  const onSubmit = async (data) => {
    modalState.isSave = true
    modalState.data = data

    handleClose(data)
  }

  return (
    <StyledDialog fullWidth maxWidth={maxWidth} open onClose={handleClose} sx={center ? { textAlign: 'center' } : null}>
      <DialogTitle variant="h5">{modalState.title}</DialogTitle>

      <DialogContent>{React.cloneElement(children, { data: modalState.data, onSubmit })}</DialogContent>

      <DialogActions sx={center ? { justifyContent: 'center' } : null}>
        <Button disabled={isLoading} variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        {modalState?.buttonText && (
          <LoadingButton loading={isLoading} variant="contained" color="secondary" form={`form`} type="submit">
            {modalState?.buttonText ?? 'Create'}
          </LoadingButton>
        )}
      </DialogActions>
    </StyledDialog>
  )
})
