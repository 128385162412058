import React from 'react'

export const DrawerItem = ({ title, isGray, children }) => {
  return (
    <div
      style={{
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: isGray ? 'lightgray' : 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0px 16px',
        alignItems: 'center'
      }}>
      <span>{title}</span>
      <span>{children}</span>
    </div>
  )
}
