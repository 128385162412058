import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText, Divider } from '@mui/material'
import { SandvikLogo } from './SanvikLogo'
import { Auth, API, Storage } from 'aws-amplify'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'
import { secondAccent } from 'mainTheme'
import { useNotification } from 'components'

const apiName = 'AdminQueries'

const getHeaders = async () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  }
}

const LegalItem = ({ value, legalText, checked, handleToggle }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        role={undefined}
        onClick={() => {
          handleToggle(value)
        }}
        dense>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
        </ListItemIcon>
        <ListItemText primary={legalText} />
      </ListItemButton>
    </ListItem>
  )
}

const keyValueMap = {
  0: 'first',
  1: 'second',
  2: 'third'
}

export const LegalDialogue = ({ refreshUser }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [legalAcceptance, setLegalAcceptance] = useState({
    first: false,
    second: false,
    third: false
  })
  const [downloadLinks, setDownloadLinks] = useState({
    eulDownloadLink: undefined,
    privacyDownloadLink: undefined
  })
  const isButtonEnabled = Object.keys(legalAcceptance).every((key) => !!legalAcceptance[key])

  const { notify } = useNotification()
  const fetchLegalDocuments = useQuery(
    ['fetchLegalDocuments'],
    async () => {
      return API.get(apiName, '/legal', {
        headers: await getHeaders()
      })
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  )

  const acceptLegalRequirement = useMutation({
    mutationFn: async () => {
      return API.patch(apiName, '/legal/accept', {
        headers: await getHeaders(),
        body: {}
      })
    },
    onSuccess: () => {
      notify('Successfully accepted legal requirement')
    },
    onError: () => {
      notify('An error occurred while processing your request')
    },
    retry: false
  })

  useEffect(() => {
    const fetchDownloadLinks = async () => {
      if (fetchLegalDocuments.data) {
        const eulDownloadLink = await Storage.get(
          `legal-documents/${fetchLegalDocuments.data.eulDocumentLocation.replace('.pdf', '')}.pdf`
        )
        const privacyDownloadLink = await Storage.get(
          `legal-documents/${fetchLegalDocuments.data.privacyPolicyDocumentLocation.replace('.pdf', '')}.pdf`
        )

        setDownloadLinks({
          eulDownloadLink,
          privacyDownloadLink
        })
      }
    }
    fetchDownloadLinks()
  }, [fetchLegalDocuments.data])

  const onSavePressed = async () => {
    setIsSaving(true)
    try {
      await acceptLegalRequirement.mutateAsync()
      await refreshUser()
    } finally {
      setIsSaving(false)
    }
  }
  return (
    <Dialog
      fullWidth
      open
      PaperProps={{
        sx: {
          zIndex: 100000
        }
      }}>
      <DialogTitle variant="h2">
        <Grid container justifyContent="space-between">
          <Grid item>
            <SandvikLogo className="loading" />
          </Grid>

          <Grid item>
            <Typography variant="body2">SRP Version 1.0 November 2023</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {fetchLegalDocuments.isInitialLoading || !downloadLinks ? (
          <Grid container justifyContent="center">
            <ProgressIndicator />
          </Grid>
        ) : (
          <>
            <Typography mb={4} variant="h5">
              DeckMapp and WearApp are hosted by Sandvik Rock Processing Australia Pty Limited, ACN 073 312 437, (“Us”).
              The Product is a cloud-based software that provides its users with support for the management of screening
              media consumables on vibrating machines for the mining, building and construction industries. Please read
              these documents carefully because the End User Licence (EUL), along with Our Privacy Policy and Personal
              Data Consent acknowledgement describe the legally binding agreement between You (referred to hereinafter
              as, “You”) and Us. These documents have been developed by Us to describe the limited Product License
              granted to You by Us, in order for You to use the DeckMapp and WearApp applications. This Agreement is
              immediately effective upon selecting the Tick Boxes below and selecting “Save”. If you do not accept the
              terms of these documents you will not be provided access to the Product and you should discuss your
              concerns with Us and your employer.
            </Typography>

            <List>
              {[
                {
                  text: (
                    <span>
                      The End User Licence (the “EUL”) applies for any and all access and use of the Digital Services
                      (as such is defined in Sandvik’s Digital Terms), including all content, functionality, dashboards,
                      data and information provided in the Digital Services. For further information on the terms of the
                      EUL refer to it{' '}
                      <a
                        target="_blank"
                        style={{ color: secondAccent }}
                        rel="noreferrer"
                        href={downloadLinks.eulDownloadLink}>
                        here
                      </a>
                      .
                    </span>
                  ),
                  key: 'EUL'
                },
                {
                  text: (
                    <span>
                      Sandvik Rock Processing Australia Pty Limited in Australia collects, holds, uses, discloses and
                      otherwise manages your Personal Information. Sandvik Rock Processing Australia Pty Limited
                      collects Personal Information in order to conduct its business and comply with a range of
                      legislative requirements. For further information on how we manage your Personal Information,
                      refer to our{' '}
                      <a
                        target="_blank"
                        style={{ color: secondAccent }}
                        rel="noreferrer"
                        href={downloadLinks.privacyDownloadLink}>
                        Privacy Policy
                      </a>
                      .
                    </span>
                  ),
                  key: 'PI'
                },
                {
                  text: (
                    <span>
                      We would like your consent (DeckMapp and WearApp): Sandvik collects and processes personal data
                      (such as device identifiers, IP addresses, and website interactions) for essential site functions
                      in delivering its digital services to connected equipment . Some of this technology is necessary
                      and can’t be turned off, while other technology is used only if you consent. You may accept or
                      reject the provision of your consent to the collection, storage use and disposal of this personal
                      data by clicking the appropriate button below. Visit our{' '}
                      <a
                        target="_blank"
                        style={{ color: secondAccent }}
                        rel="noreferrer"
                        href={downloadLinks.privacyDownloadLink}>
                        Privacy Policy
                      </a>{' '}
                      for more details on how we use personal data. We are unable to allow access at this time to our
                      digital services unless you are able to provide your consent.
                    </span>
                  ),
                  key: 'consent'
                }
              ].map((legal, index) => {
                const item = (
                  <LegalItem
                    key={legal.key}
                    value={index}
                    legalText={legal.text}
                    checked={!!legalAcceptance[keyValueMap[index]]}
                    handleToggle={(value) => {
                      setLegalAcceptance((legalAcceptance_) => {
                        return {
                          ...legalAcceptance_,
                          [keyValueMap[value]]: !legalAcceptance_[keyValueMap[value]]
                        }
                      })
                    }}
                  />
                )

                return item
              })}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!isButtonEnabled || isSaving || fetchLegalDocuments.isInitialLoading}
          loading={isSaving}
          variant="contained"
          color="secondary"
          onClick={onSavePressed}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
