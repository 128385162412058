/**
 *
 * "Defines logic for calculating HeatMap colours.
 *  Also includes rgb/hex conversions."
 *
 * @file   heatColours.js
 * @author Lateral
 * @since  2023
 */
import BigNumber from 'bignumber.js'

function pickHex(color1, color2, weight) {
  /**
   * Convert picked colour to weighted colour.
   *
   * @function
   * @param {number} color1 - User picked colour 1 hexadecimal value
   * @param {number} color2 - User picked colour 2 hexadecimal value
   * @returns {number} Weighted panel colour in hexadecimal value
   */
  const w1 = 1 - weight
  const w2 = weight
  const first = hexToRgb(color1)
  const second = hexToRgb(color2)
  const rgb = [
    Math.round(first.r * w1 + second.r * w2),
    Math.round(first.g * w1 + second.g * w2),
    Math.round(first.b * w1 + second.b * w2)
  ]
  return rgbToHex(rgb)
}

function componentToHex(c) {
  /**
   * Convert rgb single channel to hexadecimal
   *
   * @function
   * @param {number} c - r/g/b component from one channel
   * @returns {number} Hexadecimal value of single r/g/b component
   */
  const hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

function rgbToHex(rgb) {
  /**
   * Convert rgb colour value to hexadecimal
   *
   * @function
   * @param {number} rgb - rgb colour value with three channels
   * @returns {number} Hexadecimal value of rgb numbers
   */
  return '#' + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2])
}

function hexToRgb(hex) {
  /**
   * Convert hexadecimal value to rgb colour channels
   *
   * @function
   * @param {number} hex - Hexadecimal value
   * @returns {number} rgb channels
   */
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export function pickHeatColour(percentage, theme) {
  /**
   * Pick colour by wear of panels based on theme
   *
   * @async
   * @function
   * @param {number} percentage - Percentage of wear in panel
   * @param {object} theme - Theme object with pallet colours
   * @returns {number} Hexadecinmal value of panel color by wear amount
   */
  const amount = new BigNumber(percentage)
  const zero = theme.palette.heatmap.zero
  const forty = theme.palette.heatmap.forty
  const eighty = theme.palette.heatmap.eighty

  if (amount.isGreaterThanOrEqualTo(50)) {
    return pickHex(forty, eighty, new BigNumber(amount).minus(50).dividedBy(50))
  }

  return pickHex(zero, forty, amount.dividedBy(50))
}

export function getWearColour(detail, theme) {
  /**
   * Calculate the wear amount of a panel then pick its
   * colour based on the wear and theme
   *
   *
   * @async
   * @function
   * @param {object} detail - Panel details with working and original length and aperture
   * @param {object} theme - Theme object with pallet colours
   * @returns {number} Hexadecinmal value of panel color based on wear amount
   */
  const depthPercentage = new BigNumber(detail.WorkingDepth)
    .dividedBy(detail.OriginalWorkingDepth ?? 1)
    .multipliedBy(100) //Percentage of wear along depth
  const widthPercentage = new BigNumber(detail.ApertureWidth)
    .dividedBy(detail.OriginalApertureWidth ?? 1)
    .multipliedBy(100) //percentage of wear along aparature
  let percentage = new BigNumber(0)

  //smaller wear between depth and aparature
  if (depthPercentage.isNaN()) {
    if (!widthPercentage.isNaN()) {
      percentage = widthPercentage
    }
  } else if (widthPercentage.isNaN()) {
    percentage = depthPercentage
  } else {
    percentage = depthPercentage.isLessThanOrEqualTo(widthPercentage) ? depthPercentage : widthPercentage
  }

  // Return the color based all wear
  return pickHeatColour(percentage, theme)
}
