/**
 *
 * "Formate currencies to Australian Dollars"
 *
 * @file   formatters.js
 * @author Lateral
 * @since  2023
 */

export const currencyFormatter = new Intl.NumberFormat('en-AU', {
  /**
   * @constant
   * Formate currencies in AUD with 2 decimal points.
   */
  style: 'currency',
  currency: 'AUD',
  maximumFractionDigits: 2
})

export default currencyFormatter
