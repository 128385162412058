import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

export const MaintenanceNotifier = ({ type, onClose, autoHideDuration = null, children }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    onClose()
  }

  return (
    <Snackbar
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      sx={{
        zIndex: 1000000
      }}>
      <MuiAlert elevation={6} onClose={handleClose} variant="filled" severity={type} sx={{ width: '100%' }}>
        {children}
      </MuiAlert>
    </Snackbar>
  )
}
