/**
 *
 * "A large area for text input"
 *
 * @file   TextAreaInput.js
 * @author Lateral
 * @since  2023
 */
import { Stack, Typography, OutlinedInput } from '@mui/material'
import React from 'react'

export function TextAreaInput({ name, register, errors, required = false }) {
  /**
   * Create A large area for text input
   *
   * @function
   *
   * @param {string} name - Name of the text input area
   * @param {} register - Action on registering the field to object
   * @param {string} errors - Reported errors
   * @param {boolean} required - Input is required or optional
   *
   * @returns {object} - large area text input area
   */
  return (
    <Stack direction="column" sx={{ marginBottom: '0.5em' }}>
      <Typography variant="h5" component="h5" sx={{ margin: '0.5em 0 0.2em' }}>
        {name}
      </Typography>
      <OutlinedInput
        id={name}
        name={name}
        color="secondary"
        fullWidth
        multiline
        rows={3}
        sx={{ padding: '0' }}
        {...register(name, {
          required: required
        })}
      />
      {errors[name] && <p>This field is required</p>}
    </Stack>
  )
}
