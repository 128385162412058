/**
 *
 * "Page to view and handle all role related events"
 *
 * @file   RolesPage.js
 * @author Lateral
 * @since  2023
 */
import React, { useEffect, useState } from 'react'
import { log } from 'common'
import RoleTable from './components/roles/RoleTable'
import { FormDialog, SCOPES, ScopeRequirementAll, useNotification } from 'components'
import { addRole, updateRole, removeRole, disableRole, enableRole } from './common/data'
import RoleItemForm from './components/roles/RoleItemForm'
import { Stack, Button, Typography, OutlinedInput } from '@mui/material'
import { observeRoles, useCurrentUser } from 'hooks'
import { listGroups } from './common/adminDatastore'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import SearchIcon from '@mui/icons-material/Search'

const RolesPage = () => {
  /**
   * Page to view and handle role realted events
   * @const
   *
   * @returns {object} - React app page
   */
  const { notify, confirm } = useNotification()
  const [, roles] = observeRoles()
  const [roleItemModalState, setRoleItemModalState] = useState({ isOpen: false })
  const { getTransactionMetaData, currentUser } = useCurrentUser()
  const [availableCognitoGroups, setAvailableCognitoGroups] = useState([])
  const [searchText, setSearchText] = useState('')
  const { database } = useCurrentDeckContext()
  const groups = currentUser.getGroups()
  const hasCognitoAdmin = groups.filter((g) => g.toLowerCase() === 'administrators').length > 0

  const lowerSearchTexts = searchText.toLowerCase().split(' ')

  const sitesAvailable = currentUser.getSitesForScope(SCOPES.roleManagement.Read)
  const writingSitesAvailable = currentUser.getSitesForScope(SCOPES.roleManagement.Write)

  const filteredRoles = currentUser.isAdmin
    ? roles
    : roles.filter((r) => r.SiteIds?.some((s) => sitesAvailable.includes(s)))

  useEffect(() => {
    async function getCognitoGroups() {
      try {
        const groups = await listGroups()
        setAvailableCognitoGroups(groups.Groups)
      } catch (err) {
        console.error(err)
      }
    }

    if (currentUser.hasAllScopes([SCOPES.roleManagement.Write]) && hasCognitoAdmin) {
      getCognitoGroups()
    }
  }, [])

  // Handle CRUD for roles
  const handleRoleItemEvent = async (eventName, context) => {
    switch (eventName) {
      case 'editRole':
        setRoleItemModalState({ isOpen: true, title: 'Edit Role', buttonText: 'Update', data: context })
        break
      case 'deleteRole':
        await confirm(context, async (context, isDelete) => {
          if (isDelete) {
            removeRole(context.id)
          }
        })
        break
      case 'disableRole':
        await disableRole(context.id)
        break
      case 'enableRole':
        await enableRole(context.id)
        break
    }
  }

  // Modal for role CRUD
  const handleRoleItemDialogClose = async (modalState) => {
    if (modalState?.isSave) {
      const data = modalState.data
      data.Enabled = true
      const scopes = []

      for (const scope of Object.keys(SCOPES)) {
        const name = SCOPES[scope].Name
        const savedScope = data.Scopes[name]
        if (savedScope && (savedScope.CanRead || savedScope.CanWrite)) {
          scopes.push({
            Name: name,
            CanRead: savedScope.CanRead ?? false,
            CanWrite: savedScope.CanWrite ?? false
          })
        }
      }

      data.Scopes = scopes
      try {
        if (data?.id) {
          //Update Item
          await updateRole(data, getTransactionMetaData())
          notify(`Role Item updated successfully`)
        } else {
          //Add Item
          await addRole(data, getTransactionMetaData())
          notify(`Role Item Added`)
        }
      } catch (error) {
        log.error(`failed to save, error ${error}`)
        notify(`An error occurred whilst attempting to save`)
      }
    }
    setRoleItemModalState({ isOpen: false })
  }

  return (
    <Stack spacing={2}>
      <Typography sx={{ pt: 2 }} variant="h4">
        Manage Roles
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <OutlinedInput
          color="secondary"
          sx={{ height: '32px', maxWidth: '30em' }}
          onChange={(e) => setSearchText(e.currentTarget.value)}
          placeholder="Search"
          endAdornment={<SearchIcon color="secondary" />}
        />
        {!hasCognitoAdmin && currentUser.hasAllScopes([SCOPES.roleManagement.Write]) && (
          <Typography color="error">
            Your role has Role write access, but does not have Admin Cognito permission. Adding/editing Roles will be
            disabled.
          </Typography>
        )}
        {hasCognitoAdmin && (
          <ScopeRequirementAll requirements={[SCOPES.roleManagement.Write]}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ justifySelf: 'flex-end', width: '155px', height: '32px', padding: 0 }}
              onClick={() => setRoleItemModalState({ isOpen: true, title: 'Create New Role', buttonText: 'Create' })}>
              Add Role
            </Button>
          </ScopeRequirementAll>
        )}
      </Stack>

      <RoleTable
        items={filteredRoles}
        searchTexts={lowerSearchTexts}
        customers={database.customers}
        sites={database.sites}
        hasCognitoAdmin={hasCognitoAdmin}
        handleRoleItemEvent={handleRoleItemEvent}
      />

      {roleItemModalState.isOpen && (
        <FormDialog modalState={roleItemModalState} onOpenChange={handleRoleItemDialogClose}>
          <RoleItemForm
            availableCognitoGroups={availableCognitoGroups}
            customers={database.customers}
            sites={
              currentUser.isAdmin ? database.sites : database.sites.filter((s) => writingSitesAvailable.includes(s.id))
            }
          />
        </FormDialog>
      )}
    </Stack>
  )
}
export default RolesPage
