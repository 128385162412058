/**
 *
 * "The Deck History page""
 *
 * @file   DeckHistoryPage.js
 * @author Lateral
 * @since  2023
 */
import { DeckCanvas, FormDialog, SCOPES } from 'components'
import { Typography } from '@mui/material'
import BomTable from 'components/tables/bom/BomTable'
import React, { useEffect, useState } from 'react'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { DeckMode } from 'common/deckMode'
import HistoryTable from 'components/tables/history/HistoryTable'
import { createRevision, updateRevision } from 'data/revisionOperations'
import dayjs from 'dayjs'
import { updateDeckRevisionHistory } from '../common/historyData'
import { EditHistoryForm } from './components/EditHistoryForm'
import { EditRevisionForm } from './components/EditRevisionForm'
import { RestoreForm } from './components/RestoreForm'
import BigNumber from 'bignumber.js'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCurrentUser } from 'hooks'
import { Storage } from 'aws-amplify'
import { blobToBase64 } from 'common/converters'
import { dateFormat } from 'common/dates'
import ViewUpdatesModal from './components/ViewUpdatesModal'
import { DeckCanvas as MobileDeckCanvas } from 'components/mobileDeckCanvas/DeckCanvas'
import { DeckLayoutContainer } from '../common/DeckLayoutContainer'
import { use2D3DViewSwitch } from 'hooks/use2D3DViewSwitch'

function DeckHistoryPage() {
  /**
   * Generates Deck History Page
   *
   * @function
   *
   * @returns {object} - Page for Deck History
   */
  const [selected, setSelected] = useState([])
  const [materialSelected, setMaterialSelected] = useState('')
  const [selectedDeckRevision, setSelectedDeckRevision] = useState('')
  const [selectedDeckRevisionHistory, setSelectedDeckRevisionHistory] = useState('')
  const [editRevisionModalState, setEditRevisionModalState] = useState({ isOpen: false })
  const [editHistoryModalState, setEditHistoryModalState] = useState({ isOpen: false })
  const [viewUpdatesModalState, setViewUpdatesModalState] = useState({ isOpen: false })
  const [restoreModalState, setRestoreModalState] = useState({ isOpen: false })
  const {
    deckId,
    screenId,
    setDeckRevision,
    database,
    setInMaintenanceMode,
    setMaintenanceData,
    deckRevisions,
    histories
  } = useCurrentDeckContext()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { getUserName, getTransactionMetaData, currentUser } = useCurrentUser()
  const hasMaintenance = currentUser?.hasAllScopes([SCOPES.deckMaintenance.Read])

  const screen = database.screens.find((s) => s.id === screenId)
  const deck = database.decks.find((d) => d.id === deckId)
  const deckRevision = structuredClone(database.deckRevisions.find((d) => d.id === selectedDeckRevision))
  const deckRevisionHistory = structuredClone(
    database.deckRevisionHistories.find((d) => d.id === selectedDeckRevisionHistory)
  )

  const [viewType, setViewType] = use2D3DViewSwitch()

  const switchViewType = () => {
    setViewType((currentViewType) => (currentViewType === '3D' ? '2D' : '3D'))
  }

  useEffect(() => {
    if (state?.incomingId) {
      const history = database.deckRevisionHistories.find((d) => d.id === state.incomingId)
      setSelectedDeckRevision(history?.RevisionId)
      setSelectedDeckRevisionHistory(history?.id)
    } else {
      const topRevision = deckRevisions[0]
      const topHistory = histories[0]

      if (topRevision) {
        if (
          dayjs(topRevision?.createdAt).isAfter(dayjs(topHistory?.DatePerformed ?? topHistory?.createdAt)) ||
          !topHistory ||
          !hasMaintenance
        ) {
          setSelectedDeckRevision(topRevision?.id)
          setSelectedDeckRevisionHistory('')
        } else {
          setSelectedDeckRevision(topHistory?.RevisionId)
          setSelectedDeckRevisionHistory(topHistory?.id)
        }
      }
    }
  }, [hasMaintenance, deckId, state?.incomingId, histories, deckRevisions])

  // Action on clisking revision rows
  function onRevisionClick(id, historyId) {
    setSelectedDeckRevision(id)
    setSelectedDeckRevisionHistory(historyId)
  }

  // Action on lciking B.O.M. table
  function onBomClick(kit) {
    setSelected([])
    setMaterialSelected(kit.MaterialNumber)
  }

  // Action on changing panel selection
  function onSelectChange(data) {
    setSelected(data)
    setMaterialSelected('')
  }

  // Action editing deck revision
  function onEditHistory(context) {
    const deckRevision = database.deckRevisions.find((d) => d.id === context.id)

    if (deckRevision) {
      setDeckRevision(structuredClone(deckRevision))
      navigate('/equipment/edit', { state: { isEditingOld: true } })
    }
  }

  // Action editing maintenance
  async function onEditMaintenance(context) {
    const maintenance = database.deckRevisionHistories.find((d) => d.id === context.historyId)
    const deckRevision = database.deckRevisions.find((d) => d.id === context.id)

    if (maintenance && deckRevision) {
      setDeckRevision(structuredClone(deckRevision))
      setInMaintenanceMode(true)
      const photos = []
      for (const photo of maintenance.Photos) {
        const blob = await Storage.get(photo.Key, { download: true })
        //split into 'name' and 'filetype' e.g. abcdefg.jpg
        const splitKey = photo.Key.split('.')
        if (blob) {
          photos.push({
            file: {
              name: `${photo.Key}`,
              type: splitKey[1],
              data: await blobToBase64(blob.Body)
            },
            caption: photo.Caption,
            height: photo.Height,
            width: photo.Width,
            key: photo.Key
          })
        }
      }
      const clone = structuredClone(maintenance)
      if (clone.NextShutDownDate) {
        clone.NextShutDownDate = dayjs(clone.NextShutDownDate)
      }
      setMaintenanceData({
        deckRevisionHistory: clone,
        photos: photos
      })
      navigate('/equipment/maintenance', { state: { isEditingOld: true } })
    }
  }

  //Action on edting deck Maintenance or Revision details.
  //The edit menu changes, depending on if the selected row is a Revision or Maintenance
  function onEditDetails(context) {
    if (context.isHistory) {
      setEditHistoryModalState({
        isOpen: true,
        title: 'Edit Maintenance Details',
        buttonText: 'Update',
        data: { Id: context.historyId, Description: context.description, Date: dayjs(context.reported) }
      })
    } else {
      setEditRevisionModalState({
        isOpen: true,
        title: 'Edit Revision Details',
        buttonText: 'Update',
        data: { Id: context.id, Description: context.description }
      })
    }
  }

  async function onEditRevisionConformation(modalState) {
    if (modalState?.isSave) {
      let existing = database.deckRevisions.find((d) => d.id === modalState.data.Id)
      if (existing) {
        let clone = structuredClone(existing)
        clone.Description = modalState.data.Description
        await updateRevision(existing, clone, getTransactionMetaData())
      }
    }

    setEditRevisionModalState({ isOpen: false })
  }

  async function onEditHistoryConformation(modalState) {
    if (modalState?.isSave) {
      let existing = database.deckRevisionHistories.find((d) => d.id === modalState.data.Id)
      if (existing) {
        let clone = structuredClone(existing)
        clone.Description = modalState.data.Description
        clone.DatePerformed = modalState.data.Date.toISOString()
        if (!clone.CustomerId) {
          clone.CustomerId = deck.CustomerId
        }

        await updateDeckRevisionHistory(existing, clone, getTransactionMetaData())
      }
    }

    setEditHistoryModalState({ isOpen: false })
  }

  function onRestore(context) {
    setRestoreModalState({
      isOpen: true,
      title: 'Restore Revision?',
      buttonText: 'Confirm Restore',
      data: { Id: context.id, Name: `Revision ${context.revisionNumber} - ${context.description}` }
    })
  }

  async function onRestoreConfirmation(modalState) {
    if (modalState?.isSave) {
      const existing = database.deckRevisions.find((d) => d.id === modalState.data.Id)

      if (existing) {
        const topRevisionNumber = Math.max(
          ...database.deckRevisions.filter((d) => d.DeckId === existing.DeckId).map((d) => d.RevisionNumber)
        )
        const clone = structuredClone(existing)
        clone.id = undefined
        clone.RevisionNumber = new BigNumber(topRevisionNumber).plus(1).toNumber()
        clone.CreatedBy = getUserName()

        await createRevision(clone, getTransactionMetaData())
      }
    }

    setRestoreModalState({ isOpen: false })
  }

  function onViewUpdates(history) {
    setViewUpdatesModalState({
      isOpen: true,
      title: `Updates for Maintenance on ${history.created.format(dateFormat)}`,
      buttonText: '',
      data: history.updates
    })
  }

  function onPanelClick(selected) {
    if (selected) {
      setSelected(Array.isArray(selected) ? selected : [selected])
    } else {
      setSelected([])
    }
    setMaterialSelected('')
  }

  //don't show data if context missing
  if (!screen || !deck || !deckRevision) {
    return null
  }

  if (Object.entries(screen).length <= 0 || Object.entries(deck).length <= 0) {
    return <Typography>No Deck Revision found.</Typography>
  }

  return (
    <>
      <DeckLayoutContainer
        headerTitle="Deck History"
        Deck={
          <>
            {viewType === '3D' ? (
              <DeckCanvas
                data={deckRevision}
                maintenance={deckRevisionHistory}
                selected={selected}
                materialSelected={materialSelected}
                onSelectChange={onSelectChange}
                onSwitchView={switchViewType}
                mode={selectedDeckRevisionHistory ? DeckMode.maintenance : DeckMode.default}
              />
            ) : (
              <MobileDeckCanvas
                data={deckRevision}
                maintenance={deckRevisionHistory}
                selected={selected}
                materialSelected={materialSelected}
                onSelectChange={onSelectChange}
                mode={selectedDeckRevisionHistory ? DeckMode.maintenance : DeckMode.default}
                onSwitchView={switchViewType}
                onPanelClick={onPanelClick}
              />
            )}
          </>
        }
        topRightElement={
          <>
            <Typography variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
              Deck History
            </Typography>
            <HistoryTable
              deckRevisionId={selectedDeckRevision}
              historyId={selectedDeckRevisionHistory}
              deckRevisions={deckRevisions}
              deckRevisionHistories={histories}
              onClick={onRevisionClick}
              onEditDetails={onEditDetails}
              onRestore={onRestore}
              onEditHistory={onEditHistory}
              onEditMaintenance={onEditMaintenance}
              onViewUpdates={onViewUpdates}
            />
          </>
        }
        bottomRightElement={
          <BomTable data={deckRevision} selected={selected} materialSelected={materialSelected} onClick={onBomClick} />
        }
      />
      {editRevisionModalState.isOpen && (
        <FormDialog modalState={editRevisionModalState} onOpenChange={onEditRevisionConformation}>
          <EditRevisionForm />
        </FormDialog>
      )}
      {editHistoryModalState.isOpen && (
        <FormDialog modalState={editHistoryModalState} onOpenChange={onEditHistoryConformation}>
          <EditHistoryForm />
        </FormDialog>
      )}
      {restoreModalState.isOpen && (
        <FormDialog modalState={restoreModalState} onOpenChange={onRestoreConfirmation} center>
          <RestoreForm />
        </FormDialog>
      )}
      {viewUpdatesModalState.isOpen && (
        <FormDialog
          modalState={viewUpdatesModalState}
          onOpenChange={() => setViewUpdatesModalState({ isOpen: false })}
          center>
          <ViewUpdatesModal />
        </FormDialog>
      )}
    </>
  )
}

export default DeckHistoryPage
