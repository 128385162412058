import { Drawer, Fab } from '@mui/material'
import { WearAppTabs } from './WearAppTabs'
import { Visibility } from '@mui/icons-material'

export const AnalysisDrawer = ({ showResults, setShowResults, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '1em',
        height: '100%',
        flex: 1,
        overflow: 'hidden'
      }}>
      <WearAppTabs />

      <Drawer
        open={showResults}
        anchor="bottom"
        hideBackdrop
        className="analysis-drawer"
        variant="persistent"
        style={{
          height: '50%'
        }}
        PaperProps={{
          style: {
            position: 'inherit'
          }
        }}>
        <div>{children}</div>
      </Drawer>
      {!showResults && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 0,
            bottom: '15%',
            marginRight: '2em'
          }}>
          <Fab color="secondary" onClick={() => setShowResults(true)}>
            <Visibility />
          </Fab>
        </div>
      )}
    </div>
  )
}
