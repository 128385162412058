import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Amplify } from 'aws-amplify'
import { BrowserRouter } from 'react-router-dom'
import awsExports from './aws-exports'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { defaultLoggerInit } from 'common/logger'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { NotificationProvider } from 'components/snackbar/NotificationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import CssBaseline from '@mui/material/CssBaseline'
import MainTheme from './mainTheme'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Auth } from 'aws-amplify'

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
})

import 'dayjs/locale/en-au'
dayjs.extend(LocalizedFormat)
dayjs.locale('en-au')

defaultLoggerInit()
awsExports.oauth.redirectSignIn = `${window.location.origin}/dashboard`
awsExports.oauth.redirectSignOut = `${window.location.origin}/`
awsExports.graphql_headers = getIdToken
Amplify.configure(awsExports)

const queryClient = new QueryClient()

const customTheme = createTheme(MainTheme)
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
