import { useCurrentUser } from 'hooks'

/**
 * Will show the child components if all the requirements are met
 * @param {string[]} requirements - The requirements. Should be created using the SCOPES constant
 */
export function ScopeRequirementAll({ requirements, siteId, children }) {
  const { currentUser } = useCurrentUser()

  if (requirements?.length) {
    return currentUser?.hasAllScopes(requirements, siteId) ? children : false
  }

  return children
}

/**
 * Will show the child components if any of the requirements are met
 * @param {string[]} requirements - The requirements. Should be created using the SCOPES constant
 */
export function ScopeRequirementOr({ requirements, siteId, children }) {
  const { currentUser } = useCurrentUser()

  if (requirements?.length) {
    return currentUser?.hasAtLeastOneScope(requirements, siteId) ? children : false
  }

  return children
}
