/**
 *
 * "Form for adding/updating a User"
 *
 * @file   UserItemForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm, Controller } from 'react-hook-form'
import { Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export const UserItemForm = ({ data, onSubmit, roleList, companyList }) => {
  /**
   * Generates a form for adding.updating user in admin page
   *
   * @const
   * @param {object} data - User details
   * @param {string} data.id - User id
   * @param {string} data.Username - User username (unique)
   * @param {string} data.FirstName - User firstName
   * @param {string} data.LastName - User lastName
   * @param {string} data.Email - User email
   * @param {string} data.Roles - User role
   * @param {} onSubmit - Action on submiting the form
   * @param {string[]} roleList - List of all roles
   * @param {string[]} companyList - List of existing companies
   *
   * @returns {object} - A form to add/edit user
   */
  const defaultValues = {
    id: data?.id ?? null,
    CompanyNames: data?.CompanyIds ?? [],
    OriginalCompanyNames: data?.CompanyIds ?? [],
    Username: data?.Username ?? null,
    FirstName: data?.FirstName ?? '',
    LastName: data?.LastName ?? '',
    Email: data?.Email ?? '',
    Roles: data?.RoleIds ?? [],
    SandvikUserId: data?.SandvikUserId ?? data?.id ?? ''
  }

  const { register, handleSubmit, control, watch } = useForm({ defaultValues })

  const watchCompanyNames = watch('CompanyNames')

  const filteredRoles = watchCompanyNames ? roleList.filter((r) => watchCompanyNames.includes(r.CustomerId)) : []

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography type="h5">Company Names</Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            name="CompanyNames"
            id="CompanyNames"
            control={control}
            render={({ field }) => (
              <Select {...field} multiple color="secondary" fullWidth>
                <MenuItem value="">No Filter</MenuItem>
                {companyList.map((option) => (
                  <MenuItem key={option.Name} value={option.id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Email</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Email"
            name="Email"
            placeholder="E.g. user@sandvik.com"
            color="secondary"
            disabled={Object.keys(data).length > 0}
            fullWidth
            {...register('Email', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">First Name</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="FirstName"
            name="FirstName"
            placeholder="E.g. Joe"
            color="secondary"
            fullWidth
            {...register('FirstName', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Last Name</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="LastName"
            name="LastName"
            placeholder="E.g. Bloggs"
            color="secondary"
            fullWidth
            {...register('LastName', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Roles</Typography>
        </Grid>
        <Grid item md={9}>
          {filteredRoles?.length ? (
            <Controller
              name="Roles"
              id="Roles"
              control={control}
              render={({ field }) => (
                <Select {...field} multiple color="secondary" fullWidth>
                  {filteredRoles.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          ) : (
            <Typography>No Roles assigned to this Company</Typography>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
export default UserItemForm
