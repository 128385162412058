/**
 *
 * "Mini menu with 'Add Deck' and 'Edit Details' for selecting an Action for a Screen."
 *
 * @file   ScreenContextMenu.js
 * @author Lateral
 * @since  2023
 */
import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export const ScreenContextMenu = ({ anchorEl, context, handleClose, handleEvent }) => {
  /**
   * Generaets mini menu with 'Add Deck' and 'Edit Details'
   *
   * @function
   *
   * @param {object} anchorEl - The anchor buttons of mini menu
   * @param {object} context - The Screen context
   * @param {} handleClose - Action on closing menu
   * @param {} handleEvent - Action on selecting option
   *
   * @returns {object} - A mini menu
   */

  const handleClick = (eventName) => {
    handleClose()
    handleEvent(eventName, context)
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={true}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem onClick={() => handleClick('addDeck')}>Add Deck</MenuItem>
      <MenuItem onClick={() => handleClick('edit')}>Edit Details</MenuItem>
    </Menu>
  )
}
export default ScreenContextMenu
