/**
 *
 * "Status chip for tables
 * e.g. open/closed status chip for dialogue tables"
 *
 * @file   StyledStatusChip.js
 * @author Lateral
 * @since  2023
 */
import Chip from '@mui/material/Chip'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'

export function StyledStatusChip({ status }) {
  /**
   *
   * Custom status chips
   *
   * @function
   * @param {string} status - Custom status to priview in chip
   * @returns {object} - Chip component
   */
  const theme = useTheme()
  if (status) {
    return (
      <Chip
        label={
          <Typography
            variant="body2"
            sx={{ fontSize: '10px', letterSpacing: '1.5px', color: theme.palette.primary.dark }}>
            {status.toUpperCase()}
          </Typography>
        }
        sx={{
          '& .MuiChip-label': {
            px: '4px',
            alignSelf: 'flex-start'
          },
          backgroundColor: status === 'Open' ? theme.palette.supporting.lightGreen : theme.palette.supporting.red,
          borderRadius: '27px',
          height: '15px'
        }}></Chip>
    )
  }
  return null
}

export default StyledStatusChip
