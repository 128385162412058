/**
 *
 * Legends display under the deck canvas in Maintenance page in mobile sized screen mode.
 *
 * @file   Legend.js
 * @author Lateral
 * @since  2023
 */
import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as ReplaceIcon } from 'assets/icons/Replace.svg'
import { ReactComponent as RotateIcon } from 'assets/icons/Rotate.svg'
import { ReactComponent as SwapIcon } from 'assets/icons/Swap.svg'
import { ReactComponent as RelocateFromIcon } from 'assets/icons/Relocate-From.svg'
import { ReactComponent as RelocateToIcon } from 'assets/icons/Relocate-To.svg'
import { ReactComponent as ViewResult } from 'assets/icons/ViewResult.svg'

const iconStyling = { height: '20px', width: '20px', verticalAlign: 'top' }

function Legend() {
  /**
   * Generates a Legend box for mobile sized screen view
   * of maintenance page
   *
   * @function
   *
   *
   * @returns {object} - A box with maintenance legends
   */
  return (
    <Box sx={{ border: '1px solid', borderColor: 'secondary.main', marginBottom: '1em' }}>
      <Grid container sx={{ padding: '1em' }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
            Legend
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ margin: '1em 0 0.5em 0' }}>
          <Typography variant="h6" component="h6">
            Wear Amount
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1} direction="row">
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.zero' }]} />
            <Typography>0%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.twenty' }]} />
            <Typography>20%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.forty' }]} />
            <Typography>40%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.sixty' }]} />
            <Typography>60%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.eighty' }]} />
            <Typography>80%</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ margin: '1em 0 0.5em 0' }}>
          <Typography variant="h6" component="h6">
            Maintenance Actions
          </Typography>
        </Grid>
        <Grid flexDirection="row" item xs={12} container spacing={1}>
          <Grid item>
            <ReplaceIcon style={iconStyling} /> Replace
          </Grid>
          <Grid item>
            <RotateIcon style={iconStyling} /> Rotate
          </Grid>
          <Grid item>
            <SwapIcon style={iconStyling} /> Swap
          </Grid>
          <Grid item>
            <RelocateFromIcon style={iconStyling} /> Relocate From
          </Grid>
          <Grid item>
            <RelocateToIcon style={iconStyling} /> Relocate To
          </Grid>
          <Grid item>
            <ViewResult style={iconStyling} /> View Wear Result
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Legend
