/**
 *
 * "A modal form to confirm cancelling a Maintenance""
 *
 * @file   CancelForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function CancelForm({ data, onSubmit }) {
  /**
   * Generates a form to cancel mainetenance
   *
   * @function
   *
   * @param {object} data - Maintenance data
   * @param {string} data.id - Maintenance id
   * @param {} onSubmit - Action on cancelling Maintenance
   *
   * @returns {object} - A modal form
   */
  const defaultValues = {
    Id: data?.id ?? ''
  }

  const { handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body1" component="p">
            Are you sure you want to cancel maintenance?
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default CancelForm
