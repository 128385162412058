/**
 *
 * "Provides labels for 3D canvas in text formate
 *
 * @file   DeckLabels.js
 * @author Lateral
 * @since  2023
 */
import { Text } from '@react-three/drei'
import deg2Rad from '../../common/angles'
import React from 'react'
import { DeckMode } from 'common/deckMode'

const textRotation = [deg2Rad(40), deg2Rad(-33), deg2Rad(4)]
const fontSize = 1
const minWidth = 10
const minTop = 5
const minBottom = 4

export function DeckLabels({ deckHeight, deckWidth, rotation, deckMode }) {
  /**
   * Provides a cube object which is the simplest component 3D deck model.
   *
   * @function
   * @param {number} deckHeight - Deck height
   * @param {number} deckWidth - Deck width
   * @param {number} rotation - Deck rotation angles in 3D space in radian
   * @param {string} deckMode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @returns {Element} - React group element of deck labels
   */
  const topPadding = deckMode.includes(DeckMode.maintenance) ? 5 : 4
  return (
    <group rotation={rotation}>
      {/* Label for right hand sideliner */}
      <Text
        color="white"
        fontSize={fontSize}
        rotation={textRotation}
        position={[Math.min(-deckWidth - 2, -minWidth), deckHeight / 8, 0]}
        anchorX="center"
        anchorY="middle">
        RHS
      </Text>

      {/* Label for feed end */}
      <Text
        color="white"
        fontSize={fontSize}
        position={[0, Math.max(deckHeight * 0.5 + topPadding, minTop), 0]}
        rotation={textRotation}
        anchorX="center"
        anchorY="middle">
        Feed End
      </Text>

      {/* Label for Discharge end */}
      <Text
        color="white"
        fontSize={fontSize}
        position={[0, Math.min(-deckHeight * 0.5 - 2, -minBottom), 0]}
        rotation={textRotation}
        anchorX="center"
        anchorY="middle">
        Discharge End
      </Text>

      {/* Label for left hand sideliner*/}
      <Text
        color="white"
        fontSize={fontSize}
        position={[Math.max(deckWidth + 2, minWidth), deckHeight / 8, 0]}
        rotation={textRotation}
        anchorX="center"
        anchorY="middle">
        LHS
      </Text>
    </group>
  )
}

export default DeckLabels
