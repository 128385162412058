/**
 *
 * "Main theme styles of the App"
 *
 * @file   PageLayout.js
 * @author Lateral
 * @since  2023
 */
const highlightPale = 'white'
const primaryDark = '#242424'
const primaryMedium = '#171717'
const white = '#FFFFFF'
const black = '#000000'
const supportingLightGreen = 'green'
const supportingRed = '#F60347'
export const accent = '#A08C64'
export const secondAccent = '#F54B00'
const primaryLight = '#101010'

const fontFamily = [
  'sandvik-sans-text',
  'sand',
  'Arial',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(',')

const h2 = {
  color: white,
  fontFamily: 'sandvik-sans-headline',
  fontSize: '53px',
  lineHeight: '64px',
  fontWeight: 300,
  letterSpacing: '-0.5px'
}

const h4 = {
  color: white,
  fontSize: '30px',
  lineHeight: '32px',
  fontWeight: 400,
  letterSpacing: '0.25px'
}

const h5 = {
  fontFamily: 'sandvik-sans-text',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: highlightPale
}
const body1 = {
  fontFamily: 'sandvik-sans-text',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: white
}

const body2 = {
  fontFamily: 'sandvik-sans-text',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  color: white
}

export const MainTheme = {
  palette: {
    mode: 'dark',
    primary: {
      main: white,
      contrastText: primaryMedium,
      light: primaryLight
    },
    secondary: {
      main: accent,
      contrastText: white,
      light: white
    },
    background: {
      default: primaryDark,
      paper: primaryDark
    },
    text: {
      primary: white,
      secondary: white,
      highlight: primaryDark
    },
    info: {
      main: secondAccent
    },
    error: {
      main: '#EC0521'
    },
    supporting: {
      highlight: highlightPale,
      pale: white,
      dark: primaryDark,
      black: black,
      lightGreen: supportingLightGreen,
      red: supportingRed,
      gold: accent,
      primaryMedium
    },
    heatmap: {
      zero: '#fc0303',
      twenty: '#fb5b22',
      forty: '#ffb802',
      sixty: '#f1f526',
      eighty: '#2cfc03'
    }
  },

  typography: {
    h1: h2,
    h2: h2,
    h3: {
      color: white
    },
    h4: h4,
    h5: h5,
    body1: body1,
    body2: body2,
    fontFamily: fontFamily
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontSize: '14px'
        }
      }
    },
    MuiLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontSize: '14px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontSize: '16px',
          '&::hover': {
            border: `${white}!important`
          }
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          color: 'yellow',
          '&::-webkit-scrollbar': {
            width: '2px',
            height: '2px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: primaryDark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `${primaryMedium} !important`,
            borderRadius: 1
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontSize: '14px'
        },
        popupIndicator: {
          color: accent
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontsize: '14px',
          lineHeight: '16px',
          letterSpacing: '1.25px',
          textTransform: 'uppercase',
          borderRadius: '32px'
        },
        outlinedSecondary: {
          color: white
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&::hover': {
            color: white
          }
        },
        input: {
          padding: '10px 14px',
          color: 'primary.main',
          '&::Placeholder': {
            color: white,
            opacity: 0.5
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: primaryMedium
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: primaryMedium,
          ...h5
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'collapse'
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          color: 'yellow',
          '&::-webkit-scrollbar': {
            width: '4px !important',
            height: '4px !important'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: primaryDark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `${primaryMedium} !important`,
            borderRadius: 1
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          borderBottom: 'none'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          backgroundColor: primaryMedium,
          color: white,
          border: 'none',
          padding: '8px 10px 8px 10px'
        },
        body: {
          fontFamily: 'sandvik-sans-text',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          border: 'none',
          padding: '10px 10px 10px 10px'
        }
      }
    }
  }
}
export default MainTheme
