/**
 *
 * "A wrapper for protecting a page with Scopes"
 *  Mostly used in AllRoutes.js
 *
 * @file   ProtectedRoute.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { PermissionsGate } from './PermissionsGate'
import { useCurrentUser } from 'hooks'

export const ProtectedRoute = ({ requiredScopes = [], children, checkSites = false }) => {
  /**
   *
   * Protect page view by restricitng route through scopes
   *
   * @constant
   * @param {object} requiredScopes - Required scopes to view the page
   * @param {React.ElementType} children - Components requested to view
   * @returns {React.ElementType} If permission granted the returns Collection of HTML
   *                  objects requesred view else a wrapper to protect the
   *                  content from viewing
   */

  return (
    <PermissionsGate
      requiredScopes={requiredScopes}
      RenderError={() => <p>Forbidden - You are not authorized to view this page</p>}
      checkSites={checkSites}>
      <>{children}</>
    </PermissionsGate>
  )
}

export const AdminOnlyRoute = ({ children }) => {
  const user = useCurrentUser()

  if (!user || !user.currentUser || !user.currentUser.isAdmin) {
    return <>Forbidden - You are not authorized to view this page</>
  }
  return children
}
