/**
 *
 * "Generates Parent component for all the Reporting pages."
 *
 * @file   ReportingPage.js
 * @author Lateral
 * @since  2023
 */
import React, { Suspense, useContext, useEffect } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import { ReportingContext } from 'components/reportingContext/ReportingContext'
import { defaultRoutes } from 'pages'
import { Header, SCOPES } from 'components'
import { useCurrentUser } from 'hooks'

function ReportingPage() {
  /**
   * Generates Parent component (wrapper) for all the Reporting pages
   *
   * @function
   *
   * @returns {object} - Box elements and link tabls for Reporting pages
   */
  const { pathValue, setPathValue, setExistingReport, showTabs } = useContext(ReportingContext)

  const location = useLocation()
  const { currentUser } = useCurrentUser()
  const hasWrites = currentUser?.hasAllScopes([SCOPES.reporting.Write])

  const handleChange = (event, newValue) => {
    setPathValue(newValue)
  }

  useEffect(() => {
    let initialValue = 0

    if (location.pathname === '/reporting/pre-shut') {
      initialValue = 0
    } else if (location.pathname === '/reporting/post-shut') {
      initialValue = 1
    } else if (location.pathname === '/reporting/parts') {
      initialValue = 2
    } else if (location.pathname === '/reporting/all') {
      initialValue = hasWrites ? 3 : 0
    }

    setPathValue(initialValue)
  }, [hasWrites])

  function LinkTab(props) {
    return (
      <Tab color="secondary" sx={{ pl: 0, pr: 2 }} component={Link} onClick={() => setExistingReport({})} {...props} />
    )
  }

  return (
    <Box sx={{ height: '95%', width: '100%', px: '15px' }}>
      <Header routes={defaultRoutes} />

      {showTabs ? (
        <>
          <Typography variant="h1">Reporting</Typography>

          <Tabs value={pathValue} color="secondary" onChange={handleChange} aria-label="nav tabs example">
            {hasWrites ? <LinkTab label="Pre-Shut Report" to="pre-shut" /> : null}
            {hasWrites ? <LinkTab label="Post-Shut Report" to="post-shut" /> : null}
            {hasWrites ? <LinkTab label="Parts Report" to="parts" /> : null}
            <LinkTab label="All Reports" to="all" />
          </Tabs>
        </>
      ) : null}
      <Suspense fallback={<Box>Loading...</Box>}>
        <Outlet />
      </Suspense>
    </Box>
  )
}

export default ReportingPage
