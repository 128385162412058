import React, { useEffect } from 'react'
import { useWearAppContext } from '../../WearAppContext'
import { EfficiencyDrawer } from './EfficiencyDrawer'
import { ResultsDrawer } from './ResultsDrawer'
import { WearDrawer } from './WearDrawer'
import { useWearAppAnalysisContext } from '../../WearAppAnalysisContext'
import { useNavigate } from 'react-router-dom'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'

export const AnalysisResultContainer = () => {
  const { activeTab } = useWearAppContext()
  const { isDesktop } = useCurrentDeckContext()
  const {
    currentAnalysisResultsRef: { current }
  } = useWearAppAnalysisContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!current) {
      navigate(isDesktop ? '/equipment/maintenance' : '/mobile/maintenance')
    }
  }, [])

  return !!current && (activeTab === 0 ? <ResultsDrawer /> : activeTab === 1 ? <WearDrawer /> : <EfficiencyDrawer />)
}
