/**
 *
 * "Generates a form for rendering post shut Report."
 *
 * @file   PostShutForm.js
 * @author Lateral
 * @since  2023
 */
import { Box, Button, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
import {
  companyName,
  crewDetailsName,
  defaultString,
  introductionName,
  recentJobsName,
  recommendationsName,
  safetyIssuesName,
  scopeOfWorkName,
  shutdownCommentsName,
  shutEndDateName,
  shutStartDateName,
  siteContactName,
  siteName,
  siteRepresentativeName,
  titleName
} from 'pages/reporting/common/formNames'
import MaintenanceJobs from './MaintenanceJobs'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import { DropdownInput } from '../common/DropdownInput'
import { TextInput } from '../common/TextInput'
import { TextAreaInput } from '../common/TextAreaInput'
import { DatePicker } from '@mui/x-date-pickers'
import { useCurrentUser } from 'hooks'
import { SCOPES } from 'components'

function PostShutForm({ data, customers, sites, locations, decks, deckRevisions, deckRevisionHistories, onSubmit }) {
  /**
   * Table for viewing all Reporting history.
   *
   * @function
   *
   * @param {object} data - Site object of which Parts report is going to be generated
   * @param {string} data.DateOfVisit - Shut start date
   * @param {string} data.EndDate - Shut end date
   * @param {string} data.Company - Name of the company
   * @param {string} data.Site - Name of the site
   * @param {string} data.SiteContact - Contact details of site
   * @param {string} data.SiteRepresentative - Name f site representative
   * @param {string} data.Introduction - Report introduction
   * @param {string} data.ScopeOfWork - Report scope pf work
   * @param {string} data.SafetyIssues - Reproted safety issue
   * @param {string} data.CrewDetails - Details of reporting crew
   * @param {string} data.ShutdownComments - Comments shutdown report
   * @param {string} data.Recommendations - Recommendation on visiting
   * @param {object[]} customers - Array of all company object
   * @param {object[]} location - Array of all location object
   * @param {object[]} sites - Array of all site object
   * @param {object[]} decks - Array of all deck location object
   * @param {object[]} deckRevisions - Array of deck rivision records
   * @param {object[]} deckRevisionHistories - array of deck maintenance objects
   * @param {} onSubmit - Action on submitting form
   *
   * @returns {object} - Post shut report form
   */
  const defaultValues = {
    id: data?.id ?? defaultString,
    [shutStartDateName]: data ? dayjs(data.DateOfVisit) : dayjs(),
    [shutEndDateName]: data ? dayjs(data.EndDate) : dayjs(),
    [companyName]: customers.find((c) => c.Name === data?.Company)?.id ?? defaultString,
    [siteName]: sites.find((s) => s.Name === data?.Site)?.id ?? defaultString,
    [siteContactName]: data?.SiteContact ?? defaultString,
    [siteRepresentativeName]: data?.SiteRepresentative ?? defaultString,
    [introductionName]: data?.Introduction ?? defaultString,
    [scopeOfWorkName]: data?.ScopeOfWork ?? defaultString,
    [safetyIssuesName]: data?.SafetyIssues ?? defaultString,
    [crewDetailsName]: data?.CrewDetails ?? [],
    [shutdownCommentsName]: data?.ShutdownComments ?? defaultString,
    [recommendationsName]: data?.Recommendations ?? defaultString,
    [titleName]: data?.Title ?? defaultString,
    [recentJobsName]: []
  }
  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues })

  const { fields, append, remove } = useFieldArray({
    control,
    name: recentJobsName
  })

  const crewDetails = useFieldArray({
    control,
    name: crewDetailsName
  })

  const watchCompany = watch(companyName, defaultString)
  const watchSite = watch(siteName, defaultString)
  const watchStartDate = watch(shutStartDateName, data ? dayjs(data.DateOfVisit) : dayjs())
  const watchEndDate = watch(shutEndDateName, data ? dayjs(data.EndDate) : dayjs())

  const { currentUser } = useCurrentUser()

  const companyIdToFilter = watchCompany.length ? watchCompany : defaultValues[companyName]
  const siteIdToFilter = watchSite.length ? watchSite : defaultValues[siteName]
  const sitesAvailable = currentUser.getSitesForScope(SCOPES.reporting.Write)
  const companiesAvailable = currentUser.getCustomersForScope(SCOPES.reporting.Write)
  const filteredCompanies = currentUser.isAdmin ? customers : customers.filter((c) => companiesAvailable.includes(c.id))

  useEffect(() => {
    if (data && Object.entries(data).length && !fields.length && siteIdToFilter) {
      onFillRecentMaintenanceJobs()
    }
  }, [])

  const [isLoading, setIsLoading] = useState()

  //Maintenance is only rendered once a company/site/time are selected.
  function onFillRecentMaintenanceJobs(value, name) {
    const siteId = name === siteName ? value : siteIdToFilter
    const startDate = dayjs(name === shutStartDateName ? value : watchStartDate).startOf('date')
    const endDate = dayjs(name === shutEndDateName ? value : watchEndDate).endOf('date')

    if (siteId && startDate && endDate) {
      remove()
      const siteLocations = locations.filter((l) => l.SiteId === siteId)

      siteLocations.forEach((location) => {
        const locationDecks = decks.filter((d) => d.LocationId === location.id && d.CustomerId === companyIdToFilter)

        locationDecks.forEach((deck) => {
          const deckDeckRevisions = deckRevisions.filter((d) => d.DeckId === deck.id)

          deckDeckRevisions.forEach((revision) => {
            const revisionHistories = deckRevisionHistories.filter(
              (d) =>
                d.RevisionId === revision.id &&
                d.HistoryType === 'Maintenance' &&
                dayjs(d.DatePerformed).isBetween(startDate, endDate)
            )

            revisionHistories.forEach((history) => {
              append({
                HistoryId: history.id,
                Name: `${location.Name} - ${deck.DeckHeader.EquipmentId} - ${deck.DeckHeader.DeckLevel}`,
                Include: data.Selections?.includes(history.id) ?? false,
                DatePerformed: history.DatePerformed
              })
            })
          })
        })
      })
    }
  }

  // Form submission event
  async function submitForm(form) {
    try {
      setIsLoading(true)
      form[recentJobsName].forEach((job) => {
        const canvas = document.getElementById(job.HistoryId)
        job.htmlElement = canvas

        const preCanvas = document.getElementById(`${job.HistoryId}-pre`)
        job.preElement = preCanvas

        const defaultCanvas = document.getElementById(`${job.HistoryId}-default`)
        job.defaultElement = defaultCanvas
      })
      await onSubmit(form)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={(e) => handleSubmit(submitForm)(e)} style={{ height: '100%' }}>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
        Report Details
      </Typography>
      <Box sx={{ padding: '0.5em 1em' }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
            <Typography variant="h5" component="h5">
              {shutStartDateName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
            <Controller
              control={control}
              name={shutStartDateName}
              render={({ field: { onChange, ...otherFields } }) => (
                <DatePicker
                  {...otherFields}
                  maxDate={watchEndDate}
                  onChange={(e) => {
                    onChange(e)
                    onFillRecentMaintenanceJobs(e, shutStartDateName)
                  }}
                  renderInput={(params) => <TextField color="secondary" sx={{ width: '50%' }} fullWidth {...params} />}
                  format="DD/MM/YYYY"
                />
              )}
            />
            {errors[shutStartDateName] && <p>This field is required</p>}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
            <Typography variant="h5" component="h5">
              {shutEndDateName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
            <Controller
              control={control}
              name={shutEndDateName}
              render={({ field: { onChange, ...otherFields } }) => (
                <DatePicker
                  {...otherFields}
                  minDate={watchStartDate}
                  onChange={(e) => {
                    onChange(e)
                    onFillRecentMaintenanceJobs(e, shutEndDateName)
                  }}
                  renderInput={(params) => <TextField color="secondary" sx={{ width: '50%' }} fullWidth {...params} />}
                  format="DD/MM/YYYY"
                />
              )}
            />
            {errors[shutEndDateName] && <p>This field is required</p>}
          </Grid>
        </Grid>
        <DropdownInput
          name={companyName}
          options={filteredCompanies.map((c) => {
            return { id: c.id, Name: c.Name }
          })}
          onDropDownChange={() => {
            remove()
            setValue(siteName, defaultString)
          }}
          control={control}
          errors={errors}
        />
        <DropdownInput
          name={siteName}
          options={
            companyIdToFilter
              ? sites
                  .filter(
                    (s) => s.CustomerId === companyIdToFilter && (currentUser.isAdmin || sitesAvailable.includes(s.id))
                  )
                  .map((c) => {
                    return { id: c.id, Name: c.Name }
                  })
              : []
          }
          onDropDownChange={(e) => onFillRecentMaintenanceJobs(e.target.value, siteName)}
          control={control}
          errors={errors}
        />
        <TextInput name={siteContactName} register={register} errors={errors} />
        <TextInput name={siteRepresentativeName} register={register} errors={errors} />
        <TextInput name={titleName} register={register} errors={errors} />
      </Box>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
        Scope of Work
      </Typography>
      <Box sx={{ padding: '0.5em 1em' }}>
        <TextAreaInput name={introductionName} register={register} errors={errors} />
        <TextAreaInput name={scopeOfWorkName} register={register} errors={errors} />
        <TextAreaInput name={safetyIssuesName} register={register} errors={errors} />

        <Stack direction="column" spacing={1} sx={{ marginBottom: '0.5em' }}>
          <Typography variant="h5" component="h5" sx={{ margin: '0.5em 0 0.2em' }}>
            {crewDetailsName}
          </Typography>
          {crewDetails.fields.map((crew, i) => {
            return (
              <Stack key={crew.id} direction="row" spacing={2}>
                <Controller
                  control={control}
                  name={`${crewDetailsName}.${i}.Shift`}
                  render={({ field }) => (
                    <Select id="Shift" sx={{ width: '50%' }} {...field}>
                      <MenuItem value="Day">Day</MenuItem>
                      <MenuItem value="Night">Night</MenuItem>
                    </Select>
                  )}
                />
                <TextField
                  label="Name"
                  placeholder="e.g. Bob Bobson"
                  color="secondary"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register(`${crewDetailsName}.${i}.Name`, { required: true })}
                />
                <TextField
                  label="Position"
                  placeholder="e.g. Supervisor"
                  color="secondary"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...register(`${crewDetailsName}.${i}.Position`, { required: true })}
                />
              </Stack>
            )
          })}
          <Box>
            <Button
              color="secondary"
              variant="text"
              onClick={() => crewDetails.append({ Shift: 'Day', Name: '', Position: '' })}>
              <AddCircleOutlineOutlinedIcon />
              <Typography
                color="secondary"
                variant="h5"
                component="h5"
                textTransform="none"
                sx={{ marginLeft: '0.25em' }}>
                Add
              </Typography>
            </Button>
            <Button color="secondary" variant="text" onClick={() => crewDetails.remove(crewDetails.fields.length - 1)}>
              <RemoveCircleOutlineOutlinedIcon />
              <Typography
                color="secondary"
                variant="h5"
                component="h5"
                textTransform="none"
                sx={{ marginLeft: '0.25em' }}>
                Remove
              </Typography>
            </Button>
          </Box>
        </Stack>

        <TextAreaInput name={shutdownCommentsName} register={register} errors={errors} />
        <TextAreaInput name={recommendationsName} register={register} errors={errors} />
      </Box>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
        Recent Maintenance Jobs
      </Typography>

      <MaintenanceJobs
        deckRevisions={deckRevisions}
        deckRevisionHistories={deckRevisionHistories}
        fields={fields}
        control={control}
        isVisible={watchSite && watchStartDate && watchEndDate && fields.length}
        watchSite={watchSite}
        isLoading={isLoading}
      />
    </form>
  )
}
export default PostShutForm
