/**
 *
 * "common names in the Reports pages"
 *
 * @file   AllReportsTable.js
 * @author Lateral
 * @since  2023
 */
export const panelName = 'panel'
export const sideLinerName = 'sideLiner'
export const otherName = 'other'
