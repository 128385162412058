/**
 *
 * "A form for editing basic Revision details.""
 *
 * @file   EditRevisionForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function EditRevisionForm({ data, onSubmit }) {
  /**
   * Generates a form to edit Revision details
   *
   * @function
   *
   * @param {object} data - Deck revision
   * @param {string} data.id - Revision ID
   * @param {string} description - Description of the revision
   * @param {} onSubmit - Action su8bmitting the form
   *
   * @returns {object} - Form to edit deck revision
   */
  const defaultValues = {
    Id: data?.Id ?? '',
    Description: data?.Description ?? ''
  }

  const { register, handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Description
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Description"
            name="Description"
            placeholder="e.g. Added extra row"
            color="secondary"
            fullWidth
            {...register('Description', { required: true })}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default EditRevisionForm
