import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { useCallback, useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

/*
 * Hook for confirming when a User wants to leave the page, and has unsaved changes.
 */
function useConfirmExit(confirmExit, when = true) {
  const { navigator } = useContext(NavigationContext)
  const { history } = useContext(CurrentDeckContext)
  useEffect(() => {
    if (!when) {
      return
    }
    const push = navigator.push
    navigator.push = (...args) => {
      const result = confirmExit()
      if (result !== false) {
        history.clearHistory()
        push(...args)
      }
    }
    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}
//This feature used to exist in react router dom v5 but has not yet been implemented in v6
//This is a workaround until the 3rd party adds back support
export function usePrompt(message, when = true) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message
      }
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [message, when])
  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message)
    return confirm
  }, [message])
  useConfirmExit(confirmExit, when)
}
