/**
 *
 * "Help page forviewing help-related details in desktop sized screen."
 *
 * @file   HelpPage.js
 * @author Lateral
 * @since  2023
 */

import React, { useState } from 'react'
import {
  Typography,
  Stack,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SCOPES, FormDialog, useNotification, ContextMenu, ScopeRequirementAll } from 'components'
import { log } from 'common'
import { observeHelpContacts } from 'hooks'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ContactItemForm } from './components/ContactItemForm'
import { CategoryItemForm } from './components/CategoryItemForm'
import { DataOperations } from 'data'
import { defaultRoutes } from 'pages'
import { Header } from 'components'

function HelpPage() {
  /**
   * Generates help page to view on desktop sized screen
   *
   * @function
   *
   * @returns {object} - Help page
   */
  const [isEdit, setIsEdit] = useState(false)
  const [addCategoryModalState, setAddCategoryModalState] = useState({ isOpen: false })
  const [addContactModalState, setAddContactModalState] = useState({ isOpen: false })

  const { notify, confirm } = useNotification()
  const theme = useTheme()
  const contacts = observeHelpContacts()

  const contactsByCategory = contacts.reduce((acc, curr) => {
    if (!acc[curr.category]) acc[curr.category] = []
    acc[curr.category].push(curr)
    return acc
  }, {})

  // On saving help contact
  async function onSave(modalState) {
    if (modalState?.isSave) {
      const data = modalState.data
      try {
        if (data?.id) {
          await DataOperations.updateHelpContact(data)
          notify(`Contact Item updated successfully`)
        } else {
          await DataOperations.addHelpContact(data)
          notify(`Contact Item added`)
        }
      } catch (error) {
        log.error(`failed to save, error ${error}`)
        notify(`An error occurred whilst attempting to save`)
      }
    } else if (modalState.isDelete) {
      await DataOperations.deleteHelpContact(modalState.data.id)
    }

    setAddContactModalState({ isOpen: false })
  }

  // Action on adding help category
  function onAddCategory(modalState) {
    if (modalState?.isSave) {
      const category = modalState.data?.category
      setAddContactModalState({ isOpen: true, title: `New ${category} Contact`, data: { category: category } })
    }
    setAddCategoryModalState({ isOpen: false })
  }

  return (
    <Box sx={{ px: '15px' }}>
      <Header routes={defaultRoutes}></Header>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid item md={12}>
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h1">Help & Support</Typography>

            {isEdit ? (
              <Stack direction="row" spacing={1}>
                <Button
                  sx={{ height: '32px' }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsEdit(false)}>
                  Finish Editting
                </Button>
              </Stack>
            ) : (
              <ScopeRequirementAll requirements={[SCOPES.roleManagement.Write]}>
                <Button
                  sx={{ height: '32px' }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsEdit(true)}>
                  Edit
                </Button>
              </ScopeRequirementAll>
            )}
          </Stack>
        </Grid>
        {Object.keys(contactsByCategory)?.map((categoryKey) => (
          <Grid item md={12} key={categoryKey}>
            <Stack direction="row" spacing={1}>
              <Typography variant="h4">{categoryKey}</Typography>
              {isEdit && (
                <Button
                  sx={{ height: '32px' }}
                  startIcon={<AddCircleOutlineIcon />}
                  size="large"
                  color="secondary"
                  onClick={() =>
                    setAddContactModalState({
                      isOpen: true,
                      title: `New ${categoryKey} Contact`,
                      data: { category: categoryKey }
                    })
                  }>
                  Add Contact
                </Button>
              )}
            </Stack>
            <TableContainer
              sx={{
                mt: '16px',
                width: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: theme.palette.supporting.dark
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.supporting.pale,
                  borderRadius: 2
                }
              }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '10%' }}>Contact Name</TableCell>
                    <TableCell sx={{ width: '10%' }}>Email</TableCell>
                    <TableCell sx={{ width: '180px' }}>Phone</TableCell>
                    <TableCell sx={{ width: '180px' }}>Mobile</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactsByCategory[categoryKey]?.map((contact) => (
                    <TableRow key={contact.name} sx={{ color: theme.palette.primary.light }}>
                      <TableCell>{contact.name}</TableCell>
                      <TableCell>{contact.email}</TableCell>
                      <TableCell>{contact.phone}</TableCell>
                      <TableCell>{contact.mobile}</TableCell>
                      <TableCell>
                        <Stack>
                          <Typography variant="body2">{contact.address1}</Typography>
                          <Typography variant="body2">{contact.address2}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        {isEdit && (
                          <ContextMenu
                            context={contact}
                            menuItems={[
                              {
                                title: 'Edit Details',
                                action: () =>
                                  setAddContactModalState({
                                    isOpen: true,
                                    title: `Editing ${contact?.name}`,
                                    buttonText: 'Update',
                                    data: contact
                                  })
                              },
                              {
                                title: 'Delete Contact',
                                action: async () =>
                                  await confirm({ isDelete: true, data: { id: contact.id } }, async (context) =>
                                    onSave(context)
                                  )
                              }
                            ]}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}

        {isEdit && (
          <Grid item md={12}>
            <Button
              sx={{ height: '32px' }}
              startIcon={<AddCircleOutlineIcon />}
              size="large"
              color="secondary"
              onClick={() =>
                setAddCategoryModalState({ isOpen: true, title: `New Category`, buttonText: 'Create', data: {} })
              }>
              Add Category
            </Button>
          </Grid>
        )}

        {addCategoryModalState.isOpen && (
          <FormDialog modalState={addCategoryModalState} onOpenChange={onAddCategory}>
            <CategoryItemForm />
          </FormDialog>
        )}

        {addContactModalState.isOpen && (
          <FormDialog modalState={addContactModalState} onOpenChange={onSave}>
            <ContactItemForm />
          </FormDialog>
        )}
      </Grid>
    </Box>
  )
}

export default HelpPage
