/**
 *
 * "A modal for publishing a Trial."
 *
 * @file   PublishTrialForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function PublishTrialForm({ data, onSubmit }) {
  /**
   * Generates a modal to confirm publish trial
   *
   * @function
   *
   * @param {object} data - Deck revision object
   * @param {string} data.id - Deck ID
   * @param {number} data.RevisionNumber - Serial number of revision
   * @param {} onSubmit - Action on submiting form
   *
   * @returns {object} - A modal form with confirmation button and warning
   */
  const defaultValues = {
    Id: data?.id ?? ''
  }

  const { handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body1" component="p">
            Are you sure you want to publish this trial?
          </Typography>
          <Typography variant="body1" component="p">
            Publishing will create a new revision for this deck.
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default PublishTrialForm
