/**
 *
 * "Form to confirm remove objects from edit deck page"
 *
 *
 * @file   RemoveForm.js
 * @author Lateral
 * @since  2023
 */

import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function RemoveForm({ data, onSubmit }) {
  /**
   * Generates a form to CRUD kit items in deck revision
   *
   * @function
   *
   * @param {object} data - Object to remove
   * @param {} onSubmit - Action on submiting form
   *
   * @returns {object} - Form to remove object
   */
  const defaultValues = {
    Id: data?.id ?? ''
  }

  const { handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body1" component="p">
            Are you sure you want to remove
            <strong>{` ${data?.Name}`}</strong>?
          </Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default RemoveForm
