/**
 *
 * "A modal form to add/edit dialogue details."
 *
 * @file   AddNewDialogueModal.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { Grid, Typography, OutlinedInput, Stack, Autocomplete, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

const defaultString = ''
const companyName = 'Company'
const siteName = 'Site'
const locationName = 'Location'
const equipmentName = 'Equipment'

function AddNewDialogueModal({ data, onSubmit, customers, locations, sites, decks, equipment, id, isReply = false }) {
  /**
   * Creates a modal form to add/edit dialogue
   *
   * @function
   *
   * @param {object} data - Dialogue object for edit
   * @param {string} data.date - Date of dialouge
   * @param {string} data.user - Username of dialogue creator
   * @param {string} data.Location - Screen location of dialogue
   * @param {string} data.Equipment - Screen name of dialogue
   * @param {string} data.Area - Area of dialogue screen/exciters
   * @param {string} data.Type - Dialogue type Problem/Warning/Diagnostic/Observation
   * @param {string} data.Description - Dialogue description
   * @param {string} data.Comment - Dialogue comment
   * @param {string} data.Outcome - Dialogue outcome Unsuccessful/ Successful/ Temporary Fix
   * @param {string} data.Status - Dialogue comment
   * @param {string} data.Comment - Dialogue status open/closed
   * @param {object} data.children - Reply dialogue objects of the original dialogue
   * @param {} onSubmit - Action on submitting the form
   * @param {object[]} customers - Array of all company object
   * @param {object[]} location - Array of all location object
   * @param {object[]} sites - Array of all site object
   * @param {object[]} decks - Array of all deck location object
   * @param {object[]} equipment - Array of all screen object
   * @param {string} id - Id of dialogue
   * @param {boolean} isReply - IF it is reply of original dialogue
   *
   * @returns {object} - A modal form
   */

  const defaultValues = {
    id: id ? id : data?.id ?? null,
    Type: data?.Type ?? defaultString,
    [companyName]: data?.Company ?? defaultString,
    [siteName]: data?.Site ?? defaultString,
    [locationName]: data?.Location ?? defaultString,
    [equipmentName]: data?.Equipment ?? defaultString,
    Area: data?.Area ?? defaultString,
    Description: data?.Description ?? defaultString,
    Comment: data?.Comment ?? defaultString,
    Action: data?.Action ?? defaultString,
    Outcome: data?.Outcome ?? defaultString,
    Status: data?.Status ?? defaultString
  }

  if (data?.Location) {
    const location = locations.find((l) => l.Name === data.Location)
    if (location) {
      const site = sites.find((s) => s.id === location.SiteId)
      if (site) {
        defaultValues[siteName] = site.Name
        defaultValues[companyName] = customers.find((c) => c.id === site.CustomerId)?.Name ?? defaultString
      }
    }
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues })

  const typeList = ['Problem', 'Warning', 'Diagnostic', 'Observation']
  const areaList = ['Screen Media', 'Screen', 'Exciters']
  const outcomeList = ['Unsuccessful', 'Successful', 'Temporary Fix']
  const statusList = ['Open', 'Closed']

  const watchCompany = watch(companyName, defaultString)
  const watchSite = watch(siteName, defaultString)
  const watchLocation = watch(locationName, defaultString)

  const companyId = customers.find((c) => c.Name === watchCompany)?.id ?? defaultString
  const siteId = sites.find((s) => s.Name === watchSite && s.CustomerId === companyId)?.id ?? defaultString
  const locationId = locations.find((l) => l.Name === watchLocation && l.SiteId === siteId)?.id ?? defaultString

  function resetDropdowns(type) {
    if (type === companyName) {
      setValue(siteName, defaultString)
    }
    if (type === companyName || type === siteName) {
      setValue(locationName, defaultString)
    }

    setValue(equipmentName, defaultString)
  }

  function getEquipment() {
    const filteredDecks = decks.filter(
      (d) =>
        (!companyId || companyId === d.CustomerId) &&
        (!siteId || siteId === d.SiteId) &&
        (!locationId || locationId === d.LocationId)
    )

    const filteredEquipment = equipment.filter((e) => !locationId || locationId === e.LocationId).map((e) => e.Name)

    return [...new Set(filteredDecks.map((d) => d.DeckHeader.EquipmentId).concat(filteredEquipment))]
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5">Type</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name="Type"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => onChange(v)}
                  options={typeList}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Dialogue Type" />}
                  {...otherFields}
                />
              )}
            />
            {errors['Type'] && <p>This field is required</p>}
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5">Location</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name={companyName}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => {
                    onChange(v)
                    resetDropdowns(companyName)
                  }}
                  options={customers.map((m) => m.Name)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Company" />}
                  {...otherFields}
                />
              )}
            />
            {errors[companyName] && <p>This field is required</p>}
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5"></Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name={siteName}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => {
                    onChange(v)
                    resetDropdowns(siteName)
                  }}
                  options={sites.filter((s) => s.CustomerId === companyId).map((m) => m.Name)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Site" />}
                  {...otherFields}
                />
              )}
            />
            {errors[siteName] && <p>This field is required</p>}
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5"></Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name={locationName}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => {
                    onChange(v)
                    resetDropdowns(locationName)
                  }}
                  options={locations.filter((l) => l.SiteId === siteId).map((m) => m.Name)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Location" />}
                  {...otherFields}
                />
              )}
            />
            {errors[locationName] && <p>This field is required</p>}
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5"></Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name={equipmentName}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => onChange(v)}
                  options={getEquipment()}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Screen" />}
                  {...otherFields}
                />
              )}
            />
            {errors[equipmentName] && <p>This field is required</p>}
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5">Area</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name="Area"
              control={control}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => onChange(v)}
                  options={areaList}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Area" />}
                  {...otherFields}
                />
              )}
            />
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5">Description</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name="Description"
              control={control}
              render={({ field }) => (
                <OutlinedInput multiline placeholder="Enter a description" {...field} color="secondary" fullWidth />
              )}
            />
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5">Action</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name="Action"
              control={control}
              render={({ field }) => (
                <OutlinedInput multiline placeholder="Enter an action" {...field} color="secondary" fullWidth />
              )}
            />
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5">Comment</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name="Comment"
              control={control}
              render={({ field }) => (
                <OutlinedInput multiline placeholder="Enter a comment" {...field} color="secondary" fullWidth />
              )}
            />
          </Stack>
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5">Outcome</Typography>
        </Grid>
        <Grid item md={9}>
          <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
            <Controller
              name="Outcome"
              control={control}
              render={({ field: { value, onChange, ...otherFields } }) => (
                <Autocomplete
                  fullWidth
                  value={value}
                  onChange={(e, v) => onChange(v)}
                  options={outcomeList}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="Select Outcome" />}
                  {...otherFields}
                />
              )}
            />
          </Stack>
        </Grid>

        {isReply ? null : (
          <>
            <Grid item md={3}>
              <Typography variant="h5">Status</Typography>
            </Grid>
            <Grid item md={9}>
              <Stack direction="column" spacing={1} alignItems="stretch" justifyContent="center">
                <Controller
                  name="Status"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, ...otherFields } }) => (
                    <Autocomplete
                      fullWidth
                      value={value}
                      onChange={(e, v) => onChange(v)}
                      options={statusList}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} placeholder="Select Status" />}
                      {...otherFields}
                    />
                  )}
                />
                {errors['Status'] && <p>This field is required</p>}
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  )
}

export default AddNewDialogueModal
