/**
 *
 * "CRUD for the DeckRevisionHistory table"
 *
 * @file   DashboardPage.js
 * @author Lateral
 * @since  2023
 */

import { DataStore } from 'aws-amplify'
import dayjs from 'dayjs'
import { DeckRevisionHistory } from 'models'

export async function createDeckRevisionHistory(deckRevisionHistory, createdBy) {
  /**
   *  Create new deck revision hystory in database
   *
   * @function
   * @async
   *
   * @param {object} deckRevisionHistory - Deck revision hystory details
   *
   * @returns {object} - datastore query response
   */
  return await DataStore.save(
    new DeckRevisionHistory({
      RevisionId: deckRevisionHistory.RevisionId,
      CustomerId: deckRevisionHistory.CustomerId,
      SiteId: deckRevisionHistory.SiteId,
      CrossbeamComments: deckRevisionHistory.CrossbeamComments,
      DatePerformed: deckRevisionHistory.DatePerformed,
      DeckRailsComments: deckRevisionHistory.DeckRailsComments,
      Description: deckRevisionHistory.Description,
      ShortDescription: deckRevisionHistory.ShortDescription,
      Details: deckRevisionHistory.Details.filter(({ HistoryAction }) => HistoryAction !== 8),
      HistoryType: deckRevisionHistory.HistoryType,
      PostServiceComments: deckRevisionHistory.PostServiceComments,
      PreServiceComments: deckRevisionHistory.PreServiceComments,
      Recommendations: deckRevisionHistory.Recommendations,
      RevisionNumber: deckRevisionHistory.RevisionNumber,
      Tonnage: deckRevisionHistory.Tonnage,
      CreatedBy: deckRevisionHistory.CreatedBy,
      Photos: deckRevisionHistory.Photos,
      Updates: deckRevisionHistory.Updates,
      LastUpdatedBy: createdBy,
      NextShutDownDate: dayjs(deckRevisionHistory.NextShutDownDate).toISOString()
    })
  )
}

export async function updateDeckRevisionHistory(original, deckRevisionHistory, updatedBy) {
  /**
   *  Update deck revision hystory in database
   *
   * @function
   * @async
   *
   * @param {object} deckRevisionHistory - Deck revision hystory details
   *
   * @returns {object} - datastore query response
   */
  return await DataStore.save(
    DeckRevisionHistory.copyOf(original, (updated) => {
      updated.RevisionId = deckRevisionHistory.RevisionId
      updated.CustomerId = deckRevisionHistory.CustomerId
      updated.SiteId = deckRevisionHistory.SiteId
      updated.CrossbeamComments = deckRevisionHistory.CrossbeamComments
      updated.DatePerformed = deckRevisionHistory.DatePerformed
      updated.DeckRailsComments = deckRevisionHistory.DeckRailsComments
      updated.Description = deckRevisionHistory.Description
      updated.ShortDescription = deckRevisionHistory.ShortDescription
      updated.Details = deckRevisionHistory.Details.filter(({ HistoryAction }) => HistoryAction !== 8)
      updated.HistoryType = deckRevisionHistory.HistoryType
      updated.PostServiceComments = deckRevisionHistory.PostServiceComments
      updated.PreServiceComments = deckRevisionHistory.PreServiceComments
      updated.Recommendations = deckRevisionHistory.Recommendations
      updated.RevisionNumber = deckRevisionHistory.RevisionNumber
      updated.Tonnage = deckRevisionHistory.Tonnage
      updated.CreatedBy = deckRevisionHistory.CreatedBy
      updated.Photos = deckRevisionHistory.Photos
      updated.Updates = deckRevisionHistory.Updates
      updated.LastUpdatedBy = updatedBy
    })
  )
}
