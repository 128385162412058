/**
 *
 * "A form to add new role or update/Delete existing role"
 *
 * @file   RoleItemForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm, Controller } from 'react-hook-form'
import { Typography, Switch, Select, MenuItem } from '@mui/material'
import { SCOPES } from 'components'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

export const RoleItemForm = ({ data, onSubmit, availableCognitoGroups, customers, sites }) => {
  /**
   *
   * Create new role
   *
   * @const
   *
   * @param {object} data - Role details
   * @param {string} data.Name - Role name
   * @param {string} data.Description - Role description
   * @param {string} data.Group - Group name where role applicable
   * @param {} onSubmit - Action on submiting the form
   *
   * @returns {object} - Form to add, update, and delete roles
   */
  const defaultValues = {
    id: data?.id ?? null,
    CustomerId: data?.CustomerId ?? '',
    SiteIds: data?.SiteIds ?? [],
    Name: data?.Name ?? '',
    Group: data?.Group ?? '',
    Description: data?.Description ?? '',
    Scopes: parseExistingScopes(data?.Scopes)
  }

  function parseExistingScopes(scopes) {
    const results = {}
    if (scopes) {
      for (const scope of scopes) {
        results[scope.Name] = { CanRead: scope.CanRead, CanWrite: scope.CanWrite }
      }
    }

    return results
  }

  const { register, handleSubmit, control, watch, setValue } = useForm({ defaultValues })

  const watchCustomerId = watch('CustomerId')

  const filteredSites = watchCustomerId?.length ? sites.filter((s) => s.CustomerId === watchCustomerId) : sites

  function switchWriteOff(name) {
    setValue(`Scopes.${name}.CanWrite`, false, { shouldDirty: true })
  }

  function switchReadOn(name) {
    setValue(`Scopes.${name}.CanRead`, true, { shouldDirty: true })
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography type="h5">Role Name</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Name"
            name="Name"
            placeholder="E.g. Client Admin"
            color="secondary"
            fullWidth
            {...register('Name', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Role Description</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Description"
            name="Description"
            placeholder="E.g. Client Mechanical Specialist"
            color="secondary"
            fullWidth
            {...register('Description', { required: true })}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Cognito Group</Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            id="Group"
            name="Group"
            control={control}
            render={({ field }) => (
              <Select {...field} color="secondary" fullWidth>
                {availableCognitoGroups.map((group) => (
                  <MenuItem key={group.GroupName} value={group.GroupName}>
                    {group.GroupName}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Customer</Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            id="CustomerId"
            name="CustomerId"
            control={control}
            render={({ field }) => (
              <Select {...field} color="secondary" fullWidth>
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Sites</Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            id="SiteIds"
            name="SiteIds"
            control={control}
            render={({ field }) => (
              <Select {...field} color="secondary" fullWidth multiple>
                {filteredSites.map((site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        <Grid item md={3}>
          <Typography type="h5">Role Permissions</Typography>
        </Grid>
        <Grid item md={9}>
          {Object.keys(SCOPES).map((scopeKey) => {
            const value = SCOPES[scopeKey]
            return (
              <Grid container key={scopeKey}>
                <Grid item md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1">{value?.Name}</Typography>
                </Grid>
                <Grid item md={3}>
                  {value?.Read?.length ? (
                    <Controller
                      control={control}
                      name={`Scopes.${value?.Name}.CanRead`}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e)
                                  if (!e.target.checked) {
                                    switchWriteOff(value?.Name)
                                  }
                                }}
                                color="secondary"
                                size="large"
                              />
                            }
                            label={value?.Write?.length > 0 ? 'Read' : 'Enabled'}
                          />
                        </FormGroup>
                      )}
                    />
                  ) : (
                    false
                  )}
                </Grid>

                <Grid item md={3}>
                  {value?.Write?.length ? (
                    <Controller
                      control={control}
                      name={`Scopes.${value?.Name}.CanWrite`}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={field.value}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e)
                                  if (e.target.checked) {
                                    switchReadOn(value?.Name)
                                  }
                                }}
                                color="secondary"
                                size="large"
                              />
                            }
                            label={`Write${value?.RequireAdmin ? '*' : ''}`}
                          />
                        </FormGroup>
                      )}
                    />
                  ) : (
                    false
                  )}
                </Grid>
              </Grid>
            )
          })}
        </Grid>

        <Grid item md={3} />
        <Grid item md={9}>
          <Typography>Scopes with a (*) require the `Administrators` Cognito Group</Typography>
        </Grid>
      </Grid>
    </form>
  )
}
export default RoleItemForm
