/**
 *
 * "Modal window for confirming irreversible choices"
 *
 * @file   ConfirmationDialog.js
 * @author Lateral
 * @since  2023
 */
import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'

export const ConfirmationDialog = ({ isOpen, onClose }) => {
  /**
   * Generates a modal window with warning about irreversible chanes
   * @function
   *
   * @param {boolean} isOpen - If the window is open by other action
   * @param {} onClose - Action on confirming the choise
   *
   * @returns A modal window with cancel and contnue options
   */

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      background: theme.palette.supporting.black
    }
  }))

  const handleClose = (action) => {
    if (onClose) {
      onClose(action)
    }
  }
  return (
    <div>
      <StyledDialog
        open={isOpen}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Confirm'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be reversed. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={() => handleClose(true)} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  )
}

export default ConfirmationDialog
