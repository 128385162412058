/**
 *
 * "Login page component. First page to view unauthenticated Users. Login from here by clicking the 'Sign In' buttons."
 *
 * @file   Landing.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { Auth } from 'aws-amplify'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import backgroundImage from 'assets/img/RAP_Artwork_Page_1.jpg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DESKTOP_MIN_WIDTH } from 'common/config'
import { SandvikLogo } from 'components/SanvikLogo'
import { secondAccent } from 'mainTheme'

export function Landing() {
  /**
   * Generates the login page element
   * @Function
   *
   * @returns Generated paper element for login page
   */
  const theme = useTheme()
  const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH)

  const onLoginPressed = () => {
    Auth.federatedSignIn()
  }

  return (
    <Paper
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw'
      }}>
      {isDesktop ? (
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" height="100vh">
          <Stack width="50%" alignItems="center">
            <Stack height="40vh"></Stack>
            <Stack width="100%" alignItems="center">
              <Box
                width="52%"
                height="auto"
                p={0}
                sx={{ alignItems: 'center', background: '#242424', borderRadius: '32px' }}>
                <Stack sx={{ width: '165%', alignItems: 'left' }}>
                  <SandvikLogo className="auth-logo" />
                </Stack>
              </Box>
            </Stack>
            <Stack height="40vh"></Stack>
            <Box
              spacing={0}
              width="auto"
              height="auto"
              p="auto"
              sx={{ justifyContent: 'left', background: '#A08C64', borderRadius: '16px' }}>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography variant="h5">JOURNEY ACROSS THE NATION - THROUGH TRADITIONAL TRADING ROUTES</Typography>
                    <Typography variant="body2">by Luke Penrith</Typography>
                    <Typography variant="body1">
                      {' '}
                      This artwork connects traditional means of trade of the past with our contemporary collaboration
                      through reconciliation and recognition of today.
                    </Typography>
                  </React.Fragment>
                }
                arrow>
                <Button>Learn More About This Artwork</Button>
              </Tooltip>
            </Box>
          </Stack>
          <Stack sx={{ width: '5%' }}></Stack>
          <Box
            spacing={4}
            width="35%"
            height="auto"
            p="auto"
            sx={{ alignItems: 'center', background: '#242424', borderRadius: '32px' }}>
            <Stack spacing={4} sx={{ width: '100%', pl: '7%', pr: '7%', paddingBottom: '3%', paddingTop: '3%' }}>
              <Stack orientation="vertical" spacing={1}>
                <Typography variant="h2">Welcome</Typography>
                <Typography variant="body1">
                  Welcome to the Sandvik screening media deck management application. This application supports the
                  management of screens through providing a portal to record and track screen revisions and maintenance.
                </Typography>
                <Typography variant="body1">
                  Select the below and enter your account information to access the application and the deck database.
                </Typography>
              </Stack>

              <Box>
                <Typography variant="h2">Member Login</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ height: '40px', width: '209px' }}
                  onClick={onLoginPressed}>
                  Sign in
                </Button>
              </Box>

              <Box>
                <Typography variant="h4">Need Help?</Typography>
                <Typography>
                  Email:&nbsp;
                  <a
                    style={{ color: secondAccent }}
                    href={'mailto:DL_9853.Digital.aus@sandvik.com?subject=DeckMapp Support Request'}>
                    Support
                  </a>
                </Typography>
              </Box>

              <Stack orientation="vertical" spacing={1}>
                <Typography variant="h4">{"Don't have an account?"}</Typography>
                <Typography variant="body1">
                  If your site already holds a subscription please reach out to your subscription manager to enable
                  access.
                </Typography>
                <Typography variant="body1">
                  If not, to organise a new subscription please contact the Sandvik sales team at:
                </Typography>
                <Typography>Tel: +61 (0) 2 9886 6800</Typography>
                <Typography>
                  Email:&nbsp;
                  <a
                    style={{ color: secondAccent }}
                    href={'mailto:DL_9853.ScreenMediaAM@sandvik.com?subject=DeckMapp Subscription Query'}>
                    Sales
                  </a>
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Stack sx={{ width: '5%' }}></Stack>
        </Stack>
      ) : (
        <Stack spacing={2} justifyContent="center" alignItems="center" height="100%">
          <Stack width="50%" alignItems="center">
            <Box
              width="65%"
              height="auto"
              p={0}
              sx={{ alignItems: 'center', background: '#242424', borderRadius: '16px' }}>
              <Stack sx={{ width: '165%', alignItems: 'left' }}>
                <SandvikLogo className="auth-logo" />
              </Stack>
            </Box>
          </Stack>

          <Box display="flex" flexDirection="column" justifyContent={'center'} alignItems={'center'}>
            <Box width="auto" height="auto" p={0} sx={{ background: '#242424', borderRadius: '16px' }}>
              <Typography variant="h2">Member Login</Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              sx={{ height: '40px', width: '209px' }}
              onClick={onLoginPressed}>
              auto Sign in
            </Button>
          </Box>
        </Stack>
      )}
    </Paper>
  )
}
export default Landing
