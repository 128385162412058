/**
 *
 * "Cutom table cells"
 *
 * @file   EnhancedTableHead.js
 * @author Lateral
 * @since  2023
 */
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    width: '12%'
  }
}))

export default StyledTableCell
