import React, { useState } from 'react'
import { AnalysisDrawer } from './AnalysisDrawer'
import { DrawerItem } from './DrawerItem'
import { ReplacePanelResults } from './ReplacePanelDecisionResults'
import { Close } from '@mui/icons-material'
import { useWearAppAnalysisContext } from '../../WearAppAnalysisContext'

export const EfficiencyDrawer = (props) => {
  const {
    currentAnalysisResultsRef: {
      current: { efficiency }
    }
  } = useWearAppAnalysisContext()
  const [showResults, setShowResults] = useState(true)
  return (
    <AnalysisDrawer {...props} setShowResults={setShowResults} showResults={showResults}>
      <ReplacePanelResults>
        <Close
          style={{
            cursor: 'pointer'
          }}
          onClick={() => setShowResults(false)}
        />
      </ReplacePanelResults>

      <DrawerItem isGray title="Change In Screening Efficiency:">
        <p>{efficiency?.calculatedEfficiency?.toFixed(2) ?? '-'}%</p>
      </DrawerItem>
      <DrawerItem title="Original Panel Open Area:">
        <p>{efficiency?.originalPanelOpenArea ?? '-'}%</p>
      </DrawerItem>
      <DrawerItem isGray title="Measured Panel Open Area:">
        <p>{efficiency?.measuredPanelOpenArea?.toFixed(2) ?? '-'}%</p>
      </DrawerItem>
      <DrawerItem title="Original Average Aperture Width:">
        <p>{efficiency?.originalApertureWidth?.toFixed(2) ?? '-'} mm</p>
      </DrawerItem>
      <DrawerItem isGray title="Measured Average Aperture Width:">
        <p>{efficiency?.measuredApertureWidth?.toFixed(2) ?? '-'} mm</p>
      </DrawerItem>
    </AnalysisDrawer>
  )
}
