/**
 *
 * "Table head with sbuilt in sorting"
 *
 * @file   EnhancedTableHead.js
 * @author Lateral
 * @since  2023
 */

import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { visuallyHidden } from '@mui/utils'
import StyledTableCell from './StyledTableCell'
import React from 'react'

function EnhancedTableHead({ headCells, order, orderBy, onRequestSort, prependEmpty = false }) {
  /**
   * Generates a table head for sorting data"
   *
   * @function
   * @param {object[]} headCells - Headings of the table with
   * @param {string} headCells.label - Heading name
   * @param {string} headCells.id - ID of the heading
   * @param {string} order - Ascending or descending order asc/desc
   * @param {object[]} orderBy - id of the heading osrting by
   * @param {} onRequestSort - Action on requesting sort
   * @param {boolean} prependEmpty - prepend true/false
   *
   * @returns {object} - Headings of a table with sorting
   */
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ border: 'none' }}>
        {prependEmpty ? <StyledTableCell sx={{ width: '0' }} /> : null}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell sx={{ width: '0' }} />
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
