/**
 *
 * "Page to monitor data store"
 * Note: Not currently linked to any buttons, can only get here via URL.
 *
 * @file   DatastorePage.js
 * @author Lateral
 * @since  2023
 */
import React, { useState, useEffect } from 'react'
import { Button, Typography, Stack, Alert, List, ListItem } from '@mui/material'
import { DataStore, Hub } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'

const DatastorePage = () => {
  /**
   * Generates a page to monitor data store
   * @const
   *
   * @returns {object} - React app page
   */
  const [events, setEvents] = useState([])
  const theme = useTheme()

  useEffect(() => {
    const listener = Hub.listen('datastore', async (hubData) => {
      const { event, data } = hubData.payload

      let extra
      if (event === 'modelSynced') {
        extra = data?.model?.name
      }

      events.push({ id: uuidv4(), timestamp: dayjs(), event, data, extra })
      setEvents([...events])
      if (event === 'ready') {
        // do something here once the data is synced from the cloud
      }
    })
    return () => {
      listener()
    }
  }, [])

  // Function to start data sync
  async function startSyncData() {
    await DataStore.start()
  }

  // Function to data resync
  async function resyncData() {
    setEvents([])
    await DataStore.clear()
    await DataStore.start()
  }

  return (
    <Stack
      spacing={2}
      sx={{
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '2px',
          height: '2px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Datastore</Typography>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ justifySelf: 'flex-end', width: '155px', height: '32px', padding: 0 }}
            onClick={() => startSyncData()}>
            Start
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ justifySelf: 'flex-end', width: '155px', height: '32px', padding: 0 }}
            onClick={() => resyncData()}>
            Resync
          </Button>
        </Stack>
      </Stack>

      <List dense={true}>
        {events.map((event) => {
          let message = `${event.timestamp.format('hh:mm:ss:SSS')} ${event.event}`
          if (event.data) {
            message = `${message} - ${JSON.stringify(event.data)}`
          }
          if (event.extra) {
            message = `${message} - ${event.extra}`
          }
          return (
            <ListItem key={event.id} sx={{ p: '2px' }}>
              <Alert sx={{ p: '3px 8px', fontSize: '12px' }} severity="info">
                {message}
              </Alert>
            </ListItem>
          )
        })}
      </List>
    </Stack>
  )
}
export default DatastorePage
