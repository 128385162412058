/**
 *
 * "Provides the row coordinates on the 2D canvas in mobile diplay version
 *  in text formate to print by row.
 * Row coordinates are numbers start from 1
 *
 * @file   mobileDeckCanvas\RowNumbers.js
 * @author Lateral
 * @since  2023
 */

import { Box, Stack, Typography } from '@mui/material'
import BigNumber from 'bignumber.js'
import React from 'react'

/*
 *
 */
export function RowNumbers({ scale, totalRows, presentation }) {
  /**
   * Generates the grid coordinates in 2D space.
   *
   * @function
   * @param {number} scale - Scale of the canvas
   * @param {number} totalRows - Total number of rows
   * @param {boolean} presentation - In maintenance mode presentation = True to show high contrast texts
   *
   * @returns {object} - React element of row numbers in text
   */
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ marginTop: `${scale}em`, height: 'fit-content', width: 'fit-content' }}>
      {[
        Array.apply(0, Array(totalRows)).map((x, i) => {
          return (
            <Box key={i} sx={{ height: `${scale}em`, width: `${scale}em` }}>
              <Typography fontSize={12} sx={presentation ? { color: 'black' } : null}>
                {new BigNumber(i).plus(1).toNumber()}
              </Typography>
            </Box>
          )
        })
      ]}
    </Stack>
  )
}
