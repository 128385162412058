import { CircularProgress } from '@mui/material'
import { useInitFabric } from 'hooks/fabric/useInitFabric'
import { usePolygonFabric } from 'hooks/fabric/usePolygonFabric'
import { useEffect, useState } from 'react'

export const FabricWrapper = ({ updatePosition, updatePolygonAllCoordinates, coords }) => {
  const { containerRef, canvasRef, loaded } = useInitFabric({
    canvasId: 'polygon_canvas'
  })

  const [isLoading, setIsLoading] = useState(true)

  const { createPolygon } = usePolygonFabric()

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!loaded.current) {
        return
      }
      createPolygon(canvasRef.current, updatePosition, updatePolygonAllCoordinates, coords)
      clearInterval(intervalId)
      setIsLoading(false)
    }, 100)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div
      id="canvas-wrapper"
      style={{
        width: '100%',
        height: '100%',
        margin: '1em 0'
      }}
      ref={containerRef}>
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      <canvas id="polygon_canvas" />
    </div>
  )
}
