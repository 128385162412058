/**
 *
 * "A small box for text input"
 *
 * @file   TextInput.js
 * @author Lateral
 * @since  2023
 */
import { Grid, OutlinedInput, Typography } from '@mui/material'
import React from 'react'

export function TextInput({ name, register, errors, required = false }) {
  /**
   * Create A box for text input
   *
   * @function
   *
   * @param {string} name - Name of the text input area
   * @param {} register - Action on registering the field to object
   * @param {string} errors - Reported errors
   * @param {boolean} required - Input is required or optional
   *
   * @returns {object} - Text input box
   */
  return (
    <Grid container alignItems="center">
      <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
        <Typography variant="h5" component="h5">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
        <OutlinedInput
          id={name}
          name={name}
          color="secondary"
          fullWidth
          sx={{ width: '50%' }}
          {...register(name, {
            required: required
          })}
        />
        {errors[name] && <p>This field is required</p>}
      </Grid>
    </Grid>
  )
}
