import { LinearProgress, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import MaterialCSVReader from './components/materials/MaterialCSVReader'
/*
 * Page for adding/updating Materials
 */
function MaterialsPage() {
  const [totalNew, setTotalNew] = useState(new BigNumber(0))
  const [totalUpdated, setTotalUpdated] = useState(new BigNumber(0))
  const [total, setTotal] = useState(new BigNumber(1))
  const [uploadStatus, setUploadStatus] = useState({
    isUploading: false,
    csvSelected: false,
    hasFinished: false
  })
  return (
    <Stack spacing={2}>
      <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Manage Materials</Typography>
      </Stack>
      <MaterialCSVReader
        setTotal={setTotal}
        setTotalNew={setTotalNew}
        setTotalUpdated={setTotalUpdated}
        setUploadStatus={setUploadStatus}
        uploadStatus={uploadStatus}
      />
      {uploadStatus.csvSelected ? (
        <Stack spacing={2}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            sx={{ borderRadius: '5px' }}
            value={totalNew.plus(totalUpdated).dividedBy(total).times(100).toNumber()}
          />
          <Typography>Total new: {totalNew.toNumber()}</Typography>
          <Typography>Total updated: {totalUpdated.toNumber()}</Typography>
          {uploadStatus.hasFinished ? <Typography>Total processed: {total.toNumber()}</Typography> : null}
        </Stack>
      ) : null}
    </Stack>
  )
}
export default MaterialsPage
