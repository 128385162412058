import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { Role } from 'models'

/*
 * Observable for the Roles table.
 */
export function observeRoles() {
  const [roles, setRoles] = useState([])
  const [rolesLoaded, setRolesLoaded] = useState(true)
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const roles = await DataStore.query(Role, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })
        setRoles(roles)
      } finally {
        setRolesLoaded(false)
      }
    }

    const subscription = DataStore.observe(Role).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return [rolesLoaded, roles]
}

export default observeRoles
