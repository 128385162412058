/**
 *
 * "Page to view and handle all user related events"
 *
 * @file   UsersPage.js
 * @author Lateral
 * @since  2023
 */
import React, { useMemo, useState } from 'react'
import { Button, Typography, Stack, Box } from '@mui/material'
import { log } from 'common'
import { SCOPES, ScopeRequirementAll, useNotification } from 'components'
import UserItemForm from './components/users/UserItemForm'
import UserTable from './components/users/UserTable'
import {
  useGetUsers,
  createUser,
  adminUpdateUserAttributes,
  addUserToGroup,
  removeUserFromGroup,
  disableUser,
  enableUser
} from './common/adminDatastore'
import { observeRoles, observeCustomers, observeUserRoles, useCurrentUser } from 'hooks'
import { addOrUpdateUserRole, removeUserRole } from './common/data'
import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'
import { MaintenanceDialog } from 'components/maintenanceSelector/MaintenanceDialog'

const UsersPage = () => {
  /**
   * Page to view and handle user realted events
   * @const
   *
   * @returns {object} - React app page
   */
  const { notify } = useNotification()
  const [user, setUser] = useState(undefined)
  const [, roles] = observeRoles()
  const [, userRoles] = observeUserRoles()
  const customers = observeCustomers()
  const { currentUser } = useCurrentUser()
  const { usersQuery } = useGetUsers(currentUser.accessToken)

  const groups = currentUser.getGroups()
  const writeableSites = currentUser.getSitesForScope(SCOPES.userManagement.Write)
  const writeableCustomers = currentUser.getCustomersForScope(SCOPES.userManagement.Write)
  const hasCognitoAdmin = groups.filter((g) => g.toLowerCase() === 'administrators').length > 0

  const [isSavingUser, setIsSavingUser] = useState(false)
  const users = usersQuery.data
  const userRows = useMemo(() => {
    const users_ = users?.map((item) => {
      const companyIds = item.Attributes?.find((x) => x.Name === 'customerName')?.Value
      const siteIds = item.Attributes?.find((x) => x.Name === 'sites')?.Value
      const sandvikId = item.Attributes?.find((x) => x.Name === 'sandvikUserId')?.Value
      let parsedCompanyIds = []
      try {
        const parsed = JSON.parse(companyIds)
        parsedCompanyIds = parsed
      } catch {
        parsedCompanyIds = [companyIds]
      }
      const parsedSiteIds = siteIds?.length ? JSON.parse(siteIds) : []
      const companyNames = customers.filter((c) => parsedCompanyIds.includes(c.id)).map((c) => c.Name)
      const ownUserRoles = userRoles.filter((u) => u.CognitoId === sandvikId).map((u) => u.RoleId)
      const ownRoles = roles.filter((r) => ownUserRoles.includes(r.id))
      return {
        Username: item?.Username,
        SandvikUserId: sandvikId,
        CompanyIds: parsedCompanyIds,
        SiteIds: parsedSiteIds,
        CompanyNames: companyNames,
        FirstName: item?.Attributes?.find((x) => x.Name === 'given_name')?.Value,
        LastName: item?.Attributes?.find((x) => x.Name === 'family_name')?.Value,
        Email: item?.Attributes?.find((x) => x.Name === 'email')?.Value,
        RoleIds: ownRoles.map((r) => r.id),
        Roles: ownRoles.map((r) => r.Name),
        Enabled: item?.Enabled
      }
    })

    if (currentUser.isAdmin) {
      return users_
    }
    const siteIds = currentUser?.getSitesForScope(SCOPES.userManagement.Read)
    return users_.filter(
      (u) => u.CompanyIds.some((c) => currentUser.customerIds.includes(c)) && u.SiteIds.some((s) => siteIds.includes(s))
    )
  }, [users, currentUser])

  const filteredRoles = useMemo(() => {
    if (currentUser.isAdmin) {
      return roles
    }
    const siteIds = currentUser?.getSitesForScope(SCOPES.userManagement.Read)

    return roles.filter((r) => r.SiteIds?.some((s) => siteIds.includes(s)))
  }, [roles, currentUser])

  if (usersQuery.isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <ProgressIndicator />
      </Box>
    )
  if (usersQuery.error)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography>{'An error has occurred: ' + usersQuery.error.message}</Typography>
      </Box>
    )

  // Handle CRUD for roles
  const handleEvent = async (eventName, context) => {
    if (eventName === 'editUser') {
      context.id = context.Username
      setUser(context)
      return
    }
    try {
      if (eventName === 'disableUser') {
        await disableUser(context.Username)
      } else if (eventName === 'enableUser') {
        await enableUser(context.Username)
      }
    } catch {
      notify(eventName === 'disableUser' ? 'Failed to disable user' : 'Failed to enable user')
    }

    usersQuery.refetch().then()
  }

  // Modal for role CRUD
  const handleUserItemDialogClose = async (data) => {
    try {
      setIsSavingUser(true)
      if (data?.SandvikUserId) {
        notify(`Updating user ...`)

        const originalCompanies = data.OriginalCompanyNames ?? []
        const newCompanies = data.CompanyNames ?? []

        const companiesToRemove = originalCompanies.filter((company) => !newCompanies.includes(company))
        const originalRoles = roles.filter((r) => user.RoleIds.includes(r.id))
        const newRoles = roles.filter((r) => data.Roles.includes(r.id) && !companiesToRemove.includes(r.CustomerId))
        const originalGroups = originalRoles.map((r) => r.Group)
        const newGroups = newRoles.map((r) => r.Group)

        const groupsToRemove = originalGroups.filter((group) => !newGroups.includes(group))

        const groupsToAdd = newGroups.filter((group) => !originalGroups.includes(group))

        const newRoleIds = newRoles.map((r) => r.id)
        const rolesToRemove = originalRoles.filter((r) => !newRoleIds.includes(r.id))

        const sites = JSON.stringify([...new Set(newRoles.flatMap((r) => r.SiteIds).filter((s) => s))])
        const companyNames = JSON.stringify([
          ...new Set(
            newRoles
              .map((r) => r.CustomerId)
              .filter((c) => c)
              .concat(originalCompanies)
              .concat(newCompanies)
              .filter((c) => !companiesToRemove.includes(c))
          )
        ])
        const promises = []

        for (const group of groupsToRemove) {
          promises.push(removeUserFromGroup(data.Username, group, currentUser.accessToken))
        }

        for (const group of groupsToAdd) {
          promises.push(addUserToGroup(data.Username, group, currentUser.accessToken))
        }

        for (const role of rolesToRemove) {
          promises.push(removeUserRole(data?.SandvikUserId, role.id, currentUser.accessToken))
        }

        for (const role of newRoles) {
          promises.push(
            addOrUpdateUserRole({ CognitoId: data?.SandvikUserId, CustomerId: role.CustomerId, RoleId: role.id })
          )
        }

        promises.push(
          adminUpdateUserAttributes(
            data.Username,
            data.FirstName,
            data.LastName,
            companyNames,
            sites,
            currentUser.accessToken
          )
        )

        await Promise.all(promises)
        usersQuery.refetch().then()

        //notify(`User Item updated successfully`)
      } else {
        const pormises = []
        notify(`Adding user ...`)

        const newRoles = roles.filter((r) => data.Roles.includes(r.id))
        const sites = JSON.stringify([...new Set(newRoles.flatMap((r) => r.SiteIds))])
        const companyNames = JSON.stringify(data.CompanyNames)
        const user = await createUser(
          data.Email,
          data.FirstName,
          data.LastName,
          companyNames,
          sites,
          currentUser.accessToken
        )
        const usedGroups = []
        for (const role of newRoles) {
          if (role) {
            pormises.push(
              addOrUpdateUserRole({
                CognitoId: user?.User.Username,
                CustomerId: role.CustomerId,
                RoleId: role.id
              })
            )
            if (!usedGroups.includes(role.Group)) {
              pormises.push(addUserToGroup(user.User.Username, role.Group, currentUser.accessToken))
              usedGroups.push(role.Group)
            }
          }
        }

        await Promise.all(pormises)
        usersQuery.refetch().then()

        notify(`User Item Added`)
      }
      setUser(undefined)
    } catch (error) {
      log.error(`failed to save, error ${error}`)
      notify(`An error occurred whilst attempting to save`)
    } finally {
      setIsSavingUser(false)
    }
  }

  return (
    <Stack spacing={2}>
      <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Manage Users</Typography>
        {!hasCognitoAdmin && currentUser.hasAllScopes([SCOPES.userManagement.Write]) && (
          <Typography color="error">
            Your role has User write access, but does not have Admin Cognito permission. Adding/editing Users will be
            disabled.
          </Typography>
        )}
        {hasCognitoAdmin && (
          <ScopeRequirementAll requirements={[SCOPES.userManagement.Write]}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ justifySelf: 'flex-end', width: '155px', height: '32px', padding: 0 }}
              onClick={() => setUser({})}>
              Add User
            </Button>
          </ScopeRequirementAll>
        )}
      </Stack>
      <UserTable rows={userRows} hasCognitoAdmin={hasCognitoAdmin} handleEvent={handleEvent} />

      <MaintenanceDialog
        onCancel={() => setUser(undefined)}
        open={!!user}
        title={Object.keys(user ?? {}).length > 0 ? `Edit User '${user.FirstName} ${user.LastName}'` : 'Add new user'}
        isLoading={isSavingUser}
        saveButtonProps={{
          form: `form`,
          type: 'submit'
        }}>
        <UserItemForm
          roleList={filteredRoles.filter(
            (r) => r.Enabled && (currentUser.isAdmin || r.SiteIds?.some((s) => writeableSites.includes(s)))
          )}
          companyList={customers.filter((c) => currentUser.isAdmin || writeableCustomers.includes(c.id))}
          data={user}
          onSubmit={handleUserItemDialogClose}
        />
      </MaintenanceDialog>
    </Stack>
  )
}
export default UsersPage
