import { useState } from 'react'

/*
 * Hook that holds the History of a Deck in a page.
 */
export function useUndoRedo() {
  const [history, setHistory] = useState([])
  const [historyPosition, setHistoryPosition] = useState(-1)

  const isEmpty = historyPosition <= 0
  const isAtEnd = historyPosition === history.length - 1

  function addHistory(data) {
    const clone = structuredClone(history)

    for (let i = clone.length - 1; i > historyPosition; i--) {
      clone.pop()
    }

    clone.push(structuredClone(data))
    setHistory(clone)
    setHistoryPosition(historyPosition + 1)
  }

  function redoHistory() {
    if (!isAtEnd) {
      const data = history[historyPosition + 1]
      setHistoryPosition(historyPosition + 1)
      return structuredClone(data)
    }
  }

  function undoHistory() {
    if (!isEmpty) {
      const data = history[historyPosition - 1]
      setHistoryPosition(historyPosition - 1)
      return structuredClone(data)
    }
  }

  function clearHistory() {
    setHistory([])
    setHistoryPosition(-1)
  }

  return { addHistory, clearHistory, undoHistory, redoHistory, isEmpty, isAtEnd, history }
}

export default useUndoRedo
