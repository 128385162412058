/**
 *
 * "Renders a row in the SiteTable, including the
 * collapsed additional location info."
 *
 * @file   SiteRow.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { ContextMenu, SCOPES, ScopeRequirementAll } from 'components'
import StyledTableCell from 'components/tables/StyledTableCell'
import { Box, Collapse, IconButton, Stack, Table, TableBody, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useCurrentUser } from 'hooks'

export function SiteRow({
  database,
  customerId,
  row,
  onEditLocation,
  onDeleteLocation,
  onEditDetails,
  onDelete,
  onCreateLocation
}) {
  /**
   * Provides a row for a site with collapsible additional info
   *
   * @function
   * @param {object} database - The database with locations and decks object arrays
   * @param {customerId} customerId - ID of the customer
   * @param {object} row - Row of the site table
   * @param {string} row.id - ID of the site
   * @param {string} row.SiteName - Name of the site
   * @param {string} row.Contacts - Site customer contacts
   * @param {string} row.LocationsTotal - Number of location in the site
   * @param {string} row.ScreensTotal - Number of screens in the site
   * @param {} onEditLocation - Action on click edit location
   * @param {} onDeleteLocation - Action on click delete location
   * @param {} onEditDetails - Action on click edit site
   * @param {} onDelete - Action on click delete site
   * @param {} onCreateLocation - Action on click create location
   *
   * @returns {object} - A row for site table
   */
  const [open, setOpen] = useState(false)
  const tableSize = { width: '15%' }
  const locations = database.locations.filter((l) => l.SiteId === row.id)
  const { currentUser } = useCurrentUser()
  const writeableSites = currentUser.getSitesForScope(SCOPES.siteManagement.Write)

  return (
    <React.Fragment>
      <TableRow key={row?.SiteName} hover tabIndex={-1} sx={{ height: '4em' }}>
        <TableCell sx={{ width: '0' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <StyledTableCell>{row?.SiteName ?? '--'}</StyledTableCell>
        <TableCell sx={tableSize}>
          <Stack spacing={1}>
            {row.Contacts && row.Contacts.length ? (
              row.Contacts.map((c) => {
                return (
                  <Box key={c.Email}>
                    {`${c.Name ?? '--'} (${c.Position})`}
                    <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                      {c.Email ?? '--'}
                    </Typography>
                  </Box>
                )
              })
            ) : (
              <Box>
                --
                <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                  --
                </Typography>
              </Box>
            )}
          </Stack>
        </TableCell>
        <TableCell sx={tableSize}>
          <Stack>
            <Typography>{row?.LocationsTotal ?? '--'}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ width: 'auto' }}>
          <Stack>
            <Typography>{row?.ScreensTotal ?? '--'}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ width: '0' }}>
          {(writeableSites.includes(row.id) || currentUser.isAdmin) && (
            <ScopeRequirementAll requirements={[SCOPES.siteManagement.Write]}>
              <ContextMenu
                context={row}
                menuItems={[
                  { title: 'Edit Site Details', action: () => onEditDetails(row) },
                  { title: 'Add Location', action: () => onCreateLocation(row) },
                  { title: 'Delete Site', action: () => onDelete(row) }
                ]}
              />
            </ScopeRequirementAll>
          )}
        </TableCell>
      </TableRow>

      {/* Additional Location info */}
      <TableRow sx={open ? null : { border: 'none' }}>
        <TableCell colSpan={6} sx={{ padding: '0' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {locations.map((l) => {
                  const decks = database.decks.filter((d) => d.LocationId === l.id && d.CustomerId === customerId)
                  const total =
                    [
                      ...new Set(
                        decks.map((d) => {
                          return d.DeckHeader.EquipmentId
                        })
                      )
                    ].length ?? 0
                  return (
                    <TableRow key={l.id} hover sx={{ border: 'none' }}>
                      <TableCell sx={{ minWidth: '66px', width: '0' }} />
                      <StyledTableCell />
                      <TableCell sx={tableSize} />
                      <TableCell sx={tableSize}>
                        <Typography>{l.Name}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: 'auto' }}>
                        <Typography>{total}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: '0' }}>
                        {(writeableSites.includes(row.id) || currentUser.isAdmin) && (
                          <ScopeRequirementAll requirements={[SCOPES.siteManagement.Write]}>
                            <ContextMenu
                              context={l}
                              menuItems={[
                                { title: 'Edit Location Details', action: () => onEditLocation(l) },
                                { title: 'Delete Location', action: () => onDeleteLocation(l) }
                              ]}
                            />
                          </ScopeRequirementAll>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default SiteRow
