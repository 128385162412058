import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { Reports } from 'models'

/*
 * Observable for the Reports table.
 */
export function observeReports() {
  const [reports, setReports] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const retrieveData = async () => {
      const reports = await DataStore.query(Reports, null, { sort: (s) => s.Company(SortDirection.ASCENDING) })
      setReports(reports)
      setIsLoaded(true)
    }

    const subscription = DataStore.observe(Reports).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return { reports, isLoaded }
}

export default observeReports
