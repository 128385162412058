import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

export const DropDown = ({ data, dropDownKey, onChange, value, label, isLoading, enabled = true }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl
        variant="standard"
        style={{
          marginBottom: '1em',
          flex: 1
        }}
        disabled={!enabled}>
        <InputLabel
          style={{
            color: 'white',
            fontFamily: 'arial',
            fontSize: 14
          }}
          id={`label-for-${dropDownKey}`}>
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          onChange={(event) => onChange(event.target.value)}
          label={label}
          disabled={!enabled}>
          {(data ?? []).map(function ({ Id, Name }) {
            return (
              <MenuItem
                style={{
                  color: 'white',
                  fontFamily: 'arial',
                  fontSize: 14
                }}
                key={Id}
                value={Name}>
                {Name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      {isLoading && <CircularProgress style={{ marginLeft: '1em' }} color="inherit" size={16} />}
    </div>
  )
}
