import { useEffect, useState } from 'react'

export const use2D3DViewSwitch = () => {
  const [viewType, setViewType] = useState(localStorage.getItem('viewType') ?? '3D')

  useEffect(() => {
    localStorage.setItem('viewType', viewType)
  }, [viewType])

  return [viewType, setViewType]
}
