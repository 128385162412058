import BigNumber from 'bignumber.js'
import { toAlphabet } from './numberToAlphabet'

/**
 *
 * "Get parsed Revision Number from a DeckRevision"
 *
 * @file   numberToAlphabet.js
 * @author Lateral
 * @since  2023
 */
export function parsedRevisionNumberWithSubLetter(deckRevision) {
  /**
   * Convert number to String
   *
   * @function
   * @param {DeckRevision} deckRevision - The revision to get the number from
   * @returns {String} String of the RevisionNumber
   */

  const totalAlphabetLetters = 26
  const round = new BigNumber(deckRevision.MinorRevisionNumber ?? 1)
    .minus(1)
    .dividedBy(totalAlphabetLetters)
    .decimalPlaces(0, BigNumber.ROUND_DOWN)

  const minorAlphabetRound = round.isLessThanOrEqualTo(0) ? '' : toAlphabet(round, false)
  const minorAlphabet = toAlphabet(
    new BigNumber(deckRevision.MinorRevisionNumber).minus(round.times(totalAlphabetLetters)),
    false
  )

  return `${deckRevision.RevisionNumber}${
    deckRevision.MinorRevisionNumber ? `.${minorAlphabetRound}${minorAlphabet}` : ''
  }`
}

export function parsedRevisionNumber(deckRevision) {
  /**
   * Convert number to String
   *
   * @function
   * @param {DeckRevision} deckRevision - The revision to get the number from
   * @returns {String} String of the RevisionNumber
   */

  return `${deckRevision.RevisionNumber}.${deckRevision.MinorRevisionNumber ?? 0}`
}
