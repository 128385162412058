/**
 *
 * "Form for entering and CRUDing a Kit it Edit deck page.
 *
 *
 * @file   KitItemForm.js
 * @author Lateral
 * @since  2023
 */
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Grid,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { KitsItems, KitSizeItems } from 'models'
import { useForm, Controller } from 'react-hook-form'
import React, { useEffect, useMemo, useState } from 'react'
import { cSSColourNames } from 'common/colours'
import BigNumber from 'bignumber.js'
import { FormDialog } from 'components'
import RemoveForm from './RemoveForm'

import { useTheme } from '@mui/material/styles'
import { useGetMaterials } from 'hooks/database/useGetMaterials'
import { groupBy } from 'common/arrays'
import { MaintenanceNotifier } from 'components/maintenanceSelector/MaintenanceNotifier'

const inputStyling = {
  width: '100%',
  margin: '0.5em 0 0.5em 0'
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 500
})

const defaultString = ''

const sideLinerName = 'sideliner'
const panelName = 'panel'
const otherName = 'other'

function KitItemForm({ deckRevision, setDeckRevision, setMaterialSelected, materialSelected, data }) {
  /**
   * Generates a form to CRUD kit items in deck revision
   *
   * @function
   *
   * @param {object} deckRevision - Object of deckrevion details
   * @param {} setDeckRevision - Funtion to set deckrevision object
   * @param {object} data - Kits details
   * @param {string} data.vNumberName - Material Description
   * @param {string} data.vNumberFormName - Material Number
   * @param {string} data.descriptionName - Kit Description
   * @param {string} data.partNumber - Material Description
   * @param {string} data.vNumberName - Kit Part Number
   * @param {string} data.CustomerStockCode - Code in customer stock
   * @param {string} data.Weight - Unit Weight (kg)
   * @param {string} data.Colour - Material Description
   * @param {string} data.OpenArea - Open area of kit
   * @param {string} data.WorkingDepth - Working depth of kit before failing
   * @param {string} data.FailureWorkingDepth - Working depth of kit of failing
   * @param {string} data.ApertureWidth - Aperature width
   * @param {string} data.FailureApertureWidth - Minimum failing aperature
   * @param {string} data.size.Height - Height of the kit
   * @param {string} data.size.Width - Width of the kit
   *
   * @returns {object} - Form to create/Update kit
   */
  const vNumberName = 'Material Description'
  const vNumberFormName = 'Material Number'
  const descriptionName = 'Description'
  const partNumberName = 'Part Number'
  const codeName = 'Code'
  const quantityName = 'Quantity'
  const weightName = 'Unit Weight (kg)'
  const colourName = 'Colour'
  const openAreaName = 'Open Area'
  const depthName = 'Working Depth'
  const failureDepthName = 'Failure Working Depth'
  const apertureName = 'Aperture Width'
  const failureApertureName = 'Failure Aperture Width'
  const heightName = 'Height'
  const widthName = 'Width'

  const defaultValues = {
    [vNumberName]: data?.MaterialNumber ?? defaultString,
    [vNumberFormName]: { Number: data?.MaterialNumber ?? defaultString },
    [descriptionName]: data?.MaterialDescription ?? defaultString,
    [partNumberName]: data?.PartNumber ?? defaultString,
    [codeName]: data?.CustomerStockCode ?? defaultString,
    [quantityName]: data?.Quantity ?? 0,
    [weightName]: data?.Weight ?? defaultString,
    [colourName]: data?.Colour ?? defaultString,
    [openAreaName]: data?.OpenArea ?? defaultString,
    [depthName]: data?.WorkingDepth ?? defaultString,
    [failureDepthName]: data?.FailureWorkingDepth ?? defaultString,
    [apertureName]: data?.ApertureWidth ?? defaultString,
    [failureApertureName]: data?.FailureApertureWidth ?? defaultString,
    [heightName]: data?.Size?.Height ?? '1',
    [widthName]: data?.Size?.Width ?? '1'
  }

  const {
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({ defaultValues })

  const [isSandvikMaterial, setIsSandvikMaterial] = useState(true)
  const [removeModalState, setRemoveModalState] = useState({ isOpen: false })
  const watchVNumber = watch(vNumberName, data?.MaterialNumber ?? defaultString)
  const isOther =
    !data ||
    (!deckRevision.Panels.some((p) => p.MaterialNumber === data.MaterialNumber) &&
      !deckRevision.SideLiners.some((s) => s.MaterialNumber === data.MaterialNumber))

  const [showMessage, setShowMessage] = useState(false)

  const theme = useTheme()

  const { materials } = useGetMaterials()

  useEffect(() => {
    setIsSandvikMaterial(!!materials.find(({ MaterialNumber }) => MaterialNumber === materialSelected))
  }, [materialSelected])

  useEffect(() => {
    if (data?.MaterialNumber) {
      setValue(
        failureDepthName,
        materials.find(({ MaterialNumber }) => data?.MaterialNumber === MaterialNumber)?.SafetyDepth
      )
    }
  }, [materials])

  const sideLiners = useMemo(() => {
    return groupBy('MaterialNumber')(deckRevision.SideLiners)
  }, [deckRevision.SideLiners, deckRevision.SideLiners?.length])

  const panels = useMemo(() => {
    return groupBy('MaterialNumber')(deckRevision.Panels)
  }, [deckRevision.Panels, deckRevision.Panels?.length])

  const kits = useMemo(() => {
    return [...(deckRevision.Kits ?? [])].sort((a, b) => a.MaterialNumber.localeCompare(b.MaterialNumber))
  }, [deckRevision.Kits, deckRevision.Kits?.length])

  const [kitData, setKitData] = useState({
    sideLinerKits: [],
    panelKits: [],
    otherKits: []
  })

  const sideLinersMaterials = useMemo(() => {
    return kitData.sideLinerKits.map((s) => {
      return s.MaterialNumber
    })
  }, [kitData.sideLinerKits])

  const panelMaterials = useMemo(() => {
    return kitData.panelKits.map((s) => {
      return s.MaterialNumber
    })
  }, [kitData.panelKits])

  const otherMaterials = useMemo(() => {
    return kitData.otherKits.map((s) => {
      return s.MaterialNumber
    })
  }, [kitData.panelKits])

  function panelTypeCheck(material) {
    const panelKeys = Object.keys(panels)
    const sideLinerKeys = Object.keys(sideLiners)

    if (panelKeys.includes(material)) {
      return panelName
    }

    if (sideLinerKeys.includes(material)) {
      return sideLinerName
    }

    return otherName
  }

  useEffect(() => {
    const sideLinerKits = kits.filter((k) => panelTypeCheck(k.MaterialNumber) === sideLinerName)
    const panelKits = kits.filter((k) => panelTypeCheck(k.MaterialNumber) === panelName)
    const otherKits = kits.filter((k) => panelTypeCheck(k.MaterialNumber) === otherName)

    setKitData({
      sideLinerKits: sideLinerKits,
      panelKits: panelKits,
      otherKits
    })
  }, [kits])

  function switchChecked() {
    setIsSandvikMaterial(!isSandvikMaterial)
    setValue(descriptionName, defaultString)
  }

  //gathers Material data when the VNumber is changed.
  function onVNumberChange(v) {
    const vNumber = v ?? getValues(vNumberFormName)
    if (!vNumber) {
      return
    }
    const material = materials.find((m) => m.MaterialNumber === vNumber.Number)
    setValue(vNumberName, vNumber.Number)
    setValue(descriptionName, material?.MaterialDescription ?? defaultString)
    setValue(openAreaName, material?.OpenArea ?? defaultString)
    setValue(partNumberName, material?.PartNumber ?? defaultString)
    setValue(weightName, material?.Weight ?? defaultString)
    setValue(failureDepthName, material.SafetyDepth)
    clearErrors(vNumberName)
  }

  //Creat kit
  function submit(form) {
    if (form[vNumberName]) {
      if ([...otherMaterials, ...sideLinersMaterials, ...panelMaterials].includes(form[vNumberName])) {
        setShowMessage(true)
        return
      }
      const existingColours = deckRevision.Kits.map((k) => k.Colour)
      const freeColours = cSSColourNames.filter((c) => !existingColours.includes(c))

      const kit = new KitsItems({
        MaterialNumber: form[vNumberName],
        MaterialDescription: form[descriptionName],
        PartNumber: form[partNumberName],
        CustomerStockCode: form[codeName],
        Quantity: Number(form[quantityName]),
        Weight: form[weightName],
        Colour: freeColours[Math.floor(new BigNumber(Math.random()).times(freeColours.length).toNumber())],
        OpenArea: form[openAreaName],
        WorkingDepth: form[depthName],
        FailureWorkingDepth: form[failureDepthName],
        ApertureWidth: form[apertureName],
        FailureApertureWidth: form[failureApertureName],
        Size: new KitSizeItems({
          Height: Number(form[heightName]),
          Width: Number(form[widthName])
        })
      })
      const dataClone = Object.assign({}, deckRevision)
      dataClone.Kits.push(kit)

      setDeckRevision(dataClone)
      reset()
    } else {
      setError(vNumberName, { message: 'Required' })
      setError(vNumberFormName, { message: 'Required' })
    }
  }

  // Update kit
  function update(form) {
    const dataClone = Object.assign({}, deckRevision)
    const kit = structuredClone(
      dataClone.Kits?.find((k) => k.MaterialNumber === form[vNumberName]) ?? new KitsItems({})
    )
    ;(kit.MaterialNumber = form[vNumberName]),
      (kit.MaterialDescription = form[descriptionName]),
      (kit.PartNumber = form[partNumberName]),
      (kit.CustomerStockCode = form[codeName]),
      (kit.Weight = form[weightName]),
      (kit.Quantity = Number(form[quantityName])),
      (kit.Colour = form[colourName]),
      (kit.OpenArea = form[openAreaName]),
      (kit.WorkingDepth = form[depthName]),
      (kit.FailureWorkingDepth = form[failureDepthName]),
      (kit.ApertureWidth = form[apertureName]),
      (kit.FailureApertureWidth = form[failureApertureName]),
      (kit.Size = new KitSizeItems({
        Height: Number(form[heightName]),
        Width: Number(form[widthName])
      }))

    dataClone.Kits = dataClone.Kits?.filter((k) => k.MaterialNumber !== kit.MaterialNumber) ?? []
    dataClone.Kits.push(kit)
    setDeckRevision(dataClone)
  }

  //Delete kit
  function remove(modalState) {
    if (modalState?.isSave) {
      const vNumber = getValues(vNumberName)
      if (vNumber) {
        const number = vNumber
        const dataClone = Object.assign({}, deckRevision)
        dataClone.Kits.splice(
          dataClone.Kits.findIndex((k) => k.MaterialNumber === number),
          1
        )
        dataClone.Panels.map((p) => {
          if (p.MaterialNumber === number) {
            p.MaterialNumber = null
          }
        })
        dataClone.SideLiners.map((s) => {
          if (s.MaterialNumber === number) {
            s.MaterialNumber = null
          }
        })
        setDeckRevision(dataClone)
      }

      reset()
      setMaterialSelected('')
    }
    setRemoveModalState({ isOpen: false })
  }

  function textInput(name, disableIfData, inputData = {}) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3} sx={inputStyling}>
          <Typography variant="h5" component="h5">
            {name}
          </Typography>
        </Grid>
        <Grid item md={9} sx={inputStyling}>
          <OutlinedInput
            id={name}
            name={name}
            color="secondary"
            fullWidth
            inputProps={inputData}
            disabled={disableIfData && data && Object.entries(data).length > 0}
            {...register(name)}
          />
          {errors[name] && <p>This field is required</p>}
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Box sx={{ height: '100%' }}>
        <Stack direction="row">
          <Typography variant="h5" component="h5" sx={{ width: '100%' }}>
            Insert Deck Kit Item
          </Typography>
          <Stack direction="row" sx={{ width: '100%', alignItems: 'center', justifyContent: 'end' }}>
            <Typography variant="h5" component="h5">
              Sandvik Material
            </Typography>
            <Switch
              checked={isSandvikMaterial}
              disabled={data && Object.entries(data).length > 0}
              onChange={switchChecked}
              color="secondary"
            />
          </Stack>
        </Stack>
        <Grid container sx={{ height: 'calc(100% - 38px)' }}>
          {(data || watchVNumber) && (
            <Grid item md={4} sm={12} sx={{ textAlign: 'center', margin: '1em 0 1em 0' }}>
              {watchVNumber ? (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleSubmit(data ? update : submit)}
                  sx={{ width: '80%', padding: '1em 0 1em 0', marginBottom: '2em' }}>
                  {data ? 'UPDATE ITEM' : 'INSERT ITEM'}
                </Button>
              ) : null}
              {data ? (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() =>
                    setRemoveModalState({
                      isOpen: true,
                      title: 'Remove Item',
                      buttonText: 'Remove',
                      data: { Name: getValues(vNumberName) }
                    })
                  }
                  sx={{ width: '80%', padding: '1em 0 1em 0' }}>
                  REMOVE ITEM
                </Button>
              ) : null}
            </Grid>
          )}

          <Grid
            container
            item
            md={data || watchVNumber ? 8 : 12}
            sm={12}
            sx={{
              height: '100%',
              overflow: 'scroll',
              '&::-webkit-scrollbar': {
                width: '2px',
                height: '2px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.supporting.dark
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.supporting.pale,
                borderRadius: 2
              }
            }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item md={3} sx={inputStyling}>
                <Typography variant="h5" component="h5">
                  {isSandvikMaterial ? vNumberFormName : descriptionName}
                </Typography>
              </Grid>
              <Grid item md={9} sx={inputStyling}>
                {isSandvikMaterial ? (
                  <>
                    <Controller
                      name={vNumberFormName}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, ...otherFields } }) => (
                        <Autocomplete
                          fullWidth
                          filterOptions={filterOptions}
                          value={value}
                          disabled={data && Object.entries(data).length > 0}
                          onChange={(e, v) => {
                            onChange(v)
                            onVNumberChange(v)
                          }}
                          options={materials.map((m) => {
                            return {
                              Number: m.MaterialNumber,
                              Render: `${m.MaterialNumber} - ${m.MaterialDescription}`
                            }
                          })}
                          getOptionLabel={(option) => option?.Number ?? ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderOption={(props, option) => (
                            <Typography variant="body2" {...props}>
                              {option.Render}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField color="secondary" onBlur={() => onVNumberChange()} {...params} />
                          )}
                          sx={{ '& div div': { padding: '3px !important' } }}
                          {...otherFields}
                        />
                      )}
                    />
                  </>
                ) : (
                  <OutlinedInput
                    id={vNumberName}
                    name={vNumberName}
                    color="secondary"
                    fullWidth
                    required
                    disabled={data && Object.entries(data).length > 0}
                    {...register(vNumberName)}
                  />
                )}

                {errors[vNumberName] && <p>This field is required</p>}
              </Grid>
            </Grid>
            {isSandvikMaterial ? textInput(descriptionName, false) : false}
            {textInput(partNumberName, false)}
            {isOther ? textInput(quantityName, false) : false}
            {textInput(codeName, false)}
            {textInput(weightName, false)}
            {textInput(openAreaName, false)}
            {textInput(depthName, false)}
            {textInput(failureDepthName, false)}
            {textInput(apertureName, false)}
            {textInput(failureApertureName, false)}
            {textInput(heightName, true, { type: 'number', min: '1' })}
            {textInput(widthName, true, { type: 'number', min: '1' })}
          </Grid>
        </Grid>
      </Box>

      {removeModalState.isOpen && (
        <FormDialog modalState={removeModalState} onOpenChange={remove}>
          <RemoveForm />
        </FormDialog>
      )}
      {showMessage && (
        <MaintenanceNotifier onClose={() => setShowMessage(false)} autoHideDuration={600} type="info">{`Material ${
          getValues('Material Number').Number
        } already exists in B.O.M`}</MaintenanceNotifier>
      )}
    </>
  )
}

export default KitItemForm
