/**
 *
 * Wrapper around the Deck canvas in all pages of deck view in desktop sized screen,
 * and set same deck context hook for all pages so the header is correct.
 *
 * @file   DeckPage.js
 * @author Lateral
 * @since  2023
 */

import { Box, Grid } from '@mui/material'
import React, { Suspense, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from 'components'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'

function DeckPage() {
  /**
   * Generates a wrapper for deck layout
   *
   * @function
   *
   * @returns {object} - A wraper around Deck Layout
   */
  const {
    database: { decks, screens, customers, locations, sites, deckRevisions }
  } = useContext(CurrentDeckContext)
  return (
    <Grid flexDirection="column" sx={{ px: '15px', pb: '15px', height: '100vh' }}>
      <Header
        useDeckSelector={true}
        decks={decks}
        screens={screens}
        customers={customers}
        locations={locations}
        sites={sites}
        deckRevisions={deckRevisions}
      />
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            Loading...
          </Box>
        }>
        <Outlet />
      </Suspense>
    </Grid>
  )
}

export default DeckPage
