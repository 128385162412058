import React from 'react'
import { Fab, Grid, useMediaQuery } from '@mui/material'
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined
} from '@mui/icons-material'
import { DESKTOP_MIN_WIDTH } from 'common/config'
import { useQueries } from '@tanstack/react-query'
import { ProgressIndicator } from './ProgressIndicator'

const queryWearApp = async (materialNumber) => {
  if (!materialNumber) {
    return Promise.resolve({ isValid: false })
  }
  return fetch(`${process.env.REACT_APP_WEARAPP_REST_BACKEND_URL}?panelId=${materialNumber}`).then((val) => val.json())
}

export const WearAppNavButtons = ({ panelPosition, deckSize, onButtonPress, panels }) => {
  const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH)
  const getQueryKey = (row, column) => [`panel-${row}-${column}`]
  const queries = useQueries({
    queries: [
      {
        queryFn: () =>
          queryWearApp(
            panels.find(
              ({ Position }) => panelPosition.Column === Position.Column && panelPosition.Row - 1 === Position.Row
            )?.MaterialNumber
          ),
        queryKey: getQueryKey(panelPosition.Row - 1, panelPosition.Column)
      },
      {
        queryFn: () =>
          queryWearApp(
            panels.find(
              ({ Position }) => panelPosition.Column - 1 === Position.Column && panelPosition.Row === Position.Row
            )?.MaterialNumber
          ),
        queryKey: getQueryKey(panelPosition.Row, panelPosition.Column - 1)
      },
      {
        queryFn: () =>
          queryWearApp(
            panels.find(
              ({ Position }) => panelPosition.Column + 1 === Position.Column && panelPosition.Row === Position.Row
            )?.MaterialNumber
          ),
        queryKey: getQueryKey(panelPosition.Row, panelPosition.Column + 1)
      },
      {
        queryFn: () =>
          queryWearApp(
            panels.find(
              ({ Position }) => panelPosition.Column === Position.Column && panelPosition.Row + 1 === Position.Row
            )?.MaterialNumber
          ),
        queryKey: getQueryKey(panelPosition.Row + 1, panelPosition.Column)
      }
    ]
  })

  return (
    <Grid maxWidth={isDesktop ? '10%' : '30%'} flexWrap="nowrap" container alignItems="center" flexDirection="column">
      {queries.some((query) => query.isLoading) ? (
        <ProgressIndicator />
      ) : (
        <>
          <Fab
            onClick={() => onButtonPress('up')}
            disabled={panelPosition.Row === 0 || !queries[0].data?.isValid}
            color="secondary"
            variant="extended"
            size="small">
            <ArrowUpwardOutlined />
          </Fab>
          <Grid justifyContent="space-between" item container>
            <Fab
              onClick={() => onButtonPress('left')}
              disabled={panelPosition.Column === 0 || !queries[1].data?.isValid}
              color="secondary"
              variant="extended"
              size="small">
              <ArrowBackOutlined />
            </Fab>

            <Fab
              onClick={() => onButtonPress('right')}
              disabled={panelPosition.Column === deckSize.Columns - 1 || !queries[2].data?.isValid}
              color="secondary"
              variant="extended"
              size="small">
              <ArrowForwardOutlined />
            </Fab>
          </Grid>

          <Fab
            disabled={panelPosition.Row === deckSize.Rows - 1 || !queries[3].data?.isValid}
            color="secondary"
            variant="extended"
            size="small"
            onClick={() => onButtonPress('down')}>
            <ArrowDownwardOutlined />
          </Fab>
        </>
      )}
    </Grid>
  )
}
