import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { listMaterials } from 'pages/admin/common/adminDatastore'

export const useGetMaterials = () => {
  const query = useQuery(
    ['fetchMaterials'],
    async () => {
      const materials = localStorage.getItem('materials')
      if (!materials || materials === 'undefined') {
        return listMaterials()
      }
      return JSON.parse(localStorage.getItem('materials'))
    },
    {
      initialData: () => {
        const materials = localStorage.getItem('materials')
        if (!materials || materials === 'undefined') {
          return []
        }
        return JSON.parse(localStorage.getItem('materials'))
      },
      retry: 2
    }
  )

  useEffect(() => {
    localStorage.setItem('materials', JSON.stringify(query.data))
  }, [query.data])

  return { materials: query.data?.materials ?? [], query }
}
