import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataStore, Hub, syncExpression } from 'aws-amplify'
import {
  Customers,
  DeckRevisionHistory,
  DeckRevisions,
  Decks,
  Dialogue,
  Equipment,
  Reports,
  Role,
  Sites,
  UserRole
} from 'models'
import { log } from 'common'
import { Container, Link, Typography } from '@mui/material'
import { useCurrentUser } from 'hooks/user/useCurrentUser'
import { Loading } from 'components/Loading'
import { useGetMaterials } from 'hooks/database/useGetMaterials'

/*
 * Hook for getting the current user logged in, and associated information.
 */
const SyncDatabaseContext = createContext({})

const useSyncDatabase = () => useContext(SyncDatabaseContext)

const SyncDatabaseProvider = ({ refreshUser, children }) => {
  const [isDatastoreReady, setIsDatastoreReady] = useState(false)
  const [message, setMessage] = useState('')
  const [datastoreSyncError, setDatastoreSyncError] = useState(undefined)
  const { currentUser } = useCurrentUser()
  useGetMaterials()
  //for setting up DataStore
  useEffect(() => {
    const listener = Hub.listen('datastore', async ({ payload }) => {
      switch (payload.event) {
        case 'ready':
          setIsDatastoreReady(true)
          break
      }
    })

    if (!isDatastoreReady) {
      syncDatabase('Please wait, synchronizing data...')
    }

    return () => {
      listener()
    }
  }, [])

  useEffect(() => {
    const checkUserRoles = async ({ element }) => {
      await refreshUser()
      //a User's role was changed, so we'll resync everything now.
      if (element.CognitoId === currentUser.username) {
        resyncDatabase('A change to your roles has occurred. Please wait while the database resynchronises...')
      }
    }

    const checkRoles = async ({ element }) => {
      await refreshUser()
      //a Role the User has was changed.
      const roles = currentUser.getRoles()
      const roleIds = roles.map((r) => r.id)
      if (roleIds.includes(element.id)) {
        resyncDatabase('A change to your roles has occurred. Please wait while the database resynchronises...')
      }
    }

    if (isDatastoreReady) {
      const userSubscription = DataStore.observe(UserRole).subscribe(checkUserRoles)
      const roleSubscription = DataStore.observe(Role).subscribe(checkRoles)

      return () => {
        userSubscription.unsubscribe()
        roleSubscription.unsubscribe()
      }
    }
  }, [isDatastoreReady])

  async function resyncDatabase(incomingMessage) {
    setIsDatastoreReady(false)
    setMessage(incomingMessage)
    refreshUser()
    await DataStore.stop()
    await DataStore.clear()
    await refreshUser()
    syncDatabase(incomingMessage)
  }

  function syncDatabase(incomingMessage) {
    setIsDatastoreReady(false)
    setMessage(incomingMessage)
    let customerSyncExpressions = []
    //customerName is a custom attribute setup in Cognito, in order to simulate groups (you can't dynamically create groups in Amplify)
    if (!currentUser.isAdmin) {
      const customers = currentUser.customerIds
      const sites = currentUser.siteIds
      //filters out the initial sync based on the customer id
      customerSyncExpressions = [
        syncExpression(Customers, (customer) =>
          customer.or((customer) => customers.map((cust) => customer.id.eq(cust)))
        ),
        syncExpression(Decks, (deck) =>
          deck.and((deck) => [
            deck.or((deck) => customers.map((customer) => deck.CustomerId.eq(customer))),
            deck.or((deck) => sites.map((site) => deck.SiteId.eq(site)))
          ])
        ),
        syncExpression(DeckRevisions, (deckRevision) =>
          deckRevision.and((deckRevision) => [
            deckRevision.or((deckRevision) => customers.map((customer) => deckRevision.CustomerId.eq(customer))),
            deckRevision.or((deckRevision) => sites.map((site) => deckRevision.SiteId.eq(site)))
          ])
        ),
        syncExpression(DeckRevisionHistory, (deckRevisionHistory) =>
          deckRevisionHistory.and((deckRevisionHistory) => [
            deckRevisionHistory.or((deckRevisionHistory) =>
              customers.map((customer) => deckRevisionHistory.CustomerId.eq(customer))
            ),
            deckRevisionHistory.or((deckRevisionHistory) => sites.map((site) => deckRevisionHistory.SiteId.eq(site)))
          ])
        ),
        syncExpression(Dialogue, (dialogue) =>
          dialogue.or((dialogue) => customers.map((customer) => dialogue.CustomerId.eq(customer)))
        ),
        syncExpression(Equipment, (equipment) =>
          equipment.or((equipment) => customers.map((customer) => equipment.CustomerId.eq(customer)))
        ),
        syncExpression(Reports, (report) =>
          report.and((report) => [
            report.or((report) => customers.map((customer) => report.CustomerId.eq(customer))),
            report.or((report) => sites.map((site) => report.SiteId.eq(site)))
          ])
        ),
        syncExpression(Sites, (site) =>
          site.and((site) => [
            site.or((site) => customers.map((customer) => site.CustomerId.eq(customer))),
            site.or((site) => sites.map((s) => site.id.eq(s)))
          ])
        ),
        syncExpression(Role, (role) => role.or((role) => customers.map((customer) => role.CustomerId.eq(customer)))),
        syncExpression(UserRole, (userRole) =>
          userRole.or((userRole) => customers.map((customer) => userRole.CustomerId.eq(customer)))
        )
      ]
    }

    //for ANYTHING DataStore related, please view their documentation: https://docs.amplify.aws/lib/datastore/getting-started/q/platform/js/
    DataStore.configure({
      maxRecordsToSync: 50000,
      fullSyncInterval: 1440, //once a day in minutes
      syncPageSize: 50, //Note: DeckRevisions are big, and can cause the graphQL engine to fail if this is any higher (surpasses the 1MB limit), override this behavior when on customer account
      syncExpressions: customerSyncExpressions,
      errorHandler: (error) => {
        log.error(JSON.stringify(error))

        if (!isDatastoreReady) {
          setDatastoreSyncError(error?.message)
        }
      }
    })

    DataStore.start()
  }

  if (datastoreSyncError) {
    return (
      <Container
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '20px'
        }}>
        <Typography>An unrecoverable error occurred when synchronizing data.</Typography>
        <Link onClick={() => location.reload()} sx={{ cursor: 'pointer' }}>
          Please click here to refresh the page.
        </Link>
        <Typography>If the issue persists, try clearing your cache and refreshing.</Typography>
        <Typography variant="body2">{JSON.stringify(datastoreSyncError, undefined, 2)}</Typography>
      </Container>
    )
  } else if (!isDatastoreReady) {
    return <Loading type="circular" message={message} />
  }

  const value = {
    syncDatabase,
    resyncDatabase,
    isDatastoreReady
  }

  return <SyncDatabaseContext.Provider value={value}>{children}</SyncDatabaseContext.Provider>
}

export { useSyncDatabase, SyncDatabaseProvider }
