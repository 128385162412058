/**
 *
 * Wrapper around the Deck canvas in all pages of deck view in mobile sized screen,
 * and set same deck context hook for all pages so the header is correct.
 *
 * @file   DeckPageMobile.js
 * @author Lateral
 * @since  2023
 */
import { Grid, Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

function DeckPageMobile() {
  /**
   * Generates a wrapper for deck layout
   *
   * @function
   *
   * @returns {object} - A wraper around Deck Layout
   */
  return (
    <Box sx={{ px: '15px', height: '100%' }}>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Outlet />
      </Grid>
    </Box>
  )
}

export default DeckPageMobile
