/**
 *
 * "Displays a table of the most recent changes made to a Deck."
 *
 * @file   RecentActivityTable.js
 * @author Lateral
 * @since  2023
 */

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import React, { useContext } from 'react'
import { dateFormat } from 'common/dates'
//import { secondAccent } from 'mainTheme'

function RecentActivityTable({
  decks,
  sites,
  locations,
  deckRevisions,
  deckRevisionHistories,
  isMobile,
  showTitle = true
}) {
  /**
   *  Create recent activity table based on current deck context
   *
   * @function
   * @param {object[]} decks - array of deck objects of current user
   * @param {object[]} sites - array of site objects
   * @param {object[]} locations - array of location objects
   * @param {object[]} deckRevisions - array of deck revisions
   * @param {object[]} deckRevisionHistories - array of deck maintenance history
   * @param {boolean} isMobile - true/false to responsive to mobile phone browser
   *
   * @returns {object} - Recent activity table
   */
  const theme = useTheme()
  const { setCustomerId, setSiteId, setLocationId, setEquipmentId, setScreenId, setDeckId } =
    useContext(CurrentDeckContext)
  const navigate = useNavigate()

  const heightStyling = { height: '100%' }
  const maxAmount = 10

  const data = getData()

  function getData() {
    /**
     *  Get attributes of revisions of input deck in array
     *
     * @function
     * @returns {Array} - Array of deck reviosion attributes
     */
    let changes = deckRevisions.map((d) => {
      return {
        id: d.id,
        type: d.IsTrial ? 'Trial' : 'Revision',
        createdBy: d.CreatedBy,
        updated: d.updatedAt,
        deckId: d.DeckId
      }
    })

    //revisions and maintenance are two separate tables, so combine them here
    changes = changes.concat(
      deckRevisionHistories.map((d) => {
        return {
          id: d.id,
          type: 'Maintenance',
          createdBy: d.CreatedBy,
          updated: d.updatedAt,
          deckId: deckRevisions.find((dr) => dr.id === d.RevisionId)?.DeckId
        }
      })
    )

    //After concatenation sort by updated time
    changes = changes.sort((a, b) => (dayjs(b.updated).isAfter(dayjs(a.updated)) ? 1 : -1)).slice(0, maxAmount)

    changes.forEach((change) => {
      const deck = decks.find((d) => d.id === change.deckId)
      const equipment = deck?.DeckHeader.EquipmentId
      const location = locations.find((l) => l.id === deck?.LocationId)
      const site = sites.find((s) => s.id === location?.SiteId)

      change.siteId = site?.id
      change.site = site?.Name
      change.locationId = location?.id
      change.location = location?.Name
      change.screen = equipment
      change.screenId = deck?.ScreenId
      change.deckLevel = deck?.DeckHeader.DeckLevel
      change.deckId = deck?.id
      change.customerId = deck?.DeckHeader.CustomerId
    })

    return changes
  }

  function handleRowClick(row) {
    /**
     *  Set context and action on a click on a row
     *
     * @function
     * @returns {object} - Dashboard page
     */

    setCustomerId(row.customerId)
    setSiteId(row.siteId)
    setLocationId(row.locationId)
    setEquipmentId(row.screen)
    setScreenId(row.screenId)
    setDeckId(row.deckId)

    if (row.type === 'Maintenance') {
      navigate('/equipment/history', { state: { incomingId: row.id } })
    } else {
      navigate('/equipment/deckLayout')
    }
  }

  //Return recent activity table containing maxAmount rows
  return (
    <Box sx={isMobile ? null : heightStyling}>
      {showTitle && (
        <Box sx={{ marginBottom: '0.5em' }}>
          <Typography variant="h4" component="h4">
            Recent Activity
          </Typography>
        </Box>
      )}

      <TableContainer
        sx={{
          mb: '1em',
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '2px',
            height: '2px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <Table stickyHeader sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Screen</TableCell>
              <TableCell>Deck Level</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d) => {
              return (
                <TableRow
                  key={d.id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleRowClick(d)
                  }}>
                  <TableCell>{d.type}</TableCell>
                  <TableCell>{d.site}</TableCell>
                  <TableCell>{d.location}</TableCell>
                  <TableCell>{d.screen}</TableCell>
                  <TableCell>{d.deckLevel}</TableCell>
                  <TableCell>{dayjs(d.updated).format(dateFormat)}</TableCell>
                  <TableCell>{d.createdBy}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default RecentActivityTable
