/**
 *
 * "Generates a cube shped 3D object"
 *
 * @file   Cube.js
 * @author Lateral
 * @since  2023
 */

import { Edges, useBVH } from '@react-three/drei'
import React, { useRef } from 'react'
import { Matrix4 } from 'three'

const shearMatrix = new Matrix4().makeShear(0, 0, 0, 0, 0, 2)

export function Cube(props) {
  /**
   * Provides a cube object which is the simplest component 3D deck model.
   *
   * @function
   * @param {object} props - Properties of cube
   *
   * @returns {React.ElementType} - React-three mesh object with cube shaped geometry
   */
  const mesh = useRef() //Hook to keep unchanged between frames
  useBVH(mesh)

  const selected = props.selected || props.materialSelected
  const size = props.size
  const position = props.position
  const matrix = new Matrix4().setPosition(position[0], position[1], position[2]).multiply(shearMatrix)
  const cubeColour = selected ? props.selectedColour : props.colour ? `${props.colour}` : 'hotpink'

  function onClick(data, e) {
    if (props.onPanelClick) {
      props.onPanelClick(data, e)
    }
  }

  return (
    <mesh
      ref={mesh}
      scale={1}
      onClick={(e) => onClick(mesh.current.userData, e)}
      matrix={matrix}
      matrixAutoUpdate={false}
      userData={props.data}>
      <boxGeometry args={size} />
      <meshStandardMaterial color={cubeColour} map={props.texture} />
      <Edges color="black" />
    </mesh>
  )
}
