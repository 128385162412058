/**
 *
 * "Displays the Maintenance history of a Deck in Deck History table"
 *
 * @file   RecentMaintenanceTable.js
 * @author Lateral
 * @since  2023
 */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { dateFormat } from 'common/dates'
import dayjs from 'dayjs'
import React from 'react'

function RecentMaintenanceTable({ deckRevision, deckRevisionHistories, isMobile }) {
  /**
   * Generates a table for displaying recent maintenance data
   *
   * @function
   * @param {object} deckRevision - Object of deckrevion details
   * @param {string} deckRevision.DeckId - Id of the deck revised
   * @param {object[]} deckRevision.Kits - Array of the material details
   * @param {object[]} deckRevision.panels - List of panels and panel material object
   * @param {object[]} deckRevision.sideliners - List of sideliners and sideliner matrial objext
   * @param {number} deckRevision.revisionNumber - Serial number of revision
   * @param {object} deckRevision.size - Row and column size fo the deck
   * @param {object[]} deckRevisionHistories - Array of deck revision history
   * @param {string} deckRevisionHistories.Description - Description of deck revision
   * @param {string} deckRevisionHistories.RevisionId - ID of the revision
   * @param {string} deckRevisionHistories.createdAt - Date revision created
   * @param {string} deckRevisionHistories.updatedAt - Date revision updated
   * @param {boolean} isMobile - Viewing the app on a mobile: True, else: false
   *
   * @returns {object} - Table object with deck ercent deck maintenance
   */

  const theme = useTheme()
  const heightStyling = { height: '100%' }

  const data = getData()

  function getData() {
    let histories = deckRevisionHistories.map((history) => {
      return {
        id: history.id,
        isHistory: true,
        revisionNumber: deckRevision?.RevisionNumber,
        type: 'Maintenance',
        description: history.ShortDescription,
        reported: dayjs(history.DatePerformed),
        created: dayjs(history.createdAt),
        user: history.CreatedBy
      }
    })

    histories = histories.sort((a, b) => (b.reported.isAfter(a.reported) ? 1 : -1))

    return histories
  }

  return (
    <Box sx={isMobile ? null : heightStyling}>
      <Typography className="deck-title" variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
        Recent Maintenance
      </Typography>
      <TableContainer
        sx={{
          mb: '1em',
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '2px',
            height: '2px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <Table stickyHeader sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              <TableCell>Revision</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Reported</TableCell>
              <TableCell>Works Date</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d) => {
              return (
                <TableRow key={d.id}>
                  <TableCell>{d.revisionNumber}</TableCell>
                  <TableCell>{d.type}</TableCell>
                  <TableCell>{d.description}</TableCell>
                  <TableCell>{d.reported.format(dateFormat)}</TableCell>
                  <TableCell>{d.created.format(dateFormat)}</TableCell>
                  <TableCell sx={{ width: 'auto' }}>{d.user}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default RecentMaintenanceTable
