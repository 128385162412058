/**
 *
 * "Main decklayout page for desktop sized screens. The layout includes 3D cube shaped panels and sideliners.
 * The page includes deck history and B.O.M. table on right."
 *
 * @file   DeckLayoutPage.js
 * @author Lateral
 * @since  2023
 */
import { DeckCanvas, FormDialog, SCOPES, ScopeRequirementAll, useNotification } from 'components'
import { Typography, Stack, Button } from '@mui/material'
import BomTable from 'components/tables/bom/BomTable'
import React, { useContext, useEffect, useState } from 'react'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { DeckMode } from 'common/deckMode'
import HistoryTable from './components/HistoryTable'
import { useTheme } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import ArchiveForm from '../common/ArchiveForm'
import { updateRevision } from 'data/revisionOperations'
import { DataStore } from 'aws-amplify'
import { DeckRevisions } from 'models'
import PublishTrialForm from './components/PublishTrialForm'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BigNumber from 'bignumber.js'
import { parsedRevisionNumber } from 'common/revisionNumber'
import { useCurrentUser } from 'hooks'
import { DeckCanvas as MobileDeckCanvas } from 'components/mobileDeckCanvas/DeckCanvas'
import { DeckLayoutContainer } from '../common/DeckLayoutContainer'
import { use2D3DViewSwitch } from 'hooks/use2D3DViewSwitch'

const defaultModalState = { isOpen: false, title: '', buttonText: '', data: null }

function DeckLayoutPage() {
  /**
   * Generates decklayout, deck history table and B.O.M. table in a page
   * for desktop sized screen.
   * The decklayout is creaed using 3D cube shaped panels and sideliners.
   *
   * @function
   *
   * @returns {object} - Decklayout page
   */
  const theme = useTheme()
  const { notify } = useNotification()
  const [selected, setSelected] = useState([])
  const [materialSelected, setMaterialSelected] = useState('')
  const [selectedDeckRevision, setSelectedDeckRevision] = useState('')
  const [loadTrial, setLoadTrial] = useState(false)
  const [archiveModalState, setArchiveModalState] = useState(defaultModalState)
  const [publishModalState, setPublishModalState] = useState(defaultModalState)
  const [viewType, setViewType] = use2D3DViewSwitch()

  const { deckId, screenId, siteId, setDeckId, setDeckRevision, setDeckRevisionId, database } =
    useContext(CurrentDeckContext)
  const { getTransactionMetaData } = useCurrentUser()
  const screen = database.screens.find((s) => s.id === screenId)
  const deck = database.decks.find((d) => d.id === deckId)
  const deckRevision = getDeckRevision()

  useEffect(() => {
    if (!selectedDeckRevision || deckRevision.DeckId !== deckId) {
      const revisions = getDeckRevisions()
      if (revisions.length) {
        setSelectedDeckRevision(revisions[0].id)
      } else {
        setSelectedDeckRevision('')
      }
    }
  }, [deckId])

  //required to update after deleting a trial
  useEffect(() => {
    setDeckId(deckId)
  }, [database.deckRevisions])

  // Get all revision histories of selected deck from database
  function getDeckRevisions() {
    return database.deckRevisions
      .filter((d) => d.DeckId == deckId && d.IsPublished)
      .sort((a, b) => b.RevisionNumber - a.RevisionNumber)
  }

  //Get details of selected deck revision
  function getDeckRevision() {
    if (loadTrial) {
      return getTrialRevision()
    } else {
      return structuredClone(database.deckRevisions.find((d) => d.id === selectedDeckRevision))
    }
  }

  //Get details of selected trial revision
  function getTrialRevision() {
    const trials = database.deckRevisions
      .filter((d) => d.DeckId === deckId && d.IsTrial && d.IsArchived !== true)
      .sort((a, b) => new BigNumber(parsedRevisionNumber(b)).minus(parsedRevisionNumber(a)).toNumber())

    return trials[0]
  }

  // Action on clicking a row on Revision History table
  function onRevisionClick(id) {
    setSelectedDeckRevision(id)
  }

  //Action on clicking a row on B.O.M table
  function onBomClick(kit) {
    setSelected([])
    setMaterialSelected(kit.MaterialNumber)
  }

  //Action on selecting chang on material
  function onSelectChange(data) {
    setSelected(data)
    setMaterialSelected('')
  }

  function onPanelClick(selected) {
    if (selected) {
      setSelected(Array.isArray(selected) ? selected : [selected])
    } else {
      setSelected([])
    }
    setMaterialSelected('')
  }

  function onLoadTrial() {
    setLoadTrial(true)
  }

  //Action on archiving a trial from modal form
  async function onArchiveTrial(modalState) {
    if (modalState?.isSave) {
      const existing = await DataStore.query(DeckRevisions, deckRevision.id)
      const clone = structuredClone(deckRevision)
      clone.IsArchived = true
      await updateRevision(existing, clone, getTransactionMetaData())
      notify('Trial Archived')
      setLoadTrial(false)
    }

    setArchiveModalState(defaultModalState)
  }

  //Action on archiving a trial from modal form
  async function onPublishTrial(modalState) {
    if (modalState?.isSave) {
      const existing = await DataStore.query(DeckRevisions, deckRevision.id)
      const clone = structuredClone(deckRevision)
      clone.IsPublished = true
      clone.IsTrial = false
      clone.RevisionNumber = Math.round(clone.RevisionNumber + 1)
      const updatedRevision = await updateRevision(existing, clone, getTransactionMetaData())
      const updatedClone = structuredClone(updatedRevision)
      setDeckRevision(updatedClone)
      setSelectedDeckRevision(updatedClone.id)
      setDeckRevisionId(updatedClone.id)
      notify('Trial Published.')
      setLoadTrial(false)
    }

    setPublishModalState(defaultModalState)
  }
  const switchViewType = () => {
    setViewType((currentViewType) => (currentViewType === '3D' ? '2D' : '3D'))
  }

  if (!selectedDeckRevision || deckRevision.DeckId !== deckId) {
    const revisions = getDeckRevisions()

    if (revisions.length) {
      //TODO - move this to a useEffect
      setSelectedDeckRevision(revisions[0].id)
    }
  }

  //do not show data if Deck and related variables missing.
  if (!screen || !deck) {
    return null
  }

  if (deck && !deckRevision) {
    return <Typography>No Deck Revision found.</Typography>
  }

  if (!Object.entries(screen).length || !Object.entries(deck).length) {
    return <Typography>No Deck Revision found.</Typography>
  }

  return (
    <>
      <DeckLayoutContainer
        headerTitle={loadTrial ? 'Trial Deck Layout' : 'Deck Layout'}
        buttons={
          loadTrial ? null : (
            <>
              <ScopeRequirementAll requirements={[SCOPES.deckMaintenance.Write]} siteId={siteId}>
                <Button component={RouterLink} to="/equipment/maintenance" variant="outlined" color="secondary">
                  MAINTENANCE
                </Button>
              </ScopeRequirementAll>
              <ScopeRequirementAll requirements={[SCOPES.deckLayoutReview.Write]} siteId={siteId}>
                <Button component={RouterLink} to="/equipment/edit" variant="outlined" color="secondary">
                  EDIT DECK
                </Button>
              </ScopeRequirementAll>
              <ScopeRequirementAll requirements={[SCOPES.heatmap.Read]} siteId={siteId}>
                <Button component={RouterLink} to="/equipment/heatmap" variant="outlined" color="secondary">
                  HEAT MAP
                </Button>
              </ScopeRequirementAll>
              <Button component={RouterLink} to="/equipment/history" variant="outlined" color="secondary">
                HISTORY
              </Button>
              <Button component={RouterLink} to="/dialogue" variant="outlined" color="secondary">
                DIALOGUE
              </Button>
            </>
          )
        }
        Deck={
          <>
            {viewType === '3D' ? (
              <DeckCanvas
                data={deckRevision}
                selected={selected}
                materialSelected={materialSelected}
                onSelectChange={onSelectChange}
                mode={DeckMode.default}
                onLoadTrial={getTrialRevision() && !loadTrial ? onLoadTrial : null}
                onSwitchView={switchViewType}
              />
            ) : (
              <MobileDeckCanvas
                data={deckRevision}
                selected={selected}
                materialSelected={materialSelected}
                onSelectChange={onSelectChange}
                mode={DeckMode.default}
                onLoadTrial={getTrialRevision() && !loadTrial ? onLoadTrial : null}
                onSwitchView={switchViewType}
                onPanelClick={onPanelClick}
              />
            )}
          </>
        }
        topRightElement={
          <>
            <Typography variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
              {' '}
              {loadTrial ? 'Trial Details' : 'Deck History'}
            </Typography>
            <HistoryTable
              deckRevisionId={selectedDeckRevision}
              deckRevisions={loadTrial ? [deckRevision] : getDeckRevisions()}
              onRevisionSelected={onRevisionClick}
            />
          </>
        }
        bottomRightElement={
          <>
            <BomTable
              data={deckRevision}
              selected={selected}
              materialSelected={materialSelected}
              onClick={onBomClick}
            />
            {loadTrial ? (
              <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'end' }}>
                <Button
                  variant="text"
                  sx={{ color: `${theme.palette.secondary.main}` }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => setLoadTrial(false)}>
                  Back
                </Button>
                <ScopeRequirementAll requirements={[SCOPES.deckLayoutReview.Write]} siteId={siteId}>
                  <Button
                    variant="outlined"
                    sx={{ color: `${theme.palette.secondary.main}` }}
                    onClick={() =>
                      setArchiveModalState({
                        isOpen: true,
                        title: 'Archive Trial',
                        buttonText: 'Archive',
                        data: deckRevision
                      })
                    }>
                    Archive Trial
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      setPublishModalState({
                        isOpen: true,
                        title: 'Publish Trial',
                        buttonText: 'Publish',
                        data: { id: deckRevision.id }
                      })
                    }>
                    Publish Trial
                  </Button>
                </ScopeRequirementAll>
              </Stack>
            ) : null}
          </>
        }
      />

      {/* modals */}
      {archiveModalState.isOpen && (
        <FormDialog modalState={archiveModalState} onOpenChange={onArchiveTrial}>
          <ArchiveForm isTrial={deckRevision.IsTrial} />
        </FormDialog>
      )}

      {publishModalState.isOpen && (
        <FormDialog modalState={publishModalState} onOpenChange={onPublishTrial}>
          <PublishTrialForm />
        </FormDialog>
      )}
    </>
  )
}

export default DeckLayoutPage
