/**
 *
 * "Common configurations"
 *
 * @file   config.js
 * @author Lateral
 * @since  2023
 */
export const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL ?? 'http://localhost:5171'
export const DESKTOP_MIN_WIDTH = '(min-width:600px)'
