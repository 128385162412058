import React from 'react'
import { useWearAppContext } from '../../WearAppContext'
import { Carousel } from 'react-responsive-carousel'
import { useWearAppAnalysisContext } from '../../WearAppAnalysisContext'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'

export const TabPanel = () => {
  const { activeTab } = useWearAppContext()
  const { isDesktop } = useCurrentDeckContext()
  const {
    currentAnalysisResultsRef: { current: wearAnalysisResults }
  } = useWearAppAnalysisContext()
  return (
    <div role="tabpanel" id={`tab-${activeTab}`} aria-labelledby={`tab-${activeTab}`}>
      <Carousel dynamicHeight showThumbs={false}>
        <img
          className="canvas-responsive-width"
          key={`image-${activeTab}`}
          style={{
            width: '100%',
            height: isDesktop ? '400px' : '200px',
            objectFit: 'contain',
            objectPosition: 'bottom'
          }}
          alt="img"
          src={wearAnalysisResults.appertureDetectionImageUrl}
        />
        <img
          className="canvas-responsive-width"
          key={`image-${activeTab}`}
          alt="img"
          style={{
            width: '100%',
            height: isDesktop ? '400px' : '200px',
            objectFit: 'contain',
            objectPosition: 'bottom'
          }}
          src={wearAnalysisResults.heatMapImageUrl}
        />

        <img
          className="canvas-responsive-width"
          key={`image-${activeTab}`}
          alt="img"
          style={{
            width: '100%',
            height: isDesktop ? '400px' : '200px',
            objectFit: 'contain',
            objectPosition: 'bottom'
          }}
          src={wearAnalysisResults.histogramImageUrl}
        />
      </Carousel>
    </div>
  )
}
