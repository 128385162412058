/**
 *
 * "A modal form to ad caption to phot uploaded to the Maintenance"
 *
 * @file   CaptionPhotoForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useFieldArray, useForm } from 'react-hook-form'
import { Button, Grid, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

export function CaptionPhotoForm({ data, onSubmit }) {
  /**
   * Generates a form to add caption to Maintenance photo
   *
   * @function
   *
   * @param {object[]} data - Array of photo data
   * @param {string} data.caption - Image caption
   * @param {object} data.file - Image file details
   * @param {string} data.file.type - Type of image
   * @param {string} data.file.name - Image file name
   * @param {string} data.id - File id
   * @param {number} data.height - Image height
   * @param {number} data.width - Image width
   * @param {} onSubmit - Action on submitting caption
   *
   * @returns {object} - A form viewing image nd add caption
   */
  const defaultValues = {
    Files: data.map((d) => {
      return {
        data: d
      }
    })
  }
  const { register, handleSubmit, control } = useForm({ defaultValues })

  const { fields, remove } = useFieldArray({
    control,
    name: 'Files'
  })

  //returns a url if eligible, otherwise the original (assuming a base64 string)
  function getFileData(file) {
    try {
      return URL.createObjectURL(file)
    } catch {
      return file.data
    }
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {fields.map((f, i) => {
        return (
          <Grid
            rowSpacing={2}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            container
            key={f.id}
            spacing={1}>
            <Grid width="90%" item>
              <img
                src={getFileData(f.data.file)}
                style={{
                  width: '100%',
                  height: '14em',
                  borderRadius: '1em'
                }}
              />
              <OutlinedInput
                name={'Caption'}
                placeholder={'Add Caption'}
                color="secondary"
                fullWidth
                {...register(`Files.${i}.data.caption`, { required: false })}
              />
            </Grid>
            <Grid item>
              <IconButton color="error" size="small" onClick={() => remove(i)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
      })}
    </form>
  )
}
export default CaptionPhotoForm
