/**
 *
 * "CRUD (Create, read, update, and delete) for Reproting (All Reports) table"
 *
 * @file   data.js
 * @author Lateral
 * @since  2023
 */

import { DataStore } from 'aws-amplify'
import { Reports } from 'models'

export async function createReport(report, createdBy) {
  /**
   * Create new report
   *
   * @function
   * @async
   *
   * @param {object} report - Object with report components
   * @param {string} report.type - Report types Pre-Shut Report/ Post-Shut Report/ Parts Report
   * @param {string} report.Company - Name of the company
   * @param {string} report.Site - Name of the site
   * @param {string} report.Title - Title of the Report
   * @param {string} report.DateOfVisit - Date of reporting visit
   * @param {string} report.EndDate - End date of reporting
   * @param {string} report.SiteContact - Contact details of site
   * @param {string} report.SiteRepresentative - Name f site representative
   * @param {string} report.Introduction - Report introduction
   * @param {string} report.ScopeOfWork - Report scope pf work
   * @param {string} report.SafetyIssues - Reproted safety issue
   * @param {string} report.CrewDetails - Details of reporting crew
   * @param {string} report.ShutdownComments - Comments shutdown report
   * @param {string} report.Recommendations - Recommendation on visiting
   * @param {number} report.Revision - Report revison number
   * @param {object[]} report.PreShutPanelLayout - Array of panel and sideliners in
   * deck layout before shutdown
   * @param {object[]} report.AllPanels - Array of panels and sideliners
   *
   *
   * @returns {object} - A new report object
   */
  return await DataStore.save(
    new Reports({
      Type: report.Type,
      CustomerId: report.CustomerId,
      SiteId: report.SiteId,
      Company: report.Company,
      Site: report.Site,
      Title: report.Title,
      DateOfVisit: report.DateOfVisit,
      EndDate: report.EndDate,
      SiteContact: report.SiteContact,
      SiteRepresentative: report.SiteRepresentative,
      Introduction: report.Introduction,
      ScopeOfWork: report.ScopeOfWork,
      SafetyIssues: report.SafetyIssues,
      CrewDetails: report.CrewDetails,
      ShutdownComments: report.ShutdownComments,
      Recommendations: report.Recommendations,
      Revision: report.Revision,
      Selections: report.Selections,
      PreShutPanelLayout: report.PreShutPanelLayout,
      AllPanels: report.AllPanels,
      LastUpdatedBy: createdBy
    })
  )
}

export async function updateReport(report, updatedBy) {
  /**
   * Update and existing report
   *
   * @function
   * @async
   *
   * @param {object} report - Object with report components
   * @param {string} report.type - Report types Pre-Shut Report/ Post-Shut Report/ Parts Report
   * @param {string} report.Company - Name of the company
   * @param {string} report.Site - Name of the site
   * @param {string} report.Title - Title of the Report
   * @param {string} report.DateOfVisit - Date of reporting visit
   * @param {string} report.EndDate - End date of reporting
   * @param {string} report.SiteContact - Contact details of site
   * @param {string} report.SiteRepresentative - Name f site representative
   * @param {string} report.Introduction - Report introduction
   * @param {string} report.ScopeOfWork - Report scope pf work
   * @param {string} report.SafetyIssues - Reproted safety issue
   * @param {string} report.CrewDetails - Details of reporting crew
   * @param {string} report.ShutdownComments - Comments shutdown report
   * @param {string} report.Recommendations - Recommendation on visiting
   * @param {number} report.Revision - Report revison number
   * @param {object[]} report.PreShutPanelLayout - Array of panel and sideliners in
   * deck layout before shutdown
   * @param {object[]} report.AllPanels - Array of panels and sideliners
   *
   *
   * @returns {object} - A new report object
   */
  const original = await DataStore.query(Reports, report.id)
  return await DataStore.save(
    Reports.copyOf(original, (updated) => {
      ;(updated.Type = report.Type),
        (updated.CustomerId = report.CustomerId),
        (updated.SiteId = report.SiteId),
        (updated.Company = report.Company),
        (updated.Site = report.Site),
        (updated.Title = report.Title),
        (updated.DateOfVisit = report.DateOfVisit),
        (updated.EndDate = report.EndDate),
        (updated.SiteContact = report.SiteContact),
        (updated.SiteRepresentative = report.SiteRepresentative),
        (updated.Introduction = report.Introduction),
        (updated.ScopeOfWork = report.ScopeOfWork),
        (updated.SafetyIssues = report.SafetyIssues),
        (updated.CrewDetails = report.CrewDetails),
        (updated.ShutdownComments = report.ShutdownComments),
        (updated.Recommendations = report.Recommendations),
        (updated.Revision = report.Revision),
        (updated.Selections = report.Selections),
        (updated.PreShutPanelLayout = report.PreShutPanelLayout),
        (updated.AllPanels = report.AllPanels),
        (updated.LastUpdatedBy = updatedBy)
    })
  )
}
