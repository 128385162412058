/**
 *
 * "A modal form to confirm a deck Maintenance""
 *
 * @file   CreateHistory.js
 * @author Lateral
 * @since  2023
 */
import { Button, Stack } from '@mui/material'
import { FormDialog } from 'components'
import ConfirmForm from 'pages/deck/common/ConfirmForm'
import React, { useState } from 'react'
import CancelForm from '../CancelForm'
import { useLocation } from 'react-router-dom'

/*
 *A modal to confirm completing a Maintenance.
 */
function CreateHistory({ onComplete, onCancel }) {
  /**
   * Generates a form to confirm mainetenance
   *
   * @function
   *
   * @param {} onComplete - Action on clicking confirm buttom
   * @param {} onCancel - Action on clicking cancel buton
   *
   * @returns {object} - A modal form
   */
  const defaultModalState = { isOpen: false, title: '', data: null }

  const [modalState, setModalState] = useState(defaultModalState)
  const [cancelModalState, setCancelModalState] = useState(defaultModalState)
  const [loading, setIsLoading] = useState(false)
  const { state } = useLocation()

  // Action of form submission
  async function onSubmit(modalState) {
    try {
      setIsLoading(true)
      if (modalState?.isSave) {
        await onComplete(modalState)
      }
      setCancelModalState(defaultModalState)
    } finally {
      setIsLoading(false)
    }
  }

  // Action on canceling form
  function onCancelSubmit(modalState) {
    try {
      setIsLoading(true)
      if (modalState?.isSave) {
        onCancel(modalState)
      }
      setCancelModalState(defaultModalState)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            setCancelModalState({
              isOpen: true,
              title: 'Cancel Maintenance',
              buttonText: 'Confirm',
              data: null
            })
          }
          sx={{ margin: '0 0.5em 0 0.5em' }}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() =>
            setModalState({
              isOpen: true,
              title: 'Confirm Maintenance',
              buttonText: state?.isEditingOld ? 'Update' : 'Create',
              data: null
            })
          }
          sx={{ margin: '0 0.5em 0 0.5em' }}>
          {state?.isEditingOld ? 'Update Maintenance' : 'Complete Maintenance'}
        </Button>
      </Stack>

      {modalState.isOpen && (
        <FormDialog modalState={modalState} isLoading={loading} onOpenChange={onSubmit}>
          <ConfirmForm name="Maintenance" />
        </FormDialog>
      )}

      {cancelModalState.isOpen && (
        <FormDialog modalState={cancelModalState} onOpenChange={onCancelSubmit}>
          <CancelForm />
        </FormDialog>
      )}
    </>
  )
}

export default CreateHistory
