/**
 *
 * "A form to edit/change maintenance record/ History"
 *
 *
 * @file   EditHistoryForm.js
 * @author Lateral
 * @since  2023
 */

import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { Controller, useForm } from 'react-hook-form'
import { TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'

export function EditHistoryForm({ data, onSubmit }) {
  /**
   * Generates a form to edit history details
   *
   * @function
   *
   * @param {object} data - Deck history data
   * @param {string} data.id - History ID
   * @param {object} data.Date - Date of history/Maintennace
   * @param {string} description - Description of the History
   * @param {} onSubmit - Action su8bmitting the form
   *
   * @returns {object} - Form to create/Update kit
   */
  const defaultValues = {
    Id: data?.Id ?? '',
    Date: data?.Date ?? dayjs(),
    Description: data?.Description ?? ''
  }

  const { control, register, handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Works Date
          </Typography>
        </Grid>
        <Grid item md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="Date"
            render={({ field }) => (
              <DatePicker {...field} renderInput={(params) => <TextField color="secondary" fullWidth {...params} />} />
            )}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Description
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Description"
            name="Description"
            placeholder="e.g. Added extra row"
            color="secondary"
            fullWidth
            {...register('Description', { required: true })}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default EditHistoryForm
