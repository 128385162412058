/**
 *
 * "A form to add new location or update/Delete existing location"
 *
 * @file   AddLocationForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export function AddLocationForm({ data, onSubmit }) {
  /**
   *
   * Form to ad new location or delete/update existing location
   *
   * @const
   *
   * @param {object} data - Location details
   * @param {object} data.id - Location id (Existing location)
   * @param {string} data.SiteId - Location site ID
   * @param {string} data.Name - Location name
   * @param {} onSubmit - Action on submiting the form
   *
   * @returns {object} - Form to add, update, and delete location
   */
  const defaultValues = {
    Id: data?.id ?? '',
    SiteId: data?.SiteId ?? '',
    Name: data?.Name ?? ''
  }

  const { register, handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Location Name
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Name"
            name="Name"
            placeholder="e.g. Location 1"
            color="secondary"
            fullWidth
            {...register('Name', { required: true })}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default AddLocationForm
