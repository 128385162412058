/**
 *
 * "Page to view and handle all company ans site related events"
 *
 * @file   SitesPage.js
 * @author Lateral
 * @since  2023
 */
import { Stack } from '@mui/material'
import React, { useState } from 'react'
import CompanyTablePage from './components/sites/CompanyTablePage'
import SitesTablePage from './components/sites/SitesTablePage'

function SitesPage() {
  /**
   * Page to view and handle site and company realted events
   * @const
   *
   * @returns {object} - React app page
   */
  const [customerId, setCustomerId] = useState('')

  //show table of companies/customers first, then sites if a customer is selected.
  return (
    <Stack spacing={2} sx={{ height: '85%' }}>
      {customerId ? (
        <SitesTablePage customerId={customerId} setCustomerId={setCustomerId} />
      ) : (
        <CompanyTablePage setCustomerId={setCustomerId} />
      )}
    </Stack>
  )
}
export default SitesPage
