/**
 *
 * "A form to add new location or update/Delete existing site"
 *
 * @file   AddSiteForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useFieldArray, useForm } from 'react-hook-form'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'

export function AddSiteForm({ data, onSubmit }) {
  /**
   *
   * Form to ad new site or delete/update existing site
   *
   * @const
   *
   * @param {object} data - site details
   * @param {object} data.id - site id (Existing location)
   * @param {string} data.SiteName - Site name
   * @param {string} data.Contacts - Site owner customer contacts
   * @param {} onSubmit - Action on submiting the form
   *
   * @returns {object} - Form to add, update, and delete locations
   */
  const defaultValues = {
    Id: data?.id ?? '',
    Name: data?.SiteName ?? '',
    Contacts: data?.Contacts ?? null
  }

  const { control, register, handleSubmit } = useForm({ defaultValues })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Contacts'
  })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Site Name
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="Name"
            name="Name"
            placeholder="e.g. Site 1"
            color="secondary"
            fullWidth
            {...register('Name', { required: true })}
          />
        </Grid>

        <Grid item md={3} sx={{ placeSelf: 'flex-start' }}>
          <Typography variant="h5" component="h5" sx={{ margin: '1em 0' }}>
            Site Contacts
          </Typography>
        </Grid>
        <Grid item md={9}>
          {fields.map((item, i) => (
            <Stack key={item.id} spacing={3} sx={{ margin: '1em 0' }}>
              <TextField
                label="Contact Name"
                placeholder="e.g. Bob Bobson"
                color="secondary"
                fullWidth
                InputLabelProps={{ sx: { ...{ opacity: '50%' } }, shrink: true }}
                {...register(`Contacts.${i}.Name`, { required: true })}
              />
              <TextField
                label="Contact Email"
                placeholder="e.g. bob@email.com"
                color="secondary"
                fullWidth
                InputLabelProps={{ sx: { ...{ opacity: '50%' } }, shrink: true }}
                {...register(`Contacts.${i}.Email`, { required: true })}
              />
              <TextField
                label="Contact Position"
                placeholder="e.g. Manager"
                color="secondary"
                fullWidth
                InputLabelProps={{ sx: { ...{ opacity: '50%' } }, shrink: true }}
                {...register(`Contacts.${i}.Position`, { required: true })}
              />
            </Stack>
          ))}
          <Box>
            <Button color="secondary" variant="text" onClick={() => append({ Name: '', Email: '', Position: '' })}>
              <AddCircleOutlineOutlinedIcon />
              <Typography
                color="secondary"
                variant="h5"
                component="h5"
                textTransform="none"
                sx={{ marginLeft: '0.25em' }}>
                Add New
              </Typography>
            </Button>
            <Button color="secondary" variant="text" onClick={() => remove(fields.length - 1)}>
              <RemoveCircleOutlineOutlinedIcon />
              <Typography
                color="secondary"
                variant="h5"
                component="h5"
                textTransform="none"
                sx={{ marginLeft: '0.25em' }}>
                Remove
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
export default AddSiteForm
