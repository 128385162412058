/**
 *
 * Maintenance page to view in mobile sized screen with 2D deck canvas
 *
 * @file   MobileMaintenancePage.js
 * @author Lateral
 * @since  2023
 */
import { Button, Grid, Typography } from '@mui/material'
import { ONE_WEEK_MILLIS, useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import ChooseDeck from 'pages/deck/common/ChooseDeck'
import dayjs from 'dayjs'
import { Outlet } from 'react-router-dom'

/*
 *Maintenance page for mobile.
 */
export function MobileMaintenancePage() {
  /**
   * Generates Maintenance page with 2D box panels and mobile maintenance legends
   *
   * @function
   *
   * @returns {object} - Maintenance page
   */

  const {
    screen,
    deck,
    setCustomerId,
    setSiteId,
    setLocationId,
    setEquipmentId,
    setScreenId,
    setDeckId,
    database,
    maintenanceData,
    inMaintenanceMode,
    setInMaintenanceMode,
    setMaintenanceData,
    memoizedDeckRevision: deckRevision,
    maintenanceFrequency,
    nonArchivedHistories
  } = useCurrentDeckContext()

  // Action on submitting maintenance
  function onSubmit(form) {
    setCustomerId(form.CustomerId)
    setLocationId(form.LocationId)
    setEquipmentId(form.EquipmentId)
    setSiteId(form.SiteId)
    const newDeck = database.decks.find((d) => d.id == form.DeckId)
    setDeckId(form.DeckId)
    const newScreen = database.screens.find((s) => s.id == newDeck.ScreenId)
    setScreenId(newScreen.id)
  }

  // Action on reseting maintenance
  function onReset() {
    setCustomerId('')
    setLocationId('')
    setSiteId('')
    setDeckId('')
  }

  //Start maintenance pressing 'Start' button
  //maintenance only starts once a button is pressed.
  function onStart() {
    const clone = structuredClone(maintenanceData)
    clone.deckRevisionHistory.Details = []
    if (!clone.deckRevisionHistory.DatePerformed) {
      clone.deckRevisionHistory.DatePerformed = dayjs().toISOString()
    }
    if (!clone.deckRevisionHistory.NextShutDownDate) {
      clone.deckRevisionHistory.NextShutDownDate = dayjs(nonArchivedHistories.histories[0]?.DatePerformed || dayjs())
        .add((maintenanceFrequency || 0) * ONE_WEEK_MILLIS, 'milliseconds')
        .toISOString()
    }
    setInMaintenanceMode(true)
    setMaintenanceData(clone)
  }

  return (
    <Grid container>
      {screen && deck ? (
        deckRevision && Object.entries(deckRevision).length ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">Maintenance</Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '2em' }}>
              <Typography className="title" variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
                {`${screen.Name} - ${deck.DeckHeader.DeckLevel} ${deck.DeckHeader.MaterialNumber}`}
              </Typography>
              {inMaintenanceMode ? null : (
                <Grid container spacing={1} sx={{ marginTop: '0.5em', marginBottom: '1em' }}>
                  <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Button variant="outlined" sx={{ width: '100%' }} onClick={onReset}>
                      Select Deck
                    </Button>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: 'center' }}>
                    <Button variant="contained" color="secondary" sx={{ width: '100%' }} onClick={onStart}>
                      Start
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Outlet />
          </>
        ) : Object.entries(screen).length && Object.entries(deck).length ? (
          <Grid item sm={12} sx={{ marginTop: '2em', width: '100%', textAlign: 'center' }}>
            <Typography>No Deck Revision found.</Typography>
            <Button color="secondary" variant="contained" sx={{ width: '100%', marginTop: '2em' }} onClick={onReset}>
              Choose Deck
            </Button>
          </Grid>
        ) : null
      ) : (
        <Grid item xs={12}>
          <ChooseDeck database={database} onSubmit={onSubmit} />
        </Grid>
      )}
    </Grid>
  )
}
