/**
 *
 * "A table to view current roles"
 *
 * @file   RoleTable.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import { visuallyHidden } from '@mui/utils'
import { getObjectComparator } from 'common'
import { ContextMenu, SCOPES, ScopeRequirementAll } from 'components'
import { Chip } from '@mui/material'
import { useCurrentUser } from 'hooks'

const StyledTableCell = styled(TableCell)(() => ({
  /**
   *
   * Styles of table cells
   *
   * @const
   */
  [`&.${tableCellClasses.body}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80px',
    width: '7%'
  }
}))

const headCells = [
  /**
   *
   * Headings of the table
   *
   * @const
   */
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name'
  },
  {
    id: 'Description',
    numeric: false,
    disablePadding: false,
    label: 'Description'
  },
  {
    id: 'Customer',
    numeric: false,
    disablePadding: false,
    label: 'Customer'
  },
  {
    id: 'Sites',
    numeric: false,
    disablePadding: false,
    label: 'Sites'
  },
  {
    id: 'Enabled',
    numeric: false,
    disablePadding: false,
    label: 'Status'
  }
]

const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
  /**
   * Generates a table head for sorting data"
   *
   * @const
   * @param {string} order - Ascending or descending order asc/desc
   * @param {object[]} orderBy - id of the heading osrting by
   * @param {} onRequestSort - Action on requesting sort
   *
   * @returns {object} - Headings of the table with sorting
   */
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ border: 'none' }}>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell sx={{ width: '0' }} />
      </TableRow>
    </TableHead>
  )
}

export const RoleTable = ({ items, handleRoleItemEvent, hasCognitoAdmin, customers, sites, searchTexts }) => {
  /**
   * Generates a table for roles"
   *
   * @const
   * @param {object[]} items - All existing roles
   * @param {} handleRoleItemEvent - Action to CRUD row
   *
   * @returns {object} - Table of roles
   */

  const theme = useTheme()
  const { currentUser } = useCurrentUser()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('Name')

  const writeableSites = currentUser.getSitesForScope(SCOPES.userManagement.Write)

  const rows = items.map((item) => {
    return {
      id: item?.id,
      Name: item?.Name,
      CustomerId: item.CustomerId,
      Customer: customers?.find((c) => c.id === item.CustomerId)?.Name ?? '',
      SiteIds: item.SiteIds,
      Sites:
        sites
          ?.filter((s) => item.SiteIds?.includes(s.id))
          .map((s) => s.Name)
          .join(', ') ?? '',
      Description: item?.Description,
      Group: item.Group,
      Scopes: item?.Scopes,
      Enabled: item?.Enabled
    }
  })

  const filteredRows = searchTexts?.length
    ? rows?.filter((r) =>
        searchTexts.every((s) =>
          JSON.stringify([r.Name, r.Customer, r.Sites, r.Group, r.Description]).toLowerCase().includes(s)
        )
      )
    : rows

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer
      sx={{
        width: '100%',
        height: '100%',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table sx={{ minWidth: 750, minHeight: '0', p: 4 }} size="small">
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {filteredRows &&
            filteredRows.sort(getObjectComparator(order, orderBy)).map((row) => {
              const menuItems = [{ title: 'Edit Role', action: () => handleRoleItemEvent('editRole', row) }]

              if (row?.Enabled) {
                menuItems.push({ title: 'Disable Role', action: () => handleRoleItemEvent('disableRole', row) })
              } else {
                menuItems.push({ title: 'Enable Role', action: () => handleRoleItemEvent('enableRole', row) })
              }

              menuItems.push({ title: 'Delete Role', action: () => handleRoleItemEvent('deleteRole', row) })

              return (
                <TableRow key={row.id} hover tabIndex={-1} sx={{ height: '48px' }}>
                  <StyledTableCell>{row?.Name}</StyledTableCell>
                  <StyledTableCell>{row?.Description ?? ''}</StyledTableCell>
                  <StyledTableCell>{row?.Customer ?? ''}</StyledTableCell>
                  <StyledTableCell>{row?.Sites ?? ''}</StyledTableCell>
                  <StyledTableCell>
                    <Chip label={row?.Enabled ? 'ACTIVE' : 'DISABLED'} color={row?.Enabled ? 'success' : 'error'} />
                  </StyledTableCell>
                  <TableCell sx={{ width: '0' }}>
                    {hasCognitoAdmin &&
                      (currentUser.isAdmin ||
                        !row?.SiteIds ||
                        row?.SiteIds.every((s) => writeableSites.includes(s))) && (
                        <ScopeRequirementAll requirements={[SCOPES.roleManagement.Write]}>
                          <ContextMenu context={row} menuItems={menuItems} />
                        </ScopeRequirementAll>
                      )}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default RoleTable
