/**
 *
 * "Generates a form for conjuring a Parts Report."
 *
 * @file   PartsForm.js
 * @author Lateral
 * @since  2023
 */
import { Box, Button, Checkbox, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  companyName,
  defaultString,
  recentJobsName,
  shutEndDateName,
  shutStartDateName,
  siteName,
  allPanelsName
} from '../../common/formNames'
import { DatePicker } from '@mui/x-date-pickers'
import { DropdownInput } from '../common/DropdownInput'
import dayjs from 'dayjs'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { dateFormat } from 'common/dates'
import { useCurrentUser } from 'hooks'
import { SCOPES } from 'components'
import { LoadingButton } from '@mui/lab'

function PartsForm({ data, customers, sites, locations, decks, deckRevisions, deckRevisionHistories, generateData }) {
  /**
   * Table for viewing all Reporting history.
   *
   * @function
   *
   * @param {object} data - Site object of which Parts report is going to be generated
   * @param {string} data.DateOfVisit - Shut start date
   * @param {string} data.EndDate - Shut end date
   * @param {string} data.Company - Name of the company
   * @param {string} data.Site - Name of the site
   * @param {boolean} data.allPanels - If all panels are included in report
   * @param {object[]} customers - Array of all company object
   * @param {object[]} location - Array of all location object
   * @param {object[]} sites - Array of all site object
   * @param {object[]} decks - Array of all deck location object
   * @param {object[]} deckRevisions - Array of deck rivision records
   * @param {object[]} deckRevisionHistories - array of deck maintenance objects
   * @param {} generateData - Action on generating new parts reprot
   *
   * @returns {object} - All Parts Report form
   */
  const theme = useTheme()

  const defaultValues = {
    id: data.id ?? defaultString,
    [shutStartDateName]: data.DateOfVisit ? dayjs(data.DateOfVisit) : dayjs(),
    [shutEndDateName]: data.EndDate ? dayjs(data.EndDate) : dayjs(),
    [companyName]: customers.find((c) => c.Name === data?.Company)?.id ?? defaultString,
    [siteName]: sites.find((s) => s.Name === data?.Site)?.id ?? defaultString,
    [recentJobsName]: [],
    [allPanelsName]: data?.AllPanels ?? false
  }
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues })

  const { fields, append, remove } = useFieldArray({
    control,
    name: recentJobsName
  })

  const { currentUser } = useCurrentUser()
  const [isLoading, setIsLoading] = useState(false)

  const watchCompany = watch(companyName, defaultString)
  const watchSite = watch(siteName, defaultString)
  const watchStartDate = watch(shutStartDateName, data.DateOfVisit ? dayjs(data.DateOfVisit) : dayjs())
  const watchEndDate = watch(shutEndDateName, data.EndDate ? dayjs(data.EndDate) : dayjs())
  const watchAllPanels = watch(allPanelsName, data?.AllPanels ?? false)

  const company = customers.find((c) => c.id === watchCompany || c.Name === data?.Company)
  const site = sites.find((s) => s.id === watchSite || s.Name === data?.Site)
  const sitesAvailable = currentUser.getSitesForScope(SCOPES.reporting.Write)
  const companiesAvailable = currentUser.getCustomersForScope(SCOPES.reporting.Write)
  const filteredCompanies = currentUser.isAdmin ? customers : customers.filter((c) => companiesAvailable.includes(c.id))

  useEffect(() => {
    if (data && Object.entries(data).length && !fields.length && !getValues(allPanelsName)) {
      onFillRecentMaintenanceJobs()
    }
  }, [])

  //Maintenance jobs are only rendered after selecting a Company/Site and time.
  function onFillRecentMaintenanceJobs(e, name) {
    const siteId = name === siteName ? e.target.value : site?.id
    const startDate = dayjs(name === shutStartDateName ? e : watchStartDate).startOf('date')
    const endDate = dayjs(name === shutEndDateName ? e : watchEndDate).endOf('date')

    if (siteId && startDate && endDate) {
      remove()
      const siteLocations = locations.filter((l) => l.SiteId === siteId)

      siteLocations.forEach((location) => {
        const locationDecks = decks.filter((d) => d.LocationId === location.id && d.CustomerId === company.id)

        locationDecks.forEach((deck) => {
          const deckDeckRevisions = deckRevisions.filter((d) => d.DeckId === deck.id)

          deckDeckRevisions.forEach((revision) => {
            const revisionHistories = deckRevisionHistories.filter(
              (d) => d.RevisionId === revision.id && dayjs(d.DatePerformed).isBetween(startDate, endDate)
            )

            revisionHistories.forEach((history) => {
              append({
                HistoryId: history.id,
                Name: `${location.Name} - ${deck.DeckHeader.EquipmentId} - ${deck.DeckHeader.DeckLevel}`,
                Include: data?.Selections?.includes(history.id) ?? false,
                DatePerformed: history.DatePerformed
              })
            })
          })
        })
      })
    }
  }

  // Sleecting generate table downloads CSV generated by data
  // in the form
  async function onDownloadCsv() {
    try {
      setIsLoading(true)
      const form = getValues()
      await generateData(form)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form style={{ height: '100%' }}>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
        Report Details
      </Typography>
      <Box sx={{ padding: '0.5em 1em' }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
            <Typography variant="h5" component="h5">
              {shutStartDateName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
            <Controller
              control={control}
              name={shutStartDateName}
              render={({ field: { onChange, ...otherFields } }) => (
                <DatePicker
                  {...otherFields}
                  maxDate={watchEndDate}
                  onChange={(e) => {
                    onChange(e)
                    onFillRecentMaintenanceJobs(e, shutStartDateName)
                  }}
                  renderInput={(params) => <TextField color="secondary" sx={{ width: '50%' }} fullWidth {...params} />}
                  format="DD/MM/YYYY"
                />
              )}
            />
            {errors[shutStartDateName] && <p>This field is required</p>}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
            <Typography variant="h5" component="h5">
              {shutEndDateName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
            <Controller
              control={control}
              name={shutEndDateName}
              render={({ field: { onChange, ...otherFields } }) => (
                <DatePicker
                  {...otherFields}
                  minDate={watchStartDate}
                  onChange={(e) => {
                    onChange(e)
                    onFillRecentMaintenanceJobs(e, shutEndDateName)
                  }}
                  renderInput={(params) => <TextField color="secondary" sx={{ width: '50%' }} fullWidth {...params} />}
                  format="DD/MM/YYYY"
                />
              )}
            />
            {errors[shutEndDateName] && <p>This field is required</p>}
          </Grid>
        </Grid>
        <DropdownInput
          name={companyName}
          options={filteredCompanies.map((c) => {
            return { id: c.id, Name: c.Name }
          })}
          onDropDownChange={() => {
            remove()
            setValue(siteName, defaultString)
          }}
          control={control}
          errors={errors}
        />
        <DropdownInput
          name={siteName}
          options={
            company
              ? sites
                  .filter((s) => s.CustomerId === company.id && (currentUser.isAdmin || sitesAvailable.includes(s.id)))
                  .map((c) => {
                    return { id: c.id, Name: c.Name }
                  })
              : []
          }
          onDropDownChange={onFillRecentMaintenanceJobs}
          control={control}
          errors={errors}
        />
        <Grid container alignItems="center">
          <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
            <Typography variant="h5" component="h5">
              All Panels
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
            <Controller
              control={control}
              name={allPanelsName}
              render={({ field }) => <Switch color="secondary" checked={field.value ?? false} {...field} />}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography
        variant="h5"
        component="h5"
        sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
        {watchAllPanels ? '' : 'Recent Maintenance Jobs'}
      </Typography>

      <Box
        sx={{
          padding: '1em 1em 2em 1em',
          height: '35%',
          marginTop: '0.5em',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        {site && watchStartDate && watchEndDate && (fields.length || watchAllPanels) ? (
          <Stack spacing={3}>
            {watchAllPanels
              ? null
              : fields.map((f, index) => {
                  return (
                    <Stack
                      key={f.id}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ borderBottom: `1px solid ${theme.palette.primary.contrastText}` }}>
                      <Controller
                        control={control}
                        name={`${recentJobsName}.${index}.Include`}
                        render={({ field }) => (
                          <Checkbox
                            size="small"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                            sx={{ color: `${theme.palette.supporting.highlight}` }}
                            {...field}
                          />
                        )}
                      />

                      <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                        <Typography sx={{ float: 'left' }}>{f.Name}</Typography>
                        <Typography sx={{ float: 'right', opacity: '50%' }}>{`Date Performed: ${dayjs(
                          f.DatePerformed
                        ).format(dateFormat)}`}</Typography>
                      </Stack>
                    </Stack>
                  )
                })}
          </Stack>
        ) : (
          <Box>
            <Typography>
              {watchSite ? 'No maintenance carried out during this timeframe.' : 'Please select a Company and Site.'}
            </Typography>
          </Box>
        )}
      </Box>
      {site && watchStartDate && watchEndDate && (fields.length || watchAllPanels) ? (
        <Box sx={{ textAlign: 'end' }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="secondary"
            onClick={onDownloadCsv}
            sx={{ padding: '0.5em 1em' }}>
            Generate Report
          </LoadingButton>
        </Box>
      ) : null}
    </form>
  )
}
export default PartsForm
