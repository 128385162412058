/**
 *
 * "A table to view all sites of a company"
 *
 * @file   SitesTable.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { useTheme } from '@mui/material/styles'
import { getObjectComparator } from 'common'
import EnhancedTableHead from 'components/tables/EnhancedTableHead'
import SiteRow from './SiteRow'

const headCells = [
  /**
   *
   * Headings of the table
   *
   * @const
   */
  {
    id: 'SiteName',
    numeric: false,
    disablePadding: false,
    label: 'Site'
  },
  {
    id: 'Contacts',
    numeric: false,
    disablePadding: false,
    label: 'Contacts'
  },
  {
    id: 'LocationsTotal',
    numeric: false,
    disablePadding: false,
    label: 'Number of Locations'
  },
  {
    id: 'ScreensTotal',
    numeric: false,
    disablePadding: false,
    label: 'Number of Screens'
  }
]

export function SitesTable({
  customerId,
  database,
  rows,
  onEditLocation,
  onDeleteLocation,
  onEditDetails,
  onDelete,
  onCreateLocation
}) {
  /**
   * Generates a table of exisitng companies"
   *
   * @function
   * @param {string} customerId - Site customer id
   * @param {object} database - The database with locations and decks object arrays
   * @param {object[]} rows - Sites details of a company
   * @param {string} rows.id - ID of the site
   * @param {string} rows.SiteName - Name of the site
   * @param {string} rows.Contacts - Site customer contacts
   * @param {string} rows.LocationsTotal - Number of location in the site
   * @param {string} rows.ScreensTotal - Number of screens in the site
   * @param {} onEditLocation - Action on click edit location
   * @param {} onDeleteLocation - Action on click delete location
   * @param {} onEditDetails - Action on click edit site
   * @param {} onDelete - Action on click delete site
   * @param {} onCreateLocation - Action on click create location
   *
   * @returns {object} - A table of sites
   */
  const theme = useTheme()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('SiteName')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table stickyHeader sx={{ minWidth: 750, borderCollapse: 'collapse' }} size="small">
        <EnhancedTableHead
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          prependEmpty
        />
        <TableBody>
          {rows &&
            rows.sort(getObjectComparator(order, orderBy)).map((row) => {
              return (
                <SiteRow
                  key={row.id}
                  customerId={customerId}
                  database={database}
                  row={row}
                  onEditLocation={onEditLocation}
                  onDeleteLocation={onDeleteLocation}
                  onEditDetails={onEditDetails}
                  onDelete={onDelete}
                  onCreateLocation={onCreateLocation}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default SitesTable
