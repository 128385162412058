/**
 *
 * "Create a snackbar (bottom/side temporary pop up) notification window"
 *
 * @file   SimpleSnackbar.js
 * @author Lateral
 * @since  2023
 */

import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export const SimpleSnackbar = ({ message, isOpen, onClose }) => {
  /**
   *
   * A simple snackbar for all notification.
   *
   * @const
   * @param {string} message - Message to show in snackbar notification
   * @param {boolean} isOpen - If the notificatio in opened by child window
   * @param {} onclose - Action on closing notification
   *
   * @return {React.ElementType} - A react snackbar element
   */
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <Snackbar
      open={isOpen}
      message={message}
      onClose={onClose}
      action={action}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{
        zIndex: 10000000
      }}
    />
  )
}

export default SimpleSnackbar
