/**
 *
 * "Buttons fro create deck page.
 * There are two buttons 'Create Deck' and 'Import Deck'
 * Clicking create deck open a blank modal form to enter deck information
 * Clicking Import deck open modal form to browse and select deck to import"
 *
 * @file   DeckLayoutPage.js
 * @author Lateral
 * @since  2023
 */
import { Button } from '@mui/material'
import { FormDialog } from 'components'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { DeckHeaderItems, Decks, DeckSizeItems } from 'models'
import React, { useContext, useState } from 'react'
import { createDeck } from 'data/deckOperations'
import { createFirstRevision } from 'data/revisionOperations'
import CreateForm from 'pages/common/decks/CreateDeckForm'
import ImportForm from './ImportForm'
import { observeEquipment, useCurrentUser } from 'hooks'

function CreateDeckButtons({ setDeckRevision }) {
  /**
   * Buttons fro create deck page
   *
   * @function
   *
   * @returns {object} - two buttons with titel "Create Deck" and "Import Deck"
   */
  const defaultModalState = { isOpen: false, title: '', buttonText: '', data: null }
  const {
    database: { decks }
  } = useContext(CurrentDeckContext)
  const equipment = observeEquipment()
  const [createModalState, setCreateModalState] = useState(defaultModalState)
  const [importModalState, setImportModalState] = useState(defaultModalState)

  const { customerId, deckId, equipmentId, locationId, siteId, setDeckId, setDeckRevisionId, setScreenId } =
    useContext(CurrentDeckContext)

  const { getTransactionMetaData } = useCurrentUser()

  function getScreenId() {
    const singleEquipment = equipment.find((e) => e.id === equipmentId)

    if (singleEquipment) {
      return singleEquipment.ScreenId
    }

    const deck = decks.find((d) => d.DeckHeader.EquipmentId === equipmentId)

    return deck?.ScreenId
  }

  // Actions on clicking 'Creaet Deck' button
  async function onCreate(modalState) {
    if (modalState?.isSave) {
      const singleEquipment = equipment.find(
        (e) => e.Name === modalState.data.EquipmentId && e.LocationId === locationId
      )
      const singleDeck = decks.find((d) => d.DeckHeader.EquipmentId === modalState.data.EquipmentId)
      const deck = new Decks({
        CustomerId: customerId,
        LocationId: locationId,
        ScreenId: singleEquipment?.ScreenId ?? singleDeck.ScreenId,
        SiteId: siteId,
        DeckHeader: new DeckHeaderItems({
          CustomerId: customerId,
          DeckLevel: modalState.data.DeckLevel,
          DeckSize: new DeckSizeItems({
            Columns: Number(modalState.data.Columns),
            Rows: Number(modalState.data.Rows)
          }),
          EquipmentId: modalState.data.EquipmentId,
          LocationId: locationId,
          MaterialNumber: modalState.data.MaterialNumber,
          ScreenId: modalState.data.ScreenId,
          SerialNumber: modalState.data.SerialNumber,
          SiteId: siteId
        })
      })

      const newDeck = await createDeck(deck, getTransactionMetaData())
      const newRevision = await createFirstRevision(newDeck, getTransactionMetaData())
      setDeckId(newDeck.id)
      setScreenId(modalState.data.ScreenId)
      setDeckRevision(structuredClone(newRevision))
      setDeckRevisionId(newRevision.id)
    }

    setCreateModalState(defaultModalState)
  }

  // Actions on clicking 'Import Deck' button
  function onImport(modalState) {
    if (modalState?.isSave) {
      const deck = decks.find((d) => d.id === modalState.data.DeckId)
      const data = {
        DeckLevel: deck.DeckHeader.DeckLevel,
        EquipmentId: deck.DeckHeader.EquipmentId,
        ScreenId: deck.ScreenId,
        MaterialNumber: deck.DeckHeader.MaterialNumber,
        SerialNumber: deck.DeckHeader.SerialNumber,
        Columns: deck.DeckHeader.DeckSize.Columns,
        Rows: deck.DeckHeader.DeckSize.Rows
      }

      setCreateModalState({ isOpen: true, title: 'Create Deck', buttonText: 'Create', data: data })
    }

    setImportModalState(defaultModalState)
  }

  return (
    <>
      {locationId && !deckId ? (
        <>
          <Button
            variant="outlined"
            onClick={() =>
              setCreateModalState({
                isOpen: true,
                title: 'Create Deck',
                buttonText: 'Create',
                data: { EquipmentId: equipmentId, ScreenId: getScreenId() }
              })
            }
            sx={{ margin: '0 0.5em 0 0.5em' }}>
            Create Deck
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setImportModalState({
                isOpen: true,
                title: 'Import Deck',
                buttonText: 'Import',
                data: null
              })
            }
            sx={{ margin: '0 0.5em 0 0.5em' }}>
            Import Deck
          </Button>
          {createModalState.isOpen && (
            <FormDialog modalState={createModalState} onOpenChange={onCreate}>
              <CreateForm />
            </FormDialog>
          )}

          {importModalState.isOpen && (
            <FormDialog modalState={importModalState} onOpenChange={onImport}>
              <ImportForm />
            </FormDialog>
          )}
        </>
      ) : null}
    </>
  )
}

export default CreateDeckButtons
