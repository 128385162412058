/**
 *
 * "Short dialogue table to view in dashboard page"
 *
 * @file   CondensedDialogueTable.js
 * @author Lateral
 * @since  2023
 */

import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import StyledStatusChip from 'components/statusChip/StyledStatusChip'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import dayjs from 'dayjs'
import React from 'react'
import { useNavigate } from 'react-router-dom'

/*
 *Small version of the Dialogue table, mainly for the Dashboard page.
 */
function CondensedDialogueTable({ dialogue, isMobile, maxAmount = 10, showTitle = true }) {
  /**
   *  Generates table for displaying BOM data.
   *
   * @function
   * @param {object[]} dialogue - Dialogue objects
   * @param {string} dialogue.Description - Description of the dialogue event
   * @param {string} dialogue.Area - Area of the event happens
   * @param {string} dialogue.User - User created dialogue
   * @param {string} dialogue.Location - Location of the screen where
   * dialogue event happened
   * @param {string} dialogue.updatedAt - Date dialogue update
   * @param {boolean} isMobile - Viewing the app on a mobile: True, else: false
   * @param {number} maxAmount - Maximum number of dialogue showed in table. Default = 10
   *
   * @returns {object} - React table object with maxAmount dialogues
   */

  const theme = useTheme()
  const navigate = useNavigate()
  const heightStyling = { height: '100%' }

  return (
    <Box sx={isMobile ? null : heightStyling}>
      <Stack direction={showTitle ? 'row' : 'row-reverse'} sx={{ marginBottom: '0.5em' }}>
        {showTitle && (
          <Typography variant="h4" component="h4">
            Dialogue
          </Typography>
        )}

        <Box sx={{ width: '100%', textAlign: 'end' }}>
          <Button
            onClick={() => navigate('/dialogue')}
            endIcon={<ArrowForwardIcon />}
            sx={{ color: `${theme.palette.secondary.main}` }}>
            View All
          </Button>
        </Box>
      </Stack>
      <TableContainer
        sx={{
          mb: '1em',
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '2px',
            height: '2px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <Table stickyHeader sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dialogue ? (
              dialogue
                .sort((a, b) => (dayjs(b.Date).isAfter(dayjs(a.Date)) ? 1 : -1))
                .map((d) => {
                  return (
                    <TableRow key={d.id} hover>
                      <TableCell>{dayjs(d.Date).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{d.User}</TableCell>
                      <TableCell>{d.Location}</TableCell>
                      <TableCell>{d.Area}</TableCell>
                      <TableCell>{d.Description}</TableCell>
                      <TableCell>
                        <StyledStatusChip status={d?.Status ?? ''} />
                      </TableCell>
                    </TableRow>
                  )
                })
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default CondensedDialogueTable
