import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled, useTheme } from '@mui/material/styles'
import { WearInput } from './WearInput'

export const WearInputModal = ({
  deckRevision,
  maintenance,
  isMobile,
  selectedPanels = [],
  handleClose,
  onWearSubmit
}) => {
  const theme = useTheme()
  const currentPanel = selectedPanels[0]
  const StyledDialog = styled(Dialog)(() => [
    {
      '& .MuiPaper-root': {
        background: theme.palette.supporting.black
      }
    },
    isMobile
      ? {
          '& .MuiPaper-root': {
            width: '100%',
            margin: '0'
          }
        }
      : null
  ])

  const onSubmit = (data) => {
    onWearSubmit(data)
    handleClose()
  }

  return (
    <StyledDialog fullWidth open>
      <DialogTitle variant="h5">{`Update Wear Value`}</DialogTitle>

      <DialogContent>
        <WearInput data={{ deckRevision, maintenance }} panel={currentPanel} onSubmit={onSubmit} />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" form={`form`} type="submit">
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}
