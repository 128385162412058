import { Stack, Typography } from '@mui/material'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import React from 'react'
import { FabricWrapper } from './FabricWrapper'
import Button from '@mui/material/Button'
import { useWearAppContext } from '../WearAppContext'
import { useNavigate } from 'react-router-dom'
import { WearAppNavButtons } from './WearAppNavButtons'

/*
 *Houses the canvas for adjusting panel corners. Also makes the call to the WearApp Polygon API.
 */
export function CanvasCapture() {
  const { bottomNavDimen, isDesktop, deck, memoizedDeckRevision } = useCurrentDeckContext()
  const { selectedPanel, polygonPoints, isLoading, setImage, photoKeyRef, startWearAnalysis, onNavigateButtonPressed } =
    useWearAppContext()
  const width = !isDesktop ? window.innerWidth : Math.min(window.innerWidth, 1280)
  const navigate = useNavigate()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        position: 'relative'
      }}>
      {/* {isDesktop && (
        <Stack spacing={1} sx={{ width: '100%' }}>
          <Stack spacing={1} direction="row">
            <Typography variant="h5" color="secondary">
              Screen:
            </Typography>
            <Typography variant="h5">{screen.Name}</Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <Typography variant="h5" color="secondary">
              Deck:
            </Typography>
            <Typography variant="h5">{`${deck.DeckHeader.DeckLevel} ${deck.DeckHeader.MaterialNumber}`}</Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <Typography variant="h5" color="secondary">
              Panel:
            </Typography>
            <Typography variant="h5">{selectedPanel.MaterialNumber}</Typography>
          </Stack>
        </Stack>
      )} */}

      <FabricWrapper
        coords={polygonPoints.current}
        width={width}
        updatePolygonAllCoordinates={(data) => {
          polygonPoints.current = data
        }}
        updatePosition={(position, data) => {
          polygonPoints.current[position] = data
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: !isDesktop ? 'center' : 'flex-end',
          alignItems: 'flex-end',
          gap: '1em',
          width: '100%',
          zIndex: 100,
          marginBottom: isDesktop ? '1em' : undefined,
          bottom: !isDesktop ? bottomNavDimen.height : undefined,
          position: !isDesktop ? 'fixed' : undefined,
          marginLeft: !isDesktop ? '-1em' : undefined
        }}>
        <WearAppNavButtons
          onButtonPress={onNavigateButtonPressed}
          deckSize={deck.DeckHeader.DeckSize}
          panelPosition={selectedPanel.Position}
          panels={memoizedDeckRevision.Panels}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setImage(undefined)
            photoKeyRef.current = undefined
            navigate(-1)
          }}>
          Go Back
        </Button>
        <Button disabled={isLoading} variant="contained" onClick={startWearAnalysis} color="secondary">
          Start Analysis
        </Button>
      </div>
    </div>
  )
}
