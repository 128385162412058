/**
 *
 * "Legends display under the 3D canvas if in Maintenance mode to show amount
 * of wear and status of the panel."
 *
 * @file   MaintenanceLegend.js
 * @author Lateral
 * @since  2023
 */
import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as ReplaceIcon } from 'assets/icons/Replace.svg'
import { ReactComponent as RotateIcon } from 'assets/icons/Rotate.svg'
import { ReactComponent as SwapIcon } from 'assets/icons/Swap.svg'
import { ReactComponent as RelocateIcon } from 'assets/icons/Relocate-From.svg'
import { ReactComponent as ViewResult } from 'assets/icons/ViewResult.svg'
import { useMaintenance } from 'hooks'

const iconStyling = { height: '20px', width: '20px', verticalAlign: 'top' }

function MaintenanceLegend({ awaitingActionId }) {
  /**
   * Function to create maintenance legends
   *
   * @function
   * @param {number} awaitingActionId - id of await action for asynch operation
   * @returns {object} - React box element of maintenance legends
   */
  const { actions } = useMaintenance()

  return (
    <Box sx={{ position: 'absolute', bottom: '30px', left: '95px', width: '50%' }}>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: '0.5em' }}>
          <Typography variant="h6" component="h6">
            Wear Amount Remaining
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '0.5em' }}>
          <Stack spacing={1} direction="row">
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.zero' }]} />
            <Typography>0%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.twenty' }]} />
            <Typography>20%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.forty' }]} />
            <Typography>40%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.sixty' }]} />
            <Typography>60%</Typography>
            <Box sx={[iconStyling, { backgroundColor: 'heatmap.eighty' }]} />
            <Typography>80%</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1} direction="row">
            <Typography>
              {' '}
              <ReplaceIcon style={iconStyling} /> Replace
            </Typography>
            <Typography>
              {' '}
              <RotateIcon style={iconStyling} /> Rotate
            </Typography>
            <Typography sx={awaitingActionId === actions.Swap.id ? { color: 'secondary.main' } : null}>
              {' '}
              <SwapIcon style={iconStyling} /> Swap
            </Typography>
            <Typography sx={awaitingActionId === actions.Relocate.id ? { color: 'secondary.main' } : null}>
              {' '}
              <RelocateIcon style={iconStyling} /> Relocate
            </Typography>
            <Typography>
              {' '}
              <ViewResult style={{ ...iconStyling }} /> View Wear Result
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MaintenanceLegend
