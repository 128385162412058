import { useState, useEffect } from 'react'
import { DataStore } from 'aws-amplify'
import { UserRole } from 'models'

/*
 * Observable for the UserRoles table.
 */
export function observeUserRoles() {
  const [userRoles, setUserRoles] = useState([])
  const [userRolesLoaded, setUserRolesLoaded] = useState(false)
  useEffect(() => {
    const retrieveData = async () => {
      try {
        const roles = await DataStore.query(UserRole)
        setUserRoles(roles)
      } finally {
        setUserRolesLoaded(true)
      }
    }

    const subscription = DataStore.observe(UserRole).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return [userRolesLoaded, userRoles]
}

export default observeUserRoles
