/**
 *
 * "Maintenance summary form to add descriptions and comments"
 *
 * @file   SummaryForm.js
 * @author Lateral
 * @since  2023
 */

import {
  Box,
  Button,
  Checkbox,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'

import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import BomTable from 'components/tables/bom/BomTable'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import {
  crossbeamName,
  dateNextShutDownName,
  datePerformedName,
  deckRailsName,
  descriptionName,
  includeCrossbeams,
  includeDeckRails,
  includeRecommendations,
  photoKeysName,
  postServiceName,
  preServiceName,
  recommendationName,
  tonnageName
} from 'pages/common/maintenance/formNames'
import { CaptionPhotoForm } from './CaptionPhotoForm'
import { ONE_WEEK_MILLIS, useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { MaintenanceNotifier } from 'components/maintenanceSelector/MaintenanceNotifier'
import { MaintenanceDialog } from 'components/maintenanceSelector/MaintenanceDialog'

const defaultString = ''

/*
 *The main form for Maintenance. Contains all the text data gathered for Maintenance (DeckRails, Crossbeam, etc).
 */
function SummaryForm({ data, deckRevision, selected, materialSelected, onBomClick, onChange, isMobile }) {
  /**
   * Generates a form to add to add descriptions and comments of Maintenance
   *
   * @function
   *
   * @param {object} deckRevision - Object of deckrevion details
   * @param {object} data - Deck maintenance data, with photos
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} materialSelected - Selected sideliner material number
   * @param {} onBomClick - Action on clicking B.O.M. table
   * @param {} onChange - Action on changing selection
   * @param {boolean} isMobile - If the view is mobile sized screen
   * @param {boolean} inMaintenanceMode - If the context in Maintenance mode
   *
   * @returns {object} - A form to input Maintenance summary
   */
  const { nonArchivedHistories, maintenanceFrequency, deckId } = useCurrentDeckContext()
  const defaultValues = {
    [datePerformedName]: data?.deckRevisionHistory?.DatePerfomed ?? dayjs(),
    [tonnageName]: data?.deckRevisionHistory?.Tonnage ?? defaultString,
    [descriptionName]: data?.deckRevisionHistory?.Description ?? defaultString,
    [preServiceName]: data?.deckRevisionHistory?.PreServiceComments ?? defaultString,
    [postServiceName]: data?.deckRevisionHistory?.PostServiceComments ?? defaultString,
    [deckRailsName]: data?.deckRevisionHistory?.DeckRailsComments ?? defaultString,
    [includeDeckRails]: data?.deckRevisionHistory?.DeckRailsComments?.length > 0 ?? false,
    [crossbeamName]: data?.deckRevisionHistory?.CrossbeamComments ?? defaultString,
    [includeCrossbeams]: data?.deckRevisionHistory?.CrossbeamComments?.length > 0 ?? false,
    [recommendationName]: data?.deckRevisionHistory?.Recomendations ?? defaultString,
    [includeRecommendations]: data?.deckRevisionHistory?.Recomendations?.length > 0 ?? false,
    [photoKeysName]: data?.photos ?? [],
    [dateNextShutDownName]: data?.deckRevisionHistory?.NextShutDownDate
      ? dayjs.isDayjs(data?.deckRevisionHistory?.NextShutDownDate)
        ? data?.deckRevisionHistory?.NextShutDownDate
        : dayjs(data?.deckRevisionHistory?.NextShutDownDate)
      : dayjs(nonArchivedHistories.histories[0]?.DatePerformed || dayjs()).add(
          (maintenanceFrequency || 0) * ONE_WEEK_MILLIS,
          'milliseconds'
        )
  }
  const [photoErrorMessages, setPhotoErrorMessages] = useState([])
  const [timer, setTimer] = useState('')
  const [photoCaptionsModal, setPhotoCaptionsModal] = useState([])

  const {
    control,
    getValues,
    register,
    clearErrors,
    reset,
    formState: { errors },
    setValue
  } = useForm({ defaultValues })

  useEffect(() => {
    if (data?.deckRevisionHistory?.NextShutDownDate) {
      return
    }
    reset({
      'Next Shutdown Date': dayjs(nonArchivedHistories.histories[0]?.DatePerformed || dayjs()).add(
        (maintenanceFrequency || 0) * ONE_WEEK_MILLIS,
        'milliseconds'
      )
    })
  }, [deckId, maintenanceFrequency])

  useEffect(() => {
    reset({
      Tonnage: data?.deckRevisionHistory?.Tonnage,
      'Maintenance Description': data?.deckRevisionHistory?.Description,
      'Pre Service Comments': data?.deckRevisionHistory?.PreServiceComments,
      'Post Service Comments': data?.deckRevisionHistory?.PostServiceComments,
      'Deck Rails Comments': data?.deckRevisionHistory?.DeckRailsComments,
      'Crossbeam Comments': data?.deckRevisionHistory?.CrossbeamComments,
      Recommendations: data?.deckRevisionHistory?.Recommendations,
      IncludeCrossbeams: !!data?.deckRevisionHistory?.CrossbeamComments,
      IncludeDeckRails: !!data?.deckRevisionHistory?.DeckRailsComments,
      IncludeRecommendations: !!data?.deckRevisionHistory?.Recommendations,
      PhotoKeys: data?.photos ?? getValues()[photoKeysName],
      'Next Shutdown Date': getValues()[dateNextShutDownName],
      'Date Performed': getValues()[datePerformedName]
    })
  }, [data?.deckRevisionHistory, data?.photos])

  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: photoKeysName
  })

  const theme = useTheme()

  function onFormChange() {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => onTimeoutSubmit(), 1000)
    setTimer(timeoutId)
  }

  //Action on clicking upload photo
  async function onPhotoUpload(e) {
    const files = Array.from(e.target.files)
    const errors = []
    for (const file of files) {
      if (file) {
        if (file.size > 12582912) {
          errors.push(`"${file.name}" is too big!. Please make sure it is 12MB or less.`)
        } else if (!file.type.includes('image') && !file.type.includes('img')) {
          errors.push(`"${file.name}" is of the wrong type!. Please choose an image file.`)
        } else {
          const reader = new FileReader()
          reader.onload = function () {
            const img = new Image()
            img.onload = function () {
              append({ file: file, caption: '', height: img.height, width: img.width })
            }
            img.src = reader.result
          }

          reader.readAsDataURL(file)
          e.target.value = ''
        }
      }
    }
    setPhotoErrorMessages(errors)
    onFormChange()
    clearErrors()
  }

  async function onTimeoutSubmit() {
    try {
      onChange(getValues())
    } catch {
      //ignored
    }
  }

  // Action on clicking 'View Images/Add Caption'
  function onUpdatePhotoCaptions(data) {
    if (photoCaptionsModal.length === 1) {
      update(
        fields.findIndex(({ id }) => id === data.Files[0].data.id),
        data.Files[0].data
      )
    } else {
      replace(data.Files.map((f) => f.data))
    }
    onFormChange()

    setPhotoCaptionsModal([])
  }

  // Calander to input date
  function dateInput(name) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography variant="h5" component="h5">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, ...rest } }) => {
              return (
                <DatePicker
                  {...rest}
                  onChange={(e) => {
                    onChange(e)
                    onFormChange()
                  }}
                  format="DD/MM/YYYY"
                />
              )
            }}
          />
          {errors[name] && <p>This field is required</p>}
        </Grid>
      </Grid>
    )
  }

  //Small text input box
  function textInput(name, required = false) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography variant="h5" component="h5">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <OutlinedInput
            id={name}
            name={name}
            color="secondary"
            fullWidth
            sx={{ width: isMobile ? '100%' : '50%' }}
            onChange={onFormChange}
            {...register(name, {
              required: required,
              onChange: () => onFormChange()
            })}
          />
          {errors[name] && <p>This field is required</p>}
        </Grid>
      </Grid>
    )
  }

  //Larger text input area
  function textAreaInput(name, required = false, include = null) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} md={12}>
          <Stack direction="row" alignItems="center" sx={include ? { marginLeft: '-10px' } : null}>
            {include ? (
              <Controller
                control={control}
                name={include}
                render={({ field: { onChange, ...props } }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => {
                      onChange(e)
                      onFormChange()
                    }}
                    sx={{ color: `${theme.palette.supporting.highlight}` }}
                  />
                )}
              />
            ) : null}
            <Typography variant="h5" component="h5" sx={{ marginBottom: '0.2em' }}>
              {name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <OutlinedInput
            id={name}
            name={name}
            color="secondary"
            fullWidth
            multiline
            rows={3}
            onChange={() => {
              onFormChange()
            }}
            sx={{ padding: '0' }}
            {...register(name, {
              required: required,
              onChange: (event) => {
                setValue(include, !!event.target.value)
                onFormChange()
              }
            })}
          />
          {errors[name] && <p>This field is required</p>}
        </Grid>
      </Grid>
    )
  }

  return (
    <Box sx={{ height: isMobile ? null : '100%', maxWidth: isMobile ? undefined : '100%' }}>
      <Typography variant="h5" component="h5">
        Maintenance Summary
      </Typography>
      <Paper elevation={6} sx={{ backgroundImage: 'none', height: isMobile ? null : '95%', marginTop: '0.5em' }}>
        <form style={{ height: '100%' }}>
          <Typography variant="h5" component="h5" sx={{ backgroundColor: 'primary.contrastText', padding: '1em' }}>
            Create Maintenance Job
          </Typography>
          <Box
            sx={{
              height: isMobile ? null : '90%',
              padding: '0.5em 1em',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px'
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.supporting.dark
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.supporting.pale,
                borderRadius: 2
              }
            }}>
            <Stack spacing={2}>
              {/* reusing code! */}
              {/* Date input calander */}
              {dateInput(datePerformedName)}

              {dateInput(dateNextShutDownName)}
              {/* Tonnage input box */}
              {textInput(tonnageName, true)}
              {/* Maintenance Description */}
              {textAreaInput(descriptionName, true)}
              {/* Preservice comments */}
              {textAreaInput(preServiceName, true)}
              {/* Post service comments */}
              {textAreaInput(postServiceName, true)}
              {/* Deck rails comments */}
              {textAreaInput(deckRailsName, false, includeDeckRails)}
              {/* Crossbeam Comments */}
              {textAreaInput(crossbeamName, false, includeCrossbeams)}
              {/* Recommendation text input area */}
              {textAreaInput(recommendationName, false, includeRecommendations)}
              {/* Image upload button */}
              <Stack spacing={2}>
                <Typography variant="h5" component="h5">
                  Upload Media
                </Typography>

                {window.navigator.onLine ? (
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{ border: `dashed 1px ${theme.palette.text.highlight}`, height: '6em' }}
                    startIcon={<PhotoCameraIcon />}>
                    Upload
                    <input hidden accept="image/*" type="file" multiple onChange={onPhotoUpload} />
                  </Button>
                ) : (
                  <Typography>Cannot upload media whilst offline.</Typography>
                )}
                <Grid container spacing={1} flexDirection="column">
                  {fields.map((f, i) => {
                    return (
                      <Grid display="flex" flexDirection="row" key={f.id} item sx={{ alignItems: 'center' }}>
                        <Typography flex="1">{`${f.file.name}${f.caption ? ` - ${f.caption}` : ''}`}</Typography>
                        <Grid display="flex" item>
                          <Tooltip title="View/Add Caption">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setPhotoCaptionsModal([fields[i]])
                              }}>
                              <RemoveRedEye />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              remove(i)
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
                {fields.length ? (
                  <Box display="flex" justifyContent="center" mb="1em">
                    <Button variant="outlined" color="secondary" onClick={() => setPhotoCaptionsModal(fields)}>
                      View Images/Add Captions
                    </Button>
                  </Box>
                ) : null}
              </Stack>
            </Stack>

            {/* B.O.M. table */}
            <BomTable
              data={deckRevision}
              selected={selected}
              materialSelected={materialSelected}
              onClick={onBomClick}
            />
          </Box>
        </form>
      </Paper>
      {photoErrorMessages.map((error) => {
        return (
          <MaintenanceNotifier
            key={error}
            type="error"
            autoHideDuration={300}
            onClose={() => {
              setPhotoErrorMessages((current) => {
                return current.filter((error_) => error !== error_)
              })
            }}>
            {error}
          </MaintenanceNotifier>
        )
      })}
      <MaintenanceDialog
        title="Add Caption to Media"
        open={photoCaptionsModal.length > 0}
        Actions={
          <>
            <Button variant="outlined" color="secondary" onClick={() => setPhotoCaptionsModal([])}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" form={`form`} type="submit">
              Save Changes
            </Button>
          </>
        }>
        <CaptionPhotoForm data={photoCaptionsModal} onSubmit={onUpdatePhotoCaptions} />
      </MaintenanceDialog>
    </Box>
  )
}

export default SummaryForm
