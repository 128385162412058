/**
 *
 * "Generate a radial menu of circles for maintenance selectors,
 * This component showed after start maintenance"
 *
 * @file   MaintenanceSelector.js
 * @author Lateral
 * @since  2023
 */

import { Button, Zoom, Tooltip } from '@mui/material'
import BigNumber from 'bignumber.js'
import { useCurrentUser, useMaintenance } from 'hooks'
import React, { useMemo, useState } from 'react'
import { SCOPES } from 'components'
import { useNavigate } from 'react-router-dom'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { useNetworkState } from 'react-use'
import { useWearAppAnalysisContext } from './wearapp/WearAppAnalysisContext'
import { isPanelTheSame } from 'pages/common/maintenance/logic'
import dayjs from 'dayjs'
import { WearInputModal } from './WearInputModal'
import uniqBy from 'lodash.uniqby'

function MaintenanceSelector({
  isMobile,
  mouseCanvasPosition,
  selected,
  deckRevision,
  maintenance,
  onMaintenanceTypeClick,
  onWearSubmit
}) {
  /**
   * Generates radial menu for maintenance selector and wearApp
   * @function
   *
   * @param {boolean} isMobile - If the device is mobile then true elce false
   * @param {object} mouseCanvasPosition - Postion of the mouse while clicked on the deck
   * @param {object[]} selected - Array of selected panel objects
   * @param {string} selected.MaintenanceState - State of the maintenance such as ""NoActionRequired""
   * @param {string} selected.MaterialNumber - Serial number of the material of the deck
   * @param {object} selected.postion - Row and column postion of the deck
   * @param {object} deckRevision - Object of deckrevion details
   * @param {object} maintenance - Last record of deck maintenance
   * @param {} onMaintenanceTypeClick - Action on clicking maintenance type menu item
   * @param {} onWearSubmit - Action on clicking wear item
   * @param {} onWearAppSubmit - Action on clicking wearApp menu
   *
   * @returns Radial menu element with Wear. maintenance menu items, and wearApp button
   */
  const buttonSize = new BigNumber(6)
  const middleX = new BigNumber(mouseCanvasPosition.x).dividedBy(16).plus(isMobile ? 0 : 8)
  const middleY = new BigNumber(mouseCanvasPosition.y).dividedBy(16).plus(isMobile ? 0 : 16)
  const pi = new BigNumber(Math.PI)
  const radius = buttonSize.plus(3)
  const cosCalculation = radius.times(Math.cos(pi.dividedBy(4).toNumber()))
  const sinCalculation = radius.times(Math.sin(pi.dividedBy(4).toNumber()))
  const [isWearModalOpen, setIsWearModalOpen] = useState(false)
  const { actions } = useMaintenance()
  const show = new BigNumber(Object.entries(selected).length).isGreaterThan(0)
  const isDisabled = selected.length > 1
  const navigate = useNavigate()
  const { panelQuery, setMaintenanceData } = useCurrentDeckContext()
  const { online } = useNetworkState()
  const { currentUser } = useCurrentUser()
  const hasWearApp = currentUser?.hasAllScopes([SCOPES.wearapp.Read], deckRevision.SiteId)
  const { onViewResultsPressed } = useWearAppAnalysisContext()
  const wearAppResults = useMemo(() => {
    return (maintenance?.Details ?? []).find(
      ({ HistoryAction, Panel }) =>
        HistoryAction === actions.ViewWearAppResult.id && isPanelTheSame(Panel, selected?.[0])
    )
  }, [maintenance?.Details, selected])

  const hasWearAppResults = Object.keys(wearAppResults ?? {}).length > 0
  const isWearAppDisabled = hasWearAppResults
    ? false
    : !hasWearApp || !online || panelQuery.isFetching || (panelQuery.isSuccess && !panelQuery.data.isValid)

  const buttonStyling = {
    position: 'absolute',
    height: `${buttonSize}em`,
    width: `${buttonSize}em`,
    borderRadius: '50%',
    backgroundColor: `#FFAE57`,
    '&:hover': {
      backgroundColor: `#FFAE57`
    }
  }

  const getDisableReason = () => {
    if (!hasWearApp) {
      return "You don't have access to the WearApp pacakage"
    } else if (!online) {
      return 'You are not connected to the Internet'
    } else if (panelQuery.isFetching) {
      return 'WearApp is loading'
    } else if (panelQuery.isSuccess && !panelQuery.data.isValid) {
      return `Wear analysis can not be performed on the selected panel(${selected[0]?.MaterialNumber})`
    }
  }
  const wearAppButtonStyling = {
    ...buttonStyling
  }
  if (isWearAppDisabled) {
    delete wearAppButtonStyling['&:hover']
    wearAppButtonStyling['pointer'] = 'disabled'
  }

  const hasAction = useMemo(
    () =>
      (maintenance.Details ?? []).some(({ HistoryAction, Panel, WearAppResponse }) => {
        if (!isPanelTheSame(Panel, selected?.[0]) || HistoryAction === actions.ViewWearAppResult.id) {
          return false
        }
        return WearAppResponse?.length !== 0 && HistoryAction !== actions.NoChange.id
      }),
    [maintenance.Details, selected]
  )

  const onClearPress = () => {
    if (!hasAction) {
      return
    }

    const clone = structuredClone(maintenance)
    let wearAppReplaceAction = clone.Details.find(
      ({ Panel, WearAppResponse, HistoryAction }) =>
        isPanelTheSame(Panel, selected?.[0]) &&
        WearAppResponse?.length > 0 &&
        (HistoryAction === actions.Replace.id || WearAppResponse === 'add-wear-action')
    )

    if (wearAppReplaceAction) {
      wearAppReplaceAction = {
        ...structuredClone(wearAppReplaceAction),
        HistoryAction: actions.NoChange.id
      }
    }
    clone.Details = clone.Details.filter(
      ({ Panel, SwappedPanel }) => !isPanelTheSame(Panel, selected?.[0]) && !isPanelTheSame(SwappedPanel, selected?.[0])
    )
    if (wearAppReplaceAction) {
      clone.Details.push(wearAppReplaceAction)
    }
    setMaintenanceData((current) => {
      const maintenanceDataClone = structuredClone(current)
      maintenanceDataClone.deckRevisionHistory = clone
      return maintenanceDataClone
    })
  }

  const WearAppComponent = (
    <Zoom in={show}>
      <Button
        variant="contained"
        component="label"
        disabled={isWearAppDisabled}
        style={isWearAppDisabled ? { pointerEvents: 'all', cursor: 'default' } : {}}
        onClick={() => {
          if (hasWearAppResults) {
            onViewResultsPressed(wearAppResults.extraData.uuid)
            return
          }
          if (isWearAppDisabled) {
            return
          }
          navigate('wearapp')
        }}
        sx={[
          wearAppButtonStyling,
          {
            top: `${middleY.plus(radius)}em`,
            left: `${middleX}em`
          }
        ]}>
        {hasWearAppResults ? 'WearApp Results' : 'WearApp'}
      </Button>
    </Zoom>
  )

  const WearAppComponentContainer = isWearAppDisabled ? (
    <Tooltip title={getDisableReason()} followCursor>
      <div>{WearAppComponent}</div>
    </Tooltip>
  ) : (
    WearAppComponent
  )

  return (
    <>
      {/* The actual radial menu */}
      <Zoom in={show}>
        <Button
          variant="contained"
          onClick={() => {
            setIsWearModalOpen(true)
          }}
          sx={[
            buttonStyling,
            {
              top: `${middleY.minus(cosCalculation)}em`,
              left: `${middleX.minus(sinCalculation)}em`
            }
          ]}>
          Wear
        </Button>
      </Zoom>
      <Zoom in={show}>
        <Button
          variant="contained"
          onClick={() => onMaintenanceTypeClick(actions.Rotate)}
          sx={[buttonStyling, { top: `${middleY.minus(radius)}em`, left: `${middleX}em` }]}>
          Rotate
        </Button>
      </Zoom>
      <Zoom in={show}>
        <Button
          variant="contained"
          onClick={() => onMaintenanceTypeClick(actions.Swap)}
          disabled={isDisabled}
          sx={[
            buttonStyling,
            {
              top: `${middleY.minus(cosCalculation)}em`,
              left: `${middleX.plus(sinCalculation)}em`
            }
          ]}>
          Swap
        </Button>
      </Zoom>
      <Zoom in={show}>
        <Button
          variant="contained"
          onClick={() => onMaintenanceTypeClick(actions.Relocate)}
          disabled={isDisabled}
          sx={[
            buttonStyling,
            {
              top: `${middleY}em`,
              left: `${middleX.plus(radius)}em`
            }
          ]}>
          Relocate
        </Button>
      </Zoom>
      <Zoom in={show}>
        <Button
          variant="contained"
          onClick={() => onMaintenanceTypeClick(actions.Replace, { PanelReplaceDate: dayjs().toISOString() })}
          sx={[
            buttonStyling,
            {
              top: `${middleY.plus(cosCalculation)}em`,
              left: `${middleX.plus(sinCalculation)}em`
            }
          ]}>
          Replace
        </Button>
      </Zoom>
      {WearAppComponentContainer}
      {hasAction && (
        <Zoom in={show}>
          <Button
            variant="contained"
            onClick={onClearPress}
            sx={[
              buttonStyling,
              {
                top: `${middleY.plus(cosCalculation)}em`,
                left: `${middleX.plus(sinCalculation).minus(radius * 1.4)}em`
              }
            ]}>
            Clear
          </Button>
        </Zoom>
      )}
      {isWearModalOpen && (
        <WearInputModal
          deckRevision={deckRevision}
          maintenance={maintenance}
          handleClose={() => setIsWearModalOpen(false)}
          isMobile={isMobile}
          onWearSubmit={onWearSubmit}
          selectedPanels={uniqBy(selected, (item) => {
            return item.Position
          })}
        />
      )}
    </>
  )
}

export default MaintenanceSelector
