/**
 *
 * "A table to view current companies"
 *
 * @file   CompaniesTable.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material/styles'
import { getObjectComparator } from 'common'
import { ContextMenu, SCOPES } from 'components'
import StyledTableCell from 'components/tables/StyledTableCell'
import EnhancedTableHead from 'components/tables/EnhancedTableHead'
import { Typography } from '@mui/material'
import { useCurrentUser } from 'hooks'

const headCells = [
  /**
   *
   * Headings of the table
   *
   * @const
   */
  {
    id: 'Logo',
    numeric: false,
    disablePadding: false,
    label: ''
  },
  {
    id: 'CompanyName',
    numeric: false,
    disablePadding: false,
    label: 'Company'
  },
  {
    id: 'SitesTotal',
    numeric: false,
    disablePadding: false,
    label: 'Number of Sites'
  },
  {
    id: 'LocationsTotal',
    numeric: false,
    disablePadding: false,
    label: 'Number of Locations'
  },
  {
    id: 'ScreensTotal',
    numeric: false,
    disablePadding: false,
    label: 'Number of Screens'
  },
  {
    id: 'CompanyContact',
    numeric: false,
    disablePadding: false,
    label: 'Company Contact'
  }
]

export function CompaniesTable({ rows, onViewSites, onEditDetails, onDelete }) {
  /**
   * Generates a table of exisitng companies"
   *
   * @function
   * @param {object[]} rows - Exisitng company details
   * @param {string} rows.Logo - company logo id
   * @param {string} rows.CompanyName - Name of the company
   * @param {string} rows.SitesTotal - Number of sites
   * @param {string} rows.LocationsTotal - Number of locations
   * @param {string} rows.ScreensTotal - Number of screens
   * @param {object} rows.CompanyContact - Company contact details
   * @param {object} rows.CompanyEmail - Company email address
   * @param {} onViewSites - Action on clicking on site
   * @param {} onEditDetails - Action on clicking edit company details
   * @param {} onDelete - Action on deleting row
   *
   * @returns {object} - A table of existing company
   */
  const theme = useTheme()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('CompanyName')
  const { currentUser } = useCurrentUser()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table stickyHeader sx={{ minWidth: 750, borderCollapse: 'collapse' }} size="small">
        <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {rows &&
            rows.sort(getObjectComparator(order, orderBy)).map((row) => {
              const menuItems = [{ title: 'View Sites', action: () => onViewSites(row) }]
              if (currentUser?.hasAllScopes([SCOPES.siteManagement.Write])) {
                menuItems.push({ title: 'Edit Company Details', action: () => onEditDetails(row) })
                if (row.id !== process.env.REACT_APP_ADMIN_CUSTOMER_ID) {
                  menuItems.push({ title: 'Delete Company', action: () => onDelete(row), color: 'error' })
                }
              }
              return (
                <TableRow key={row?.CompanyName} hover tabIndex={-1} sx={{ height: '4em' }}>
                  <TableCell sx={{ width: '50px' }}>
                    {row?.Logo ? <img src={row.Logo} style={{ width: '100px' }} /> : '--'}
                  </TableCell>
                  <StyledTableCell>{row?.CompanyName ?? '--'}</StyledTableCell>
                  <StyledTableCell>{row?.SitesTotal ?? '--'}</StyledTableCell>
                  <StyledTableCell>{row?.LocationsTotal ?? '--'}</StyledTableCell>
                  <StyledTableCell>{row?.ScreensTotal ?? '--'}</StyledTableCell>
                  <TableCell sx={{ width: 'auto' }}>
                    {row?.CompanyContact ?? '--'}
                    <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                      {row?.CompanyEmail ?? '--'}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ width: '0' }}>
                    <ContextMenu context={row} menuItems={menuItems} />
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default CompaniesTable
