/**
 *
 * "Generates a form for rendering pre shut Report."
 *
 * @file   PreShutForm.js
 * @author Lateral
 * @since  2023
 */
import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import {
  companyName,
  defaultString,
  screensName,
  shutStartDateName,
  siteName,
  titleName
} from 'pages/reporting/common/formNames'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import { DropdownInput } from '../common/DropdownInput'
import DeckModel from 'components/mobileDeckCanvas/components/DeckModel/DeckModel'
import { DeckMode } from 'common/deckMode'
import { ReportingContext } from 'components/reportingContext/ReportingContext'
import { TextInput } from '../common/TextInput'
import { SCOPES } from 'components'
import { useCurrentUser } from 'hooks'
import { LoadingButton } from '@mui/lab'

function PreShutForm({
  data,
  preShutDecks,
  setSelectedPreShutDeck,
  setFormData,
  setPreShutDecks,
  onSubmit,
  customers,
  sites,
  decks,
  locations,
  getDeckRevision,
  generatePanelLayout
}) {
  /**
   * Table for viewing all Reporting history.
   *
   * @function
   *
   * @param {object} data - Site object of which Parts report is going to be generated
   * @param {string} data.id - Id of the report
   * @param {string} data.shutStartDateName - Shut start date
   * @param {string} data.Company - Name of the company
   * @param {string} data.Site - Name of the site
   * @param {object[]} preShutDecks - Selected deck layouts
   * @param {} setSelectedPreShutDeck - Update SelectedPreShutDeck hook
   * @param {} setFormData - Update FormData hook
   * @param {} setPreShutDecks - Update PreShutDecks hook
   * @param {} onSubmit - Action on submitting form
   * @param {object[]} customers - Array of all company object
   * @param {object[]} location - Array of all location object
   * @param {object[]} sites - Array of all site object
   * @param {object[]} decks - Array of all deck location object
   * @param {} getDeckRevision - Get recent maintenances of selected deck
   * @param {} generatePanelLayout - Get panel layout of selected deck
   *
   * @returns {object} - Pre shut report form
   */
  const theme = useTheme()
  const { setShowTabs } = useContext(ReportingContext)
  const [isLoading, setIsLoading] = useState(false)
  const defaultValues = {
    id: data.id ?? defaultString,
    [shutStartDateName]: data[shutStartDateName] ? dayjs(data[shutStartDateName]) : dayjs(),
    [companyName]: data[companyName] ?? defaultString,
    [siteName]: data[siteName] ?? defaultString,
    [screensName]: getScreenChoices(),
    [titleName]: data[titleName] ?? defaultString
  }
  const {
    control,
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({ defaultValues })

  const { fields, append, remove } = useFieldArray({
    control,
    name: screensName
  })

  const { currentUser } = useCurrentUser()

  const watchCompany = watch(companyName, data[companyName] ?? defaultString)
  const watchSite = watch(siteName, data[siteName] ?? defaultString)
  const sitesAvailable = currentUser.getSitesForScope(SCOPES.reporting.Write)
  const companiesAvailable = currentUser.getCustomersForScope(SCOPES.reporting.Write)
  const filteredCompanies = currentUser.isAdmin ? customers : customers.filter((c) => companiesAvailable.includes(c.id))
  const values = getValues()

  const canGenerateReport = useMemo(() => {
    const eligibleScreens = values[screensName].filter((f) => f.Include)

    if (eligibleScreens.length === 0) {
      return false
    }

    eligibleScreens.forEach((field) => {
      const deck = preShutDecks?.find((p) => p.id === field.DeckId)

      if (!deck) {
        return false
      }
    })

    return true
  }, [values[screensName]])

  function getScreenChoices(value) {
    let siteId = value
    if (!siteId) {
      siteId = watchSite?.length > 0 ? watchSite : data[siteName]
    }

    const companyId = watchCompany?.length > 0 ? watchCompany : data[companyName]
    const results = []

    if (siteId) {
      const siteLocations = locations.filter((l) => l.SiteId === siteId)
      siteLocations.forEach((location) => {
        const locationDecks = decks.filter((d) => d.LocationId === location.id && d.CustomerId === companyId)
        locationDecks.forEach((deck) => {
          if (getDeckRevision(deck.id)) {
            const existing = preShutDecks.filter((p) => p.id === deck.id).length > 0
            results.push({
              DeckId: deck.id,
              Name: `${location.Name} - ${deck.DeckHeader.EquipmentId} - ${deck.DeckHeader.DeckLevel}`,
              Include: existing
            })
          }
        })
      })
    }

    return results
  }

  function onFillScreens(e) {
    const results = getScreenChoices(e?.target?.value)

    setPreShutDecks([])
    setSelectedPreShutDeck('')
    remove()

    results.forEach((result) => {
      append(result)
    })
  }

  function onSelectPanels() {
    const form = getValues()
    const eligibleScreens = form[screensName].filter((f) => f.Include)

    if (eligibleScreens.length) {
      let decksToProcess = []

      eligibleScreens.forEach((field) => {
        decksToProcess.push(generatePanelLayout(field.DeckId))
      })

      setPreShutDecks(decksToProcess)
      setShowTabs(false)
      setFormData(form)
      setSelectedPreShutDeck(eligibleScreens[0].DeckId)
    }
  }

  async function submitForm(form) {
    try {
      setIsLoading(true)
      form[screensName].forEach((screen) => {
        const maintenanceCanvas = document.getElementById(`${screen.DeckId}-maintenance`)
        screen.maintenanceHtmlElement = maintenanceCanvas
        const layoutCanvas = document.getElementById(`${screen.DeckId}-layout`)
        screen.layoutHtmlElement = layoutCanvas
      })

      await onSubmit(form, isDirty)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <form onSubmit={(e) => handleSubmit(submitForm)(e)} style={{ height: '100%' }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
          Create New Report
        </Typography>
        <Box sx={{ padding: '0.5em 1em' }}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
              <Typography variant="h5" component="h5">
                {shutStartDateName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
              <Controller
                control={control}
                name={shutStartDateName}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    renderInput={(params) => (
                      <TextField color="secondary" sx={{ width: '50%' }} fullWidth {...params} />
                    )}
                    format="DD/MM/YYYY"
                  />
                )}
              />
              {errors[shutStartDateName] && <p>This field is required</p>}
            </Grid>
          </Grid>
          <DropdownInput
            name={companyName}
            options={filteredCompanies.map((c) => {
              return { id: c.id, Name: c.Name }
            })}
            onDropDownChange={() => {
              remove()
              setValue(siteName, defaultString)
            }}
            control={control}
            errors={errors}
          />
          <DropdownInput
            name={siteName}
            options={
              watchCompany || data[companyName]
                ? sites
                    .filter(
                      (s) => s.CustomerId === watchCompany && (currentUser.isAdmin || sitesAvailable.includes(s.id))
                    )
                    .map((c) => {
                      return { id: c.id, Name: c.Name }
                    })
                : []
            }
            onDropDownChange={onFillScreens}
            control={control}
            errors={errors}
          />
          <TextInput name={titleName} register={register} errors={errors} />
        </Box>
        <Typography
          variant="h5"
          component="h5"
          sx={{ color: 'supporting.pale', backgroundColor: 'primary.contrastText', padding: '1em' }}>
          Select Screens
        </Typography>
        <Box
          sx={{
            padding: '1em 1em 2em 1em',
            height: '45%',
            marginTop: '0.5em',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: theme.palette.supporting.dark
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.supporting.pale,
              borderRadius: 2
            }
          }}>
          {fields.length ? (
            <Stack spacing={3}>
              {fields.map((f, i) => {
                const preShutDeck = preShutDecks.find((p) => p.id === f.DeckId)
                return (
                  <Box key={f.id}>
                    <FormControlLabel
                      label={f.Name}
                      control={
                        <Controller
                          control={control}
                          name={`${screensName}.${i}.Include`}
                          render={({ field: { value, onChange, ...field } }) => (
                            <Checkbox
                              checked={value}
                              size="small"
                              onChange={onChange}
                              sx={{ color: `${theme.palette.supporting.highlight}` }}
                              {...field}
                            />
                          )}
                        />
                      }
                      sx={{ margin: '0' }}
                    />

                    {preShutDeck ? (
                      <>
                        <Box id={`${f.DeckId}-maintenance`} sx={{ padding: '1em' }} hidden>
                          <DeckModel
                            data={preShutDeck.DeckRevision}
                            maintenance={preShutDeck.PanelLayout}
                            mode={DeckMode.maintenance}
                            selected={[]}
                            materialSelected=""
                            presentation={true}
                          />
                        </Box>
                        <Box id={`${f.DeckId}-layout`} sx={{ padding: '1em' }} hidden>
                          <DeckModel
                            data={preShutDeck.DeckRevision}
                            maintenance={preShutDeck.PanelLayout}
                            mode={DeckMode.default}
                            selected={[]}
                            materialSelected=""
                            presentation={true}
                          />
                        </Box>
                      </>
                    ) : null}
                  </Box>
                )
              })}
            </Stack>
          ) : (
            <Box>
              <Typography>{watchSite ? 'No Screens found.' : 'Please select a Company and Site.'}</Typography>
            </Box>
          )}
        </Box>
        {fields.length ? (
          <Stack direction="row" spacing={2} justifyContent="end">
            <LoadingButton
              variant="outlined"
              onClick={onSelectPanels}
              loading={isLoading}
              sx={{ color: `${theme.palette.secondary.main}`, padding: '0.5em 1em' }}>
              Select Panels
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ padding: '0.5em 1em' }}
              loading={isLoading}
              disabled={!canGenerateReport}>
              Generate Report
            </LoadingButton>
          </Stack>
        ) : null}
      </form>
    </Box>
  )
}
export default PreShutForm
