import axios from 'axios'
import { Auth } from 'aws-amplify'

export const getApiClient = (baseURL) => {
  const ApiClient = axios.create({ baseURL })

  ApiClient.interceptors.request.use(async (request) => {
    const credentials = await Auth.currentSession()
    request.headers = {
      ...request.headers,
      Authorization: credentials.getIdToken().getJwtToken(),
      'x-app': 'deckmapp'
    }
    return request
  })

  ApiClient.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      // if (error.response && [401, 403].includes(error.response.status)) {
      //   //await Auth.signOut()
      //   return
      // }
      return Promise.reject(error)
    }
  )

  return ApiClient
}
