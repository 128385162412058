/**
 *
 * "Bottom navigation bar components for mobile version"
 *
 * @file   BottomNav.js
 * @author Lateral
 * @since  2023
 */
import React, { forwardRef, useMemo } from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { ReactComponent as DashboardIcon } from 'assets/icons/Dashboard.svg'
import { ReactComponent as EquipmentIcon } from 'assets/icons/Equipment.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/Settings.svg'

/**
 *
 * Generates bottom navigation bar for mobile sized screen view
 *
 * @constant
 * @returns {React.ElementType} React box relement of bottom navigation bar for mobile version
 */
/*
 *Component for the bottom navigation in mobile.
 */
// eslint-disable-next-line react/display-name
export const BottomNav = forwardRef((props, ref) => {
  const [value, setValue] = React.useState(0)

  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = useMemo(() => location.pathname?.substring(1)?.toLowerCase(), [location])

  return (
    <Box sx={{ height: 64, width: '100%' }} ref={ref}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
          let path = 'dashboard'
          if (newValue === 1) {
            path = 'mobile/maintenance'
          } else if (newValue === 2) {
            path = 'mobile/help'
          }
          navigate(path)
        }}>
        <BottomNavigationAction
          icon={
            <DashboardIcon
              style={{
                height: '32px',
                fill: currentPath === 'dashboard' ? theme.palette.secondary.main : theme.palette.supporting.highlight
              }}
            />
          }
        />
        <BottomNavigationAction
          icon={
            <EquipmentIcon
              style={{
                height: '32px',
                fill:
                  currentPath === 'mobile/maintenance'
                    ? theme.palette.secondary.main
                    : theme.palette.supporting.highlight
              }}
            />
          }
        />
        <BottomNavigationAction
          icon={
            <SettingsIcon
              style={{
                height: '32px',
                width: '32px',
                fill: currentPath === 'mobile/help' ? theme.palette.secondary.main : theme.palette.supporting.highlight
              }}
            />
          }
        />
      </BottomNavigation>
    </Box>
  )
})
