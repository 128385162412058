/**
 *
 * "DeckLayout page for Mobile. Generates using 2D panels and sideliners components.
 * Also include B.O.M. table at bottom.
 * Should only be used for mobile-sized screens."
 *
 * @file   MobileDeckLayoutPage.js
 * @author Lateral
 * @since  2023
 */
import DeckCanvas from 'components/mobileDeckCanvas/DeckCanvas'
import { Box, Button, Grid, Typography, Zoom } from '@mui/material'
import BomTable from 'components/tables/bom/BomTable'
import React, { useState } from 'react'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { DeckMode } from 'common/deckMode'
import ChooseDeck from 'pages/deck/common/ChooseDeck'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTheme } from '@mui/material/styles'
import { useInView } from 'react-intersection-observer'

/*
 *DeckLayout page for Mobile. Should only be used for mobile-sized screens.
 */
function MobileDeckLayoutPage() {
  /**
   * Generates decklayout page with B.O.M. table for mobile sized screen.
   * using 2D box shaped panels and sideliners.
   *
   * @function
   *
   * @returns {object} - Decklayout page
   */
  const [materialSelected, setMaterialSelected] = useState('')
  const {
    deckId,
    screenId,
    setCustomerId,
    setSiteId,
    setLocationId,
    setEquipmentId,
    setScreenId,
    setDeckId,
    database,
    setSelectedPanel,
    selectedPanel
  } = useCurrentDeckContext()
  const navigate = useNavigate()
  const { ref: scrollingRef, inView, entry } = useInView({ delay: 100, trackVisibility: true, threshold: 0.1 })
  const theme = useTheme()

  const screen = database.screens.find((s) => s.id === screenId)
  const deck = database.decks.find((d) => d.id === deckId)
  const deckRevision = getDeckRevision()

  // Get deck revision data from database
  function getDeckRevision() {
    const revisions = database.deckRevisions
      .filter((d) => d.DeckId == deckId && d.IsPublished)
      .sort((a, b) => b.RevisionNumber - a.RevisionNumber)
    if (revisions.length) {
      const revision = structuredClone(revisions[0])
      return revision
    }
  }

  // Action on submitting ChooseDeck form to parse deck details from database
  function onSubmit(form) {
    setCustomerId(form.CustomerId)
    setLocationId(form.LocationId)
    setEquipmentId(form.EquipmentId)
    setSiteId(form.SiteId)
    const newDeck = database.decks.find((d) => d.id == form.DeckId)
    setDeckId(form.DeckId)
    const newScreen = database.screens.find((s) => s.id == newDeck.ScreenId)
    setScreenId(newScreen.id)
  }

  // Action on clicking on a panel/Sideliners
  function onPanelClick(selected) {
    if (selected) {
      setSelectedPanel(Array.isArray(selected) ? selected : [selected])
    } else {
      setSelectedPanel([])
    }
    setMaterialSelected('')
  }

  // Action on clicking rows on B.O.M. table
  function onBomClick(kit) {
    setSelectedPanel([])
    setMaterialSelected(kit.MaterialNumber)
  }

  // Action on resetting ChooseDeck form (CLearing fields)
  function onReset() {
    setCustomerId('')
    setLocationId('')
    setSiteId('')
    setDeckId('')
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '1em' }}>
        <Typography variant="h2" sx={{ width: '100%' }}>
          Deck Layout
        </Typography>
      </Grid>
      {screen && deck ? (
        deckRevision && Object.entries(deckRevision).length ? (
          <>
            <Grid item xs={12} sx={{ height: '100%' }}>
              <Typography className="title" variant="h5" component="h5" sx={{ color: 'text.secondary' }}>
                {`${screen.Name} - ${deck.DeckHeader.DeckLevel} ${deck.DeckHeader.MaterialNumber}`}
              </Typography>
              <Grid
                container
                item
                spacing={1}
                xs={12}
                sx={{ marginTop: '0.5em', marginBottom: '1em', textAlign: 'center' }}>
                <Grid item xs={6}>
                  <Button variant="outlined" sx={{ width: '100%' }} onClick={onReset}>
                    Select Deck
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    sx={{ color: 'secondary.main', width: '100%' }}
                    onClick={() => navigate('/mobile/maintenance')}>
                    Maintenance
                  </Button>
                </Grid>
              </Grid>
              <DeckCanvas
                data={deckRevision}
                onPanelClick={onPanelClick}
                selected={selectedPanel}
                materialSelected={materialSelected}
                mode={DeckMode.default}
              />
            </Grid>
            <Grid ref={scrollingRef} item xs={12} sx={{ marginTop: '1em' }}>
              <BomTable
                data={deckRevision}
                selected={selectedPanel}
                materialSelected={materialSelected}
                onClick={onBomClick}
              />
            </Grid>
            <Zoom in={!inView}>
              <Box
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  position: 'fixed',
                  bottom: '54px',
                  marginLeft: '-15px'
                }}>
                <Button
                  sx={{
                    height: '20px',
                    backgroundColor: `${theme.palette.primary.dark}`
                  }}
                  onClick={() => entry.target?.scrollIntoView({ behavior: 'smooth' })}>
                  <KeyboardArrowDownIcon fontSize="large" color="secondary" />
                </Button>
              </Box>
            </Zoom>
          </>
        ) : Object.entries(screen).length && Object.entries(deck).length ? (
          <>
            <Grid item xs={12} sx={{ marginTop: '2em', width: '100%', textAlign: 'center' }}>
              <Typography>No Deck Revision found.</Typography>
              <Button color="secondary" variant="contained" sx={{ width: '100%', marginTop: '2em' }} onClick={onReset}>
                Choose Deck
              </Button>
            </Grid>
          </>
        ) : null
      ) : deckId ? (
        <Box>Loading...</Box>
      ) : (
        <Grid item xs={12}>
          <ChooseDeck database={database} onSubmit={onSubmit} />
        </Grid>
      )}
    </Grid>
  )
}

export default MobileDeckLayoutPage
