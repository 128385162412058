/**
 *
 * Module to insert or delte rows/columns in deck canvas
 *
 * @file   Inserter.js
 * @author Lateral
 * @since  2023
 */
import BigNumber from 'bignumber.js'
import { Selector } from './components/Selector'
import React from 'react'
import { PanelsItems, SideLinersItems } from 'models'

/*
 *Handles the inserting of rows/columns into the 3D canvas.
 */
export function Inserter({ data, theme, rotation, isInserting, isDeleting, onComplete, saveDeckRevision }) {
  /**
   * Function to insert rows/columns into the 3D canvas.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} theme - Panel theme object
   * @param {number} rotation - Deck rotation angles in 3D space in radian
   * @param {boolean} isInserting - If the action is insert rows/column then true else false
   * @param {boolean} isDeleting - If the action is delete rows/column then true else false
   * @param {} onComplete - Action after inserting/deleting rows/columns
   * @param {} saveDeckRevision - Function to save deckrivision hystory
   * @returns {object} - Cirlce object
   */

  const size = data.Size

  // Helper function to pass row and column number to insert or delete function by selecting selector
  function onClick(column, row) {
    if (isInserting) {
      onInsert(column, row)
    } else if (isDeleting) {
      onDelete(column, row)
    }
  }

  function onInsert(column, row) {
    /**
     * Function to insert panels at row and column
     *
     * @function
     * @param {number} column - Column number of the selector
     * @param {number} row - Row number of the selector
     */
    data.Panels.map((p) => {
      const panelColumn = new BigNumber(p.Position.Column)
      const panelRow = new BigNumber(p.Position.Row)
      if (column !== null && panelColumn.isGreaterThanOrEqualTo(column)) {
        p.Position.Column = panelColumn.plus(1).toNumber()
      }
      if (row !== null && panelRow.isGreaterThanOrEqualTo(row)) {
        p.Position.Row = panelRow.plus(1).toNumber()
      }
    })

    if (column !== null) {
      for (let i = 0; i < size.Rows; i++) {
        data.Panels.push(
          new PanelsItems({
            Position: { Column: column, Row: i }
          })
        )
      }
      const sizeColumns = new BigNumber(data.Size.Columns)
      data.Size.Columns = sizeColumns.plus(1).toNumber()
    }

    if (row !== null) {
      for (let i = 0; i < size.Columns; i++) {
        data.Panels.push(
          new PanelsItems({
            Position: { Column: i, Row: row }
          })
        )
      }

      const maxPosition = Math.max.apply(
        null,
        data.SideLiners.map((s) => s.StartPosition)
      )
      const maxSideliner = data.SideLiners.find((s) => s.StartPosition === maxPosition)

      data.SideLiners.push(
        new SideLinersItems({
          StartPosition: new BigNumber(maxPosition).plus(maxSideliner.Height).toNumber(),
          Height: 1,
          Side: 'Left'
        })
      )
      data.SideLiners.push(
        new SideLinersItems({
          StartPosition: new BigNumber(maxPosition).plus(maxSideliner.Height).toNumber(),
          Height: 1,
          Side: 'Right'
        })
      )
      const sizeRows = new BigNumber(data.Size.Rows)
      data.Size.Rows = sizeRows.plus(1).toNumber()
    }

    onComplete()
    saveDeckRevision(structuredClone(data))
  }

  function onDelete(column, row) {
    /**
     * Function to delete panels at row and column
     *
     * @function
     * @param {number} column - Column number of the selector
     * @param {number} row - Row number of the selector
     */
    const panels = data.Panels.filter((p) => {
      if (column !== null) {
        const positionColumn = new BigNumber(p.Position.Column)
        if (!positionColumn.isEqualTo(column)) {
          if (positionColumn.isGreaterThan(column)) {
            p.Position.Column = positionColumn.minus(1).toNumber()
          }

          return p
        }
      }

      if (row !== null) {
        const positionRow = new BigNumber(p.Position.Row)
        if (!positionRow.isEqualTo(row)) {
          if (positionRow.isGreaterThan(row)) {
            p.Position.Row = positionRow.minus(1).toNumber()
          }

          return p
        }
      }
    })

    let cloneData = structuredClone(data)
    cloneData.Panels = panels
    const sizeColumns = new BigNumber(cloneData.Size.Columns)
    if (column !== null && sizeColumns.isGreaterThan(column)) {
      cloneData.Size.Columns = sizeColumns.minus(1).toNumber()
    }

    const sizeRows = new BigNumber(cloneData.Size.Rows)
    if (row !== null && sizeRows.isGreaterThan(row)) {
      cloneData.Size.Rows = sizeRows.minus(1).toNumber()

      const maxPosition = Math.max.apply(
        null,
        data.SideLiners.map((s) => s.StartPosition)
      )
      cloneData.SideLiners = cloneData.SideLiners.filter((s) => s.StartPosition !== maxPosition)
    }

    onComplete()
    saveDeckRevision(cloneData)
  }

  function renderCircles() {
    /**
     * Render circle for selctors
     * @returns - Selector object as circles
     * @function
     */
    const circles = []
    let totalXCoordinate = new BigNumber(-size.Columns).dividedBy(2)
    if (isDeleting) {
      totalXCoordinate = totalXCoordinate.plus(0.5)
    }
    const yCoordinate = new BigNumber(size.Rows).dividedBy(2)
    const columnOffset = 1.5

    let columnTotal = new BigNumber(size.Columns)
    if (isInserting) {
      columnTotal = columnTotal.plus(1)
    }

    for (let i = 0; i < columnTotal.toNumber(); i++) {
      const xCoordinate = totalXCoordinate
      totalXCoordinate = totalXCoordinate.plus(1)
      circles.push(
        <Selector
          key={`column${i}`}
          theme={theme}
          circlePosition={[xCoordinate, yCoordinate.plus(columnOffset), 0.25]}
          startPosition={[xCoordinate, yCoordinate.plus(columnOffset), 0.15]}
          endPosition={[xCoordinate, yCoordinate.minus(size.Rows), 0.15]}
          onClick={() => onClick(i, null)}
        />
      )
    }

    let totalYCoordinate = yCoordinate.plus(0.75)

    if (isDeleting) {
      totalYCoordinate = totalYCoordinate.minus(0.5)
    }

    const sideXCoordinate = new BigNumber(size.Columns).dividedBy(2)
    const rowOffset = 1

    const endOffset = new BigNumber(size.Columns).multipliedBy(2)

    let rowTotal = new BigNumber(size.Rows)
    if (isInserting) {
      rowTotal = rowTotal.plus(1)
    }

    for (let i = 0; i < rowTotal.toNumber(); i++) {
      const yCoordinate = totalYCoordinate
      totalYCoordinate = totalYCoordinate.minus(1)
      circles.push(
        <Selector
          key={`row${i}`}
          theme={theme}
          circlePosition={[sideXCoordinate.plus(rowOffset), yCoordinate, 0.25]}
          startPosition={[sideXCoordinate.plus(rowOffset), yCoordinate, 0.15]}
          endPosition={[sideXCoordinate.minus(endOffset), yCoordinate, 0.15]}
          onClick={() => onClick(null, i)}
        />
      )
    }

    return circles
  }

  return <group rotation={rotation}>{renderCircles()}</group>
}

export default Inserter
