import { Button, Grid } from '@mui/material'
import { useWearAppContext } from '../WearAppContext'
import { ReplacePanelDecision } from './ReplacePanelDecision'
import { ProcessEfficiency, screeningTypes } from './ProcessEfficiency'
import React, { useState } from 'react'

const ReplacePanelProcessEfficiency_ = ({ safetyDepth, panelInstallDate, nextShutDownDate }) => {
  const { onReplacePanelEfficencyActionButtonPressed } = useWearAppContext()
  const [efficiency, setEfficiencyData] = useState({
    openArea: '25',
    screeningType: screeningTypes[0],
    particleType: ''
  })

  const [replacePanel, setReplacePanelData] = useState({
    panelInstallDate: new Date(panelInstallDate).getTime(),
    nextShutDownDate: new Date(nextShutDownDate).getTime(),
    safetyDepth
  })

  const disabled = (() => {
    if (['panelInstallDate', 'nextShutDownDate', 'safetyDepth'].some((key) => !replacePanel[key])) {
      return true
    }
    if (
      ['openArea', 'screeningType', 'particleType'].some((key) => {
        if (key === 'particleType' && efficiency.screeningType === 'Sizing: Dry, Wet, Scalping, etc') {
          return false
        }
        return !efficiency[key]
      })
    ) {
      return true
    }
    return false
  })()

  return (
    <Grid container>
      <ReplacePanelDecision replacePanelData={replacePanel} setReplacePanelData={setReplacePanelData} />
      <ProcessEfficiency efficiencyData={efficiency} setEfficiencyData={setEfficiencyData} />
      <Grid display="flex" container justifyContent="end" gap="1em">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onReplacePanelEfficencyActionButtonPressed('cancel')}>
          Cancel
        </Button>
        <Button
          disabled={disabled}
          variant="contained"
          onClick={() => {
            onReplacePanelEfficencyActionButtonPressed({ replacePanel, efficiency })
          }}
          color="secondary">
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}

export const ReplacePanelProcessEfficiency = React.memo(ReplacePanelProcessEfficiency_)
