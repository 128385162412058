/**
 *
 * "A table to view current users"
 *
 * @file   UserTable.js
 * @author Lateral
 * @since  2023
 */
import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material/styles'
import { getObjectComparator } from 'common'
import { ContextMenu, SCOPES, ScopeRequirementAll } from 'components'
import StyledTableCell from 'components/tables/StyledTableCell'
import EnhancedTableHead from 'components/tables/EnhancedTableHead'
import { Chip } from '@mui/material'
import { useCurrentUser } from 'hooks'

const headCells = [
  /**
   *
   * Headings of the table
   *
   * @const
   */
  {
    id: 'CompanyName',
    numeric: false,
    disablePadding: false,
    label: 'Company'
  },
  {
    id: 'FirstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name'
  },
  {
    id: 'LastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name'
  },
  {
    id: 'Email',
    numeric: false,
    disablePadding: false,
    label: 'Email'
  },
  {
    id: 'Roles',
    numeric: false,
    disablePadding: false,
    label: 'Roles'
  },
  {
    id: 'Enabled',
    numeric: false,
    disablePadding: true,
    label: 'Status'
  }
]

export const UserTable = ({ rows, hasCognitoAdmin, handleEvent }) => {
  /**
   * Generates a table with user details in rows"
   *
   * @const
   * @param {object[]} rows - All existing user details
   * @param {string} rows.CompanyNames - Company names of the user
   * @param {string} rows.FirstName - User first name
   * @param {string} rows.LastName - User last name
   * @param {string} rows.Email - User email address
   * @param {string} rows.Roles - User roles
   * @param {string} rows.Enabled - If the User is enabled or not
   * @param {} handleEvent - Action to CRUD row
   *
   * @returns {object} - Table of Users
   */
  const theme = useTheme()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('Company')
  const { currentUser } = useCurrentUser()
  const writeableSites = currentUser.getSitesForScope(SCOPES.userManagement.Write)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer
      sx={{
        width: '100%',
        height: '100%',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table sx={{ minWidth: 750, minHeight: '0' }} size="small">
        <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {rows &&
            rows.sort(getObjectComparator(order, orderBy)).map((row) => {
              const menuItems = [{ title: 'Edit User', action: () => handleEvent('editUser', row) }]

              if (currentUser.isAdmin) {
                if (row?.Enabled) {
                  menuItems.push({ title: 'Disable User', action: () => handleEvent('disableUser', row) })
                } else {
                  menuItems.push({ title: 'Enable User', action: () => handleEvent('enableUser', row) })
                }
              }

              return (
                <TableRow key={row?.Username} hover tabIndex={-1} sx={{ height: '3em' }}>
                  <StyledTableCell>{row?.CompanyNames.join(', ') ?? '--'}</StyledTableCell>
                  <StyledTableCell>{row?.FirstName ?? '--'}</StyledTableCell>
                  <StyledTableCell>{row?.LastName ?? '--'}</StyledTableCell>
                  <StyledTableCell>{row?.Email ?? '--'}</StyledTableCell>
                  <TableCell sx={{ width: 'auto' }}>{row?.Roles?.join(', ') ?? '--'}</TableCell>
                  <StyledTableCell>
                    <Chip label={row?.Enabled ? 'ACTIVE' : 'DISABLED'} color={row?.Enabled ? 'success' : 'error'} />
                  </StyledTableCell>
                  {
                    <TableCell sx={{ width: '0' }}>
                      {hasCognitoAdmin &&
                        (currentUser.isAdmin ||
                          !row.SiteIds ||
                          row.SiteIds?.some((s) => writeableSites.includes(s))) && (
                          <ScopeRequirementAll requirements={[SCOPES.userManagement.Write]}>
                            <ContextMenu context={row} menuItems={menuItems} />
                          </ScopeRequirementAll>
                        )}
                    </TableCell>
                  }
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default UserTable
