import React from 'react'
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'

const CustomStack = ({ children }) => (
  <Stack
    minHeight="0"
    minWidth="0"
    overflow="hidden"
    gridRow="span 2"
    sx={{ display: 'flex', flexDirection: 'column', minHeight: '0', gap: '20px' }}>
    {children}
  </Stack>
)

export const DeckLayoutContainer = ({ Deck, headerTitle, buttons, topRightElement, bottomRightElement }) => {
  const { screen, deck } = useCurrentDeckContext()

  const theme = useTheme()
  return (
    <>
      <Grid
        container
        boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1);"
        position="sticky"
        padding=".4em"
        top={62}
        zIndex={1200}
        display="flex"
        bgcolor={theme.palette.supporting.dark}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row">
        <Grid item>
          <Typography variant="h1"> {headerTitle}</Typography>
        </Grid>
        <Grid item container gap={1} width="max-content">
          {buttons}
        </Grid>
      </Grid>
      <Box
        display="grid"
        height="100%"
        width="100%"
        columnGap="17px"
        sx={{
          maxHeight: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '2px',
            height: '2px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}
        minHeight="0"
        padding="1em"
        gridTemplateColumns="minmax(0, 50%) 50%"
        gridTemplateRows="180px"
        justifyContent="stretch"
        alignItems="stretch">
        <Box gridRow="span 2" minHeight="0" minWidth="0" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography className="title" variant="h5" component="h5" mb={2}>
            {`${screen.Name} - ${deck.DeckHeader.DeckLevel} ${deck.DeckHeader.MaterialNumber}`}
          </Typography>
          {Deck}
        </Box>
        <Box gridRow="span 2" display="grid" gridTemplateRows="minmax(0, 40%)">
          <CustomStack>{topRightElement}</CustomStack>
          <CustomStack>{bottomRightElement}</CustomStack>
        </Box>
      </Box>
    </>
  )
}
