/**
 *
 * "Dialogue table for Dialogue page"
 *
 * @file   DialogueTable.js
 * @author Lateral
 * @since  2023
 */

import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import StyledStatusChip from 'components/statusChip/StyledStatusChip'
import { observeDialogue } from 'hooks'
import AddNewDialogueModal from './AddNewDialogueModal'
import { FormDialog } from 'components'
import { styled, useTheme } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ReactComponent as ReplyIcon } from 'assets/icons/Reply.svg'
import { visuallyHidden } from '@mui/utils'
import { getObjectComparator } from 'common'
import dayjs from 'dayjs'
import { updateChildDialogue } from '../common/data'
import { dateFormat } from 'common/dates'

// Table cell style
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '140px',
    width: 'auto',
    height: 'auto'
  }
}))

// Headings of table
const headCells = [
  {
    id: 'Date',
    numeric: false,
    disablePadding: false,
    label: 'Date'
  },
  {
    id: 'User',
    numeric: false,
    disablePadding: false,
    label: 'User'
  },
  {
    id: 'Location',
    numeric: false,
    disablePadding: false,
    label: 'Location'
  },
  {
    id: 'Equipment',
    numeric: false,
    disablePadding: false,
    label: 'Screen'
  },
  {
    id: 'Area',
    numeric: false,
    disablePadding: false,
    label: 'Area'
  },
  {
    id: 'Type',
    numeric: false,
    disablePadding: false,
    label: 'Type'
  },
  {
    id: 'Description',
    numeric: false,
    disablePadding: false,
    label: 'Description'
  },
  {
    id: 'Comment',
    numeric: false,
    disablePadding: false,
    label: 'Comment'
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action'
  },
  {
    id: 'Outcome',
    numeric: false,
    disablePadding: false,
    label: 'Outcome'
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status'
  }
]

// Sortable table head
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ border: 'none' }}>
        <TableCell sx={{ width: '0' }} />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <TableCell sx={{ width: '0' }} />
        <TableCell sx={{ width: '0' }} />
      </TableRow>
    </TableHead>
  )
}

// Dialogue rows with hidden replies
function EnhancedRow({ row, setReplyOpen, setDialogueId, dialogueContextMenu }) {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <React.Fragment>
      <TableRow hover sx={{ height: '48px' }}>
        <TableCell sx={{ width: '0' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <StyledTableCell scope="row" sx={{ padding: '10px' }}>
          {dayjs(row.Date).isValid() ? dayjs(row?.Date).format(dateFormat) : ''}
        </StyledTableCell>
        <StyledTableCell>{row?.User ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Location ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Equipment ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Area ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Type ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Description ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Comment ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Action ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Outcome ?? ''}</StyledTableCell>
        <StyledTableCell>
          <StyledStatusChip status={row?.Status ?? ''} />
        </StyledTableCell>
        <TableCell sx={{ width: '0' }}>
          <IconButton
            aria-label="reply"
            size="small"
            onClick={() => [
              setReplyOpen({
                isOpen: true,
                title: 'Reply',
                buttonText: 'Create',
                data: row
              }),
              setDialogueId(row.id)
            ]}>
            <ReplyIcon style={{ height: '16px', width: '16px' }} />
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: '0' }}>
          <IconButton aria-label="submenu" onClick={(event) => setAnchorEl(event.currentTarget)}>
            <MoreHorizIcon sx={{ color: 'white' }} />
          </IconButton>
          {anchorEl &&
            React.cloneElement(dialogueContextMenu, {
              context: row,
              anchorEl: anchorEl,
              handleClose: () => setAnchorEl(null)
            })}
        </TableCell>
      </TableRow>
      {open && row.Children ? <ChildRows row={row.Children} /> : ''}
    </React.Fragment>
  )
}

// Reply dialogues hidden under original dialogue
function ChildRows({ row }) {
  if (row && row.length) {
    return row?.map((child, index) => {
      return <RenderChildRows child={child} key={index} />
    })
  }
  return <RenderChildRows child={row} />
}

// Render reply rows
function RenderChildRows({ child }) {
  return (
    <React.Fragment>
      <TableRow hover sx={{ height: '48px' }}>
        <TableCell sx={{ width: '0' }} />
        <StyledTableCell scope="row" sx={{ padding: '10px' }}>
          {dayjs(child.Date).isValid() ? dayjs(child?.Date).format(dateFormat) : ''}
        </StyledTableCell>
        <StyledTableCell>{child?.User ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Location ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Equipment ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Area ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Type ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Description ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Comment ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Action ?? ''}</StyledTableCell>
        <StyledTableCell>{child?.Outcome ?? ''}</StyledTableCell>
        <StyledTableCell />
        <TableCell sx={{ width: '0' }} />
        <TableCell sx={{ width: '0' }} />
      </TableRow>
    </React.Fragment>
  )
}

export function DialogueTable({
  dialogueContextMenu,
  customers,
  sites,
  locations,
  decks,
  equipment,
  getUser,
  searchText
}) {
  /**
   * Create Dialogue table
   *
   * @function
   *
   * @param {} dialogueContextMenu - Mini edit and delete menu for rows
   * @param {object[]} customers - Array of all company object
   * @param {object[]} location - Array of all location object
   * @param {object[]} sites - Array of all site object
   * @param {object[]} decks - Array of all deck location object
   * @param {object[]} equipment - Array of all screen object
   * @param {} getUser - Get usename of the creator
   *
   * @returns {object} - Dialogue table
   */
  const theme = useTheme()
  const dialogue = observeDialogue()
  const rows = getRowData()

  const [replyOpen, setReplyOpen] = useState({ isOpen: false })
  const [dialogueId, setDialogueId] = useState('')
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('Date')

  // Update reply after closing reply modal
  async function handleDialogueItemDialogClose(modalState) {
    if (modalState?.isSave) {
      const data = modalState.data
      data.User = getUser()
      data.Date = dayjs()
      await updateChildDialogue(data)
    }
    setReplyOpen({ isOpen: false })
  }

  // Get dialogue object from row
  function getRowData() {
    let data = dialogue.map((item) => {
      return {
        id: item?.id,
        Date: item?.Date,
        User: item?.User,
        Location: item.Location,
        Equipment: item.Equipment,
        Area: item?.Area,
        Type: item?.Type,
        Description: item?.Description,
        Comment: item?.Comment,
        Action: item?.Action,
        Outcome: item?.Outcome,
        Status: item?.Status,
        Children: item?.Children
      }
    })

    //Search dialogues by search text
    if (searchText) {
      const lowerSearchTexts = searchText.toLowerCase().split(' ')
      data = data.filter((d) =>
        lowerSearchTexts.every((l) =>
          JSON.stringify([
            d.Date ? dayjs(d.Date).format(dateFormat) : d.Date,
            d.User,
            d.Location,
            d.Equipment,
            d.Area,
            d.Type,
            d.Description,
            d.Comment,
            d.Action,
            d.Outcome,
            d.Status
          ])
            .toLowerCase()
            .includes(l)
        )
      )
    }

    return data
  }

  // Sort rows by selected property
  function handleRequestSort(e, property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          height: '100%',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <Table sx={{ minWidth: 750, minHeight: '0', p: 4 }} size="small">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {rows &&
              rows.sort(getObjectComparator(order, orderBy)).map((row) => {
                return (
                  <EnhancedRow
                    key={row.id}
                    row={row}
                    setReplyOpen={setReplyOpen}
                    setDialogueId={setDialogueId}
                    dialogueContextMenu={dialogueContextMenu}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {replyOpen.isOpen && (
        <FormDialog modalState={replyOpen} onOpenChange={handleDialogueItemDialogClose}>
          <AddNewDialogueModal
            customers={customers}
            locations={locations}
            sites={sites}
            decks={decks}
            equipment={equipment}
            id={dialogueId}
            isReply
          />
        </FormDialog>
      )}
    </>
  )
}
export default DialogueTable
