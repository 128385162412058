import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { Dialogue, Locations } from 'models'

/*
 * Observable for the Dialogue table.
 */
export function observeDialogue() {
  const [dialogue, setDialogue] = useState([])

  useEffect(() => {
    const retrieveData = async () => {
      const locations = await DataStore.query(Locations).then((locations) => {
        return locations.map((l) => l.Name)
      })
      const results = await DataStore.query(Dialogue, null, { sort: (s) => s.User(SortDirection.ASCENDING) }).then(
        (d) => {
          return d.filter((f) => locations.includes(f.Location))
        }
      )
      setDialogue(results)
    }

    const subscription = DataStore.observe(Dialogue).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return dialogue
}

export default observeDialogue
