/**
 *
 * "Provides the grid coordinates on the 3D canvas in text formate to print by row and column.
 * Row coordinates are numbers start from 1
 * Coulumn coordinates are letters start from A
 * Left side panel is LS
 * Right side panel is RS"
 *
 * @file   DeckGridCoordinates.js
 * @author Lateral
 * @since  2023
 */
import BigNumber from 'bignumber.js'
import React from 'react'
import { Text } from '@react-three/drei'
import { toAlphabet } from 'common/numberToAlphabet'

export function DeckGridCoordinates({ data, rotation }) {
  /**
   * Provides a cube object which is the simplest component 3D deck model.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {number} rotation - Deck rotation angles in 3D space in radian
   * @returns {Element} - React element of grid coordinates in 3D space
   */
  const size = data.Size
  const fontSize = 0.6

  // Helper function to render coordinates in grid
  function renderGrid() {
    const texts = []
    let totalXCoordinate = new BigNumber(-size.Columns).dividedBy(2)

    const yCoordinate = new BigNumber(size.Rows).dividedBy(2)
    const columnOffset = 1.5

    let columnTotal = new BigNumber(size.Columns)

    // Right sideliner coordinate
    texts.push(
      <Text
        key={`column-1`}
        color="white"
        fontSize={fontSize}
        position={[totalXCoordinate.minus(4).toNumber(), yCoordinate.plus(columnOffset).plus(1).toNumber(), 0.25]}
        anchorX="center"
        anchorY="middle">
        RS
      </Text>
    )

    // Column coordinates starts from A from right sideliner
    for (let i = 0; i < columnTotal.toNumber(); i++) {
      const xCoordinate = totalXCoordinate
      totalXCoordinate = totalXCoordinate.plus(1)
      texts.push(
        <Text
          key={`column${i}`}
          color="white"
          fontSize={fontSize}
          position={[xCoordinate.toNumber(), yCoordinate.plus(columnOffset).toNumber(), 0.25]}
          anchorX="center"
          anchorY="middle">
          {toAlphabet(new BigNumber(i).plus(1))}
        </Text>
      )
    }

    // Left sideliner coordinate
    texts.push(
      <Text
        key={`column${columnTotal}`}
        color="white"
        fontSize={fontSize}
        position={[totalXCoordinate.plus(2).toNumber(), yCoordinate.plus(columnOffset).plus(1).toNumber(), 0.25]}
        anchorX="center"
        anchorY="middle">
        LS
      </Text>
    )

    let totalYCoordinate = yCoordinate

    const sideXCoordinate = new BigNumber(-size.Columns).dividedBy(2)

    let rowTotal = new BigNumber(size.Rows)

    // Row coordinates starts from 1 from top
    for (let i = 0; i < rowTotal.toNumber(); i++) {
      const yCoordinate = totalYCoordinate
      totalYCoordinate = totalYCoordinate.minus(1)
      texts.push(
        <Text
          key={`row${i}`}
          color="white"
          fontSize={fontSize}
          position={[-sideXCoordinate.toNumber(), yCoordinate.toNumber(), 0.25]}
          anchorX="center"
          anchorY="middle">
          {new BigNumber(i).plus(1).toNumber()}
        </Text>
      )
    }

    return texts
  }

  return <group rotation={rotation}>{renderGrid()}</group>
}

export default DeckGridCoordinates
