import { useAreaSelection } from 'hooks/useAreaSelection'
import React, { createContext, useContext, useEffect, useState } from 'react'

export const SelectionContext = createContext({})

export const useSelectionContext = () => useContext(SelectionContext)

export const SelectionContextProvider = ({
  isMultiSelectEnabled,
  selectContainerRef,
  onPanelClick,
  selected: selectedPanel,
  selectedPanelSideRef,
  children
}) => {
  useEffect(() => {
    const selected = selectedPanel[0]
    if (!selected || selected === 'cancel') {
      return
    }
    if (selected?.Side) {
      selectedPanelSideRef.current = 'SideLiners'
    } else {
      selectedPanelSideRef.current = 'DeckPanels'
    }
  }, [selectedPanel?.[0]])

  const [selection, setSelection] = useAreaSelection({
    containerRef: selectContainerRef,
    isMultiSelectEnabled
  })

  useEffect(() => {
    if (!selectedPanelSideRef.current || !selection) {
      return
    }
    const panels = document.querySelectorAll(`[data-panel-type='${selectedPanelSideRef.current}']`)
    const selected = []
    for (const element of panels) {
      const elementPosition = element.getBoundingClientRect()

      const selectionDimensions = selection
      const isSelected = !(
        elementPosition.y + elementPosition.height < selectionDimensions.y ||
        elementPosition.y > selectionDimensions.y + selectionDimensions.height ||
        elementPosition.x + elementPosition.width < selectionDimensions.x ||
        elementPosition.x > selectionDimensions.x + selectionDimensions.width
      )
      if (isSelected) {
        const itemData = JSON.parse(element.dataset.item)
        selected.push(itemData)
      }
    }
    onPanelClick(selected)
  }, [selection])

  const [clickEvent, setClickEvent] = useState()

  return (
    <SelectionContext.Provider
      value={{
        selection,
        selectedPanelSideRef,
        setSelection,
        clickEvent,
        setClickEvent
      }}>
      {children}
    </SelectionContext.Provider>
  )
}
