/**
 *
 * "Scope types in DeckMapp"
 *
 * @file   Scopes.js
 * @author Lateral
 * @since  2023
 */
export const SCOPES = {
  /**
   * Types of scope in deck canvas to use
   *
   * @constant
   */
  deckLayout: {
    Name: 'DeckLayout',
    Read: 'DeckLayout.Read'
  },
  deckMaintenance: {
    Name: 'DeckMaintenance',
    Read: 'DeckMaintenance.Read',
    Write: 'DeckMaintenance.Write'
  },
  deckLayoutReview: {
    Name: 'DeckLayoutReview',
    Read: 'DeckLayoutReview.Read',
    Write: 'DeckLayoutReview.Write'
  },
  reporting: {
    Name: 'Reporting',
    Read: 'Reporting.Read',
    Write: 'Reporting.Write'
  },
  heatmap: {
    Name: 'Heatmap',
    Read: 'Heatmap.Read'
  },
  wearapp: {
    Name: 'Wearapp',
    Read: 'Wearapp.Read'
  },
  siteManagement: {
    Name: 'SiteManagement',
    Read: 'SiteManagement.Read',
    Write: 'SiteManagement.Write'
  },
  userManagement: {
    Name: 'UserManagement',
    Read: 'UserManagement.Read',
    Write: 'UserManagement.Write',
    RequireAdmin: true
  },
  roleManagement: {
    Name: 'RoleManagement',
    Read: 'RoleManagement.Read',
    Write: 'RoleManagement.Write',
    RequireAdmin: true
  },
  materialManagement: {
    Name: 'MaterialManagement',
    Read: 'MaterialManagement.Read'
  }
}
