import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { HelpContacts } from 'models'

const lowerCaseFirstLetter = (str) => str[0].toLowerCase() + str.slice(1)

/*
 * Observable for the HelpContacts table.
 */
export function observeHelpContacts() {
  const [helpContacts, setHelpContacts] = useState([])

  useEffect(() => {
    const retrieveData = async () => {
      const helpContacts = await DataStore.query(HelpContacts, null, { sort: (s) => s.Name(SortDirection.ASCENDING) })

      const helpContactsCamelCase = helpContacts.map((obj) =>
        Object.fromEntries(Object.entries(obj).map(([k, v]) => [lowerCaseFirstLetter(k), v]))
      )
      setHelpContacts(helpContactsCamelCase)
    }

    const subscription = DataStore.observe(HelpContacts).subscribe(retrieveData)
    retrieveData()

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return helpContacts
}

export default observeHelpContacts
