/**
 *
 * "A form to add/edit Contact in Help page.""
 *
 * @file   ContactItemForm.js
 * @author Lateral
 * @since  2023
 */

import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export const ContactItemForm = ({ data, onSubmit }) => {
  /**
   * Generates a form to add or edit help category
   *
   * @const
   *
   * @param {object} data - Existing contact for edit
   * @param {string} data.id - Contact ID
   * @param {string} data.category - Help category of contact
   * @param {string} data.name - Name of contact
   * @param {string} data.email - Email of conatct
   * @param {string} data.phone - Phone number of contact
   * @param {string} data.mobile - Mobile number of contact
   * @param {string} data.address1 - Conatct affress line 1
   * @param {string} data.address2 - Conatct affress line 2
   * @param {} onSubmit - Action on submitting form
   *
   * @returns {object} - A form object
   */
  const defaultValues = {
    id: data?.id ?? null,
    category: data?.category,
    name: data?.name ?? '',
    email: data?.email ?? '',
    phone: data?.phone ?? '',
    mobile: data?.mobile ?? '',
    address1: data?.address1 ?? '',
    address2: data?.address2 ?? ''
  }
  const { register, handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5">Name</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="name"
            name="name"
            placeholder="e.g. Matt Smith"
            color="secondary"
            fullWidth
            {...register('name', { required: true })}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5">Email</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="email"
            name="email"
            placeholder="e.g. matt@mail.com"
            color="secondary"
            fullWidth
            {...register('email', { required: true })}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5">Phone</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="phone"
            name="phone"
            placeholder="e.g. +61 2 9876 5432"
            color="secondary"
            fullWidth
            {...register('phone', { required: true })}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5">Mobile</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="mobile"
            name="mobile"
            placeholder="e.g. +61 432 198 765"
            color="secondary"
            fullWidth
            {...register('mobile', { required: true })}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5">Address</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="address1"
            name="address1"
            placeholder="e.g. 11 Hill St"
            color="secondary"
            fullWidth
            {...register('address1', { required: true })}
          />
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="address2"
            name="address2"
            placeholder="e.g. City, State, Postcode"
            color="secondary"
            fullWidth
            {...register('address2', { required: true })}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default ContactItemForm
