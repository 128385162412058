/**
 *
 * "Provides a group object consists of sideliners and panels, \
 * and control their zoom in 2D plane for mobile version"
 *
 * @file   mobileDeckCanvas\DeckModel.js
 * @author Lateral
 * @since  2023
 */
import { Box, Stack, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { DeckPanels } from './DeckPanels'
import { RowNumbers } from './RowNumbers'
import { SideLiner } from './SideLiners'

const scale = 1

export function DeckModel({
  data,
  maintenance,
  materialSelected,
  onPanelClick,
  mode,
  presentation = false,
  heatMapData,
  selected
}) {
  /**
   * Provides a 2D structure with sideliners and panels and control their zoom and rotation.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {number} materialSelected - Material number
   * @param {Function} onPanelClick - Action on clicking panel
   * @param {object[]} selected - Array of selected panel objects
   * @param {string} mode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @param {boolean} presentation - Mintenance mode presentation True to view higher contrast  text
   *
   * @returns {object} - React box element of deck model with deck components
   */

  const sideLiners = useMemo(() => {
    if (!data) {
      return {
        left: [],
        right: []
      }
    }
    const sideLiners = structuredClone(data.SideLiners).sort((a, b) => a.StartPosition - b.StartPosition)
    return { left: sideLiners.filter((d) => d.Side === 'Left'), right: sideLiners.filter((d) => d.Side === 'Right') }
  }, [data?.SideLiners])
  return (
    <Box sx={{ height: '100%' }}>
      <Stack spacing={1} sx={{ textAlign: 'center', paddingTop: '1em' }}>
        <Typography variant="h5" sx={presentation ? { color: 'black' } : null}>
          Feed End
        </Typography>
        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <Typography variant="h5" sx={[{ marginRight: '0.5em' }, presentation ? { color: 'black' } : null]}>
            RHS
          </Typography>
          <RowNumbers scale={scale} totalRows={data.Size.Rows} presentation={presentation} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SideLiner
              data={sideLiners.right}
              kits={data.Kits}
              maintenance={maintenance}
              scale={scale}
              side="left"
              materialSelected={materialSelected}
              onPanelClick={onPanelClick}
              mode={mode}
              presentation={presentation}
              heatMapData={heatMapData}
              selected={selected}
            />

            <DeckPanels
              data={data}
              maintenance={maintenance}
              materialSelected={materialSelected}
              scale={scale}
              onPanelClick={onPanelClick}
              mode={mode}
              presentation={presentation}
              heatMapData={heatMapData}
              selected={selected}
            />

            <SideLiner
              data={sideLiners.left}
              kits={data.Kits}
              maintenance={maintenance}
              scale={scale}
              side="right"
              materialSelected={materialSelected}
              onPanelClick={onPanelClick}
              mode={mode}
              presentation={presentation}
              heatMapData={heatMapData}
              selected={selected}
            />
          </Box>
          <Typography variant="h5" sx={[{ marginLeft: '0.5em' }, presentation ? { color: 'black' } : null]}>
            LHS
          </Typography>
        </Stack>
        <Typography variant="h5" sx={presentation ? { color: 'black' } : null}>
          Discharge End
        </Typography>
      </Stack>
    </Box>
  )
}

export default DeckModel
