/**
 *
 * "Main table to view Screens"
 *
 * @file   ScreenTable.js
 * @author Lateral
 * @since  2023
 */

import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import { styled, useTheme } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { visuallyHidden } from '@mui/utils'
import DeckTable from './DeckTable'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { getObjectComparator } from 'common'
import { SCOPES, ScopeRequirementAll } from 'components'

// Table cell style
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80px',
    width: '7%'
  }
}))

// Table headings
const headCells = [
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Screen Name'
  },
  {
    id: 'Company',
    numeric: false,
    disablePadding: false,
    label: 'Company'
  },
  {
    id: 'Site',
    numeric: false,
    disablePadding: false,
    label: 'Site'
  },
  {
    id: 'Location',
    numeric: false,
    disablePadding: false,
    label: 'Location'
  },
  {
    id: 'DWGNumber',
    numeric: false,
    disablePadding: false,
    label: 'DWG #'
  },
  {
    id: 'SchenckSerial',
    numeric: false,
    disablePadding: false,
    label: 'Sandvik Serial #'
  },
  {
    id: 'SiteSerial',
    numeric: false,
    disablePadding: false,
    label: 'Serial #'
  },
  {
    id: 'DeckLevels',
    numeric: false,
    disablePadding: false,
    label: 'Levels'
  },
  {
    id: 'ScreenBody',
    numeric: false,
    disablePadding: false,
    label: 'Body'
  },
  {
    id: 'Exciters',
    numeric: false,
    disablePadding: false,
    label: 'Exciters'
  },
  {
    id: 'DateInstalled',
    numeric: false,
    disablePadding: false,
    label: 'Installed'
  },
  {
    id: 'ScreenTonnage',
    numeric: false,
    disablePadding: false,
    label: 'Tonnage'
  },
  {
    id: 'ScreenCapacity',
    numeric: false,
    disablePadding: false,
    label: 'Capacity'
  },
  {
    id: 'MaintenanceFrequency',
    numeric: false,
    disablePadding: false,
    label: 'Maintenance'
  }
]

// Table sortable heading
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ border: 'none' }}>
        <TableCell sx={{ width: '0' }} />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <TableCell sx={{ width: '0' }} />
      </TableRow>
    </TableHead>
  )
}

// Add enhanced funtionality on table row
function EnhancedRow({ row, screenContextMenu, onEditDeck, onCloneDeck }) {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <React.Fragment>
      <TableRow hover tabIndex={-1} sx={{ height: '48px' }}>
        <TableCell sx={{ width: '0' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell scope="row" padding="none">
          {row?.Name}
        </TableCell>
        <TableCell>{row?.Company ?? ''}</TableCell>
        <TableCell>{row?.Site ?? ''}</TableCell>
        <TableCell>{row?.Location ?? ''}</TableCell>
        <StyledTableCell>{row?.DWGNumber ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.SchenckSerial ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.SiteSerial ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Decks?.length ?? 0}</StyledTableCell>
        <StyledTableCell>{row?.Screen ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.Exciters ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.DateInstalled}</StyledTableCell>
        <StyledTableCell>{row?.ScreenTonnage ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.ScreenCapacity ?? ''}</StyledTableCell>
        <StyledTableCell>{row?.MaintenanceFrequency ?? ''}</StyledTableCell>
        <TableCell sx={{ width: '0' }}>
          <ScopeRequirementAll requirements={[SCOPES.deckLayoutReview.Write]} siteId={row.SiteId}>
            <IconButton aria-label="submenu" onClick={(event) => setAnchorEl(event.currentTarget)}>
              <MoreHorizIcon sx={{ color: 'white' }} />
            </IconButton>

            {anchorEl &&
              React.cloneElement(screenContextMenu, {
                context: row,
                anchorEl: anchorEl,
                handleClose: () => setAnchorEl(null)
              })}
          </ScopeRequirementAll>
        </TableCell>
      </TableRow>
      <TableRow sx={{ border: 'none' }}>
        <TableCell colSpan={16}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {row.Decks && row.Decks.length ? (
                <DeckTable onEditDeck={onEditDeck} onCloneDeck={onCloneDeck} decks={row.Decks} />
              ) : (
                <Typography variant="body2">This screen has no decks</Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export function ScreenTable({ items, screenContextMenu, onEditDeck, onCloneDeck }) {
  /**
   * A table of Screens for View Screen Assets page
   *
   * @funtion
   *
   * @param {object[]} items - Array of Screen objects
   * @param {string} items.id - Row ID in Screens table
   * @param {string} items.Name - Screen name
   * @param {string} items.customerName - Comapny name
   * @param {string} items.customerId - Comapny ID
   * @param {string} items.customerName - Comapny name
   * @param {string} items.customerName - Comapny name
   * @param {string} items.id - Row ID in Screens table
   * @param {string} items.locationId - ID of screen location
   * @param {string} items.ScreenId - ID of screen
   * @param {string} items.DWGNumber - Scheck DWG number
   * @param {string} items.SchenckSerial - Scheck serial number
   * @param {string} items.SiteSerial - Site serial number
   * @param {string} items.Exciters - Esciter number
   * @param {object} items.DateInstalled - Date of installation
   * @param {string} items.ScreenTonnage - Tonnage of screen in tone
   * @param {string} items.ScreenCapacity - Capacity of screen in tone
   * @param {string} items.MaintenanceFrequency - Grequency of Maintenance
   * @param {string} items.siteName - Site name
   * @param {string} items.locationName - Location name
   * @param {string} items.screenName - Screen name
   * @param {string} items.ScreenBody - Screen body type
   * @param {object[]} items.decks - Decks in the screen
   * @param {} screenContextMenu - Mini menu for rows
   * @param {} onEditDeck - Action on clicking Edit Details
   * @param {} onCloneDeck - Action on clicking clone on deck menu
   *
   * @returns {object} - A table to view assets
   */
  const theme = useTheme()

  let rows = items.map((item) => {
    return {
      id: item?.id,
      Name: item?.Name,
      Company: item?.customerName,
      CustomerId: item?.customerId,
      Site: item?.siteName,
      SiteId: item?.siteId,
      Location: item?.locationName,
      LocationId: item?.locationId,
      Screen: item?.screenName,
      ScreenId: item?.screenId,
      DWGNumber: item?.DWGNumber,
      SchenckSerial: item?.SchenckSerial,
      SiteSerial: item?.SiteSerial,
      ScreenBody: item?.ScreenBody,
      Exciters: item?.Exciters,
      DateInstalled: item?.DateInstalled,
      ScreenTonnage: item?.ScreenTonnage,
      ScreenCapacity: item?.ScreenCapacity,
      MaintenanceFrequency: item?.MaintenanceFrequency,
      Decks: item?.decks
    }
  })

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('Name')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer
      sx={{
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table stickyHeader sx={{ minWidth: 750, minHeight: '0', p: 4 }} size="small">
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {rows &&
            rows.sort(getObjectComparator(order, orderBy)).map((row) => {
              return (
                <EnhancedRow
                  key={row.id}
                  row={row}
                  screenContextMenu={screenContextMenu}
                  onEditDeck={onEditDeck}
                  onCloneDeck={onCloneDeck}
                />
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default ScreenTable
