/**
 *
 * "sorts incoming comparative data (a, b) by their field name (orderBy)"
 *
 * @file   equality.js
 * @author Lateral
 * @since  2023
 */

import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'

const descendingComparator = (a, b, orderBy) => {
  /**
   * @constant
   * Define return comparator to compare a and b for
   * sorting regardless of data type
   */
  let first = a[orderBy]
  let second = b[orderBy]

  if (first === null || first === undefined) {
    first = ''
  }
  if (second === null || second === undefined) {
    second = ''
  }

  //If a and b are numbers if a>b positive returns else returns negative
  if (!isNaN(first) && !isNaN(second)) {
    return new BigNumber(second).minus(first).toNumber()
  }

  const aDate = dayjs(first)
  const bDate = dayjs(second)

  //if a and b are dates return dates in chronological roder
  if (aDate.isValid() && bDate.isValid()) {
    return bDate.isAfter(aDate) ? 1 : -1 //Boolian to sort dates in chronological order
  }

  //Else compare a and b in string
  return first.toString().toLowerCase().localeCompare(second.toString().toLowerCase())
}

export const getObjectComparator = (order, orderBy) => {
  /**
   *  Convert any string to a hexadecimal colour code
   *
   * @constant
   * @param {order} string - Order of sort ('desc' or else)
   * @param {order} orderBy - base of sort ('name'/'date'...)
   * @returns {number} - Sorted row/objects
   */
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
