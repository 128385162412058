/**
 *
 * "The Dashboard page. First page viewed by a logged in User."
 *
 * @file   DashboardPage.js
 * @author Lateral
 * @since  2023
 */

import React, { useContext } from 'react'
import { Box, Stack, Typography, Button, styled, Grid, useTheme } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DESKTOP_MIN_WIDTH } from 'common/config'
import { observeDialogue, useCurrentUser } from 'hooks'
import RecentActivityTable from 'components/tables/history/RecentActivityTable'
import CondensedDialogueTable from 'components/tables/dialogue/CondensedDialogueTable'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { Header, SCOPES, ScopeRequirementAll } from 'components'

const DashboardButton = styled(Button)(({ theme }) => {
  /**
   *  Dashboard button styling
   *
   * @function
   * @returns {object} - Button style
   */
  return {
    color: theme.palette.secondary.main,
    background: '#242424',
    width: '100%',
    height: '96px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '2px'
  }
})

function DashboardPage() {
  /**
   *  Create dashboard page layout
   *
   * @function
   * @returns {object} - Dashboard page
   */
  const { database } = useContext(CurrentDeckContext)
  const dialogue = observeDialogue()
  const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH)
  const { currentUser } = useCurrentUser()
  const theme = useTheme()
  return (
    <Box
      className="dashboard"
      sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%', px: '15px', pb: '15px' }}>
      <Header />
      <Box bgcolor={theme.palette.supporting.dark}>
        <Typography variant="h4" component="h3">
          {`Welcome back, ${currentUser.attributes['given_name']}`}
        </Typography>
      </Box>

      {isDesktop ? (
        <Grid container mt={4}>
          <Grid
            height="min-content"
            bgcolor={theme.palette.supporting.dark}
            boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1);"
            position="sticky"
            top={56}
            zIndex={100}
            pt={2}
            pb={2}
            container
            gap={2}
            item>
            <Button variant="contained" color="secondary" component={RouterLink} to="/equipment/screens">
              View Screen Assets
            </Button>
            <Button variant="contained" color="secondary" component={RouterLink} to="/equipment/decklayout">
              Deck Layout
            </Button>
            <ScopeRequirementAll requirements={[SCOPES.deckMaintenance.Write]}>
              <Button variant="contained" color="secondary" component={RouterLink} to="/equipment/maintenance">
                Maintenance
              </Button>
            </ScopeRequirementAll>
            <ScopeRequirementAll requirements={[SCOPES.deckLayoutReview.Write]}>
              <Button variant="contained" color="secondary" component={RouterLink} to="/equipment/edit">
                Edit Deck
              </Button>
            </ScopeRequirementAll>
            <ScopeRequirementAll requirements={[SCOPES.heatmap.Read]}>
              <Button variant="contained" color="secondary" component={RouterLink} to="/equipment/heatmap">
                Heat Map
              </Button>
            </ScopeRequirementAll>
            <Button variant="contained" color="secondary" component={RouterLink} to="/equipment/history">
              History
            </Button>
          </Grid>
          <Grid gap={2} item container flexDirection="row" justifyContent="space-between" mt={4} flexWrap="nowrap">
            <Grid sm={6} item>
              <RecentActivityTable
                decks={database.decks}
                deckRevisions={database.deckRevisions}
                deckRevisionHistories={database.deckRevisionHistories}
                sites={database.sites}
                locations={database.locations}
                isMobile={false}
              />
            </Grid>
            <Grid sm={6} item>
              <CondensedDialogueTable dialogue={dialogue} isMobile={false} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Stack spacing={2} direction="column" mt="2em">
          <DashboardButton component={RouterLink} to="/mobile/decklayout">
            Deck Layout
          </DashboardButton>
          <DashboardButton component={RouterLink} to="/mobile/maintenance">
            Maintenance
          </DashboardButton>
        </Stack>
      )}
    </Box>
  )
}
export default DashboardPage
