import React from 'react'
import { useWearAppContext } from '../../WearAppContext'
import { useWearAppAnalysisContext } from '../../WearAppAnalysisContext'

export const ReplacePanelResults = ({ children, detail }) => {
  const { activeTab } = useWearAppContext()
  const {
    currentAnalysisResultsRef: {
      current: { replacePanel }
    }
  } = useWearAppAnalysisContext()
  return (
    <>
      <div
        style={{
          backgroundColor: replacePanel
            ? replacePanel.replacePanel === 'replace'
              ? 'red'
              : replacePanel.replacePanel === 'do_not_repalce'
              ? 'green'
              : 'gray'
            : 'gray',
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: replacePanel ? 'row' : 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: replacePanel ? '0 1em' : '1em 1em'
        }}>
        {replacePanel && (
          <p>
            {replacePanel?.replacePanel === 'replace'
              ? 'REPLACE PANEL: EXCESSIVE WEAR'
              : replacePanel?.replacePanel === 'do_not_repalce'
              ? 'PANEL DOES NOT NEED REPLACEMENT'
              : 'UNABLE TO DETERMINE IF PANEL REQUIRES REPLACEMENT'}
          </p>
        )}
        {children}
      </div>
      {detail && (
        <>
          <div
            style={{
              backgroundColor: 'lightgray',
              fontWeight: 'bold',
              color: 'black',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0px 16px',
              justifyItems: 'center',
              alignItems: 'center'
            }}>
            <p>Next Shutdown:</p>
            <span>
              {replacePanel?.daysToNextShutdown
                ? `${replacePanel.daysToNextShutdown} ${replacePanel.daysToNextShutdown > 1 ? 'days' : 'day'}`
                : '-'}
            </span>
          </div>
          <div
            style={{
              backgroundColor: 'white',
              fontWeight: 'bold',
              color: 'black',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0px 16px',
              justifyItems: 'center'
            }}>
            <p>Remaining Life:</p>
            <p>
              {replacePanel?.remainingLife
                ? `${replacePanel.remainingLife} ${replacePanel.remainingLife > 1 ? 'days' : 'day'}`
                : '-'}
            </p>
          </div>
          {activeTab === 1 && (
            <div
              style={{
                backgroundColor: 'lightgray',
                fontWeight: 'bold',
                color: 'black',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '0px 16px',
                justifyItems: 'center'
              }}>
              <p>Panel Wear Rate:</p>
              <p> {replacePanel?.wearRate ? `${replacePanel.wearRate.toFixed(2)} mm/day` : '-'}</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
