/**
 *
 * "Create a context hook for notification in cheldrens"
 *
 * @file   NotificationProvider.js
 * @author Lateral
 * @since  2023
 */
import { ConfirmationDialog } from 'components'
import React, { useState, createContext, useContext, useRef } from 'react'

import { SimpleSnackbar } from './SimpleSnackbar'

const SnackbarContext = createContext()

const NotificationProvider = ({ children }) => {
  /**
   *
   * Generates sncakbar notification
   *
   * @const
   * @param {React.ElementType} children - React sncakbar component to
   *                                       show notification
   *
   * @returns {object} - React context hook for notification
   */
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [message, setMessage] = useState('')

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isConfirmDialogEventArgs, setIsConfirmDialogEventArgs] = useState()

  const confirmDialogRef = useRef()

  const callback = () => {
    setIsSnackbarOpen(false)
  }

  const notify = (message) => {
    setMessage(message)
    setIsSnackbarOpen(true)
  }

  const confirm = (eventArgs, onReponseCallback) => {
    confirmDialogRef.current = onReponseCallback
    setIsConfirmDialogEventArgs(eventArgs)
    setIsConfirmDialogOpen(true)
  }

  const closeConfirm = (action) => {
    setIsConfirmDialogOpen(false)
    confirmDialogRef.current(isConfirmDialogEventArgs, action)
  }

  const value = { notify, confirm }
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {<SimpleSnackbar message={message} isOpen={isSnackbarOpen} onClose={callback} />}
      {<ConfirmationDialog isOpen={isConfirmDialogOpen} onClose={closeConfirm} />}
    </SnackbarContext.Provider>
  )
}

const useNotification = () => {
  /**
   *
   * React hook for notification in only snackbar context
   *
   * @const
   *
   * @returns {object} - Notification context
   */
  const context = useContext(SnackbarContext)

  if (context === undefined) {
    throw new Error('useNotification must be used within an SnackbarProvider')
  }

  const value = { ...context }
  return value
}

export { NotificationProvider, useNotification }
