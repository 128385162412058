import replacement from 'assets/img/Replace.png'
import rotate from 'assets/img/Rotate.png'
import swap from 'assets/img/Swap.png'
import relocateFrom from 'assets/img/Relocate-From.png'
import relocateTo from 'assets/img/Relocate-To.png'
import replacementSvg from 'assets/icons/Replace.svg'
import rotateSvg from 'assets/icons/Rotate.svg'
import swapSvg from 'assets/icons/Swap.svg'
import relocateFromSvg from 'assets/icons/Relocate-From.svg'
import relocateToSvg from 'assets/icons/Relocate-To.svg'
import viewResultSvg from 'assets/icons/ViewResult.svg'
import viewResult from 'assets/icons/ViewResult.png'
import wear0 from 'assets/img/Wear0.jpg'
import wear20 from 'assets/img/Wear20.jpg'
import wear40 from 'assets/img/Wear40.jpg'
import wear60 from 'assets/img/Wear60.jpg'
import wear80 from 'assets/img/Wear80.jpg'
import empty from 'assets/img/Empty.png'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three'

/*
 * Hook for some essentials in Maintenance.
 */
export function useMaintenance() {
  const [replacementMap, relocateToMap, rotateMap, swapMap, relocateFromMap, emptyMap, viewResultMap] = useLoader(
    TextureLoader,
    [replacement, relocateTo, rotate, swap, relocateFrom, empty, viewResult]
  )

  const actions = {
    MarkForReplacement: {
      id: 0,
      img: replacement,
      svg: replacementSvg,
      state: 'Marked For Replacement',
      texture: replacementMap
    },
    Replace: {
      id: 1,
      img: replacement,
      svg: replacementSvg,
      state: 'Marked For Replacement',
      texture: replacementMap
    },
    Revert: {
      id: 2,
      img: relocateTo,
      svg: relocateToSvg,
      state: 'Marked For Reversion',
      texture: relocateToMap
    },
    Rotate: {
      id: 3,
      img: rotate,
      svg: rotateSvg,
      state: 'Marked For Rotating',
      texture: rotateMap
    },
    Swap: {
      id: 4,
      img: swap,
      svg: swapSvg,
      state: 'Marked For Swapping',
      texture: swapMap
    },
    Relocate: {
      id: 5,
      img: relocateFrom,
      svg: relocateFromSvg,
      state: 'Marked For Relocation',
      texture: relocateFromMap
    },
    RelocateTo: {
      id: 6,
      img: relocateTo,
      svg: relocateToSvg,
      state: 'Marked For Relocating To',
      texture: relocateToMap
    },
    NoChange: {
      id: 7,
      img: empty,
      svg: empty,
      state: 'No Action Required',
      texture: emptyMap
    },
    ViewWearAppResult: {
      id: 8,
      img: viewResult,
      svg: viewResultSvg,
      state: 'WearApp Result Available',
      texture: viewResultMap
    }
  }

  const wearColours = {
    zero: {
      img: wear0
    },
    twenty: {
      img: wear20
    },
    forty: {
      img: wear40
    },
    sixty: {
      img: wear60
    },
    eighty: {
      img: wear80
    }
  }

  function getAction(id) {
    const options = Object.values(actions)
    if (id < options.length) {
      return options[id]
    }

    return {}
  }

  return {
    actions,
    getAction,
    wearColours
  }
}
