import { useState, useEffect } from 'react'
import { DataStore, SortDirection } from 'aws-amplify'
import { Equipment, Locations } from 'models'

/*
 * Observable for the Equipment table.
 */
export function observeEquipment() {
  const [equipment, setEquipment] = useState([])
  useEffect(() => {
    const subscription = DataStore.observeQuery(Equipment, null, {
      sort: (s) => s.Name(SortDirection.ASCENDING)
    }).subscribe(async (e) => {
      if (e.isSynced) {
        //filter based on known sites.
        const locations = await DataStore.query(Locations).then((locations) => {
          return locations.map((l) => l.id)
        })
        setEquipment(e.items.filter((f) => locations.includes(f.LocationId)))
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return equipment
}

export default observeEquipment
