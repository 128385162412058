/**
 *
 * "Main module to generate deck canvas for mobile in 2D.
 * Only required for layout and Maintenance."
 *
 * @file   mobileDeckCanvas\DeckCanvas.js
 * @author Lateral
 * @since  2023
 */

import { Button, Fab, Grid, Paper, Typography, Zoom, IconButton, useTheme, Tooltip } from '@mui/material'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import DeckModel from './components/DeckModel/DeckModel'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import BigNumber from 'bignumber.js'
import { useMaintenance } from 'hooks'
import { DeckMode } from 'common/deckMode'
import MaintenanceSelector from 'components/maintenanceSelector/MaintenanceSelector'

import { ReactComponent as UndoSvg } from 'assets/icons/Undo.svg'
import { ReactComponent as RedoSvg } from 'assets/icons/Redo.svg'
import { InfoSharp } from '@mui/icons-material'
import { useKeyPress } from 'hooks/useKeyboardShortcut'
import uniqWith from 'lodash.uniqwith'
import { isPanelTheSame } from 'pages/common/maintenance/logic'
import { SelectionContextProvider, useSelectionContext } from 'components/selection/SelectionContext'

const canvasHeight = new BigNumber(window.innerHeight).times(0.65)

const Container = ({
  onPanelClick,
  isMultiSelectEnabled,
  selectContainerRef,
  group,
  unSelectGroup,
  reset,
  children
}) => {
  const { setSelection } = useSelectionContext()
  return (
    <Paper
      ref={selectContainerRef}
      id="panels-container-1"
      onClick={() => {
        if (!isMultiSelectEnabled) {
          if (group.current === 0) {
            reset()
            onPanelClick('cancel')
          } else {
            unSelectGroup()
          }

          setSelection(undefined)
        }
      }}
      elevation={8}
      sx={{
        marginTop: '0.1em',
        width: '100%',
        height: `${canvasHeight}px`,
        backgroundColor: 'primary.dark',
        backgroundImage: 'none',
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none'
      }}>
      {children}
    </Paper>
  )
}

export const DeckCanvas = forwardRef(function DeckCanvas_(
  {
    data,
    maintenance,
    onPanelClick,
    onMaintenanceTypeClick,
    selected,
    materialSelected,
    mouseCanvasPosition,
    onWearSubmit,
    awaitingActionId,
    cancelAwaitingAction,
    mode,
    onSwitchView,
    heatMapData,
    onUndo,
    isHistoryEmpty,
    onRedo,
    isHistoryAtEnd,
    saveDeckRevision,
    onSelectChange,
    type
  },
  ref
) {
  /**
   * Generates 2D deck canvas
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {} onPanelClick - Action to click on a panel/sideliners. Same action applicable for panels and sideliners
   * @param {} onMaintenanceTypeClick - Action to click on Maintenance type in Maintenance mode
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} materialSelected - Selected sideliner material number
   * @param {object} mouseCanvasPosition - x and y coordinates of pointer on canvas
   * @param {} onMaintenanceTypeClick - Action on changing panel/sideliner selection
   * @param {} onWearSubmit - Action on submiting wear in Maintenance mode
   * @param {number} awaitingActionId - id of await action for asynch operation
   * @param {number} cancelAwaitingAction - Id of await action when click cancel in maintenance mode
   * @param {string} mode - Current mode of deck layout
   * @returns {object} - React element of 3D deck canvas
   */
  const initialScale = Math.min(new BigNumber(1).dividedBy(data.Size.Rows).times(25).toNumber(), 1)
  const { actions } = useMaintenance()
  const theme = useTheme()
  const [isInserting, setIsInserting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const hasUsedKits =
    data?.Panels.filter((p) => p.MaterialNumber !== undefined && p.MaterialNumber !== null)?.length > 0 ||
    data?.SideLiners.filter((s) => s.MaterialNumber !== undefined && s.MaterialNumber !== null)?.length > 0

  const canShowDeleteButton = mode === DeckMode.edit && saveDeckRevision && !hasUsedKits

  const [group, setGroup] = useState({
    current: 0,
    0: []
  })

  useEffect(() => {
    return () => {
      onPanelClick('cancel')
    }
  }, [])

  const reset = useCallback(() => {
    setGroup({
      current: 0,
      0: []
    })
    selectedPanelSideRef.current = undefined
  }, [])

  useEffect(() => {
    if (mode == 'default') {
      reset()
    }
  }, [mode, reset])

  function onCancel() {
    setIsInserting(false)
    setIsDeleting(false)
  }
  const selectedPanelSideRef = useRef(undefined)

  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false)
  const canMultiSelect = type !== 'report' && isMultiSelectEnabled && mode !== 'default' && selectedPanelSideRef.current

  useEffect(() => {
    if (canMultiSelect && group[group.current].length > 1 && selectedPanelSideRef.current) {
      setGroup((state) => {
        const current = state.current + 1
        return {
          ...state,
          [current]: [],
          current
        }
      })
    }
  }, [canMultiSelect, group.current])

  const selectContainerRef = useRef(null)

  const getUniquePanels = useCallback((data) => {
    const objects = {}
    for (const item of data) {
      const key =
        typeof item.StartPosition === 'undefined'
          ? `${item.Position.Row}-${item.Position.Column}`
          : `${item.StartPosition}-${item.Side}`

      if (!(key in objects)) {
        objects[key] = item
      }
    }

    return Object.values(objects)
  }, [])

  const onPanelClick_ = useCallback(
    (data) => {
      data = Array.isArray(data) ? data : [data]
      if (awaitingActionId === actions.Swap.id || awaitingActionId === actions.Relocate.id || type === 'report') {
        onPanelClick(data)
        return
      }
      setGroup((state) => {
        const groupedData = Object.keys(state)
          .flatMap((key) => {
            return [...(Array.isArray(state[key]) ? state[key] : [])]
          })
          .concat(data)
        // const allSelected = uniqWith(
        //   Object.keys(state)
        //     .flatMap((key) => {
        //       return [...(Array.isArray(state[key]) ? state[key] : [])]
        //     })
        //     .concat(data),
        //   (item1, item2) => {
        //     if (['Right', 'Left'].includes(item1.Side) || ['Left', 'Right'].includes(item2.Side)) {
        //       return false
        //     }
        //     return isPanelTheSame(item1, item2)
        //   }
        // )
        onPanelClick(getUniquePanels(groupedData))

        let data_ = data
        if (state.current > 0) {
          const previousData = state[state.current - 1]
          data_ = getUniquePanels(data.concat(previousData).concat(state[state.current]))
        }

        return {
          ...state,
          [state.current]: data_
        }
      })
    },
    [onPanelClick, awaitingActionId, actions, type, getUniquePanels]
  )

  const unSelectGroup = useCallback(() => {
    if (group.current === 0) {
      return
    }
    setGroup((state) => {
      const current = state.current
      const newState = {}
      for (let i = 0; i < current; i++) {
        newState[i] = state[i]
      }
      onPanelClick(Object.values(newState).flat())
      return { ...newState, current: current - 1 }
    })
  }, [group, onPanelClick])

  const onKeyPressed = useCallback((event) => {
    setIsMultiSelectEnabled(event.type === 'keydown')
  }, [])

  useKeyPress(['Shift'], onKeyPressed)

  return (
    <SelectionContextProvider
      isMultiSelectEnabled={canMultiSelect}
      selectContainerRef={selectContainerRef}
      selectedPanelSideRef={selectedPanelSideRef}
      selected={selected}
      mode={mode}
      onPanelClick={onPanelClick_}>
      <TransformWrapper
        disabled={isMultiSelectEnabled}
        centerOnInit
        limitToBounds={false}
        initialScale={initialScale}
        minScale={initialScale}>
        <Container
          group={group}
          isMultiSelectEnabled={canMultiSelect}
          onPanelClick={onPanelClick}
          selectContainerRef={selectContainerRef}
          unSelectGroup={unSelectGroup}
          reset={reset}>
          <Grid
            container
            flexDirection={onUndo || onRedo || canShowDeleteButton ? 'row' : 'row-reverse'}
            justifyContent="space-between"
            padding="1em">
            {(onUndo || onRedo) && (
              <Grid item>
                {onUndo && (
                  <IconButton
                    onClick={onUndo}
                    disabled={isHistoryEmpty}
                    sx={{
                      '& *': {
                        opacity: isHistoryEmpty ? '50%' : '100%',
                        width: '0.5em'
                      }
                    }}>
                    <UndoSvg fill={`${theme.palette.secondary.main}`} />
                  </IconButton>
                )}
                {onRedo && (
                  <IconButton
                    onClick={onRedo}
                    disabled={isHistoryAtEnd}
                    sx={{
                      marginRight: '1em',
                      '& *': {
                        opacity: isHistoryAtEnd ? '50%' : '100%',
                        width: '0.5em'
                      }
                    }}>
                    <RedoSvg fill={`${theme.palette.secondary.main}`} />
                  </IconButton>
                )}
              </Grid>
            )}

            {/* buttons for inserting/deleting columns/rows */}
            {canShowDeleteButton ? (
              <Grid item sm={6} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {isInserting || isDeleting ? (
                  <Button variant="outlined" onClick={() => onCancel()} sx={{ color: 'text.secondary' }}>
                    Cancel
                  </Button>
                ) : (
                  <>
                    <Button variant="outlined" onClick={() => setIsInserting(true)}>
                      Insert
                    </Button>
                    <Typography marginLeft="1em" marginRight="1em" component="p" color={'text.secondary'}>
                      |
                    </Typography>
                    <Button variant="outlined" onClick={() => setIsDeleting(true)} sx={{ color: 'text.secondary' }}>
                      Delete
                    </Button>
                  </>
                )}
              </Grid>
            ) : null}
            {!!onSwitchView && (
              <>
                <Grid item alignItems="center" display="flex" flexDirection="row-reverse" container>
                  <Fab onClick={onSwitchView} variant="extended" color="secondary" size="small">
                    3D view
                  </Fab>
                  <Tooltip
                    sx={{ marginRight: '1em' }}
                    placement="top"
                    color="secondary"
                    title="To select multiple panels, select a panel and then hold shift, click and drag to select.">
                    <InfoSharp />
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>

          <Zoom in={awaitingActionId === actions.Swap.id || awaitingActionId === actions.Relocate.id}>
            <Grid
              container
              sx={
                !onSwitchView
                  ? {
                      textAlign: 'center',
                      alignItems: 'center',
                      backgroundColor: 'black',
                      width: '100vw',
                      position: 'absolute',
                      zIndex: '100',
                      padding: '.6em'
                    }
                  : {
                      backgroundColor: 'black',
                      padding: '.6em',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }
              }>
              <Grid item>
                <Typography>
                  {awaitingActionId === actions.Swap.id
                    ? 'Select panel to swap with'
                    : 'Where are you moving this panel to?'}
                </Typography>
              </Grid>
              <Grid item>
                <Button color="secondary" onClick={cancelAwaitingAction}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Zoom>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} sx={{ height: '100%' }} ref={ref}>
              <TransformComponent
                contentStyle={{ display: 'block', width: '100%', height: '100%' }}
                wrapperStyle={{ width: '100%', height: '100%' }}>
                <DeckModel
                  data={data}
                  maintenance={maintenance}
                  onPanelClick={onPanelClick_}
                  selected={selected}
                  materialSelected={materialSelected}
                  mode={mode}
                  heatMapData={heatMapData}
                  onSelectChange={onSelectChange}
                  isMultiSelectEnabled={canMultiSelect}
                />
              </TransformComponent>
            </Grid>
            {mode === DeckMode.maintenance && mouseCanvasPosition && !canMultiSelect ? (
              <MaintenanceSelector
                isMobile={!onSwitchView}
                mouseCanvasPosition={mouseCanvasPosition}
                onMaintenanceTypeClick={onMaintenanceTypeClick}
                selected={selected}
                maintenance={maintenance}
                deckRevision={data}
                onWearSubmit={onWearSubmit}
              />
            ) : null}
          </Grid>
        </Container>
      </TransformWrapper>
    </SelectionContextProvider>
  )
})

export default DeckCanvas
