/**
 *
 * "App routes to pages"
 *
 * @file   AllRoutes.js
 * @author Lateral
 * @since  2023
 */
import { useRoutes, Navigate } from 'react-router-dom'
import {
  ScreenAssetsPage,
  DashboardPage,
  DeckPage,
  DeckPageMobile,
  DeckLayoutPage,
  EditDeckPage,
  DialoguePage,
  AdminPage,
  RolesPage,
  UsersPage,
  SitesPage,
  DatastorePage,
  AccountPage,
  MaintenancePage,
  MobileMaintenancePage,
  MobileDeckLayoutPage,
  ReportingPage,
  AllReportsPage,
  PreShutPage,
  PostShutPage,
  PartsPage,
  DeckHistoryPage,
  HeatMapPage,
  HelpPage,
  HelpMobilePage,
  MaterialsPage
} from 'pages'
import { ProtectedRoute, SCOPES, CustomPropsBreadcrumb } from 'components'
import { ReactComponent as DashboardIcon } from 'assets/icons/Dashboard.svg'
import { ReactComponent as EquipmentIcon } from 'assets/icons/Equipment.svg'
import { ReactComponent as ReportingIcon } from 'assets/icons/Reporting.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/Settings.svg'
import HelpIcon from '@mui/icons-material/Help'
import React from 'react'
import { ReportingProvider } from '../components/reportingContext/ReportingContext'
import { DeckCanvasAndMaintenanceForm } from './deck/maintenance/components/DeckCanvasAndMaintenanceForm'
import { WearAppContextProvider } from 'components/maintenanceSelector/wearapp/WearAppContext'
import { CameraContainer } from 'components/maintenanceSelector/wearapp/components/CameraContainer'
import { CanvasCapture } from 'components/maintenanceSelector/wearapp/components/CanvasCapture'
import { AnalysisResultContainer } from 'components/maintenanceSelector/wearapp/components/results/AnalysisResultContainer'
import { LegalRequirment } from './legal/LegalRequirement'
import { AdminOnlyRoute } from 'components/auth/ProtectedRoute'
import { WearAppAnalysisContextProvider } from 'components/maintenanceSelector/wearapp/WearAppAnalysisContext'
import { MobileDeckCanvasAndMaintenanceForm } from './deck/maintenance/mobile/MobileDeckCanvasAndMaintenanceForm'

//renders the App's pages
export const navRoutes = [
  {
    breadcrumb: null,
    path: '',
    icon: <DashboardIcon />,
    props: { title: 'Home' },
    parentPath: 'dashboard',
    children: [
      {
        breadcrumb: CustomPropsBreadcrumb,
        element: <DashboardPage />,
        path: 'dashboard',
        props: { title: 'Dashboard' },
        children: []
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        element: <DialoguePage />,
        path: 'dialogue',
        props: { title: 'Dialogue' },
        children: []
      }
    ]
  },
  {
    element: <DeckPage />,
    path: 'equipment',
    icon: <EquipmentIcon />,
    props: { title: 'Equipment' },
    children: [
      {
        element: <ScreenAssetsPage />,
        props: { title: 'Screen Assets' },
        path: 'screens'
      },
      {
        path: 'decklayout',
        props: { title: 'Deck Layout' },
        element: <DeckLayoutPage />
      },
      {
        path: 'maintenance',
        props: { title: 'Maintenance', scopes: [SCOPES.deckMaintenance.Write] },
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.deckMaintenance.Write]} checkSites>
            <WearAppAnalysisContextProvider>
              <MaintenancePage />
            </WearAppAnalysisContextProvider>
          </ProtectedRoute>
        ),
        children: [
          {
            path: '',
            props: { title: 'Equipment' },
            element: <DeckCanvasAndMaintenanceForm />
          },
          {
            path: 'wearapp',
            element: <WearAppContextProvider />,
            children: [
              {
                path: '',
                element: <CameraContainer />
              },
              {
                path: 'canvas',
                element: <CanvasCapture />
              },
              {
                path: 'results',
                element: <AnalysisResultContainer />
              }
            ]
          }
        ]
      },
      {
        props: { title: 'Edit Deck', scopes: [SCOPES.deckLayoutReview.Write] },
        path: 'edit',
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.deckLayoutReview.Write]} checkSites>
            <EditDeckPage />
          </ProtectedRoute>
        )
      },
      {
        props: { title: 'Heat Map', scopes: [SCOPES.heatmap.Read] },
        path: 'heatmap',
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.heatmap.Read]} checkSites>
            <HeatMapPage />
          </ProtectedRoute>
        )
      },
      {
        props: { title: 'History' },
        path: 'history',
        element: <DeckHistoryPage />
      }
    ]
  },
  {
    breadcrumb: CustomPropsBreadcrumb,
    props: { title: 'Reporting', scopes: [SCOPES.reporting.Read] },
    path: 'reporting',
    icon: <ReportingIcon />,
    element: (
      <ProtectedRoute requiredScopes={[SCOPES.reporting.Read]}>
        <ReportingProvider>
          <ReportingPage />
        </ReportingProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        breadcrumb: null,
        path: '',
        element: <AllReportsPage />
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Pre-Shut Report', scopes: [SCOPES.reporting.Write] },
        path: 'pre-shut',
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.reporting.Write]}>
            <PreShutPage />
          </ProtectedRoute>
        )
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Post-Shut Report', scopes: [SCOPES.reporting.Write] },
        path: 'post-shut',
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.reporting.Write]}>
            <PostShutPage />
          </ProtectedRoute>
        )
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Parts Report', scopes: [SCOPES.reporting.Write] },
        path: 'parts',
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.reporting.Write]}>
            <PartsPage />
          </ProtectedRoute>
        )
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'All Reports', scopes: [SCOPES.reporting.Read] },
        path: 'all',
        element: <AllReportsPage />
      }
    ]
  },
  {
    breadcrumb: null,
    props: { title: 'Admin' },
    path: 'admin',
    icon: <SettingsIcon />,
    children: [
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Account Details' },
        path: 'accounts',
        icon: <SettingsIcon />,
        element: <AdminPage />,
        children: [
          {
            breadcrumb: CustomPropsBreadcrumb,
            props: { title: 'Account Details' },
            path: '',
            element: <AccountPage />
          },
          {
            breadcrumb: CustomPropsBreadcrumb,
            path: 'roles',
            props: { title: 'Roles', scopes: [SCOPES.roleManagement.Read] },
            element: (
              <ProtectedRoute requiredScopes={[SCOPES.roleManagement.Read]}>
                <RolesPage />
              </ProtectedRoute>
            )
          },
          {
            breadcrumb: CustomPropsBreadcrumb,
            path: 'users',
            props: { title: 'Users', scopes: [SCOPES.userManagement.Read] },
            element: (
              <ProtectedRoute requiredScopes={[SCOPES.userManagement.Read]}>
                <UsersPage />
              </ProtectedRoute>
            )
          },
          {
            breadcrumb: CustomPropsBreadcrumb,
            path: 'sites',
            props: { title: 'Sites', scopes: [SCOPES.siteManagement.Read] },
            element: (
              <ProtectedRoute requiredScopes={[SCOPES.siteManagement.Read]}>
                <SitesPage />
              </ProtectedRoute>
            )
          },
          {
            breadcrumb: CustomPropsBreadcrumb,
            path: 'materials',
            props: { title: 'Materials', scopes: [SCOPES.materialManagement.Read] },
            element: (
              <ProtectedRoute requiredScopes={[SCOPES.materialManagement.Read]}>
                <MaterialsPage />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Help & Support' },
        element: <HelpPage />,
        path: 'help',
        icon: <HelpIcon />,
        children: []
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Legal' },
        path: 'legal',
        element: (
          <AdminOnlyRoute>
            <LegalRequirment />
          </AdminOnlyRoute>
        )
      }
    ]
  }
]

//Routes for mobile
export const mobileRoutes = [
  {
    breadcrumb: null,
    path: 'mobile',
    element: <DeckPageMobile />,
    children: [
      {
        breadcrumb: 'Deck Layout',
        path: 'decklayout',
        element: <MobileDeckLayoutPage />
      },
      {
        breadcrumb: 'Maintenance',
        path: 'maintenance',
        element: (
          <ProtectedRoute requiredScopes={[SCOPES.deckMaintenance.Write]}>
            <WearAppAnalysisContextProvider>
              <MobileMaintenancePage />
            </WearAppAnalysisContextProvider>
          </ProtectedRoute>
        ),
        children: [
          {
            path: '',
            element: <MobileDeckCanvasAndMaintenanceForm />
          },
          {
            path: 'wearapp',
            element: <WearAppContextProvider />,
            children: [
              {
                path: '',
                element: <CameraContainer />
              },
              {
                path: 'canvas',
                element: <CanvasCapture />
              },
              {
                path: 'results',
                element: <AnalysisResultContainer />
              }
            ]
          }
        ]
      },
      {
        path: 'maintenance/wearapp',
        element: <WearAppContextProvider />,
        children: []
      },
      {
        breadcrumb: CustomPropsBreadcrumb,
        props: { title: 'Help & Support' },
        path: 'help',
        element: <HelpMobilePage />
      }
    ]
  }
]

//All other routes should be defined in default routes
export const defaultRoutes = [
  ...navRoutes,
  ...mobileRoutes,
  { path: 'datastore', element: <DatastorePage /> },
  { path: '*', element: <Navigate to="/dashboard" replace /> },
  { index: true, element: <Navigate to="/dashboard" replace /> }
]
export const AllRoutes = () => {
  const routes = useRoutes(defaultRoutes)
  return routes
}
export default AllRoutes
