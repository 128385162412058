import React from 'react'
import { SandvikLogo } from 'components/SanvikLogo'
import { ProgressIndicator } from 'components/maintenanceSelector/wearapp/components/ProgressIndicator'
import Box from '@mui/material/Box'
import { Typography, useMediaQuery } from '@mui/material'
import { DESKTOP_MIN_WIDTH } from 'common/config'

export const Loading = ({ message, type = 'linear', withLogo = true }) => {
  const isDesktop = useMediaQuery(DESKTOP_MIN_WIDTH)

  return (
    <Box display="flex" width="100%" height="100%" flexDirection="column" justifyContent="center" alignItems="center">
      {withLogo && (
        <Box width={isDesktop ? '15%' : undefined}>
          <SandvikLogo className="loading" />
        </Box>
      )}
      <Box width={isDesktop ? '30%' : '100%'} display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" justifyContent="center">
          {!!message && (
            <Typography textAlign="center" variant="body">
              {message}
            </Typography>
          )}
        </Box>

        <Box width="50%" display="flex" justifyContent="center" marginTop="1em">
          <ProgressIndicator type={type ?? 'linear'} />
        </Box>
      </Box>
    </Box>
  )
}
