/**
 *
 * "CRUD (Create, read, update, and delete) for HelpContact table"
 *
 * @file   dataOperations.js
 * @author Lateral
 * @since  2023
 */

import { DataStore } from 'aws-amplify'
import { HelpContacts } from 'models'

//
export async function addHelpContact(data) {
  /**
   * Create help contact
   *
   * @function
   * @param {object} data - HelpContacts defined in models/index
   * @returns {object} - Varification of created help contact record
   */
  const newItem = await DataStore.save(
    new HelpContacts({
      Name: data.name,
      Email: data.email,
      Phone: data.phone,
      Mobile: data.mobile,
      Address1: data.address1,
      Address2: data.address2,
      Category: data.category
    })
  )
  return newItem
}

export async function updateHelpContact(data) {
  /**
   * Update help contact
   *
   * @function
   * @param {object} data - HelpContacts defined in models/index
   * @returns {object} - Varification of updated help contact record
   */
  const original = await DataStore.query(HelpContacts, data.id)
  await DataStore.save(
    HelpContacts.copyOf(original, (updated) => {
      ;(updated.Name = data.name),
        (updated.Email = data.email),
        (updated.Phone = data.phone),
        (updated.Mobile = data.mobile),
        (updated.Address1 = data.address1),
        (updated.Address2 = data.address2),
        (updated.Category = data.category)
    })
  )
}

export async function deleteHelpContact(id) {
  /**
   * Delete help contact
   *
   * @function
   * @param {number} id - Id of the help contact to delete
   */
  const original = await DataStore.query(HelpContacts, id)
  await DataStore.delete(original)
}
