/**
 *
 * "Generates the panels as group of 2D boxes objects calculated from deck revisions data
 * for mobile device version"
 *
 * @file   mobileDeckCanvas\DeckPanels.js
 * @author Lateral
 * @since  2023
 */

import { Box, Stack, Typography } from '@mui/material'
import BigNumber from 'bignumber.js'
import { toAlphabet } from 'common/numberToAlphabet'
import React, { useMemo } from 'react'
import { DeckRow } from './DeckRow'

/*
 *
 */
export const DeckPanels = ({
  data,
  maintenance,
  materialSelected,
  onPanelClick,
  scale,
  mode,
  presentation,
  heatMapData,
  selected
}) => {
  /**
   * Generates panels generated by incoming data in 2D space.
   *
   * @function
   * @param {object} data - Deck rivision object from database
   * @param {object} maintenance - Deck Revision Histories
   * @param {number} materialSelected - Material number
   * @param {Function} onPanelClick - Action on clicking panel
   * @param {object[]} selected - Array of selected panel objects
   * @param {number} scale - Zoom scale to view
   * @param {string} mode - Deck modes:   'default', 'edit', 'maintenance', 'heatmap', 'maintnance', 'pre-maintenance', 'post-maintenance'
   * @param {boolean} presentation - Mintenance mode presentation True to view higher contrast  text
   *
   *
   * @returns {object} - React box element of 2D boxes as panels
   */

  const kits = data.Kits
  const size = data.Size

  const panels = useMemo(() => {
    if (!data) {
      return []
    }
    const size = data.Size
    const panels = [...Array(size.Rows)].map(() => Array(size.Columns))
    structuredClone(data.Panels).map((p) => {
      return (panels[p.Position.Row][p.Position.Column] = p)
    })

    return panels
  }, [data])

  return (
    <Box sx={{ height: 'fit-content', width: 'fit-content' }}>
      <Stack key="columns" direction="row">
        {[...Array(size.Columns)].map((x, i) => {
          return (
            <Typography
              key={i}
              fontSize={12}
              sx={[{ width: '100%', textAlign: 'center' }, presentation ? { color: 'black' } : null]}>
              {toAlphabet(new BigNumber(i).plus(1))}
            </Typography>
          )
        })}
      </Stack>

      {panels.map((row, i) => {
        return (
          <DeckRow
            key={`row-${i}`}
            row={row}
            kits={kits}
            materialSelected={materialSelected}
            maintenance={maintenance}
            scale={scale}
            mode={mode}
            heatMapData={heatMapData}
            rowIndex={i}
            onPanelClick={onPanelClick}
            selected={selected}
          />
        )
      })}
    </Box>
  )
}
