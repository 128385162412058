/**
 *
 * "Site table page"
 *
 * @file   SitesTablePage.js
 * @author Lateral
 * @since  2023
 */
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ArrowBack } from '@mui/icons-material'
import SitesTable from './components/SitesTable'
import AddSiteForm from './components/AddSiteForm'
import { FormDialog, SCOPES, ScopeRequirementAll, useNotification } from 'components'
import { addLocation, addSite, deleteLocation, deleteSite, updateLocation, updateSite } from 'pages/admin/common/data'
import { Sites } from 'models'
import AddLocationForm from './components/AddLocationForm'
import DeleteForm from './components/DeleteForm'
import { Locations } from 'models'
import JSZip from 'jszip'
import { usePapaParse } from 'react-papaparse'
import { saveAs } from 'file-saver'
import BigNumber from 'bignumber.js'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { useCurrentUser } from 'hooks'

function SitesTablePage({ customerId, setCustomerId }) {
  /**
   * Page for company table
   *
   * @function
   * @param {string} customerId - ID of the customer own the sites
   * @param {} setCustomerId - Action on setting customer id in the context
   *
   * @returns {object} - React app page
   */
  const [addSiteModalState, setAddSiteModalState] = useState({ isOpen: false })
  const [deleteSiteModalState, setDeleteSiteModalState] = useState({ isOpen: false })
  const [addLocationModalState, setAddLocationModalState] = useState({ isOpen: false })
  const [deleteLocationModalState, setDeleteLocationModalState] = useState({ isOpen: false })
  const { jsonToCSV } = usePapaParse()
  const { database } = useCurrentDeckContext()
  const { notify } = useNotification()
  const { getTransactionMetaData, currentUser } = useCurrentUser()

  const customer = database.customers.find((c) => c.id === customerId)

  //Action on editing site details
  function onEditDetails(context) {
    setAddSiteModalState({ isOpen: true, title: 'Edit Site', buttonText: 'Update', data: context })
  }

  // Action on deleting row
  function onDelete(context) {
    setDeleteSiteModalState({
      isOpen: true,
      title: 'Delete Site?',
      buttonText: 'Delete',
      data: { id: context.id, Name: context.SiteName }
    })
  }

  //Action on confirming delete
  function onDeleteConfirmation(modelState) {
    if (modelState?.isSave) {
      deleteSite(modelState.data.Id)
    }

    setDeleteSiteModalState({ isOpen: false })
  }

  // Action on adding new site
  async function onAddSite(modelState) {
    if (modelState?.isSave) {
      const existing = database.sites.find((s) => s.id === modelState.data.Id)

      if (existing) {
        let model = structuredClone(existing)
        model.Name = modelState.data.Name
        model.Contacts = modelState.data.Contacts
        await updateSite(model, getTransactionMetaData())
      } else {
        const model = new Sites({
          CustomerId: customerId,
          Name: modelState.data.Name,
          Contacts: modelState.data.Contacts
        })

        await addSite(model, getTransactionMetaData())
      }
    }

    setAddSiteModalState({ isOpen: false })
  }

  // Action on create new location in the site
  function onCreateLocation(context) {
    setAddLocationModalState({
      isOpen: true,
      title: 'Add Location',
      buttonText: 'Create',
      data: { SiteId: context.id }
    })
  }

  //Action on edit location details
  function onEditLocationDetails(context) {
    setAddLocationModalState({ isOpen: true, title: 'Edit Location', buttonText: 'Update', data: context })
  }

  //Action on deleting ;ocation of the site
  function onDeleteLocation(context) {
    setDeleteLocationModalState({
      isOpen: true,
      title: 'Delete Location?',
      buttonText: 'Delete',
      data: { id: context.id, Name: context.Name }
    })
  }

  // Action on confirining location delete
  function onDeleteLocationConfirmation(modelState) {
    if (modelState?.isSave) {
      deleteLocation(modelState.data.Id)
    }

    setDeleteLocationModalState({ isOpen: false })
  }

  //Action on adding new location
  async function onAddLocation(modelState) {
    if (modelState?.isSave) {
      const existing = database.locations.find((l) => l.id === modelState.data.Id)

      if (existing) {
        let model = structuredClone(existing)
        model.Name = modelState.data.Name
        await updateLocation(model)
      } else {
        const model = new Locations({
          SiteId: modelState.data.SiteId,
          Name: modelState.data.Name
        })

        await addLocation(model)
      }
    }

    setAddLocationModalState({ isOpen: false })
  }

  // Exporting kits to the site
  async function exportKits() {
    const customer = database.customers.find((c) => c.id === customerId)
    const sites = database.sites.filter((s) => s.CustomerId === customerId)
    const siteIds = sites.map((s) => {
      return s.id
    })
    const locations = database.locations.filter((l) => siteIds.includes(l.SiteId))
    const locationIds = locations.map((l) => {
      return l.id
    })
    const decks = database.decks.filter((d) => locationIds.includes(d.LocationId) && d.CustomerId === customerId)

    let zip = new JSZip()

    decks.forEach((deck) => {
      const location = locations.find((l) => l.id === deck.LocationId)
      const site = sites.find((s) => s.id === location.SiteId)
      const deckRevisions = database.deckRevisions
        .filter((d) => d.DeckId === deck.id && d.IsPublished)
        .sort((a, b) => b.RevisionNumber - a.RevisionNumber)

      if (deckRevisions.length && location && site) {
        const deckRevision = deckRevisions[0]

        let kits = deckRevision.Kits.map((k) => {
          const totalPanels = deckRevision.Panels.filter((p) => p.MaterialNumber === k.MaterialNumber).length
          const totalSideliners = deckRevision.SideLiners.filter((s) => s.MaterialNumber === k.MaterialNumber).length
          return {
            Quantity: new BigNumber(totalPanels).plus(totalSideliners).toNumber(),
            MaterialNumber: k.MaterialDescription ? k.MaterialNumber : '',
            Description: k.MaterialDescription ? k.MaterialDescription : k.MaterialNumber,
            PartNumber: k.PartNumber,
            Code: k.CustomerStockCode,
            Weight: k.Weight,
            Price: k.UnitPrice,
            OpenArea: k.OpenArea,
            WorkingDepth: k.WorkingDepth,
            FailureWorkingDepth: k.FailureWorkingDepth,
            ApertureWidth: k.ApertureWidth,
            FailureApertureWidth: k.FailureApertureWidth,
            MaterialType: k.MaterialType,
            Colour: k.Colour,
            Height: k.Size?.Height ?? 1,
            Width: k.Size?.Width ?? 1
          }
        })

        const kitsCsv = jsonToCSV(JSON.stringify(kits))
        const fileName = `${site.Name}-${location.Name}-${deck.DeckHeader.EquipmentId}-${deck.DeckHeader.DeckLevel}`

        try {
          zip.file(`${fileName.replace('.', '-')}.csv`, kitsCsv)
        } catch (err) {
          notify(`CSV for ${fileName} failed to generate due to a problem with the data or the name.`)
          console.log(err)
        }
      }
    })

    var file = await zip.generateAsync({ type: 'blob' })

    try {
      saveAs(file, `${customer.Name.replace('.', '-')}-Kits-Export.zip`)
    } catch (err) {
      notify('File failed to generate due to a problem with the data or the name.')
      console.log(err)
    }
  }

  // Render rows of the site
  function siteRows() {
    const userSites = currentUser.getSitesForScope(SCOPES.siteManagement.Read)
    return database.sites
      .filter((s) => s.CustomerId === customerId && (currentUser.isAdmin || userSites.includes(s.id)))
      .map((s) => {
        const locations = database.locations.filter((l) => l.SiteId === s.id)
        const locationIds = locations.map((l) => {
          return l.id
        })
        const decks = database.decks.filter((d) => locationIds.includes(d.LocationId) && d.CustomerId === customerId)
        const screenTotal = []
        decks.forEach((d) => {
          if (!screenTotal.find((s) => s.EquipmentId === d.DeckHeader.EquipmentId && s.LocationId === d.LocationId)) {
            screenTotal.push({ EquipmentId: d.DeckHeader.EquipmentId, LocationId: d.LocationId })
          }
        })
        return {
          id: s.id,
          SiteName: s.Name,
          LocationsTotal: locations?.length ?? 0,
          ScreensTotal: screenTotal?.length ?? 0,
          Contacts: s.Contacts
        }
      })
  }

  return (
    <>
      {customer ? (
        <>
          <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <IconButton color="secondary" onClick={() => setCustomerId('')}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h4">{customer.Name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ justifySelf: 'flex-end', width: '10em', height: '2em', padding: 0 }}
                onClick={() => exportKits()}>
                Export Kits
              </Button>
              <ScopeRequirementAll requirements={[SCOPES.siteManagement.Write]}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ justifySelf: 'flex-end', width: '10em', height: '2em', padding: 0 }}
                  onClick={() =>
                    setAddSiteModalState({ isOpen: true, title: 'Create New Site', buttonText: 'Create' })
                  }>
                  Add Site
                </Button>
              </ScopeRequirementAll>
            </Stack>
          </Stack>
          <SitesTable
            customerId={customerId}
            database={database}
            onEditDetails={onEditDetails}
            onDelete={onDelete}
            onCreateLocation={onCreateLocation}
            rows={siteRows()}
            onEditLocation={onEditLocationDetails}
            onDeleteLocation={onDeleteLocation}
          />

          {addSiteModalState.isOpen && (
            <FormDialog modalState={addSiteModalState} onOpenChange={onAddSite}>
              <AddSiteForm />
            </FormDialog>
          )}

          {deleteSiteModalState.isOpen && (
            <FormDialog modalState={deleteSiteModalState} onOpenChange={onDeleteConfirmation} center>
              <DeleteForm />
            </FormDialog>
          )}

          {addLocationModalState.isOpen && (
            <FormDialog modalState={addLocationModalState} onOpenChange={onAddLocation}>
              <AddLocationForm />
            </FormDialog>
          )}

          {deleteLocationModalState.isOpen && (
            <FormDialog modalState={deleteLocationModalState} onOpenChange={onDeleteLocationConfirmation} center>
              <DeleteForm />
            </FormDialog>
          )}
        </>
      ) : (
        <Box>Loading...</Box>
      )}
    </>
  )
}
export default SitesTablePage
