/**
 *
 * "View Screen Assets page"
 *
 * @file   ScreenAssetsPage.js
 * @author Lateral
 * @since  2023
 */
import React, { useEffect, useState } from 'react'
import { Button, OutlinedInput, Stack, Typography, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ScreenTable } from './components/ScreenTable'
import { FormDialog, SCOPES, ScopeRequirementAll, useNotification } from 'components'
import { ScreenItemForm } from './components/ScreenItemForm'
import ScreenContextMenu from './components/ScreenContextMenu'
import { addScreen, updateDecks, updateScreen } from './common/data'
import { log } from 'common'
import { observeDialogue, observeEquipment, useCurrentUser } from 'hooks'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import RecentActivityTable from 'components/tables/history/RecentActivityTable'
import CondensedDialogueTable from 'components/tables/dialogue/CondensedDialogueTable'
import dayjs from 'dayjs'
import { dateFormat } from 'common/dates'
import { jsonToCSV } from 'react-papaparse'
import JSZip from 'jszip'
import BigNumber from 'bignumber.js'
import { saveAs } from 'file-saver'
import { ReactComponent as ExportIcon } from 'assets/icons/Export.svg'
import CreateDeckForm from 'pages/common/decks/CreateDeckForm'
import { Decks, DeckHeaderItems, DeckSizeItems } from 'models'
import { createDeck, updateDeck } from 'data/deckOperations'
import { createFirstRevision, createRevision } from 'data/revisionOperations'
import SearchIcon from '@mui/icons-material/Search'
import CloneDeckForm from './components/CloneDeckForm'
import { Add } from '@mui/icons-material'
import { DeckAccordion } from '../deck/common/DeckAccordion'
import { usePrevious } from 'react-use'
import { useNavigate } from 'react-router-dom'

function ScreenAssetsPage() {
  /**
   * Generates View Screen Assets page
   *
   * @function
   *
   * @returns {object} - A page to view screen assets
   */
  const theme = useTheme()
  const { notify } = useNotification()
  const dialogue = observeDialogue()
  const equipment = observeEquipment()
  const { getUserName, getTransactionMetaData, currentUser } = useCurrentUser()
  const editableSites = currentUser.getSitesForScope(SCOPES.deckLayoutReview.Write)
  const editableCustomers = currentUser.getCustomersForScope(SCOPES.deckLayoutReview.Write)
  const {
    customerId,
    siteId,
    locationId,
    equipmentId,
    deckId,
    setCustomerId,
    setSiteId,
    setLocationId,
    setEquipmentId,
    setDeckId,
    database
  } = useCurrentDeckContext()
  const [searchText, setSearchText] = useState('')
  const [timer, setTimer] = useState('')
  const [screenItemFormModalState, setScreenItemFormModalState] = useState({ isOpen: false })
  const [deckItemFormModalState, setDeckItemFormModalState] = useState({ isOpen: false })
  const [deckCloneFormModalState, setDeckCloneFormModalState] = useState({ isOpen: false })
  const previousDeck = usePrevious(deckId)
  const navigate = useNavigate()
  const filteredDecks = database.decks.filter(
    (d) =>
      (!customerId || customerId === d.CustomerId) &&
      (!siteId || siteId === d.SiteId) &&
      (!locationId || locationId === d.LocationId) &&
      (!equipmentId || equipmentId === d.DeckHeader.EquipmentId) &&
      (!deckId || deckId === d.id)
  )

  useEffect(() => {
    if (previousDeck !== undefined && previousDeck !== deckId) {
      navigate('/equipment/decklayout')
    }
  }, [deckId])

  const filteredDeckRevisions = database.deckRevisions.filter((dr) =>
    filteredDecks.map((d) => d.id).includes(dr.DeckId)
  )

  const filteredDeckRevisionHistories = database.deckRevisionHistories.filter((drh) =>
    filteredDeckRevisions.map((dr) => dr.id).includes(drh.RevisionId)
  )

  const screensToDisplay = getData()

  //data requires a fair bit of filtering to show the correct Screens.
  //Because the EquipmentId (also known as a Screen) can exist in a table and as a Deck field, we need to account for both.
  function getData() {
    const siteIds = database.sites
      .filter((s) => (!siteId || siteId === s.id) && (!customerId || customerId === s.CustomerId))
      .map((s) => s.id)

    const locationIds = database.locations
      .filter((l) => (!locationId || locationId === l.id) && siteIds.includes(l.SiteId))
      .map((l) => l.id)

    const filteredEquipment = equipment
      .filter((e) => locationIds.includes(e.LocationId) && (!equipmentId || equipmentId === e.Name))
      .map((e) => e.Name)

    let equipmentNames = [...new Set(filteredDecks.map((d) => d.DeckHeader.EquipmentId).concat(filteredEquipment))]

    let data = equipmentNames.map((equipmentName) => {
      const singleEquipment = equipment.find((e) => e.Name === equipmentName && locationIds.includes(e.LocationId))
      const decks = filteredDecks.filter((d) => d.DeckHeader.EquipmentId === equipmentName)
      const firstDeck = decks[0]
      const location = database.locations.find(
        (l) => l.id === firstDeck?.LocationId || l.id === singleEquipment?.LocationId
      )
      const site = database.sites.find((s) => s.id === location?.SiteId)
      const customer = database.customers.find((c) => c.id === site?.CustomerId)
      const screen = database.screens.find((s) => s.id === singleEquipment?.ScreenId || s.id === firstDeck?.ScreenId)

      return {
        id: singleEquipment?.id ?? decks[0]?.DeckHeader.EquipmentId,
        Name: singleEquipment?.Name ?? decks[0]?.DeckHeader.EquipmentId,
        DWGNumber: singleEquipment?.DWGNumber,
        SchenckSerial: singleEquipment?.SchenckSerial,
        SiteSerial: singleEquipment?.SiteSerial,
        ScreenBody: singleEquipment?.ScreenBody,
        Exciters: singleEquipment?.Exciters,
        DateInstalled: singleEquipment?.DateInstalled ? dayjs(singleEquipment?.DateInstalled).format(dateFormat) : '',
        ScreenTonnage: singleEquipment?.ScreenTonnage,
        ScreenCapacity: singleEquipment?.ScreenCapacity,
        MaintenanceFrequency: singleEquipment?.MaintenanceFrequency,
        customerId: customer?.id,
        customerName: customer?.Name,
        siteId: site?.id,
        siteName: site?.Name,
        locationId: location?.id,
        locationName: location?.Name,
        screenId: screen?.id,
        screenName: screen?.Name,
        decks: decks.map((d) => {
          const revision = database.deckRevisions
            .filter((dr) => dr.DeckId == d.id && dr.IsPublished)
            .sort((a, b) => b.RevisionNumber - a.RevisionNumber)[0]

          return {
            id: d.id,
            DeckHeader: d.DeckHeader,
            Revision: revision,
            RevisionNumber: revision?.RevisionNumber ?? 0,
            RevisionDate: dayjs(revision?.createdAt).format(dateFormat)
          }
        })
      }
    })

    //quick searching
    if (searchText) {
      const lowerSearchTexts = searchText.toLowerCase().split(' ')
      data = data.filter((d) =>
        lowerSearchTexts.every((l) =>
          JSON.stringify([
            d.Name,
            d.DWGNumber,
            d.SchenckSerial,
            d.SiteSerial,
            d.ScreenBody,
            d.Exciters,
            d.DateInstalled,
            d.ScreenTonnage,
            d.ScreenCapacity,
            d.MaintenanceFrequency,
            d.customerName,
            d.siteName,
            d.locationName,
            d.screenName
          ])
            .toLowerCase()
            .includes(l)
        )
      )
    }

    return data
  }

  function onSearchChange(value) {
    clearTimeout(timer)
    const timeoutId = setTimeout(() => onTimeoutSubmit(value), 500)
    setTimer(timeoutId)
  }

  async function onTimeoutSubmit(value) {
    setSearchText(value)
  }

  async function handleScreenItemDialogClose(modalState) {
    if (modalState?.isSave) {
      const data = modalState.data

      try {
        let equipmentId = data.Name
        const existing = equipment.find((e) => e.id === data.id)
        if (existing) {
          //Update Item
          await updateScreen(data, getTransactionMetaData())
          updateDecks(existing.Name, equipmentId, data.ScreenId, getTransactionMetaData())
          notify(`Screen updated successfully`)
        } else {
          //Add Item
          const newScreen = await addScreen(data, getTransactionMetaData())
          equipmentId = newScreen.Name
          updateDecks(data.id, equipmentId, data.ScreenId, getTransactionMetaData())
          notify(`Added ${newScreen.Name}`)
        }

        setCustomerId(data.CustomerId)
        setSiteId(data.SiteId)
        setLocationId(data.LocationId)
        setEquipmentId(equipmentId)
        setDeckId('')
      } catch (error) {
        log.error(JSON.stringify(error))
        notify(`An error occurred whilst attempting to save`)
      }
    }
    setScreenItemFormModalState({ isOpen: false })
  }

  async function handleDeckItemDialogClose(modalState) {
    if (modalState?.isSave) {
      const data = modalState.data
      const deck = new Decks({
        CustomerId: data.CompanyId,
        LocationId: data.LocationId,
        ScreenId: data.ScreenId,
        SiteId: data.SiteId,
        DeckHeader: new DeckHeaderItems({
          CustomerId: data.CompanyId,
          DeckLevel: data.DeckLevel,
          DeckSize: new DeckSizeItems({
            Columns: Number(data.Columns),
            Rows: Number(data.Rows)
          }),
          EquipmentId: data.EquipmentId,
          LocationId: data.LocationId,
          MaterialNumber: data.MaterialNumber,
          ScreenId: data.ScreenId,
          SerialNumber: data.SerialNumber,
          SiteId: data.SiteId
        })
      })

      try {
        if (data.id) {
          await updateDeck(deck, data.id, getTransactionMetaData())
          notify(`Deck updated successfully`)
        } else {
          const newDeck = await createDeck(deck, getTransactionMetaData())
          await createFirstRevision(newDeck, getTransactionMetaData())
          notify(`Deck created successfully`)
        }
      } catch (error) {
        log.error(JSON.stringify(error))
        notify(`An error occurred whilst attempting to save`)
      }
    }
    setDeckItemFormModalState({ isOpen: false })
  }

  async function handleScreenItemEvent(eventName, context) {
    if (eventName === 'edit') {
      setScreenItemFormModalState({
        isOpen: true,
        title: 'Edit Screen Details',
        buttonText: 'Update',
        data: context
      })
    } else if (eventName === 'addDeck') {
      setDeckItemFormModalState({
        isOpen: true,
        data: {
          CompanyId: context.CustomerId,
          SiteId: context.SiteId,
          LocationId: context.LocationId,
          EquipmentId: context.Name ?? context.id,
          ScreenId: context.ScreenId
        },
        title: 'Create New Deck',
        buttonText: 'Create',
        isCreate: true
      })
    }
  }

  async function onCloneDeckComplete(modalState) {
    if (modalState?.isSave) {
      const data = modalState.data
      const existing = database.decks.find((d) => d.id === data.ExistingId)

      if (existing) {
        const deck = structuredClone(existing)
        deck.CustomerId = data.CompanyId
        deck.DeckHeader.CustomerId = data.CompanyId
        deck.LocationId = data.LocationId
        deck.DeckHeader.LocationId = data.LocationId
        deck.SiteId = data.SiteId
        deck.DeckHeader.SiteId = data.SiteId
        deck.DeckHeader.EquipmentId = data.EquipmentId
        deck.DeckHeader.MaterialNumber = data.MaterialNumber
        deck.DeckHeader.SerialNumber = data.SerialNumber

        try {
          const newDeck = await createDeck(deck, getTransactionMetaData())
          const existingRevision = database.deckRevisions.find((d) => d.id === data.RevisionId)
          const revision = structuredClone(existingRevision)
          revision.DeckId = newDeck.id
          revision.CustomerId = newDeck.CustomerId
          revision.SiteId = newDeck.SiteId
          revision.RevisionNumber = 0
          revision.CreatedBy = getUserName()
          await createRevision(revision, getTransactionMetaData())
          notify(`Deck cloned successfully`)
          setCustomerId(newDeck.CustomerId)
          setLocationId(newDeck.LocationId)
          setSiteId(newDeck.SiteId)
          setEquipmentId(newDeck.DeckHeader.EquipmentId)
          setDeckId(newDeck.id)
        } catch (error) {
          log.error(JSON.stringify(error))
          notify(`An error occurred whilst attempting to clone`)
        }
      }
    }
    setDeckCloneFormModalState({ isOpen: false })
  }

  //export to CSV
  async function exportKits() {
    let zip = new JSZip()

    for (const screen of screensToDisplay) {
      for (const deck of screen.decks) {
        if (deck.Revision) {
          let kits = deck.Revision.Kits.map((k) => {
            const totalPanels = deck.Revision.Panels.filter((p) => p.MaterialNumber === k.MaterialNumber).length
            const totalSideliners = deck.Revision.SideLiners.filter((s) => s.MaterialNumber === k.MaterialNumber).length
            return {
              Quantity: new BigNumber(totalPanels).plus(totalSideliners).toNumber(),
              SandvikMaterialNumber: k.MaterialDescription ? k.MaterialNumber : '',
              Description: k.MaterialDescription ? k.MaterialDescription : k.MaterialNumber,
              PartNumber: k.PartNumber,
              Code: k.CustomerStockCode,
              Weight: k.Weight,
              Price: k.UnitPrice,
              OpenArea: k.OpenArea,
              WorkingDepth: k.WorkingDepth,
              FailureWorkingDepth: k.FailureWorkingDepth,
              ApertureWidth: k.ApertureWidth,
              FailureApertureWidth: k.FailureApertureWidth,
              MaterialType: k.MaterialType,
              Colour: k.Colour,
              Height: k.Size?.Height ?? 1,
              Width: k.Size?.Width ?? 1
            }
          })

          const kitsCsv = jsonToCSV(JSON.stringify(kits))
          const fileName = `${screen.customerName}-${screen.locationName}-${deck.DeckHeader.EquipmentId}-${deck.DeckHeader.MaterialNumber}`

          try {
            zip.file(`${fileName.replace('.', '-')}.csv`, kitsCsv)
          } catch (err) {
            notify(`CSV for ${fileName} failed to generate due to a problem with the data or the name.`)
            console.log(err)
          }
        }
      }
    }

    if (Object.entries(zip.files).length) {
      var file = await zip.generateAsync({ type: 'blob' })

      try {
        saveAs(file, 'Screen-Assets-Export.zip')
      } catch (err) {
        notify('File failed to generate due to a problem with the data or the name.')
      }
    } else {
      notify('No Deck Revisions to export.')
    }
  }

  return (
    <>
      <Grid
        mb={2}
        container
        gap={1}
        justifyContent="space-between"
        boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1);"
        position="sticky"
        padding={2}
        top={64}
        zIndex={1200}
        display="flex"
        alignItems="center"
        bgcolor={theme.palette.supporting.dark}>
        <Grid item>
          <Typography variant="h4">View Screen Assets</Typography>
        </Grid>
        <Grid item justifyContent="flex-end">
          <OutlinedInput
            color="secondary"
            sx={{ height: '32px' }}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            placeholder="Search"
            endAdornment={<SearchIcon color="secondary" />}
          />
          <Button
            variant="outlined"
            onClick={exportKits}
            startIcon={<ExportIcon style={{ width: '1em' }} fill={`${theme.palette.secondary.main}`} />}
            sx={{ height: '32px', color: `${theme.palette.secondary.main}`, margin: '0 0.5em 0 0.5em' }}>
            Export Layouts
          </Button>
          <ScopeRequirementAll requirements={[SCOPES.deckLayoutReview.Write]} siteId={siteId}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ height: '32px', padding: '0.5em 1.5em' }}
              startIcon={<Add style={{ height: '1em', width: '1em' }} />}
              onClick={() =>
                setScreenItemFormModalState({
                  isOpen: true,
                  title: 'Create New Screen',
                  buttonText: 'Create',
                  data: { CustomerId: customerId, SiteId: siteId, LocationId: locationId }
                })
              }>
              Add Screen
            </Button>
          </ScopeRequirementAll>
        </Grid>
      </Grid>
      <Grid container flexDirection="column" gap="1em" width="100%">
        <DeckAccordion title="Screens">
          <ScreenTable
            items={screensToDisplay}
            onEditDeck={(data) =>
              setDeckItemFormModalState({
                isOpen: true,
                data: data,
                title: 'Edit Deck',
                buttonText: 'Update'
              })
            }
            onCloneDeck={(data) => {
              setDeckCloneFormModalState({
                isOpen: true,
                data: data,
                title: 'Clone Deck',
                buttonText: 'Clone'
              })
            }}
            screenContextMenu={
              <ScreenContextMenu handleEvent={(event, context) => handleScreenItemEvent(event, context)} />
            }
          />
        </DeckAccordion>

        <Grid container columnGap="1em" flexWrap="nowrap">
          <Grid sm="6" item>
            <DeckAccordion title="Recent Activity" defaultExpanded={false}>
              <Stack
                minHeight="0"
                minWidth="0"
                overflow="hidden"
                direction="column"
                sx={{ minHeight: '0', gap: '20px' }}>
                <RecentActivityTable
                  decks={filteredDecks}
                  deckRevisions={filteredDeckRevisions}
                  deckRevisionHistories={filteredDeckRevisionHistories}
                  sites={database.sites}
                  locations={database.locations}
                  isMobile={false}
                  showTitle={false}
                />
              </Stack>
            </DeckAccordion>
          </Grid>

          <Grid sm="6" item>
            <DeckAccordion title="Dialogue" defaultExpanded={false}>
              <Stack
                minHeight="0"
                minWidth="0"
                overflow="hidden"
                direction="column"
                sx={{ minHeight: '0', gap: '20px' }}>
                <CondensedDialogueTable
                  dialogue={dialogue?.filter(
                    (d) =>
                      filteredDecks
                        .map((fd) => fd.LocationId)
                        .includes(database.locations.find((l) => l.Name === d.Location)?.id) &&
                      (!equipmentId || d.Equipment === equipmentId)
                  )}
                  isMobile={false}
                  showTitle={false}
                />
              </Stack>
            </DeckAccordion>
          </Grid>
        </Grid>
      </Grid>
      {screenItemFormModalState.isOpen && (
        <FormDialog modalState={screenItemFormModalState} onOpenChange={handleScreenItemDialogClose}>
          <ScreenItemForm
            customers={
              customerId
                ? database.customers.filter((c) => c.id === customerId)
                : database.customers.filter((c) => currentUser.isAdmin || editableCustomers.includes(c.id))
            }
            sites={database.sites.filter(
              (s) =>
                (!siteId || siteId === s.id) &&
                (!customerId || customerId === s.CustomerId) &&
                (currentUser.isAdmin || editableSites.includes(s.id))
            )}
            locations={database.locations.filter(
              (l) => (!siteId || siteId === l.SiteId) && (!locationId || locationId === l.id)
            )}
            screens={database.screens}
          />
        </FormDialog>
      )}

      {deckItemFormModalState.isOpen && (
        <FormDialog modalState={deckItemFormModalState} onOpenChange={handleDeckItemDialogClose}>
          <CreateDeckForm includeSize={deckItemFormModalState?.isCreate ?? false} />
        </FormDialog>
      )}

      {deckCloneFormModalState.isOpen && (
        <FormDialog modalState={deckCloneFormModalState} onOpenChange={onCloneDeckComplete}>
          <CloneDeckForm customers={database.customers} sites={database.sites} locations={database.locations} />
        </FormDialog>
      )}
    </>
  )
}
export default ScreenAssetsPage
