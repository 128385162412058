/**
 *
 * "A modal form with dropdown lists to select deck in mobile version"
 *
 * @file   ChooseDeck.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import Grid from '@mui/material/Grid'
import { useForm, Controller } from 'react-hook-form'
import { Select, MenuItem, Typography, Button } from '@mui/material'

const DefaultString = ''
const CustomerString = 'CustomerId'
const LocationString = 'LocationId'
const SiteString = 'SiteId'
const EquipmentString = 'EquipmentId'
const DeckString = 'DeckId'

/*
 *A modal with dropdowns for selecting a Deck. onSubmit should update the DeckContext when submitting the form.
 */
export function ChooseDeck({ onSubmit, database }) {
  /**
   *  Generates a form with dropdown list to choose deck
   *
   * @function
   *
   * @param {} onSubmit - Action on submitting the form
   * @param {object[]} - Arrays of all decks, screens, customers, locations, and sites, objects
   *
   * @returns {object} - A modal form
   */
  const defaultValues = {
    [CustomerString]: DefaultString,
    [LocationString]: DefaultString,
    [SiteString]: DefaultString,
    [EquipmentString]: DefaultString,
    [DeckString]: DefaultString
  }
  const { watch, handleSubmit, control } = useForm({ defaultValues })

  //watch fields allow for dynamic dropdowns based on ID.
  const watchCustomerId = watch(CustomerString, DefaultString)
  const watchLocationId = watch(LocationString, DefaultString)
  const watchSiteId = watch(SiteString, DefaultString)
  const watchEquipmentId = watch(EquipmentString, DefaultString)
  const watchDeckId = watch(DeckString, DefaultString)

  const filteredDecks = () => {
    const filteredDecks = database.decks.filter(
      (d) => d.CustomerId === watchCustomerId && d.SiteId === watchSiteId && d.LocationId === watchLocationId
    )

    const results = [
      ...new Set(
        filteredDecks.map((f) => {
          return f.DeckHeader.EquipmentId
        })
      )
    ]
    return results.sort((a, b) => a.localeCompare(b))
  }

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Typography variant="h5" color="secondary" sx={{ marginTop: '1em' }}>
        No Deck Selected
      </Typography>
      <Typography sx={{ marginTop: '1em' }}>Select a Deck to start maintenance.</Typography>
      <Grid container spacing={1} alignItems="center" justifyItems="center" sx={{ marginTop: '1em' }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" color="secondary">
            Customer
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={CustomerString}
            control={control}
            defaultValue={DefaultString}
            render={({ field }) => (
              <Select {...field} color="secondary" fullWidth>
                <MenuItem key={`${DefaultString}`} value={DefaultString}>
                  Select a Customer
                </MenuItem>
                {database.customers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        {watchCustomerId ? (
          <>
            <Grid item xs={12} sx={{ marginTop: '1em' }}>
              <Typography variant="h5" component="h5" color="secondary">
                Site
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={SiteString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem key={`${DefaultString}`} value={DefaultString}>
                      Select a Site
                    </MenuItem>
                    {database.sites
                      .filter((s) => s.CustomerId === watchCustomerId)
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.Name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchSiteId ? (
          <>
            <Grid item xs={12} sx={{ marginTop: '1em' }}>
              <Typography variant="h5" component="h5" color="secondary">
                Location
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={LocationString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem key={`${DefaultString}`} value={DefaultString}>
                      Select a Location
                    </MenuItem>
                    {database.locations
                      .filter((l) => l.SiteId === watchSiteId)
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.Name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchLocationId ? (
          <>
            <Grid item xs={12} sx={{ marginTop: '1em' }}>
              <Typography variant="h5" component="h5" color="secondary">
                Equipment
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={EquipmentString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem value={DefaultString}>Select a Screen</MenuItem>
                    {filteredDecks().map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchEquipmentId ? (
          <>
            <Grid item xs={12} sx={{ marginTop: '1em' }}>
              <Typography variant="h5" component="h5" color="secondary">
                Deck
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={DeckString}
                control={control}
                defaultValue={DefaultString}
                render={({ field }) => (
                  <Select {...field} color="secondary" fullWidth>
                    <MenuItem value={DefaultString}>Select a Deck</MenuItem>
                    {database.decks
                      .filter(
                        (d) =>
                          d.CustomerId == watchCustomerId &&
                          d.SiteId == watchSiteId &&
                          d.LocationId == watchLocationId &&
                          d.DeckHeader.EquipmentId == watchEquipmentId
                      )
                      .map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.DeckHeader.DeckLevel} ${option.DeckHeader.MaterialNumber}`}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>
          </>
        ) : null}

        {watchDeckId ? (
          <Grid item xs={12} sx={{ marginTop: '2em' }}>
            <Button color="secondary" variant="contained" sx={{ width: '100%' }} type="submit">
              View Deck Layout
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </form>
  )
}
export default ChooseDeck
