/**
 *
 * "Parts Report page for viewing and interact Parts Form and export Parts Report."
 *
 * @file   PartsPage.js
 * @author Lateral
 * @since  2023
 */
import { Box, Paper, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import PartsForm from './components/parts/PartsForm'
import { useCurrentUser, useMaintenance } from 'hooks'
import { reportTypes } from './common/reportTypes'
import { ReportingContext } from 'components/reportingContext/ReportingContext'
import {
  allPanelsName,
  companyName,
  recentJobsName,
  shutEndDateName,
  shutStartDateName,
  siteName
} from './common/formNames'
import dayjs from 'dayjs'
import { createReport } from './common/data'
import BigNumber from 'bignumber.js'
import { CurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { jsonToCSV } from 'react-papaparse'
import { saveAs } from 'file-saver'

function PartsPage() {
  /**
   * Generates Parts Report page with Parts Form to view on desktop sized screen
   * and export Parts Report in a CSV file
   *
   * @function
   *
   * @returns {object} - Parts Report page
   */
  const theme = useTheme()
  const { database } = useContext(CurrentDeckContext)
  const { existingReport, setExistingReport } = useContext(ReportingContext)
  const { actions, getAction } = useMaintenance()
  const { getTransactionMetaData } = useCurrentUser()

  //generate the Report!
  async function generateData(form) {
    let itemsSummary = []
    const customer = database.customers.find((c) => c.id === form[companyName])
    const site = database.sites.find((s) => s.id === form[siteName])
    const startDate = dayjs(form[shutStartDateName])
    const endDate = dayjs(form[shutEndDateName])

    let model = existingReport?.Type === reportTypes.Parts ? structuredClone(existingReport) : {}
    model.Type = reportTypes.Parts
    model.CustomerId = customer.id
    model.Company = customer.Name
    model.SiteId = site.id
    model.Site = site.Name
    model.DateOfVisit = startDate.toISOString()
    model.EndDate = endDate.toISOString()
    model.AllPanels = form[allPanelsName]

    if (!form[allPanelsName]) {
      model.Selections = form[recentJobsName].filter((j) => j.Include).map((j) => j.HistoryId)
    }

    if (!model.Revision) {
      model.Revision = 0
    }
    if (existingReport && JSON.stringify(existingReport) !== JSON.stringify(model)) {
      model.Revision += 1
    }

    //create a new report if this is an edit, and something was changed.
    if (!existingReport || model.Revision !== existingReport?.Revision) {
      const result = await createReport(model, getTransactionMetaData())
      setExistingReport(result)
    }

    //allPanels gets all parts from all decks. Otherwise, gets the filtered ones.
    if (form[allPanelsName]) {
      const locations = database.locations.filter((l) => l.SiteId === site.id)
      const decks = database.decks.filter(
        (d) => d.CustomerId === customer.id && d.SiteId === site.id && locations.map((l) => l.id).includes(d.LocationId)
      )
      const deckRevisions = database.deckRevisions.filter(
        (dr) => decks.map((d) => d.id).includes(dr.DeckId) && endDate.isAfter(dr.createdAt)
      )
      const latestDeckRevisions = Object.values(
        deckRevisions.reduce((r, o) => {
          r[o.DeckId] = r[o.DeckId] && r[o.DeckId].RevisionNumber > o.RevisionNumber ? r[o.DeckId] : o

          return r
        }, {})
      )
      latestDeckRevisions.forEach((revision) => {
        const deck = decks.find((d) => d.id === revision.DeckId)
        revision.Kits.map((k) => {
          itemsSummary.push({
            Screen: deck.DeckHeader.EquipmentId,
            KitNumber: deck.DeckHeader.MaterialNumber,
            DeckLevel: deck.DeckHeader.DeckLevel,
            Revision: revision.RevisionNumber,
            Quantity: new BigNumber(revision.Panels.filter((p) => p.MaterialNumber === k.MaterialNumber).length)
              .plus(revision.SideLiners.filter((s) => s.MaterialNumber === k.MaterialNumber).length)
              .toNumber(),
            UnitPrice: k.UnitPrice,
            PartNumber: k.PartNumber,
            CustomerStockCode: k.CustomerStockCode,
            OpenArea: k.OpenArea,
            MaterialNumber: k.MaterialNumber,
            MaterialType: k.MaterialType,
            MaterialDescription: k.MaterialDescription,
            Colour: k.Colour,
            Weight: k.Weight,
            WorkingDepth: k.WorkingDepth,
            FailureWorkingDepth: k.FailureWorkingDepth,
            ApertureWidth: k.ApertureWidth,
            FailureApertureWidth: k.FailureApertureWidth,
            Height: k.Size?.Height ?? 1,
            Width: k.Size?.Width ?? 1
          })
        })
      })
    } else {
      form[recentJobsName]
        .filter((f) => f.Include)
        .forEach((job) => {
          const history = database.deckRevisionHistories.find((d) => d.id === job.HistoryId)
          const deckRevision = database.deckRevisions.find((d) => d.id === history.RevisionId)
          const deck = database.decks.find((d) => d.id === deckRevision.DeckId)
          const screen = database.screens.find((s) => s.id === deck.ScreenId)

          let materialChecked = []
          history.Details.forEach((detail) => {
            const materialNumber = detail.Panel?.MaterialNumber ?? detail.SideLiner?.MaterialNumber
            if (
              materialNumber &&
              detail.HistoryAction !== actions.NoChange.id &&
              !materialChecked.find(
                (i) => i.MaterialNumber === materialNumber && i.HistoryAction === detail.HistoryAction
              )
            ) {
              const similarDetails = history.Details.filter(
                (d) =>
                  (d.Panel?.MaterialNumber === materialNumber || d.SideLiner?.MaterialNumber === materialNumber) &&
                  d.HistoryAction === detail.HistoryAction
              )

              const action = getAction(detail.HistoryAction)

              const existing = itemsSummary.find(
                (i) =>
                  i.MaterialNumber === materialNumber &&
                  i.HistoryAction === detail.HistoryAction &&
                  i.SchenckNumber === screen.SchenckNumber
              )
              if (existing) {
                existing.Quantity = new BigNumber(existing.Quantity).plus(similarDetails.length).toNumber()
              } else {
                itemsSummary.push(
                  structuredClone({
                    MaterialNumber: materialNumber,
                    SchenckNumber: screen.SchenckSerial,
                    HistoryAction: detail.HistoryAction,
                    Description: action.state,
                    Quantity: similarDetails.length
                  })
                )
              }

              materialChecked.push({ MaterialNumber: materialNumber, HistoryAction: detail.HistoryAction })
            }
          })
        })
    }

    //render to CSV
    const csv = jsonToCSV(JSON.stringify(itemsSummary))
    const blob = new Blob([csv], { type: 'text/csv' })
    const fileName = `${customer.Name.replace('.', '-')} - ${site.Name.replace('.', '-')} - ${
      form[allPanelsName] ? 'All' : `${model.Selections.length} Jobs`
    }.csv`
    saveAs(blob, fileName)
  }

  return (
    <Box sx={{ height: '74%', width: '50%' }}>
      <Typography variant="h4" component="h4" sx={{ marginTop: '1em' }}>
        Parts Report
      </Typography>
      <Paper
        elevation={6}
        sx={{
          backgroundImage: 'none',
          height: '100%',
          marginTop: '0.5em',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.supporting.dark
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.supporting.pale,
            borderRadius: 2
          }
        }}>
        <PartsForm
          data={existingReport}
          customers={database.customers}
          sites={database.sites}
          locations={database.locations}
          decks={database.decks}
          deckRevisions={database.deckRevisions}
          deckRevisionHistories={database.deckRevisionHistories}
          generateData={generateData}
        />
      </Paper>
    </Box>
  )
}
export default PartsPage
