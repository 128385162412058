/**
 *
 * "Header for all pages. Shows the DeckSelector if required,
 * or the Breadcrumbs."
 *
 * @file   Header.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { AppBar, Divider, Link } from '@mui/material'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { useTheme } from '@mui/material/styles'
import { DeckSelector } from 'components'
import Logo from 'assets/logo/sandvik_symbol_white_rgb.svg'
import './Header.scss'

const StyledLink = ({ title, url }) => {
  return (
    <Box sx={{ backgroundColor: 'primary.contrastText', padding: '8px 16px' }}>
      <Link variant="body2" sx={{ fontWeight: 400 }} component={RouterLink} to={url}>
        {title}
      </Link>
    </Box>
  )
}

export const CustomPropsBreadcrumb = ({ match, title }) => {
  return <StyledLink key={match.pathname} title={title} url={match.pathname}></StyledLink>
}

export const DefaultBreadcrumbs = ({ routes }) => {
  const breadcrumbs = useBreadcrumbs(routes)

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="">
      <StyledLink title="Dashboard" url="/dashboard"></StyledLink>
      {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
    </Breadcrumbs>
  )
}

export const Header = ({
  routes,
  useDeckSelector = false,
  decks = [],
  screens = [],
  customers = [],
  locations = [],
  sites = []
}) => {
  /**
   * Generates the header elements
   *
   * @const
   *
   * @param {object} routes - Routes of the page for breadcrumbs
   * @param {boolean} useDeckSelector - True: Heade = DeckStelector, False: Header = Breadcrumbs
   * @param {object[]} decks - Array of deck objects
   * @param {object[]} screens - Array of listed screen objects
   * @param {object[]} customers - Array of customer objects
   * @param {object[]} sites - Array of location objects
   * @param {object[]} deckRevisions - Array of deck rivision records
   * @param {object[]} deckRevisions - Array of deck rivision records
   *
   * @returns Generated header element
   */
  const theme = useTheme()

  return (
    <Box
      mb={2}
      pt={2}
      position="sticky"
      top={0}
      zIndex={100000}
      bgcolor={theme.palette.supporting.dark}
      boxShadow="0px 10px 15px -3px rgba(0,0,0,0.1);">
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.supporting.dark,
          boxShadow: 'none',
          justifyContent: 'space-between',
          flexDirection: 'row',
          backgroundImage: 'none',
          alignItems: 'center',
          marginBottom: '8px'
        }}>
        {useDeckSelector ? (
          <DeckSelector decks={decks} screens={screens} customers={customers} locations={locations} sites={sites} />
        ) : routes ? (
          <DefaultBreadcrumbs routes={routes} />
        ) : (
          <Box />
        )}
        <RouterLink to="/dashboard">
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <img className={`main-logo header-logo`} src={Logo} />
          </Box>
        </RouterLink>

        {/* </Toolbar> */}
      </AppBar>

      <Divider />
    </Box>
  )
}

export default Header
