import { Roarr as log, getLogLevelName } from 'roarr'

const ROARR = (globalThis.ROARR = globalThis.ROARR || {})

export const defaultLoggerInit = () => {
  ROARR.write = (message) => {
    const payload = JSON.parse(message)
    const logLevel = getLogLevelName(payload?.context?.logLevel)
    if (logLevel === 'trace') {
      console.log(`%c${JSON.stringify(payload)}`, 'background: #DBDBDB')
    } else if (logLevel === 'debug') {
      console.debug(payload.message)
    } else if (logLevel === 'info') {
      console.info(payload.message)
    } else if (logLevel === 'warn') {
      console.warn(payload.message)
    } else if (logLevel === 'error') {
      console.error(payload.message)
    }
  }
}
export const trace = log.trace
export const debug = log.debug
export const info = log.info
export const warn = log.warn
export const error = log.error
export const fatal = log.fatal

export default log
