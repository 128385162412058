/**
 *
 * "CRUD (Create, read, update, and delete) for Screens table "
 *
 * @file   data.js
 * @author Lateral
 * @since  2023
 */
import { DataStore } from 'aws-amplify'
import { Decks, Equipment } from 'models'
import dayjs from 'dayjs'

export function groupBy(arr, prop) {
  const map = new Map(Array.from(arr, (obj) => [obj[prop], []]))
  arr.forEach((obj) => map.get(obj[prop]).push(obj))
  return Array.from(map.values())
}

export const addScreen = async (data, createdBy) => {
  /**
   * Add new screen to datasotre as equipment to view in Screen Assets page
   *
   * @const
   * @async
   *
   * @param {object} data - Screen asset/ Equipment object created from add screen modal to view recent
   * @param {string} data.name - Screen name
   * @param {string} data.locationId - ID of screen location
   * @param {string} data.ScreenId - ID of screen
   * @param {string} data.DWGNumber - Scheck DWG number
   * @param {string} data.SchenckSerial - Scheck serial number
   * @param {string} data.SiteSerial - Site serial number
   * @param {string} data.Exciters - Esciter number
   * @param {object} data.DateInstalled - Date of installation
   * @param {string} data.ScreenTonnage - Tonnage of screen in tone
   * @param {string} data.ScreenCapacity - Capacity of screen in tone
   * @param {string} data.MaintenanceFrequency - Grequency of Maintenance
   * @param {string} data.SiteId - SiteId of screen
   *
   *
   * @returns {object} - Add new screen to datasotre as equipment
   */
  const newScreen = await DataStore.save(
    new Equipment({
      Name: data.Name,
      CustomerId: data.CustomerId,
      LocationId: data.LocationId,
      ScreenId: data.ScreenId,
      DWGNumber: data.DWGNumber,
      SchenckSerial: data.SchenckSerial,
      SiteSerial: data.SiteSerial,
      Exciters: data.Exciters,
      DateInstalled: dayjs(data.DateInstalled).toISOString(),
      ScreenTonnage: data.ScreenTonnage,
      ScreenCapacity: data.ScreenCapacity,
      MaintenanceFrequency: data.MaintenanceFrequency,
      LastUpdatedBy: createdBy,
      SiteId: data.SiteId
    })
  )

  return newScreen
}

export const updateScreen = async (data, updatedBy) => {
  /**
   * Add new screen to datasotre as equipment to view in Screen Assets page
   *
   * @const
   * @async
   *
   * @param {object} data - Screen asset object created from add screen modal to view recent
   * @param {string} data.id - Screen Asstes ID in datastore
   * @param {string} data.name - Screen name
   * @param {string} data.locationId - ID of screen location
   * @param {string} data.ScreenId - ID of screen
   * @param {string} data.DWGNumber - Scheck DWG number
   * @param {string} data.SchenckSerial - Scheck serial number
   * @param {string} data.SiteSerial - Site serial number
   * @param {string} data.Exciters - Esciter number
   * @param {object} data.DateInstalled - Date of installation
   * @param {string} data.ScreenTonnage - Tonnage of screen in tone
   * @param {string} data.ScreenCapacity - Capacity of screen in tone
   * @param {string} data.MaintenanceFrequency - Grequency of Maintenance
   *
   *
   *
   * @returns {object} - Add new screen to datasotre as equipment
   */
  const original = await DataStore.query(Equipment, data.id)
  await DataStore.save(
    Equipment.copyOf(original, (updated) => {
      ;(updated.Name = data.Name),
        (updated.CustomerId = data.CustomerId),
        (updated.LocationId = data.LocationId),
        (updated.ScreenId = data.ScreenId),
        (updated.DWGNumber = data.DWGNumber),
        (updated.SchenckSerial = data.SchenckSerial),
        (updated.SiteSerial = data.SiteSerial),
        (updated.Exciters = data.Exciters),
        (updated.DateInstalled = dayjs(data.DateInstalled).toISOString()),
        (updated.ScreenTonnage = data.ScreenTonnage),
        (updated.ScreenCapacity = data.ScreenCapacity),
        (updated.MaintenanceFrequency = data.MaintenanceFrequency),
        (updated.LastUpdatedBy = updatedBy)
    })
  )
}

export const updateDecks = async (originalName, newName, screenId, updatedBy) => {
  /**
   * Update deck under screen assets
   *
   * @const
   * @async
   *
   * @param {string} originalName - Original name of deck
   * @param {string} newName - New name of deck
   * @param {string} screenId - ID of screen
   *
   */

  const allDecks = await DataStore.query(Decks)
  const decks = allDecks.filter((a) => a.DeckHeader.EquipmentId === originalName)

  //This causes multiple frontend updates, but DataStore doesn't have batch updating.
  //TODO: Update if DataStore supports batch.
  for (const deck of decks) {
    await DataStore.save(
      Decks.copyOf(deck, (updated) => {
        ;(updated.DeckHeader.EquipmentId = newName),
          (updated.ScreenId = screenId),
          (updated.DeckHeader.ScreenId = screenId),
          (updated.LastUpdatedBy = updatedBy)
      })
    )
  }
}
