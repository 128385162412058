/**
 *
 * "Warning Messages"
 *
 * @file   messaging.js
 * @author Lateral
 * @since  2023
 */

export const loseDataWarning = 'Please ensure you have saved all changes. Are you sure you want to continue?'
