import React, { useState } from 'react'
import { AnalysisDrawer } from './AnalysisDrawer'
import { DrawerItem } from './DrawerItem'
import { ReplacePanelResults } from './ReplacePanelDecisionResults'
import { Close } from '@mui/icons-material'
import { useWearAppAnalysisContext } from '../../WearAppAnalysisContext'

export const WearDrawer = (props) => {
  const {
    currentAnalysisResultsRef: {
      current: { depths }
    }
  } = useWearAppAnalysisContext()
  // const selectedPanel = useSelector(
  //   (state: WearAppState) => state.analysis.selectedPanel
  // )

  const [showDepths, setShowDepths] = useState(true)

  return (
    <AnalysisDrawer {...props} setShowResults={setShowDepths} showResults={showDepths}>
      <div>
        <ReplacePanelResults detail>
          <Close
            style={{
              cursor: 'pointer'
            }}
            onClick={() => setShowDepths(false)}
          />
        </ReplacePanelResults>

        {/* <DrawerItem isGray title='Panel No:'>
          <p>{selectedPanel}</p>
        </DrawerItem> */}

        <DrawerItem title="Average Panel Wear:">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
            {depths?.less_topzone_mean ? (
              <p>
                <b>&lt;&nbsp;</b>
              </p>
            ) : null}
            {depths?.more_wd_mean ? (
              <p>
                <b>&gt;&nbsp;</b>
              </p>
            ) : null}
            <p>{depths?.depth_mean?.toFixed(2)}mm</p>
          </div>
        </DrawerItem>

        <DrawerItem isGray title="95th Percentile Panel Wear:">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
            {depths?.less_topzone_p95 ? (
              <p>
                <b>&lt;&nbsp;</b>
              </p>
            ) : null}
            {depths?.more_wd_p95 ? (
              <p>
                <b>&gt;&nbsp;</b>
              </p>
            ) : null}
            <p>{depths?.depth_p95?.toFixed(2)}mm</p>
          </div>
        </DrawerItem>
      </div>
    </AnalysisDrawer>
  )
}
