/**
 *
 * "CRUD (Create, read, update, and delete) for Dialogue table"
 *
 * @file   data.js
 * @author Lateral
 * @since  2023
 */
import { DataStore } from 'aws-amplify'
import { Dialogue } from 'models'
import dayjs from 'dayjs'

export const addDialogue = async (data, createdBy) => {
  /**
   * Create new dialogue
   *
   * @const
   * @async
   *
   * @param {object} data - Object with dialogue components
   * @param {string} data.date - Date of dialouge
   * @param {string} data.user - Username of dialogue creator
   * @param {string} data.Location - Screen location of dialogue
   * @param {string} data.Equipment - Screen name of dialogue
   * @param {string} data.Area - Area of dialogue screen/exciters
   * @param {string} data.Type - Dialogue type Problem/Warning/Diagnostic/Observation
   * @param {string} data.Description - Dialogue description
   * @param {string} data.Comment - Dialogue comment
   * @param {string} data.Outcome - Dialogue outcome Unsuccessful/ Successful/ Temporary Fix
   * @param {string} data.Status - Dialogue comment
   * @param {string} data.Comment - Dialogue status open/closed
   * @param {object} data.children - Replies of original dialogue as dialogue objects
   * @returns {object} - A dialogue object
   */
  const newDialogue = await DataStore.save(
    new Dialogue({
      Date: data.Date ? data.Date.toISOString() : dayjs().toISOString(),
      User: data.User,
      CustomerId: data.CustomerId,
      Location: data.Location,
      Equipment: data.Equipment,
      Area: data.Area,
      Type: data.Type,
      Description: data.Description,
      Comment: data.Comment,
      Action: data.Action,
      Outcome: data.Outcome,
      Status: data.Status,
      Children: data.Children,
      LastUpdatedBy: createdBy
    })
  )

  return newDialogue
}

export const updateDialogue = async (data, updatedBy) => {
  /**
   * Updates an existing dialogue in Datastore
   *
   * @const
   * @async
   *
   * @param {object} data - Object with dialogue components
   * @param {string} data.id - Dialogue ID
   * @param {string} data.date - Date of dialouge
   * @param {string} data.user - Username of dialogue creator
   * @param {string} data.Location - Screen location of dialogue
   * @param {string} data.Equipment - Screen name of dialogue
   * @param {string} data.Area - Area of dialogue screen/exciters
   * @param {string} data.Type - Dialogue type Problem/Warning/Diagnostic/Observation
   * @param {string} data.Description - Dialogue description
   * @param {string} data.Comment - Dialogue comment
   * @param {string} data.Outcome - Dialogue outcome Unsuccessful/ Successful/ Temporary Fix
   * @param {string} data.Status - Dialogue comment
   * @param {string} data.Comment - Dialogue status open/closed
   */
  const original = await DataStore.query(Dialogue, data.id)
  await DataStore.save(
    Dialogue.copyOf(original, (updated) => {
      ;(updated.Date = dayjs(data.Date).toISOString()),
        (updated.User = data.User),
        (updated.CustomerId = data.CustomerId),
        (updated.Location = data.Location),
        (updated.Equipment = data.Equipment),
        (updated.Area = data.Area),
        (updated.Type = data.Type),
        (updated.Description = data.Description),
        (updated.Comment = data.Comment),
        (updated.Action = data.Action),
        (updated.Outcome = data.Outcome),
        (updated.Status = data.Status),
        (updated.LastUpdatedBy = updatedBy)
    })
  )
}

export const updateChildDialogue = async (data, updatedBy) => {
  /**
   * Updates an reply dialogue of an existing dialogue in Datastore
   *
   * @const
   * @async
   *
   * @param {object} data - Object with dialogue components
   * @param {string} data.id - Origianl dialogue ID
   * @param {object} data.children - Reply dialogue objects of a original dialogue
   */
  const original = await DataStore.query(Dialogue, data.id)
  await DataStore.save(
    Dialogue.copyOf(original, (updated) => {
      updated.LastUpdatedBy = updatedBy
      if (!updated.Children) {
        updated.Children = data
        return
      }
      if (!original.Children.length) {
        updated.Children = [original.Children, data]
        return
      }
      updated.Children = [...original.Children, data]
    })
  )
}

export const deleteDialogue = async (data) => {
  /**
   * Delete dialogue
   *
   * @const
   * @async
   *
   * @param {object} data - Object with dialogue components
   * @param {string} data.id - Origianl dialogue ID
   */
  const original = await DataStore.query(Dialogue, data.id)
  await DataStore.delete(original)
}
