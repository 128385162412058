import React from 'react'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'

export const ReplacePanelDecision = ({ replacePanelData, setReplacePanelData }) => {
  return (
    <Grid
      style={{
        marginTop: '1em'
      }}
      container
      item
      direction="column"
      spacing={2}>
      <Grid item>
        <Typography variant="h6">Panel Remaining Life</Typography>
      </Grid>
      <Grid item>
        <DatePicker
          label="Panel Install Date"
          maxDate={dayjs(Date.now())}
          value={dayjs(replacePanelData.panelInstallDate)}
          onChange={(newValue) => {
            setReplacePanelData((prev) => ({
              ...prev,
              panelInstallDate: newValue?.valueOf()
            }))
          }}
          format="DD/MM/YYYY"
        />
      </Grid>

      <Grid item>
        <DatePicker
          label="Next Shutdown Date"
          value={dayjs(replacePanelData.nextShutDownDate)}
          onChange={(newValue) => {
            setReplacePanelData((prev) => ({
              ...prev,
              nextShutDownDate: newValue?.valueOf()
            }))
          }}
          format="DD/MM/YYYY"
        />
      </Grid>

      <Grid item>
        <TextField
          onChange={(event) =>
            setReplacePanelData((prev) => ({
              ...prev,
              safetyDepth: event.target.value
            }))
          }
          onKeyDown={(e) => {
            if (e.key === '-' || e.key === 'e') {
              e.preventDefault()
            }
          }}
          label="Safety Depth (mm)"
          type="number"
          value={replacePanelData.safetyDepth || '0'}
          inputProps={{
            min: 0
          }}
          style={{
            width: '100%'
          }}
        />
      </Grid>
    </Grid>
  )
}
