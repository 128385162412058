/**
 *
 * "Admin page with link to other admin action pages"
 *
 * @file   AdminPage.js
 * @author Lateral
 * @since  2023
 */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Link, Outlet } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { defaultRoutes } from 'pages'
import { Header, SCOPES } from 'components'
import { useCurrentUser } from 'hooks'

function LinkTab(props) {
  return <Tab sx={{ pl: 0, pr: 2 }} component={Link} {...props} />
}

const AdminPage = () => {
  /**
   * Generates a page vewing admin links
   *
   * @const
   *
   * @returns {object} - React app page
   */
  const location = useLocation()
  const { currentUser } = useCurrentUser()
  const hasRoles = currentUser?.hasAllScopes([SCOPES.roleManagement.Read])
  const hasUsers = currentUser?.hasAllScopes([SCOPES.userManagement.Read])
  const hasSites = currentUser?.hasAllScopes([SCOPES.siteManagement.Read])
  const hasMaterial = currentUser?.hasAllScopes([SCOPES.materialManagement.Read])

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    let initialValue = hasRoles + hasUsers + hasSites + hasMaterial

    if (location.pathname === '/admin/accounts/roles') {
      initialValue = 0
    } else if (location.pathname === '/admin/accounts/users') {
      initialValue = hasRoles ? 1 : 0
    } else if (location.pathname === '/admin/accounts/sites') {
      initialValue = hasRoles + hasUsers
    } else if (location.pathname === '/admin/accounts/materials') {
      initialValue = hasRoles + hasUsers + hasMaterial
    } else if (location.pathname === '/admin/accounts') {
      initialValue = hasRoles + hasUsers + hasMaterial + hasSites
    }

    setValue(initialValue)
  }, [hasRoles, hasUsers, hasSites, hasMaterial])

  return (
    <Box sx={{ width: '100%', height: '100%', px: '15px' }}>
      <Header routes={defaultRoutes} />
      <Typography variant="h1">Admin</Typography>
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
        {hasRoles ? <LinkTab label="Manage Roles" to="roles" /> : null}
        {hasUsers ? <LinkTab label="Manage Users" to="users" /> : null}
        {hasSites ? <LinkTab label="Manage Sites" to="sites" /> : null}
        {hasMaterial ? <LinkTab label="Manage Materials" to="materials" /> : null}
        <LinkTab label="Account" to="" />
      </Tabs>
      <Outlet />
    </Box>
  )
}
export default AdminPage
