/**
 *
 * "common names in the Reporting pages"
 *
 * @file   AllReportsTable.js
 * @author Lateral
 * @since  2023
 */
export const defaultString = ''
export const shutStartDateName = 'Shut Start Date'
export const shutEndDateName = 'Shut End Date'
export const companyName = 'Company'
export const siteName = 'Site'
export const siteContactName = 'Site Contact'
export const siteRepresentativeName = 'Sandvik Site Representative'
export const introductionName = 'Introduction'
export const scopeOfWorkName = 'Scope of Work'
export const safetyIssuesName = 'Safety Issues'
export const crewDetailsName = 'Crew Details'
export const shutdownCommentsName = 'Shutdown Comments'
export const recommendationsName = 'Recommendations'
export const recentJobsName = 'Jobs'
export const screensName = 'Screens'
export const allPanelsName = 'AllPanels'
export const titleName = 'Title'
