/**
 *
 * "Various maintenance function and logics"
 *
 * @file   logic.js
 * @author Lateral
 * @since  2023
 */
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import { HistoryDetailItem, HistoryPhotoItem } from 'models'
import {
  crossbeamName,
  dateNextShutDownName,
  datePerformedName,
  deckRailsName,
  descriptionName,
  includeCrossbeams,
  includeDeckRails,
  includeRecommendations,
  photoKeysName,
  postServiceName,
  preServiceName,
  recommendationName,
  tonnageName
} from './formNames'

/*
 * Updates a maintenance with incoming data.
 */
export function setHistoryWithForm(form, maintenanceData) {
  /**
   * Updates a maintenance with incoming deck context data
   *
   * @function
   *
   * @param {} form - Form to get deck revision hystory
   * @param {object} maintenanceData - deckRevisionHistory object
   * @returns {object} - React app page
   *
   */
  const clone = structuredClone(maintenanceData)
  clone.deckRevisionHistory.CrossbeamComments = form[includeCrossbeams] ? form[crossbeamName] : ''
  clone.deckRevisionHistory.DatePerformed = dayjs(form[datePerformedName]).toISOString()
  clone.deckRevisionHistory.DeckRailsComments = form[includeDeckRails] ? form[deckRailsName] : ''
  clone.deckRevisionHistory.Description = form[descriptionName]
  clone.deckRevisionHistory.PostServiceComments = form[postServiceName]
  clone.deckRevisionHistory.PreServiceComments = form[preServiceName]
  clone.deckRevisionHistory.Recommendations = form[includeRecommendations] ? form[recommendationName] : ''
  clone.deckRevisionHistory.Tonnage = form[tonnageName]
  clone.deckRevisionHistory.NextShutDownDate = dayjs(form[dateNextShutDownName]).toISOString()
  if (!clone.deckRevisionHistory.Details) {
    clone.deckRevisionHistory.Details = []
  }
  clone.photos = form[photoKeysName]
  return clone
}

/*
 * Adds an Action to the Details list in a Maintenance. Includes swapping logic for Swap/Relocate.
 */
export function addMaintenanceAction(action, awaitingActionId, selected, maintenanceData, actions, extraData) {
  selected = Array.isArray(selected) ? selected : [selected]
  /**
   * Genberates a form to create new deck/ update existing deck
   *
   * @function
   *
   * @param {action} action - Details of action choose from radial menu
   * @param {number} awaitingActionId - Maintenance action IDs
   *                                    MarkForReplacement:0, NoChange:7, Relocate:5
   *                                    RelocateTo:6, Replace:1, Revert:2, Rotate:3,
   *                                    Swap:4.
   * @param {object[]} selected - Array of selected panel objects
   * @param {object} maintenanceData - deckRevisionHistory object
   * @param {object[]} actions - List of all mentnance actions
   * @param {number} actions.id - Id of the action from awaitingActionId
   * @param {string} actions.img - Address of the action image in data
   * @param {string} actions.state - Detailed name of the action
   *
   * @returns {object} - Deck revision history object generated by action
   */
  if (Object.entries(selected).length) {
    let clone = structuredClone(maintenanceData)
    const ids = [actions.Swap.id, actions.Relocate.id]
    if (ids.includes(action.id)) {
      const first = selected[0]
      const panel = first.Position ? first : null
      const sideliner = first.StartPosition !== null && first.StartPosition !== undefined ? first : null

      const existing = findExistingAndRemove(clone.deckRevisionHistory, panel, sideliner)

      let firstModel = {
        HistoryAction: action.id,
        Panel: panel,
        SideLiner: sideliner,
        SwappedPanel: null,
        SwappedSideLiner: null,
        OriginalWorkingDepth: existing?.OriginalWorkingDepth,
        OriginalApertureWidth: existing?.OriginalApertureWidth,
        WorkingDepth: existing?.WorkingDepth,
        ApertureWidth: existing?.ApertureWidth,
        PanelReplaceDate: extraData?.PanelReplaceDate,
        WearAppResponse: existing?.WearAppResponse
      }

      if (action.id === awaitingActionId) {
        let originalModel = clone.deckRevisionHistory.Details[clone.deckRevisionHistory.Details.length - 1]
        const originalPanel = originalModel?.Panel
        const originalSideLiner = originalModel?.SideLiner

        if ((panel && originalPanel) || (sideliner && originalSideLiner)) {
          originalModel.SwappedPanel = panel
          originalModel.SwappedSideLiner = sideliner
          firstModel.SwappedPanel = originalPanel
          firstModel.SwappedSideLiner = originalSideLiner
          if (action.id === actions.Relocate.id) {
            firstModel.HistoryAction = actions.RelocateTo.id
          }
          clone.deckRevisionHistory.Details.push(firstModel)
          return { clone: clone, actionId: -2 }
        }
      } else {
        clone.deckRevisionHistory.Details.push(firstModel)
        return { clone: clone, actionId: action.id }
      }
    } else {
      selected.map((s) => {
        const panel = s.Position ? s : null
        const sideliner = s.StartPosition !== null && s.StartPosition !== undefined ? s : null
        const existing = findExistingAndRemove(clone.deckRevisionHistory, panel, sideliner)
        const details = {
          HistoryAction: action.id,
          Panel: panel,
          SideLiner: sideliner,
          SwappedPanel: null,
          SwappedSideLiner: null,
          OriginalWorkingDepth: existing?.OriginalWorkingDepth,
          OriginalApertureWidth: existing?.OriginalApertureWidth,
          WorkingDepth: existing?.WorkingDepth,
          ApertureWidth: existing?.ApertureWidth,
          PanelReplaceDate: extraData?.PanelReplaceDate,
          WearAppResponse: existing?.WearAppResponse
        }
        if (extraData) {
          delete extraData.PanelReplaceDate
          details.extraData = extraData
        }
        clone.deckRevisionHistory.Details.push(new HistoryDetailItem(details))
      })
    }
    return { clone, actionId: -1 }
  }

  return null
}

/*
 * Adds a Wear action to a Maintenance.
 */
export function addWearAction(form, maintenanceData, selected, noChangeId) {
  let clone = structuredClone(maintenanceData)

  selected.map((s) => {
    const panel = s.Position ? s : null
    const sideliner = s.StartPosition !== null && s.StartPosition !== undefined ? s : null
    const existing = findExistingAndRemove(clone.deckRevisionHistory, panel, sideliner)
    clone.deckRevisionHistory.Details.push(
      new HistoryDetailItem({
        HistoryAction: existing?.HistoryAction ?? noChangeId,
        Panel: panel,
        SideLiner: sideliner,
        SwappedPanel: existing?.SwappedPanel,
        SwappedSideLiner: existing?.SwappedSideLiner,
        OriginalWorkingDepth: new BigNumber(form.OriginalDepth).toNumber(),
        OriginalApertureWidth: new BigNumber(form.OriginalWidth).toNumber(),
        WorkingDepth: new BigNumber(form.WorkingDepth).toNumber(),
        ApertureWidth: new BigNumber(form.ApertureWidth).toNumber(),
        WearAppResponse: 'add-wear-action'
      })
    )
  })

  return clone
}

/**
 *  Adds a WearApp action to a Maintenance.
 *
 * @function
 *
 * @param {object} data - Response from wearApp
 * @param {object} maintenanceData - deckRevisionHistory object
 * @param {object[]} selected - Array of selected panel objects
 * @param {number} noChangeId - Id of no change action
 * @param {object} deckRevision - Object of deckrevion details
 * @param {object} deck - Deck object to include wearApp capture
 * @param {string} deck.customerId - Cusotmer ID of the deck photo captured from
 * @param {string} deck.id - Deck ID
 *
 * @returns {object} - Deck revision history object generated by wearApp input
 */
/*
 * Adds a WearApp action to a Maintenance.
 */
export function addWearAppAction(data, maintenanceData, selected, historyActionId, deckRevision, panelReplaceDate) {
  const clone = structuredClone(maintenanceData)
  data = structuredClone(data)

  delete data.heatMapImageUrl
  delete data.appertureDetectionImageUrl
  delete data.histogramImageUrl

  const WearAppResponsePhotos = []

  //upload photos returned by WearApp
  WearAppResponsePhotos.push(
    new HistoryPhotoItem({
      Key: data.imageKeys.im_aperture_detection,
      Caption: 'im_aperture_detection'
    })
  )
  WearAppResponsePhotos.push(
    new HistoryPhotoItem({
      Key: data.imageKeys.im_heatmap,
      Caption: 'im_heatmap'
    })
  )
  WearAppResponsePhotos.push(
    new HistoryPhotoItem({
      Key: data.imageKeys.im_histogram,
      Caption: 'im_histogram'
    })
  )
  const panel = selected.Position ? selected : null
  const sideliner = selected.StartPosition !== null && selected.StartPosition !== undefined ? selected : null
  const existing = removeWearAppResults(clone.deckRevisionHistory, panel)
  const materialNumber = selected.MaterialNumber
  const kit = deckRevision.Kits.find((k) => k.MaterialNumber === materialNumber)
  const originalWorkingDepth = kit?.WorkingDepth
    ? new BigNumber(kit?.WorkingDepth).toNumber()
    : data.panel.Working_Depth_Lig

  clone.deckRevisionHistory.Details.push(
    new HistoryDetailItem({
      HistoryAction: historyActionId,
      Panel: panel,
      SideLiner: sideliner,
      SwappedPanel: existing?.SwappedPanel,
      SwappedSideLiner: existing?.SwappedSideLiner,
      WearAppPhotoKey: data.photoKey,
      WearAppResponsePhotos: WearAppResponsePhotos,
      WearAppResponse: JSON.stringify(data),
      OriginalWorkingDepth: originalWorkingDepth,
      OriginalApertureWidth: new BigNumber(kit?.ApertureWidth ?? data.panel.Aperture_Size_W1).toNumber(),
      WorkingDepth: new BigNumber(originalWorkingDepth - data.depths?.depth_p95).toNumber(),
      ApertureWidth: new BigNumber(data.results.w_mean).toNumber(),
      PanelReplaceDate: panelReplaceDate
    })
  )

  clone.deckRevisionHistory.NextShutDownDate = dayjs(clone.deckRevisionHistory.NextShutDownDate).toISOString()

  return clone
}

/*
 * Searches incoming data for an existing Panel/Sideliner.
 */

export function findExisting(data, panel, sideliner) {
  return (
    data.Details.find(
      (d) =>
        (panel && d.Panel?.Position.Column === panel.Position.Column && d.Panel?.Position.Row === panel.Position.Row) ||
        (sideliner && d.SideLiner?.StartPosition === sideliner.StartPosition && d.SideLiner?.Side === sideliner.Side)
    ) ?? {}
  )
}

/*
 * Searches incoming data for an existing Panel/Sideliner, and removes it if it exists.
 */
export function findExistingAndRemove(data, panel, sideliner) {
  /**
   *  Searches incoming data for an existing Panel/Sideliner, and removes it if it exists.
   *
   * @function
   *
   * @param {object[]} data - Panels and sideliners in wearApp database of deck in context
   * @param {object} panel - Panel search for
   * @param {object} sideliner - Sideliner search for
   *
   * @returns {boolean} - Returns True if the panel/sideliner found in database else False.
   */
  const existing = findExisting(data, panel, sideliner)

  if (Object.entries(existing).length) {
    //remove existing and swapped if applicable
    data.Details = data.Details.filter(
      (d) =>
        !(
          existing.Panel &&
          d.Panel?.Position.Column === existing.Panel.Position.Column &&
          d.Panel?.Position.Row === existing.Panel.Position.Row
        ) &&
        !(
          existing.SideLiner &&
          d.SideLiner?.StartPosition === existing.SideLiner.StartPosition &&
          d.SideLiner?.Side === existing.SideLiner.Side
        ) &&
        !(
          existing.SwappedPanel &&
          d.Panel?.Position.Column === existing.SwappedPanel.Position.Column &&
          d.Panel?.Position.Row === existing.SwappedPanel.Position.Row
        ) &&
        !(
          existing.SwappedSideLiner &&
          d.SideLiner?.StartPosition === existing.SwappedSideLiner.StartPosition &&
          d.SideLiner?.Side === existing.SwappedSideLiner.Side
        )
    )
  }

  return existing
}

export const removeWearAppResults = (deckRevisionHistory, panel) => {
  const existing = deckRevisionHistory.Details.find(({ Panel }) => isPanelTheSame(Panel, panel))
  deckRevisionHistory.Details = deckRevisionHistory.Details.filter(({ Panel }) => !isPanelTheSame(Panel, panel))

  return existing
}

export const isPanelTheSame = (panel1, panel2) =>
  panel1?.Position?.Column === panel2?.Position?.Column && panel1?.Position?.Row === panel2?.Position?.Row
