/**
 *
 * "A form to add/edit help category.""
 *
 * @file   CategoryItemForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm } from 'react-hook-form'
import { Typography } from '@mui/material'

export const CategoryItemForm = ({ data, onSubmit }) => {
  /**
   * Generates a form to add or edit help category
   *
   * @function
   *
   * @param {object} data - Existing category object for edit
   * @param {} onSubmit - Action on submitting form
   *
   * @returns {object} - A form object
   */
  const defaultValues = {
    category: data?.category ?? ''
  }
  const { register, handleSubmit } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography type="h5">Name</Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="category"
            name="category"
            placeholder="E.g. Service Support"
            color="secondary"
            fullWidth
            {...register('category', { required: true })}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default CategoryItemForm
