import React from 'react'
import { MaintenanceDialog } from '../../MaintenanceDialog'
import { Typography, Button } from '@mui/material'

export const ErrorDialog = React.memo(
  function ErrorDialog_({ onOkPressed, error }) {
    if (!error) {
      return null
    }
    return (
      <MaintenanceDialog
        open
        title={'Wear App - Eror'}
        Actions={
          <>
            <Button variant="outlined" color="secondary" onClick={onOkPressed}>
              Ok
            </Button>
          </>
        }>
        <Typography>{error}</Typography>
      </MaintenanceDialog>
    )
  },
  (prev, next) => prev.error === next.error
)
