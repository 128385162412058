/**
 *
 * "A modal form to clone deck in screen assets"
 *
 * @file   CloneDeckForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm, Controller } from 'react-hook-form'
import { MenuItem, Select, Typography } from '@mui/material'

export const CloneDeckForm = ({ data, locations, customers, sites, onSubmit }) => {
  /**
   * Add new screen to datasotre as equipment to view in Screen Assets page
   *
   * @const
   * @async
   *
   * @param {object} data - Deck object
   * @param {string} data.id - Deck ID
   * @param {string} data.RevisionId - Deck revision ID
   * @param {string} data.CompanyId - Deck owner company ID
   * @param {string} data.SiteId - Deck site ID
   * @param {string} data.LocationId - Deck location ID
   * @param {string} data.EquipmentId - Screen name
   * @param {string} data.MaterialNumber - Kit number
   * @param {string} data.SerialNumber - Scheck serial number
   *
   * @returns {object} - A modal form
   */
  const defaultValues = {
    ExistingId: data?.id ?? '',
    RevisionId: data?.RevisionId ?? '',
    CompanyId: data?.CompanyId ?? '',
    SiteId: data?.SiteId ?? '',
    LocationId: data?.LocationId ?? '',
    EquipmentId: data?.EquipmentId,
    MaterialNumber: '',
    SerialNumber: ''
  }
  const { register, handleSubmit, control, watch } = useForm({ defaultValues })

  const watchCompany = watch('CompanyId', '')
  const watchSite = watch('SiteId', '')
  const companyIdForFilter = watchCompany.length ? watchCompany : data?.CompanyId
  const siteIdForFilter = watchSite.length ? watchSite : data?.SiteId

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Company
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="CompanyId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {customers.map((l) => {
                  return (
                    <MenuItem key={l.id} value={l.id}>
                      {l.Name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Site
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="SiteId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {sites
                  .filter((s) => s.CustomerId === companyIdForFilter)
                  .map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.Name}
                      </MenuItem>
                    )
                  })}
              </Select>
            )}
          />
        </Grid>

        <Grid item xs={12} md={3} sx={{ marginTop: '0.5em' }}>
          <Typography variant="h5" component="h5">
            Location
          </Typography>
        </Grid>
        <Grid item xs={12} md={9} sx={{ marginBottom: '0.5em' }}>
          <Controller
            control={control}
            name="LocationId"
            render={({ field }) => (
              <Select sx={{ width: '100%' }} required {...field}>
                {locations
                  .filter((l) => l.SiteId === siteIdForFilter)
                  .map((l) => {
                    return (
                      <MenuItem key={l.id} value={l.id}>
                        {l.Name}
                      </MenuItem>
                    )
                  })}
              </Select>
            )}
          />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Equipment Id
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="EquipmentId"
            name="EquipmentId"
            placeholder="Input Equipment Id"
            color="secondary"
            fullWidth
            {...register('EquipmentId')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Material Number
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="MaterialNumber"
            name="MaterialNumber"
            placeholder="Input Material Number"
            color="secondary"
            required
            fullWidth
            {...register('MaterialNumber')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Serial Number
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="SerialNumber"
            name="SerialNumber"
            placeholder="Input Serial Number"
            color="secondary"
            fullWidth
            {...register('SerialNumber')}
          />
        </Grid>
      </Grid>
    </form>
  )
}
export default CloneDeckForm
