import React, { useRef } from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import { DeckMode } from 'common/deckMode'
import { useMaintenance } from 'hooks'
import BigNumber from 'bignumber.js'
import { getWearColour, pickHeatColour } from 'common/heatColours'

const RowItem = ({ kits, col, materialSelected, mode, scale, maintenance, heatMapData, selected, onClick }) => {
  const ref = useRef(null)
  const isSelected =
    selected.filter(
      (s) =>
        s.MaterialNumber == col?.MaterialNumber &&
        s.Position?.Column === col?.Position?.Column &&
        s.Position?.Row === col?.Position?.Row
    ).length > 0
  const theme = useTheme()
  const { actions, getAction } = useMaintenance()
  const interactionColour = theme.palette.secondary.main
  const emptyColour = theme.palette.primary.dark
  const kit = kits.find((k) => k.MaterialNumber === col?.MaterialNumber)
  const panelSize = kit?.Size
  const isMaterialSelected = materialSelected === col?.MaterialNumber

  let colour =
    isSelected || isMaterialSelected
      ? interactionColour
      : mode.includes(DeckMode.maintenance)
      ? emptyColour
      : kit?.Colour ?? emptyColour

  const height = `${new BigNumber(panelSize?.Height ?? 1).multipliedBy(scale).toNumber()}em`
  const width = `${new BigNumber(panelSize?.Width ?? 1).multipliedBy(scale).toNumber()}em`

  let maintenanceAction = null

  //if in Maintenance mode, change the colours
  if (mode.includes(DeckMode.maintenance) && maintenance) {
    const column = new BigNumber(col.Position.Column)
    const row = new BigNumber(col.Position.Row)
    const detail = maintenance.Details?.find(
      (d) => column.isEqualTo(d.Panel?.Position.Column) && row.isEqualTo(d.Panel?.Position.Row)
    )

    if (detail) {
      maintenanceAction = getAction(detail.HistoryAction)
      if (
        (mode === DeckMode.maintenance && maintenanceAction.id === actions.NoChange.id) ||
        (mode === DeckMode.preMaintenance &&
          (detail.WorkingDepth || detail.WorkingDepth === 0 || detail.ApertureWidth || detail.ApertureWidth === 0))
      ) {
        colour = getWearColour(detail, theme)
      } else if (mode !== DeckMode.preMaintenance && maintenanceAction.id !== actions.NoChange.id) {
        colour = 'white'
      }
    }
  } else if (mode === DeckMode.heatMap) {
    const panelHeatData = heatMapData.Panels.find(
      (p) => p.Column === col?.Position?.Column && p.Row === col?.Position?.Row
    )

    const wearCount = new BigNumber(panelHeatData?.WearCount ?? 0)
    const total = heatMapData.Total.isEqualTo(0) ? new BigNumber(1) : heatMapData.Total
    const heatCount = total.minus(wearCount).dividedBy(total).times(100).toNumber()
    colour = pickHeatColour(heatCount, theme)
  }

  return (
    <Box
      data-selection={isSelected}
      data-panel-type="DeckPanels"
      ref={ref}
      data-item={JSON.stringify(col)}
      onClick={(e) => {
        e.stopPropagation()
        onClick(col)
      }}>
      {maintenanceAction &&
      maintenanceAction.id !== actions.NoChange.id &&
      (mode === DeckMode.maintenance || mode === DeckMode.postMaintenance) ? (
        <img
          src={`${maintenanceAction.svg}`}
          style={{
            border: '1px solid black',
            height: `${height}`,
            width: `${width}`,
            verticalAlign: 'top'
          }}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: `${colour}`,
            border: '1px solid black',
            width: { width },
            height: { height },
            '&:hover': {
              backgroundColor: interactionColour
            }
          }}
        />
      )}
    </Box>
  )
}

export const DeckRow = ({
  row,
  materialSelected,
  kits,
  mode,
  scale,
  maintenance,
  heatMapData,
  rowIndex,
  onPanelClick,
  selected
}) => {
  return (
    <Stack direction="row" sx={{ height: '1em' }}>
      {row.map((col, j) => {
        return (
          <RowItem
            key={`panel-${rowIndex}-${j}`}
            col={col}
            colIndex={j}
            heatMapData={heatMapData}
            kits={kits}
            maintenance={maintenance}
            materialSelected={materialSelected}
            mode={mode}
            onClick={onPanelClick}
            rowIndex={rowIndex}
            scale={scale}
            selected={selected}
          />
        )
      })}
    </Stack>
  )
}
