//various constant names used for Mainteance across the system.
export const datePerformedName = 'Date Performed'
export const tonnageName = 'Tonnage'
export const descriptionName = 'Maintenance Description'
export const preServiceName = 'Pre Service Comments'
export const postServiceName = 'Post Service Comments'
export const deckRailsName = 'Deck Rails Comments'
export const crossbeamName = 'Crossbeam Comments'
export const recommendationName = 'Recommendations'
export const includeDeckRails = 'IncludeDeckRails'
export const includeCrossbeams = 'IncludeCrossbeams'
export const includeRecommendations = 'IncludeRecommendations'
export const photoKeysName = 'PhotoKeys'
export const dateNextShutDownName = 'Next Shutdown Date'
