import React from 'react'
import { Grid, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { DropDown } from './DropDown'

const screeningTypesAndMaterials = {
  'Sizing: Dry, Wet, Scalping, etc': [],
  'Deslime, Drain & Rinse': [
    'Coal',
    'Fine Trash',
    'Course Trash',
    'Iron Ore Course',
    'Oil Sand Trash',
    'Iron Ore Fine'
  ],
  Dewatering: [
    'Coal',
    'Coal Trash',
    'Gold Trash',
    'Oil Sand Fines',
    'Oil Sand Trash',
    'Dense Ore Fines',
    'Water',
    'Stone crushed'
  ]
}

export const screeningTypes = Object.keys(screeningTypesAndMaterials)

export const ProcessEfficiency = ({ efficiencyData, setEfficiencyData }) => {
  return (
    <Grid
      style={{
        marginTop: '1em'
      }}
      container
      item
      direction="column"
      spacing={2}>
      <Grid item>
        <Typography variant="h6">Process Efficiency</Typography>
      </Grid>

      <Grid item>
        <TextField
          onChange={(event) =>
            setEfficiencyData((prev) => ({
              ...prev,
              openArea: event.target.value
            }))
          }
          style={{
            width: '100%'
          }}
          label="Original Panel Open Area (%)"
          value={efficiencyData.openArea}
        />
      </Grid>

      <Grid item>
        <DropDown
          data={Object.keys(screeningTypesAndMaterials).map((screeningType) => ({
            Id: screeningType,
            Name: screeningType
          }))}
          dropDownKey="screeningType"
          label="Screening Type"
          value={efficiencyData.screeningType}
          onChange={(val) =>
            setEfficiencyData((prev) => ({
              ...prev,
              screeningType: val
            }))
          }
        />
      </Grid>
      <Grid item>
        <DropDown
          data={screeningTypesAndMaterials[efficiencyData.screeningType].map((screeningType) => ({
            Id: screeningType,
            Name: screeningType
          }))}
          dropDownKey="particleType"
          label="Material"
          value={efficiencyData.particleType}
          onChange={(val) =>
            setEfficiencyData((prev) => ({
              ...prev,
              particleType: val
            }))
          }
          enabled={screeningTypesAndMaterials[efficiencyData.screeningType].length > 0}
        />
      </Grid>
    </Grid>
  )
}
