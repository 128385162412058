/**
 *
 * "Simplify B.O.M. table toexport to a .DocX document"
 *
 * @file   ReportBomTable.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import { otherName, panelName, sideLinerName } from 'pages/reporting/common/panelNames'
import { changeColourShade, colourNameToHex } from 'common/colours'

function ReportBomTable({ kits }) {
  /**
   * Create a simplified B.O.M. table from kits to export to .DocX document
   *
   * @function
   *
   * @param {object[]} kits - Array of kit material objects
   *
   * @returns {object} - A B.O.M. table with kit list
   */
  const sideLinersKits = kits.filter((k) => k.Type === sideLinerName)
  const panelKits = kits.filter((k) => k.Type === panelName)
  const otherKits = kits.filter((k) => k.Type === otherName)
  const cellStyling = {
    fontFamily: 'arial',
    borderBottom: '1px solid #A08C64',
    fontWeight: 'normal',
    padding: '0.25em 0'
  }

  function getRow(kit) {
    const colour = changeColourShade(colourNameToHex(kit.Colour), 40)
    return (
      <tr
        key={kit.Material}
        style={{
          backgroundColor: `${colour}`,
          padding: '0 1em',
          color: '#000000',
          borderBottom: '1px solid #A08C64',
          textAlign: 'left'
        }}>
        <th style={cellStyling}>{kit.Item}</th>
        <th style={cellStyling}>{kit.Quantity}</th>
        <th style={cellStyling}>{kit.Material}</th>
        <th style={cellStyling}>{kit.Description}</th>
        <th style={cellStyling}>{kit.CustomerStockCode}</th>
      </tr>
    )
  }

  function getSplitter(title) {
    return (
      <tr>
        <td
          colSpan={5}
          style={{
            backgroundColor: '#ffffff',
            color: '#000000',
            borderBottom: '1px solid #A08C64',
            paddingLeft: '5px'
          }}>
          {title}
        </td>
      </tr>
    )
  }

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead style={{ backgroundColor: '#EBE6DC' }}>
        <tr>
          <th
            style={{
              borderBottom: '1px solid #A08C64',
              borderTop: '1px solid #A08C64',
              textAlign: 'left',
              fontFamily: 'arial',
              paddingLeft: '5px'
            }}>
            Item
          </th>
          <th
            style={{
              borderBottom: '1px solid #A08C64',
              borderTop: '1px solid #A08C64',
              textAlign: 'left',
              fontFamily: 'arial',
              paddingLeft: '5px'
            }}>
            Quantity
          </th>
          <th
            style={{
              borderBottom: '1px solid #A08C64',
              borderTop: '1px solid #A08C64',
              textAlign: 'left',
              fontFamily: 'arial',
              paddingLeft: '5px'
            }}>
            Material
          </th>
          <th
            style={{
              borderBottom: '1px solid #A08C64',
              borderTop: '1px solid #A08C64',
              textAlign: 'left',
              fontFamily: 'arial',
              paddingLeft: '5px'
            }}>
            Description
          </th>
          <th
            style={{
              borderBottom: '1px solid #A08C64',
              borderTop: '1px solid #A08C64',
              textAlign: 'left',
              fontFamily: 'arial',
              paddingLeft: '5px'
            }}>
            Code
          </th>
        </tr>
      </thead>
      <tbody>
        {getSplitter('Panels')}
        {panelKits.map((kit) => {
          return getRow(kit)
        })}
        {getSplitter('Sideliners')}
        {sideLinersKits.map((kit) => {
          return getRow(kit)
        })}
        {getSplitter('Others')}
        {otherKits.map((kit) => {
          return getRow(kit)
        })}
      </tbody>
    </table>
  )
}

export default ReportBomTable
