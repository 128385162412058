/**
 *
 * "CRUD (Create, read, update, and delete) functions for Users in Cognito"
 *
 * @file   adminDatastore.js
 * @author Lateral
 * @since  2023
 */

import { Auth, API } from 'aws-amplify'
import { useQuery } from '@tanstack/react-query'

const apiName = 'AdminQueries'

const unauthorisedIntercepter = async (apiCallFunc) => {
  let retry = 0
  try {
    return await apiCallFunc()
  } catch {
    if (retry === 0) {
      retry++
      return await apiCallFunc((await Auth.currentSession()).getAccessToken().getJwtToken())
    }
  }
}

const defaultHeader = async (accessToken = undefined) => {
  return {
    'Content-Type': 'application/json',
    Authorization: accessToken ?? `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  }
}

export const listUsers = async (accesstoken = undefined) => {
  /**
   *
   * Get user list
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.get(apiName, '/listUsersExtended', {
      headers: await defaultHeader(newAccessToken ?? accesstoken)
    })
  })
}

export const loadAndExtendUsers = async (accesstoken = undefined) => {
  /**
   *
   * Load user list and extend by user roles
   *
   * @function
   * @async
   * @returns {object} - Extended user list
   */
  return unauthorisedIntercepter(async (newAccessToken) => {
    const userResult = await listUsers(newAccessToken ?? accesstoken)
    return Object.keys(userResult).map((k) => userResult[k])
  })
}

export const listGroups = async (accesstoken = undefined) => {
  /**
   *
   * Get user group list
   *
   * @function
   * @async
   * @returns {object} - Group list
   */

  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.get(apiName, '/listGroups', {
      headers: await defaultHeader(newAccessToken ?? accesstoken)
    })
  })
}

export const createUser = async (email, firstName, lastName, companyName, sites, accessToken = undefined) => {
  /**
   *
   * Create new user in datastore
   *
   * @param {string} email - Email of new user
   * @param {string} firstName - New user's first name
   * @param {string} lastName - New user's last name
   * @param {string} companyName - New user's company name
   *
   *
   * @function
   * @async
   * @returns {object} - Api response
   */

  return unauthorisedIntercepter(async (newAccessToken) => {
    return await API.post(apiName, '/adminCreateUser', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: {
        username: email,
        attributes: [
          {
            Name: 'given_name',
            Value: firstName
          },
          {
            Name: 'family_name',
            Value: lastName
          },
          {
            Name: 'email',
            Value: email
          },
          {
            Name: 'dev:custom:customerName_',
            Value: companyName
          },
          {
            Name: 'dev:custom:sites_',
            Value: sites
          }
        ]
      }
    })
  })
}

export const adminUpdateUserAttributes = async (
  userName,
  firstName,
  lastName,
  companyNames,
  sites,
  accessToken = undefined
) => {
  /**
   *
   * Update user in datastore
   *
   * @param {string} userName - User id/usrename
   * @param {string} firstName - New user's first name
   * @param {string} lastName - New user's last name
   *
   *
   * @function
   * @async
   * @returns {object} - Api response
   */

  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.post(apiName, '/adminUpdateUserAttributes', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: {
        username: userName,
        attributes: [
          {
            Name: 'given_name',
            Value: firstName
          },
          {
            Name: 'family_name',
            Value: lastName
          },
          {
            Name: 'dev:custom:customerName_',
            Value: companyNames
          },
          {
            Name: 'dev:custom:sites_',
            Value: sites
          }
        ]
      }
    })
  })
}

export const addUserToGroup = async (userName, groupName, accessToken = undefined) => {
  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.post(apiName, '/addUserToGroup', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: {
        username: userName,
        groupname: groupName
      }
    })
  })
}

export const removeUserFromGroup = async (userName, groupName, accessToken = undefined) => {
  /**
   *
   * Add a user to a group
   *
   * @param {string} userName - User id/usrename
   * @param {string} groupName - Group name of the user
   *
   *
   * @function
   * @async
   * @returns {object} - Api response
   */

  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.post(apiName, '/removeUserFromGroup', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: {
        username: userName,
        groupname: groupName
      }
    })
  })
}

export const disableUser = async (userName, accessToken = undefined) => {
  /**
   *
   * Disable a user
   *
   * @param {string} userName - User id/usrename
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.post(apiName, '/disableUser', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: {
        username: userName
      }
    })
  })
}

export const enableUser = async (userName, accessToken = undefined) => {
  /**
   *
   * Enables a user
   *
   * @param {string} userName - User id/usrename
   *
   * @function
   * @async
   * @returns {object} - Api response
   */
  return unauthorisedIntercepter(async (newAccessToken) => {
    return API.post(apiName, '/enableUser', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: {
        username: userName
      }
    })
  })
}

export const useGetUsers = (accessToken = undefined) => {
  /**
   *
   * Get extended role of the user in admin access
   *
   *
   * @function
   * @async
   * @returns {object[]} - Extended user list
   */
  const usersQuery = useQuery(['users'], () => loadAndExtendUsers(accessToken), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true
  })

  const value = {
    usersQuery
  }
  return value
}

export const listMaterials = async (accessToken = undefined) => {
  return unauthorisedIntercepter(async (newAccessToken) =>
    API.get(apiName, '/materials', {
      headers: await defaultHeader(newAccessToken ?? accessToken)
    })
  )
}

export const updateMaterial = async (id, data, accessToken = undefined) => {
  return unauthorisedIntercepter(async (newAccessToken) =>
    API.patch(apiName, `/materials/${id}`, {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: data
    })
  )
}

export const saveMaterial = async (data, accessToken = undefined) => {
  return unauthorisedIntercepter(async (newAccessToken) =>
    API.post(apiName, '/materials', {
      headers: await defaultHeader(newAccessToken ?? accessToken),
      body: data
    })
  )
}
