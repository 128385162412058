import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { styled, useTheme } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const MaintenanceDialog = React.memo(
  function WearAppDialog_({ title, Actions, open, maxWidth, color, saveButtonProps, onCancel, isLoading, children }) {
    const theme = useTheme()
    const StyledDialog = styled(Dialog)(() => [
      {
        '& .MuiPaper-root': {
          background: theme.palette.supporting.black
        }
      },
      isMobile
        ? {
            '& .MuiPaper-root': {
              width: '100%',
              margin: '0'
            }
          }
        : null
    ])

    return (
      <StyledDialog open={open} fullWidth maxWidth={maxWidth}>
        <DialogTitle
          variant="h5"
          color={color ?? 'secondary'}
          sx={{
            zIndex: 100000
          }}>
          {title}
        </DialogTitle>

        <DialogContent>{children}</DialogContent>
        {!!Actions && <DialogActions>{Actions}</DialogActions>}
        {!!saveButtonProps && (
          <DialogActions>
            <Button disabled={isLoading} variant="outlined" color="secondary" onClick={() => onCancel?.(undefined)}>
              Cancel
            </Button>
            <LoadingButton loading={isLoading} variant="contained" color="secondary" {...saveButtonProps}>
              Save
            </LoadingButton>
          </DialogActions>
        )}
      </StyledDialog>
    )
  },
  (prev, current) =>
    prev.title === current.title &&
    prev.maxWidth === current.maxWidth &&
    prev.open === current.open &&
    prev.isLoading === current.isLoading
)
