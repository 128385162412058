/**
 *
 * "Wear menu input modal window and actions of input values"
 *
 * @file   WearInput.js
 * @author Lateral
 * @since  2023
 */

import { Box, Grid, OutlinedInput, Stack, Typography } from '@mui/material'
import BigNumber from 'bignumber.js'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import dayjs from 'dayjs'
import React from 'react'
import { useForm } from 'react-hook-form'

const titleStyling = {
  marginBottom: '0.2em'
}

const OriginalDepth = 'OriginalDepth'
const DepthName = 'WorkingDepth'
const DepthPercentage = 'DepthPercentage'
const OriginalWidth = 'OriginalWidth'
const WidthName = 'ApertureWidth'
const WidthPercentage = 'WidthPercentage'
const defaultPercentage = 100

/*
 *Modal for inputting wear manually.
 */
export function WearInput({ data, onSubmit, panel }) {
  /**
   * Main finction of wear input
   * @function
   *
   * @param {object} data - Deck revision and maintenance data
   * @param {} onSubmit - Action on submitting the form
   *
   * @returns An mpdal form for panel wear inputs
   */
  const deckRevision = data.deckRevision
  const maintenance = data.maintenance
  const { database } = useCurrentDeckContext()

  const existing = maintenance.Details.find((d) =>
    panel.Position
      ? d.Panel?.Position.Column === panel.Position.Column && d.Panel?.Position.Row === panel.Position.Row
      : d.SideLiner?.StartPosition === panel.StartPosition && d.SideLiner?.Side === panel.Side
  )

  const originalDepth =
    existing?.OriginalWorkingDepth ||
    (deckRevision.Kits.find((k) => k.MaterialNumber === panel?.MaterialNumber)?.WorkingDepth ?? 0)

  const originalWidth =
    existing?.OriginalApertureWidth ??
    deckRevision.Kits.find((k) => k.MaterialNumber === panel?.MaterialNumber)?.ApertureWidth ??
    0

  const defaultValues = {
    [OriginalDepth]: originalDepth,
    [DepthName]: existing?.WorkingDepth ?? originalDepth,
    [DepthPercentage]: existing
      ? new BigNumber(existing[DepthName])
          .dividedBy(existing.OriginalWorkingDepth)
          .times(100)
          .decimalPlaces(2)
          .toNumber()
      : 100,
    [OriginalWidth]: originalWidth,
    [WidthName]: existing?.ApertureWidth ?? originalWidth,
    [WidthPercentage]: existing
      ? new BigNumber(existing[WidthName])
          .dividedBy(existing.OriginalApertureWidth)
          .times(100)
          .decimalPlaces(2)
          .toNumber()
      : 100
  }

  const previous = getPreviousHistory()

  const { handleSubmit, register, setValue, getValues, watch } = useForm({ defaultValues })

  const watchOriginalDepth = watch(OriginalDepth, originalDepth)
  // const watchOriginalWidth = watch(OriginalWidth, originalWidth)
  const watchDepth = watch(DepthName, 1)

  //the following functions contain all the logic to keep every input synced up.
  function onChangeDepth(value) {
    /**
     * Update depth percentage based on input depth value
     * @function
     *
     * @param {number} value - Depth input
     *
     */
    let depth = new BigNumber(value)
    const original = getValues(OriginalDepth)
    if (depth.isLessThan(0)) {
      depth = new BigNumber(0)
      setValue(DepthName, 0)
    } else if (depth.isGreaterThan(original)) {
      depth = new BigNumber(original)
      setValue(DepthName, original)
    }
    const result = new BigNumber(depth).dividedBy(original).times(100).decimalPlaces(2).toNumber()
    setValue(DepthPercentage, isNaN(result) ? defaultPercentage : result)
  }

  // function onChangeWidth(value) {
  //   /**
  //    * Update width percentage based on input width value
  //    * @function
  //    *
  //    * @param {number} value - Width input
  //    *
  //    */
  //   let width = new BigNumber(value)
  //   const original = getValues(OriginalWidth)
  //   if (width.isLessThan(0)) {
  //     width = new BigNumber(0)
  //     setValue(WidthName, 0)
  //   } else if (width.isGreaterThan(original)) {
  //     width = new BigNumber(original)
  //     setValue(WidthName, original)
  //   }
  //   const result = new BigNumber(width).dividedBy(original).times(100).decimalPlaces(2).toNumber()
  //   setValue(WidthPercentage, isNaN(result) ? defaultPercentage : result)
  // }

  function onChangeDepthPercentage(value) {
    /**
     * Update working depth based on DepthPercentage input
     * In this function depth indicating DepthPercentage input value
     * @function
     *
     * @param {number} value - DepthPercentage input
     *
     */
    let depth = new BigNumber(value)
    const original = getValues(OriginalDepth)
    if (depth.isLessThan(0)) {
      depth = new BigNumber(0)
    } else if (depth.isGreaterThan(100)) {
      depth = new BigNumber(100)
    }

    const result = new BigNumber(original).dividedBy(100).times(depth).decimalPlaces(2).toNumber()
    setValue(DepthPercentage, depth.toNumber())
    setValue(DepthName, isNaN(result) ? original : result)
  }

  // function onChangeWidthPercentage(value) {
  //   /**
  //    * Update aperature width based on WidthPercentag input
  //    * In this function width indicating WidthPercentag input value
  //    * @function
  //    *
  //    * @param {number} value - WidthPercentag input
  //    *
  //    */
  //   let width = new BigNumber(value)
  //   const original = getValues(OriginalWidth)
  //   if (width.isLessThan(0)) {
  //     width = new BigNumber(0)
  //   } else if (width.isGreaterThan(100)) {
  //     width = new BigNumber(100)
  //   }

  //   const result = new BigNumber(original).dividedBy(100).times(width).decimalPlaces(2).toNumber()
  //   setValue(WidthPercentage, width.toNumber())
  //   setValue(WidthName, isNaN(result) ? original : `${result}`)
  // }

  function onChangeOriginalDepth(value) {
    /**
     * Update DepthPercentage based on change in OriginalDepth input
     * @function
     *
     * @param {number} value - OriginalDepth input
     *
     */
    let original = new BigNumber(value)
    const depth = new BigNumber(getValues(DepthName) ?? 0)
    if (original.isLessThan(0)) {
      original = new BigNumber(0)
    } else if (original.isLessThan(depth)) {
      original = new BigNumber(depth)
    }

    const result = new BigNumber(depth).dividedBy(original).times(100).decimalPlaces(2).toNumber()
    setValue(DepthPercentage, result)
    setValue(OriginalDepth, original)
  }

  /*
   *
   */
  function getPreviousHistory() {
    /**
     * Searches for previous wear entries from Maintenance history.
     * if found returns previous origianl, and working width, and aperature
     * @function
     *
     * @returns {object} if previous value found then previous Working depth,
     * Working width, original depth, and original width. else default values
     *
     */
    const first = panel

    if (first) {
      const panel = first.Position ? first : null
      const sideliner = first.StartPosition !== null && first.StartPosition !== undefined ? first : null
      const histories = database.deckRevisionHistories
        .filter(
          (d) =>
            d.RevisionId === deckRevision.id &&
            d.Details?.find(
              (d) =>
                (panel &&
                  d.Panel?.Position.Column === panel.Position.Column &&
                  d.Panel?.Position.Row === panel.Position.Row) ||
                (sideliner &&
                  d.SideLiner?.StartPosition === sideliner.StartPosition &&
                  d.SideLiner?.Side === sideliner.Side)
            )
        )
        .sort((a, b) => (dayjs(b.DatePerformed ?? b.createdAt).isAfter(a.DatePerformed ?? a.createdAt) ? 1 : -1))

      const latest = histories[0]
      const previousDetail = latest?.Details.find(
        (d) =>
          (panel &&
            d.Panel?.Position.Column === panel.Position.Column &&
            d.Panel?.Position.Row === panel.Position.Row) ||
          (sideliner && d.SideLiner?.StartPosition === sideliner.StartPosition && d.SideLiner?.Side === sideliner.Side)
      )

      if (previousDetail) {
        return {
          [DepthName]: previousDetail[DepthName],
          [OriginalDepth]: previousDetail.OriginalWorkingDepth,
          [WidthName]: previousDetail[WidthName],
          [OriginalWidth]: previousDetail.OriginalApertureWidth
        }
      }
    }

    return {
      [DepthName]: originalDepth,
      [OriginalDepth]: originalDepth,
      [WidthName]: originalWidth,
      [OriginalWidth]: originalWidth
    }
  }

  return (
    <form id="form" style={{ height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} sx={{ height: '100%' }}>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography variant="h5" component="h5" sx={titleStyling}>
              Original Working Depth:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <OutlinedInput
              id={OriginalDepth}
              name={OriginalDepth}
              color="secondary"
              inputProps={{ type: 'number', min: `${watchDepth}`, step: 'any' }}
              sx={{ width: '6em' }}
              {...register(OriginalDepth, { onBlur: (e) => onChangeOriginalDepth(e.target.value) })}
              disabled={!!previous[OriginalDepth]}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid container item xs={6} sx={{ alignItems: 'end' }}>
            <Typography variant="h5" component="h5" sx={titleStyling}>
              Recorded:
            </Typography>
          </Grid>
          <Grid container item xs={6} sx={{ alignItems: 'center' }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <Box>
                <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                  NEW
                </Typography>
                <OutlinedInput
                  id={DepthName}
                  name={DepthName}
                  color="secondary"
                  inputProps={{ type: 'number', min: '0', max: `${watchOriginalDepth}`, step: 'any' }}
                  sx={{ width: '6em' }}
                  {...register(DepthName, { onBlur: (e) => onChangeDepth(e.target.value) })}
                />
              </Box>
              <Box>
                <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                  PREV.
                </Typography>
                <OutlinedInput
                  value={`${previous[DepthName] ?? watchOriginalDepth}mm`}
                  color="secondary"
                  disabled
                  sx={{ width: '6em' }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6} sx={{ alignItems: 'end' }}>
            <Typography variant="h5" component="h5" sx={titleStyling}>
              Percentage Left:
            </Typography>
          </Grid>
          <Grid container item xs={6} sx={{ alignItems: 'center' }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <OutlinedInput
                color="secondary"
                inputProps={{ type: 'number', min: '0', max: '100', step: 'any' }}
                sx={{ width: '6em' }}
                {...register(DepthPercentage, { onBlur: (e) => onChangeDepthPercentage(e.target.value) })}
              />
              <OutlinedInput
                value={`${new BigNumber(previous[DepthName] ?? previous[OriginalDepth])
                  .dividedBy(previous[OriginalDepth] > 0 ? previous[OriginalDepth] : 1)
                  .times(100)
                  .toNumber()}%`}
                color="secondary"
                disabled
                sx={{ width: '6em' }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {/* <Stack spacing={1} sx={{ height: '100%', marginTop: '3em' }}>
        <Grid container>
          <Grid container item xs={6} sx={{ alignItems: 'end' }}>
            <Typography variant="h5" component="h5" sx={titleStyling}>
              Recorded:
            </Typography>
          </Grid>
          <Grid container item xs={6} sx={{ alignItems: 'center' }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <Box>
                <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                  NEW
                </Typography>
                <OutlinedInput
                  id={WidthName}
                  name={WidthName}
                  color="secondary"
                  inputProps={{ type: 'number', min: '0', max: `${watchOriginalWidth}`, step: 'any' }}
                  fullWidth
                  sx={{ width: '6em' }}
                  {...register(WidthName, { onBlur: (e) => onChangeWidth(e.target.value) })}
                />
              </Box>
              <Box>
                <Typography fontSize={12} sx={{ opacity: '0.5' }}>
                  PREV.
                </Typography>
                <OutlinedInput value={`${previous[WidthName]}mm`} color="secondary" disabled sx={{ width: '6em' }} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6} sx={{ alignItems: 'end' }}>
            <Typography variant="h5" component="h5" sx={titleStyling}>
              Percentage Left:
            </Typography>
          </Grid>
          <Grid container item xs={6} sx={{ alignItems: 'center' }}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <OutlinedInput
                color="secondary"
                inputProps={{ type: 'number', min: '0', max: '100', step: 'any' }}
                sx={{ width: '6em' }}
                {...register(WidthPercentage, { onBlur: (e) => onChangeWidthPercentage(e.target.value) })}
              />
              <OutlinedInput
                value={`${new BigNumber(previous[WidthName] ?? previous[OriginalWidth])
                  .dividedBy(previous[OriginalWidth] > 0 ? previous[OriginalWidth] : 1)
                  .times(100)
                  .toNumber()}%`}
                color="secondary"
                disabled
                sx={{ width: '6em' }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack> */}
    </form>
  )
}
