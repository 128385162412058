/**
 *
 * "Generates a kit table for .DocX export"
 *
 * @file   data.js
 * @author Lateral
 * @since  2023
 */
import ReportBomTable from '../components/common/ReportBomTable'
import { otherName, panelName, sideLinerName } from './panelNames'
import { renderToStaticMarkup } from 'react-dom/server'

export function getKitTable(deckRevision) {
  /**
   * Create a B.O.M. table to export to .DocX document
   *
   * @function
   *
   * @param {object} deckRevision - Deck revision object
   * @param {object[]} deckRevision.kits - Array of kit material objects
   * @param {object[]} deckRevision.Panels - Array of deck panel objects
   * @param {object[]} deckRevision.SideLiners - Array of deck sideliner objects
   *
   * @returns {object} - A B.O.M. table with kit list
   */

  let kitList = []

  for (const kit of deckRevision.Kits) {
    const panels = deckRevision.Panels.filter((p) => p.MaterialNumber === kit.MaterialNumber)
    const sideLiners = deckRevision.SideLiners.filter((s) => s.MaterialNumber === kit.MaterialNumber)

    if (panels.length) {
      kitList.push({
        Type: panelName,
        Item: 0,
        Quantity: panels.length,
        Material: kit.MaterialDescription ? kit.MaterialNumber : '',
        Description: kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber,
        Code: kit.CustomerStockCode,
        Colour: kit.Colour
      })
    } else if (sideLiners.length) {
      kitList.push({
        Type: sideLinerName,
        Item: 0,
        Quantity: sideLiners.length,
        Material: kit.MaterialDescription ? kit.MaterialNumber : '',
        Description: kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber,
        Code: kit.CustomerStockCode,
        Colour: kit.Colour
      })
    } else {
      kitList.push({
        Type: otherName,
        Item: 0,
        Quantity: 0,
        Material: kit.MaterialDescription ? kit.MaterialNumber : '',
        Description: kit.MaterialDescription ? kit.MaterialDescription : kit.MaterialNumber,
        Code: kit.CustomerStockCode,
        Colour: kit.Colour
      })
    }
  }

  kitList = kitList.sort((a, b) => {
    if (a.Type === panelName && b.Type !== panelName) {
      return -1
    }
    if (a.Type !== panelName && b.Type === panelName) {
      return 1
    }
    if (a.Type === sideLinerName && b.Type === otherName) {
      return -1
    }
    if (a.Type === otherName && b.Type === sideLinerName) {
      return 1
    }

    if (a.Material > b.Material) {
      return 1
    }
    return -1
  })

  for (let i = 0; i < kitList.length; i++) {
    kitList[i].Item = i + 1
  }

  return renderToStaticMarkup(<ReportBomTable kits={kitList} />)
}
