/**
 *
 * "A Table to view deck revision history"
 *
 * @file   HistoryTable.js
 * @author Lateral
 * @since  2023
 */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { dateFormat } from 'common/dates'
import dayjs from 'dayjs'
import React from 'react'

/*
 *A basic table for viewing a Deck's revision history only.
 */
function HistoryTable({ deckRevisionId, deckRevisions, onRevisionSelected }) {
  /**
   * Generates a deck hystory table with deck revsion in rows
   *
   * @function
   *
   * @param {string} deckRevisionId - Most recent deck revision ID
   * @param {object[]} deckRevisions - List of all deckRevision object of the selected deck
   * @param {string} deckRevisions.ID - Id of the deck revision
   * @param {number} deckRevision.RevisionNumber - Serial number of the revision
   * @param {boolean} deckRevision.IsTrial - If the revision is a trial
   * @param {string} deckRevision.Description - Description of the revision
   * @param {string} deckRevision.createdAt - Date of revision
   * @param {string} deckRevision.CreatedBy - User name of the revision creator
   * @param {} onRevisionSelected - Action on selecting a reison row
   *
   * @returns {object} - A table showing revision history
   */

  const theme = useTheme()

  return (
    <TableContainer
      sx={{
        mb: '1em',
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.palette.supporting.dark
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.supporting.pale,
          borderRadius: 2
        }
      }}>
      <Table stickyHeader sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow>
            <TableCell>Revision</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Reported</TableCell>
            <TableCell>Works Date</TableCell>
            <TableCell>User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deckRevisions.map((deckRevision) => {
            const isHighlighted = deckRevisionId === deckRevision.id
            return (
              <TableRow
                key={deckRevision.id}
                hover
                sx={{ backgroundColor: isHighlighted ? theme.palette.primary.light : '' }}
                onClick={() => onRevisionSelected(deckRevision.id)}>
                <TableCell>{deckRevision.RevisionNumber}</TableCell>
                <TableCell>{deckRevision.IsTrial ? 'Trial' : 'Revision'}</TableCell>
                <TableCell>{deckRevision.Description}</TableCell>
                <TableCell>{dayjs(deckRevision.createdAt).format(dateFormat)}</TableCell>
                <TableCell>{dayjs(deckRevision.createdAt).format(dateFormat)}</TableCell>
                <TableCell>{deckRevision.CreatedBy}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default HistoryTable
