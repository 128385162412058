/**
 *
 * "Form to create/update decks"
 *
 * @file   CreateDeckForm.js
 * @author Lateral
 * @since  2023
 */
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Grid from '@mui/material/Grid'
import { useForm, Controller } from 'react-hook-form'
import { Select, MenuItem, Typography } from '@mui/material'

const DeckLevels = ['TopDeck', 'MiddleDeck', 'BottomDeck']
const DefaultString = ''

export function CreateDeckForm({ onSubmit, data, includeSize = true }) {
  /**
   * Genberates a form to create new deck/ update existing deck
   *
   * @function
   *
   * @param {} onSubmit - Action on submiting the form
   * @param {object} data - Screen data of deck
   * @param {string} data.EquipmentId - Screen name
   * @param {object} data.ScreenId - Screen ID
   * @param {boolean} includeSize - Include size of the deck in the form Yes:True/No:False
   *
   *
   * @returns {object} - React app page
   */
  const defaultValues = data
  const { register, handleSubmit, control } = useForm({ defaultValues })

  return (
    <form id="form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Equipment Id
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="EquipmentId"
            name="EquipmentId"
            placeholder="Input Equipment Id"
            color="secondary"
            fullWidth
            disabled
            {...register('EquipmentId')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Material Number
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="MaterialNumber"
            name="MaterialNumber"
            placeholder="Input Material Number"
            color="secondary"
            required
            fullWidth
            {...register('MaterialNumber')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Serial Number
          </Typography>
        </Grid>
        <Grid item md={9}>
          <OutlinedInput
            id="SerialNumber"
            name="SerialNumber"
            placeholder="Input Serial Number"
            color="secondary"
            fullWidth
            {...register('SerialNumber')}
          />
        </Grid>

        <Grid item md={3}>
          <Typography variant="h5" component="h5">
            Deck Level
          </Typography>
        </Grid>
        <Grid item md={9}>
          <Controller
            name="DeckLevel"
            defaultValue={DefaultString}
            control={control}
            render={({ field }) => (
              <Select {...field} color="secondary" fullWidth>
                <MenuItem key={DefaultString} value={DefaultString}>
                  None
                </MenuItem>
                {DeckLevels.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        {includeSize ? (
          <>
            <Grid item md={3}>
              <Typography variant="h5" component="h5">
                Columns
              </Typography>
            </Grid>
            <Grid item md={9}>
              <OutlinedInput
                id="Columns"
                name="Columns"
                placeholder="1"
                color="secondary"
                fullWidth
                inputProps={{ type: 'number', min: '1' }}
                {...register('Columns')}
              />
            </Grid>

            <Grid item md={3}>
              <Typography variant="h5" component="h5">
                Rows
              </Typography>
            </Grid>
            <Grid item md={9}>
              <OutlinedInput
                id="Rows"
                name="Rows"
                placeholder="1"
                color="secondary"
                fullWidth
                inputProps={{ type: 'number', min: '1' }}
                {...register('Rows')}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </form>
  )
}
export default CreateDeckForm
