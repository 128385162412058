/**
 *
 * "Provides a mini menu for tables with action like 'edit details'/ 'edit revisons' "
 *
 *
 * @file   ContextMenu.js
 * @author Lateral
 * @since  2023
 */

import React, { useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Menu, MenuItem, ListItemText, IconButton, Typography } from '@mui/material'

export function ContextMenu({ context, menuItems }) {
  /**
   * Create a react element of menu items for the given context with menuItems
   *
   * @function
   * @param {object} context - Context of the table
   * @param {Array} menuItems - Array of menuItem objects:
   * @param {String} menuItems.title - Title of the item
   * @param {boolean} menuItems.condition - Condition to shown in submenu
   * @param {funtions} menuItems.action - Action for onclick
   * @returns {React.ElementType} - React element of table row submenu
   */
  const [anchorEl, setAnchorEl] = useState(null)

  // onclick event of submenu within the given table context
  function handleEvent(event) {
    setAnchorEl(null)
    if (event) {
      event(context)
    }
  }

  return (
    <>
      <IconButton aria-label="submenu" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreHorizIcon sx={{ color: 'white' }}></MoreHorizIcon>
      </IconButton>

      {anchorEl && (
        <Menu id="basic-menu" anchorEl={anchorEl} open={true} onClose={() => handleEvent()}>
          {menuItems.map((menuItem) => {
            if (menuItem.condition !== undefined) {
              if (menuItem.condition === false) {
                return null
              }
            }
            return (
              <MenuItem key={menuItem.title} onClick={() => handleEvent(menuItem.action)}>
                <ListItemText>
                  <Typography color={menuItem.color}>{menuItem.title}</Typography>
                </ListItemText>
              </MenuItem>
            )
          })}
        </Menu>
      )}
    </>
  )
}
export default ContextMenu
