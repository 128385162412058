/**
 *
 * "Granted permission to access scope by user role"
 *
 * @file   PermissionsGate.js
 * @author Lateral
 * @since  2023
 */

import { useCurrentUser } from 'hooks'
import React, { cloneElement } from 'react'
import { log } from 'common'
import { Typography } from '@mui/material'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { Loading } from 'components/Loading'

export const PermissionsGate = ({
  children,
  RenderError = () => <></>,
  errorProps = null,
  requiredScopes = [],
  checkSites = false
}) => {
  /**
   *
   * Used for maintaining the scope logic, and throwing a Forbidden
   * if the User does not have permission.
   *
   * @constant
   * @param {React.ElementType} children - components rquested to access
   * @param {object} roles - Roles of the current user
   * @returns {React.ElementType} - React element to get view permission
   */
  const { currentUser } = useCurrentUser()
  const userScopes = currentUser?.getScopes()
  const { siteId } = useCurrentDeckContext()

  if (currentUser.isAdmin) {
    return <>{children}</>
  }

  if (!userScopes?.length) {
    return <Loading type="circular" message="Loading your permissions ..." />
  }

  const permissionGranted = currentUser?.hasAtLeastOneScope(requiredScopes)

  if (!permissionGranted && !errorProps) {
    log.warn(`Forbidden - Missing scopes: ${requiredScopes.map((s) => s.Name).join(',')} `)
    return <RenderError />
  }

  if (!permissionGranted && errorProps) {
    log.warn(`Forbidden - Missing scopes: ${requiredScopes.map((s) => s.Name).join(',')} `)
    return cloneElement(children, { ...errorProps })
  }

  //check if the user has permission at the Site level for this page
  if (checkSites && !currentUser?.isAdmin) {
    for (const requiredScope of requiredScopes) {
      const userSites = currentUser?.getSitesForScope(requiredScope)
      if (!userSites.includes(siteId)) {
        const splitScopeName = requiredScope.split('.')
        return (
          <Typography>
            You do not have the correct {splitScopeName[0]} permissions for this Site. Please contact support if this is
            a mistake.
          </Typography>
        )
      }
    }
  }

  return <>{children}</>
}

export default PermissionsGate
