import React, { useCallback, useRef, useState } from 'react'
import { useWearAppContext } from '../WearAppContext'
import Webcam from 'react-webcam'
import { FileUpload, CameraAlt, FlipCameraAndroid } from '@mui/icons-material'
import { CircularProgress, Fab, Stack, Typography } from '@mui/material'
import { useCurrentDeckContext } from 'components/currentDeckContext/CurrentDeckContext'
import { useWearAppAnalysisContext } from '../WearAppAnalysisContext'

export const CameraContainer = () => {
  const cameraRef = useRef()
  const { onSelectImageClick, setImage, selectedPanel, photoKeyRef, setIsFile, image } = useWearAppContext()
  const { isConnected } = useWearAppAnalysisContext()
  const { bottomNavDimen, deck, screen, isDesktop } = useCurrentDeckContext()

  const videoConstraints = {
    width: { max: isDesktop ? 3200 : 2400 },
    height: { max: isDesktop ? 2400 : 1600 },
    aspectRatio: 4 / 3
  }
  const inputRef = useRef()
  const [facingMode, setFacingMode] = useState('environment')

  const takePicture = useCallback(() => {
    if (!cameraRef.current) {
      return
    }
    const imageSrc = cameraRef.current?.getScreenshot()

    if (imageSrc) {
      photoKeyRef.current = undefined
      setIsFile(false)
      setImage(imageSrc)
    }
  }, [])

  const onSwitchCameraPressed = useCallback(() => {
    setFacingMode((current) => {
      return current === 'user' ? 'environment' : 'user'
    })
  }, [])

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        position: 'relative'
      }}>
      {!isDesktop && (
        <Stack spacing={1} sx={{ width: '100%', marginBottom: '1em' }}>
          <Stack spacing={1} direction="row">
            <Typography variant="h5" color="secondary">
              Screen:
            </Typography>
            <Typography variant="h5">{screen.Name}</Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <Typography variant="h5" color="secondary">
              Deck:
            </Typography>
            <Typography variant="h5">{`${deck.DeckHeader.DeckLevel} ${deck.DeckHeader.MaterialNumber}`}</Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <Typography variant="h5" color="secondary">
              Panel:
            </Typography>
            <Typography variant="h5">{selectedPanel.MaterialNumber}</Typography>
          </Stack>
        </Stack>
      )}
      {!image && (
        <Webcam
          style={{
            width: '100%',
            flex: !isDesktop ? undefined : '1 1 auto'
          }}
          audio={false}
          ref={cameraRef}
          forceScreenshotSourceSize
          screenshotQuality={1}
          screenshotFormat="image/png"
          videoConstraints={{
            ...videoConstraints,
            facingMode: {
              ideal: facingMode
            }
          }}
        />
      )}

      <div
        style={{
          display: 'flex',
          maxHeight: '8em',
          flex: '0 0 auto',
          paddingBottom: '.5em',
          justifyContent: isConnected ? 'space-around' : 'center',
          alignItems: 'center',
          paddingTop: '.5em',
          backgroundColor: 'rgba(0,0,0, .3)',
          position: !isDesktop ? 'fixed' : 'absolute',
          bottom: bottomNavDimen.height,
          width: '100%',
          marginLeft: !isDesktop ? '-1em' : undefined
        }}>
        {!isConnected && <CircularProgress color="secondary" />}
        {isConnected && (
          <>
            {!isDesktop && (
              <Fab onClick={onSwitchCameraPressed} size="small" style={{ marginRight: '1em' }}>
                <FlipCameraAndroid color="secondary" />
              </Fab>
            )}
            <Fab onClick={takePicture}>
              <CameraAlt color="secondary" />
            </Fab>
            <Fab
              size={!isDesktop ? 'small' : undefined}
              onClick={() => {
                inputRef.current?.click()
              }}>
              <FileUpload color="secondary" />
              <input ref={inputRef} hidden accept="image/*" type="file" onChange={onSelectImageClick} />
            </Fab>
          </>
        )}
      </div>
    </div>
  )
}
